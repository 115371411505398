.bat-modal {
	align-items: center;
	background-color: $dark-90;
	display: none;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: $modal-zindex;
	
	//AEM author mode 
	.authorEditMode & { // sass-lint:disable-line class-name-format
		display: block;
		height: auto;
		position: relative;
		width: auto;
	}
	
	&.active {
		display: flex;
	}
	
	&-content  {
		background-color: $white;
		display: inline-block;
		margin: 0 $gutter;
		max-height: 90%;
		max-width: 90%;
		overflow-y: scroll;
		
		//max-widths
		.sm-max-width & {
			max-width: $max-width-sm;
		}
		
		.md-max-width & {
			max-width: $max-width-md;
		}
		
		.lg-max-width & {
			max-width: $max-width-lg;
		}
		
		.xl-max-width & {
			max-width: $max-width-xl;
		}
		
		@include media-breakpoint-up(lg) {
			overflow-y: auto;
		}

		&-video {
			height: 50.625vw;
			max-height: calc(100vh - 100px);
			max-width: calc(177.7777vh - 177px);
			width: 90vw;
			&-container {
				display: block;
				max-width: 100%;
				position: relative;

				&-content {
					padding-top: 56.25%;
				}
			}
		}
		
		//AEM author mode 
		.authorEditMode & { // sass-lint:disable-line class-name-format
			display: block;
		}
	}
	
	&-close {
		position: absolute;
		right: 20px;
		top: 20px;
		
		i {
			color: $gray;
			font-size: 34px;
		}
	}
}
