// File:           _headline.scss
// Description:    Headline Styles

.bat-headline {
	
	span {
		color: $system-red;
		display: inline;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
	}
	
	&-style {
		
		&.headline1-large-color1,
		&.headline1-color1,
		&.headline2-color1,
		&.headline3-color1,
		&.headline4-color1,
		&.headline5-color1,
		&.headline6-color1 {
			color: $system-red;
			
			span {
				color: $navy;
			}
		}
		&.headline1-large-color2,
		&.headline1-color2,
		&.headline2-color2,
		&.headline3-color2,
		&.headline4-color2,
		&.headline5-color2,
		&.headline6-color2 {
			color: $navy;
			
			span {
				color: $system-red;
			}
		}
		&.headline1-large-color3,
		&.headline1-color3,
		&.headline2-color3,
		&.headline3-color3,
		&.headline4-color3,
		&.headline5-color3,
		&.headline6-color3 {
			color: $system-red;
			
			span {
				color: $navy;
			}
		}
		&.headline1-large-color4,
		&.headline1-color4,
		&.headline2-color4,
		&.headline3-color4,
		&.headline4-color4,
		&.headline5-color4,
		&.headline6-color4 {
			color: $system-red;
			
			span {
				color: $navy;
			}
		}
	}
}

// Alignment Classes
bat-headline-default {
	
	&.m-align-left { 
		
		text-align: left;
	}
	
	&.m-align-right  { 
		text-align: right;
		
		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}
	
	&.m-align-center { 
		text-align: center;
		
		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}
	
	&.d-align-left { 
		
		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}
	
	&.d-align-right { 
		
		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}
	
	&.d-align-center { 
		@include media-breakpoint-up(md) {
			text-align: center;
		}
	}
}

// Headline for News section
.bat-headline-news {
	margin-bottom: 24px;
	@include media-breakpoint-down(md) {
		margin: 0 auto;
		margin-bottom: 18px;
		text-align: center;
	}
}
// View All for News section
.bat-headline-sub {
	padding-right: 0;
	text-align: right;
	@include media-breakpoint-down(md) {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	.bat-cta {
		display: flex;
		justify-content: end;
		@include media-breakpoint-down(md) {
			justify-content: center;
		}
	}
}

// Headline for BlogHub interior page
.bat-headline-blog {
	font-size: 40px;
	line-height: 40px;
	margin-top: 30px;
	padding-bottom: 20px;
}


//headline section
.headline-common-section {
	text-align: center;
	width: 100%;
	max-width: 800px;
	margin: 0 auto 40px;

	&.light {
		color: #fff;

		.bat-headline-style {
			color: #fff !important;
		}
	}

	.text {
		p {
			font-size: 14px;
		}
	}


	@include media-breakpoint-down(sm) {
		padding: 0 10px;
	}
}