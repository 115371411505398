// File:          _search.scss
// Description:   Search Styles

.bat-search {

	&-input {

		&-button {
			height: 38px;
			right: 0;
			top: 0;
			width: 38px;
			
			i {
				font-size: 30px;
				
				&:before {
					font-size: 30px;
				}
			}
		}
	}
	
	&-no-results {
		margin: 1.75rem 0 2.5rem;
	}
	
	&-results {

		ul {
			margin: 1.75rem 0 2.5rem;
			
			li {
				
				a {
					display: block;
					@include font-rem($font-sm, $font-sm + 10);
					text-decoration: underline;
				}
				
				h6 {
					font-weight: 700;
					margin-bottom: 10px;
					text-transform: capitalize;
				}
			}
		}
	}
}
