//FORMS
// Create default variables

.bat {
	// sass-lint:disable-line no-mergeable-selectors
	form {
		// sass-lint:disable-line no-mergeable-selectors
		@include root-prop(--input-active, #{$navy-dark});
		@include root-prop(--input-bg, #{$white});
		@include root-prop(--input-disabled, #F6F6F6);
		@include root-prop(--input-error, #{$system-red});
		@include root-prop(--input-focus, #{$navy});
		@include root-prop(--text-disabled, #A1A6AE);
		@include root-prop(--text-error, #{$system-red});
		@include root-prop(--text-input, #{$black});
		@include root-prop(--text-label, #{#2b363b});
		@include root-prop(--text-note, #{$mid-gray});
		@include root-prop(--text-placeHolder, #{$gray});
	}

	.gray-form {
		@include root-prop(--bg-color, #{$pale-gray});
		// @include root-prop(--input-active, #{$navy-dark});
		@include root-prop(--input-bg, #{$white});
		@include root-prop(--input-disabled, #{$concrete});
		// @include root-prop(--input-error, #{$system-red});
		// @include root-prop(--input-focus, #{$navy});
		// @include root-prop(--text-disabled, #{$black});
		// @include root-prop(--text-error, #{$system-red});
		// @include root-prop(--text-input, #{$black});
		@include root-prop(--text-label, #{$navy-dark});
		// @include root-prop(--text-note, #{$black});
		// @include root-prop(--text-placeHolder, #{$pale-gray}); same as default

		background-color: var(--bg-color);
	}

	.dark-mode {
		form {
			@include root-prop(--input-active, #{$white});
			@include root-prop(--input-bg, #{$dark-gray});
			@include root-prop(--input-disabled, #{$mid-gray});
			@include root-prop(--input-error, #{$system-dark-mode-red});
			@include root-prop(--input-focus, #{$white});
			@include root-prop(--text-disabled, #{$dark-gray});
			@include root-prop(--text-error, #{$system-red});
			@include root-prop(--text-input, #{$white});
			@include root-prop(--text-label, #{$white});
			@include root-prop(--text-note, #{$white});
			@include root-prop(--text-placeHolder, #{$pale-gray});
		}
	}

	// These properties should match those in .dark-mode
	// @media (prefers-color-scheme: dark) {
	// 	.gray-form {
	// 		form {
	// 			@include root-prop(--bg-color, #{$black});
	// 			@include root-prop(--input-active, #{$white});
	// 			@include root-prop(--input-bg, #{$dark-gray});
	// 			@include root-prop(--input-disabled, #{$concrete});
	// 			@include root-prop(--input-error, #{$system-dark-mode-red});
	// 			@include root-prop(--input-focus, #{$white});
	// 			@include root-prop(--text-disabled, #{$pale-gray});
	// 			@include root-prop(--text-error, #{$white});
	// 			@include root-prop(--text-input, #{$white});
	// 			@include root-prop(--text-label, #{$white});
	// 			@include root-prop(--text-note, #{$white});
	// 			@include root-prop(--text-placeHolder, #{$pale-gray});
	// 		}
	// 		.gray-form {
	// 			background-color: var(--bg-color);
	// 		}
	// 	}
	// }
}

@mixin focus-style {
	// duel box-shadows to create desired effect
	border-radius: 1px;
	box-shadow: 0 0 0 5px var(--bg-color), 0 0 0 6px var(--input-focus);
	outline-width: none;
}

.bat {
	// sass-lint:disable-line no-mergeable-selectors
	form {
		// sass-lint:disable-line no-mergeable-selectors
		label {
			@include font-rem(15px, 18px);
			align-items: center;
			color: var(--text-label);
			display: flex;
			font-family: $primary-font;
			margin-bottom: 4px;
			padding: 0;
			a {
				text-decoration: underline
			}
		}

		.error-msg {
			@include font-rem(13px, 16px);
			color: var(--text-error);
			padding: 5px 0;
		}

		.field-note {
			@include font-rem($font-xsm, 18px);
			color: var(--text-note);
		}
		input,
		select,
		textarea {
			transition: border-color 0.4s ease;
		}
		//INPUTS
		input {
			@include font-rem(15px, 20px);
			background-color: var(--input-bg);
			border: 1px solid #CFCFCF;
			border-radius: 4px;
			color: var(--text-input);
			font-family: $primary-font;
			height: 45px;
			margin-bottom: 4px;
			padding: 8px 20px;
			position: relative;
			width: 100%;

			// sass-lint:disable no-important
			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px var(--input-bg) inset !important;
				-webkit-text-fill-color: var(--text-input) !important;

				&:active,
				&:focus,
				&:hover {
					-webkit-box-shadow: 0 0 0 30px var(--input-bg) inset !important;
					-webkit-text-fill-color: var(--text-input) !important;
				}
			}

			&::-webkit-input-placeholder {
				color: var(--text-placeHolder);
			}

			&:-moz-placeholder {
				/* FF 4-18 */
				color: var(--text-placeHolder);
			}

			&::-moz-placeholder {
				/* FF 19+ */
				color: var(--text-placeHolder);
			}

			&:-ms-input-placeholder {
				/* IE 10+ */
				color: var(--text-placeHolder);
			}

			&::-ms-input-placeholder {
				/* Microsoft Edge */
				color: var(--text-placeHolder);
			}

			&::placeholder {
				/* modern browser */
				color: var(--text-placeHolder);
			}

			& + .error-msg {
				display: none;
			}

			&:focus {
				// @include focus-style();
				border-color: #010101;
			}

			&:active {
				border-color: #010101;
				outline: none;
			}

			&.complete {
				border: 1px solid #CFCFCF;
				//border-bottom: 2px solid $light-blue;
			}

			&.error {
				border-color: var(--input-error);
				color: var(--text-error);

				& + .error-msg {
					display: inline-block;
				}
			}

			&:disabled {
				background-color: var(--input-disabled);
				color: var(--text-disabled);
				opacity: 1;
				pointer-events: none;

				&::-webkit-input-placeholder {
					color: var(--text-placeHolder);
				}

				&:-moz-placeholder {
					/* FF 4-18 */
					color: var(--text-placeHolder);
				}

				&::-moz-placeholder {
					/* FF 19+ */
					color: var(--text-placeHolder);
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: var(--text-placeHolder);
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: var(--text-placeHolder);
				}

				&::placeholder {
					/* modern browser */
					color: var(--text-placeHolder);
				}
			}
		}

		//TEXTAREA
		textarea {
			@include font-rem(15px, 20px);
			background-color: var(--input-bg);
			border: 1px solid #CFCFCF;

			color: var(--text-input);
			font-family: $primary-font;
			height: 150px;
			padding: 8px 20px;
			width: 100%;
			border-radius: 4px;

			&::-webkit-input-placeholder {
				color: var(--text-placeHolder);
			}

			&:-ms-input-placeholder {
				color: var(--text-placeHolder);
			}

			&::placeholder {
				color: var(--text-placeHolder);
			}

			& + .error-msg {
				visibility: hidden;
			}

			&:focus {
				border-color: #010101;
				// @include focus-style();
			}

			&:active {
				border-color: #010101;
				outline: none;
			}

			&.complete {
				border: 1px solid #CFCFCF;
				//border-bottom: 2px solid $light-blue;
			}

			&.error {
				border-color: var(--text-error);

				& + .error-msg {
					visibility: visible;
				}
			}

			&:disabled {
				background-color: var(--input-disabled);
				color: var(--text-disabled);
				opacity: 1;
				pointer-events: none;

				&::-webkit-input-placeholder {
					color: var(--text-placeHolder);
				}

				&:-moz-placeholder {
					/* FF 4-18 */
					color: var(--text-placeHolder);
				}

				&::-moz-placeholder {
					/* FF 19+ */
					color: var(--text-placeHolder);
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: var(--text-placeHolder);
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: var(--text-placeHolder);
				}

				&::placeholder {
					/* modern browser */
					color: var(--text-placeHolder);
				}
			}
		}

		//SELECT MENUS
		.select-wrapper {
			position: relative;

			&.wrapper-focused {
				@include focus-style();
			}

			select {
				-moz-appearance: none;
				-webkit-appearance: none;
				appearance: none;

				@include font-rem($font-sm, $font-sm + 6); //14px, 20px
				background-color: var(--input-bg);
				border: 1px solid #CFCFCF;
				color: var(--text-input);
				font-family: $primary-font;
				height: 45px;
				padding: 8px 20px;
				width: 100%;
				border-radius: 4px;
				background-repeat: no-repeat;
				background-position: right 20px center;
				background-image: url('data:image/svg+xml,<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.01453 4.81251L6.56438 3.1324C7.28751 2.34907 8.01086 1.56595 8.73443 0.783037C8.96099 0.538544 9.2594 0.470285 9.55881 0.583223C9.8482 0.692128 10.0337 0.94748 9.99489 1.24441C9.97632 1.38654 9.91545 1.52106 9.81912 1.63286C8.40918 3.17366 6.99456 4.71022 5.57525 6.24254C5.25412 6.58942 4.76758 6.58384 4.44679 6.23757C3.02258 4.69429 1.59972 3.15008 0.178187 1.60494C-0.0854672 1.31763 -0.0520509 0.898457 0.248696 0.663273C0.564814 0.415057 0.993211 0.45322 1.27157 0.754802C2.46052 2.04345 3.64914 3.33242 4.83742 4.62169C4.88955 4.68126 4.94469 4.73804 5.01453 4.81251Z" fill="rgb(142,142,142)"/></svg>');
				padding-right: 35px;
				&:disabled {
					background-color: var(--input-disabled);
					color: var(--text-disabled);
					opacity: 1;
					pointer-events: none;
				}

				&:focus {
					outline: none;
					border-color: var(--input-active);
				}
				&:active {
					outline: none;
					border-color: var(--input-active);
				}

				&.error {
					border-color: var(--input-error);
				}

				&.active {
					border-color: var(--input-active);
				}

				&.complete {
					border: 1px solid #CFCFCF;
					//border-bottom: 2px solid $light-blue;
				}

				&::-ms-expand {
					display: none;
				}
			}

			& + .error-msg {
				visibility: hidden;
			}

			&.complete {
				border: 1px solid #CFCFCF;
				//border-bottom: 2px solid $light-blue;
			}

			&.disabled {
				opacity: 1;
				pointer-events: none;

				select {
					background-color: var(--input-disabled);
					color: var(--text-disabled);
					background-image: url('data:image/svg+xml,<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.01453 4.81251L6.56438 3.1324C7.28751 2.34907 8.01086 1.56595 8.73443 0.783037C8.96099 0.538544 9.2594 0.470285 9.55881 0.583223C9.8482 0.692128 10.0337 0.94748 9.99489 1.24441C9.97632 1.38654 9.91545 1.52106 9.81912 1.63286C8.40918 3.17366 6.99456 4.71022 5.57525 6.24254C5.25412 6.58942 4.76758 6.58384 4.44679 6.23757C3.02258 4.69429 1.59972 3.15008 0.178187 1.60494C-0.0854672 1.31763 -0.0520509 0.898457 0.248696 0.663273C0.564814 0.415057 0.993211 0.45322 1.27157 0.754802C2.46052 2.04345 3.64914 3.33242 4.83742 4.62169C4.88955 4.68126 4.94469 4.73804 5.01453 4.81251Z" fill="rgb(0,0,0)"/></svg>');
				}

				&::-webkit-input-placeholder {
					color: var(--text-placeHolder);
				}

				&:-moz-placeholder {
					/* FF 4-18 */
					color: var(--text-placeHolder);
				}

				&::-moz-placeholder {
					/* FF 19+ */
					color: var(--text-placeHolder);
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: var(--text-placeHolder);
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: var(--text-placeHolder);
				}

				&::placeholder {
					/* modern browser */
					color: var(--text-placeHolder);
				}
			}

			&.error {
				border: 0;

				& + .error-msg {
					visibility: visible;
				}
			}

			// &:after {
			// 	color: $black;
			// 	content: '\e95a';
			// 	font-family: 'icomoon';
			// 	font-size: 24px;
			// 	font-weight: 300;
			// 	height: 22px;
			// 	pointer-events: none;
			// 	position: absolute;
			// 	right: 23px;
			// 	top: 9px;
			// 	width: 24px;
			// }
			&:after {
				content: none;
			}
		}

		//RADIO and CHECKBOX
		fieldset {
			border: 0;

			ul {
				padding: 0;
			}

			label {
				align-items: flex-start;
				display: flex;
				margin-bottom: 15px;

				&.disabled {
					opacity: 1;
					pointer-events: none;

					input {
						&:before {
							color: $gray !important; // sass-lint:disable-line no-important
						}

						+ span {
							color: $gray !important; // sass-lint:disable-line no-important
						}
					}
				}

				& > input {
					// to help override magento
					input[type='radio'],
					input[type='checkbox'] {
						border: 0;
						clip: auto;
						height: auto;
						margin: auto;
						overflow: unset;
						padding: 0;
						position: realitive;
						width: auto;
						flex-shrink: 0;
					}

					&[type='checkbox'],
					&[type='radio'] {
						background-color: $white;
						clip: auto;
						display: inline;
						margin: 0;
						margin-right: 16px;
						overflow: unset !important;
						padding: 1px;
						position: relative;
						width: 18px;
						height: 21px; // if 24px part of regular chekbox is shown
						flex-shrink: 0;
						border: none;
						&:before {
							content: '';
							width: 20px;
							height: 20px;
							position: absolute;
							top: 50%;
							left: 50%;
							margin-top: -10px;
							margin-left: -10px;
							border: 1px solid #CFCFCF;
							border-radius: 3px;

						}

						&:checked {
							+ span {
								font-size: 15px;
								line-height: 18px;
								color: #2B363B;
								font-weight: normal;
								letter-spacing: normal;
								padding: 0;
								text-transform: none;
							}
						}

						+ span {
							font-size: 15px;
							line-height: 18px;
							color: #2B363B;
							font-weight: normal;
							letter-spacing: normal;
							text-transform: none;
							padding: 0;
						}
					}

					&[type='checkbox'] {
						

						&:checked {
							&:before {
								background-repeat: no-repeat;
								background-position: center;
								background-image: url('data:image/svg+xml, <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4.2L3.33333 7L8 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
							}
						}
						&:disabled {
							&:before {
								background-color: #F6F6F6;
								border-color: #CCCCCC
							}
							&:checked:before{
								background-image: url('data:image/svg+xml, <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4.2L3.33333 7L8 1" stroke="gray" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
							}
						}
					}

					&[type='radio'] {
						&:before {
							border-radius: 50%;
						}
						&:after {
							border-radius: 50%;
							content: '';
							position: absolute;
							width: 14px;
							height: 14px;
							top: 50%;
							left: 50%;
							margin-top: -7px;
							margin-left: -7px;
							border-radius: 50%;
							opacity: 0;
							transition: opacity 0.4s ease;
							background-color: #2B363B;
							z-index: 5;
						}

						&:checked {
							&:after {
								opacity: 1;
							}
						}
						&:disabled {
							&:before {
								background-color: #F6F6F6;
								border-color: #CCCCCC
							}
							&:after {
								background-color: #d0d1d1;
							}
						}
					}
				}
			}

			& + .error-msg {
				visibility: hidden;
			}

			&.error {
				& + .error-msg {
					visibility: visible;
				}
			}
		}

		// override Magento style sheet
		input[type='radio'] + label,
		input[type='checkbox'] + label {
			&::before {
				border: 0;
				box-shadow: none;
				height: unset !important;
				width: unset !important;
			}

			p {
				line-height: 100%;
				margin-bottom: 0;
			}
		}

		.bat-agegate--zonnic-content-dob-input-container {
			position: relative;
		}

		.tooltip {
			position: relative;

			&-content {
				background-color: $light-blue;
				bottom: 40px;
				display: none;
				left: -104px;
				max-width: 320px;
				min-width: 300px;
				padding: 20px;
				position: absolute;
				text-transform: none;
				width: 100%;
				z-index: 99;

				p {
					@include font-rem($font-xsm, 18px);
					color: $black;
					&:first-of-type {
						@include font-rem(16px, 24px);
						font-weight: 700;
					}
				}

				&.active {
					display: block;
				}

				&:after {
					border-left: solid 16px transparent;
					border-right: solid 16px transparent;
					border-top: solid 16px $light-blue;
					content: '';
					height: 0;
					left: 50%;
					margin-left: -41px;
					position: absolute;
					top: 100%;
					width: 0;
				}
			}

			&-close {
				cursor: pointer;
				position: absolute;
				right: 12px;
				top: 12px;
			}

			&-trigger {
				cursor: pointer;

				.bat-icon {
					height: 20px;
					margin-left: 12px;
					padding-bottom: 4px;
					width: 20px;
				}
			}
		}

		// DOB Field
		// on tablet and down span full width
		@include media-breakpoint-down(md) {
			// on mobile make input span full width
			.bat--zonnic-content-dob-input {
				-webkit-appearance: textfield;
				-moz-appearance: textfield;
				display: flex;
				flex: 1 0 0;
				min-height: 1em;
			}
		}

		// Error Message for DOB Field
		// does not use the form builder validations but overwrites them
		.dob-error {
			border: 0;
			border-bottom: 2px solid var(--input-error) !important; // sass-lint:disable-line no-important
			color: var(--text-error);

			& + .error-msg {
				display: inline-block;
			}
		}

		//
		.bat-form--newsletter-email-assisted-trial-message {
			margin-bottom: 4px;

			.bat-message-text {
				h4 {
					margin-top: 24px;
				}

				p {
					@include font-rem(14px, 20px);
					color: $black;
				}
			}
		}

		.bat-form--newsletter-email-assisted-trial-checkbox {
			margin-bottom: 0;

			label {
				align-items: flex-start;
			}

			p {
				@include font-rem(14px, 20px);
				color: $black;
			}
		}

		&.active {
			button {
				&[type='submit'] {
					border: solid 2px transparent;
					pointer-events: none;
					position: relative;

					span {
						visibility: hidden;
					}

					&:after {
						-webkit-animation: spin 2s linear infinite; /* Safari */
						animation: spin 2s linear infinite;
						border: 5px solid $white;
						border-radius: 50%;
						border-top: 5px solid $concrete;
						bottom: 0;
						content: '';
						height: 30px;
						left: 0;
						margin: auto;
						position: absolute;
						right: 0;
						top: 0;
						width: 30px;
						z-index: 1;
					}
				}
			}
		}
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
body .bat-form .bat-message p {
	padding-left: 0;
}
@include media-breakpoint-down(sm) {
	.bat form {
		input,
		select,
		textarea {
			padding: 5px 8px
		}
		.select-wrapper select {
			padding: 5px 8px;
			padding-right: 27px;
			background-position: right 8px center;
		}
	}
}
.in-store .bat-form .bat-form-step,
.bat-form .bat-form-step {
	&:not(:first-child) {
		.bat-form-heading {
			margin-bottom: 0;
		}
	}
	.bat-form-heading {

		.form-message-headline {
			font-weight: 400;
			font-size: 15px;
			line-height: 150%;
			text-align: center;
			color: #1E8174;
			padding-top: 15px;
		}
	}
	.form-error-message {
		padding-top: 15px;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		text-align: left;
		svg {
			flex-shrink: 0;
		}
		p {
			padding-left: 10px;
			font-weight: 400;
			font-size: 15px;
			line-height: (24 / 15);
			color: #ED4823;
			margin-bottom: 0;
			span {
				font-weight: inherit;
				font-size: inherit;
				line-height: inherit;
				color: inherit;
			}
		}
	}
}
[dir="rtl"] {
	.in-store .bat-form .bat-form-step,
	.bat-form .bat-form-step {
		.form-error-message {
			p {
				padding-left: 0;
				padding-right: 10px;
			}
		}
	}
}