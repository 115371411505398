.bat-footer-small {
	
	footer {
		
		align-content: center;
		align-items: center;
		background-color: $white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: 64px;
		padding: $gutter;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			margin: 0 auto;
			max-width: $max-width-xxl;
			min-height: 77px;
        }
	}
	
	&-nav {
		display: flex;
		flex-direction: column;
		justify-content: center;
		order: 1;
		padding: $gutter;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			flex: 1 1 100%;
			flex-direction: row;
			order: 2;
		}
		
		ul {
			line-height: 1rem;
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;
			
			@include media-breakpoint-up(lg) {
				order: 2;
				text-align: left;
			}
			
			li {
				display: inline-block;
				margin: 0;
				padding: .5rem;
				@include font-rem(14px, 14px);
				
				a {
					display: inline;
					padding: 0 $gutter / 4;
					text-decoration: underline;
					
					&:hover {
						text-decoration: none;
					}
				}
				
				&:not(:last-child) {
					
					&:after {
						color: $black;
						content: '|';
						display: inline-block;
						line-height: 1;
						position: relative;
						right: -7px;
						top: -1px;
						vertical-align: middle;
					}
				}
			}
		}
	}
}
