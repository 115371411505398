.bat-hero {
	position: relative;
	width: 100%;

	img {
		height: 100%;
		left: 50%;
		max-width: none;
		min-width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
	
	&-content {
		padding: 20px;
		position: relative;
		
		p {
			margin-bottom: 0;
			
			& + p {
				margin-top: 10px;
			}
		}

		&-container {
			display: flex;
			width: 100%;

			&.right {
				justify-content: flex-end;
			}
		}
	}
}
