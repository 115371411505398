.gtr-home-register-airport.gtr-em-signup {
    background-color: #322e61;
    .em-banner {
        .bat-image {
            img {
                width: 100%;
            }
        }
    }
    .bat-section-content > .text {
        padding-bottom: 0;
        .bat-text {
            p:last-child {
                margin-bottom: 0;
            }
            p .sm {
                display: inline-block;
            }
        }
    }
    .htmlContainer {
        padding: 29px 44px 46px;
        background: linear-gradient(180deg, rgba(35, 32, 70, 0.00) 0%, #232046 31.42%, #212F53 61.98%, #1A465D 100%);
        margin-top: -89px;
        padding-top: 89px;
        .title {
            padding-top: 29px;
            color: #FFF;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 19px;
        }
        ul {
            color: #FFF;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            list-style: none;
            padding-left: 0;
            li {
                display: flex;
                margin-bottom: 0;
                & + li {
                    margin-top: 21px;
                }
                span {
                    flex-shrink: 0;
                    width: 27px;
                    height: 27px;
                    border-radius: 50%;
                    color: #000;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 130%;
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 13px;
                    background-color: #F9A51A;
                }
            }
        }
    }
    .bat-contentfragment-em {
        padding: 0 18px;
        background: linear-gradient(180deg, #1A465D 13.02%, #009681 45.31%, #0047B8 73.44%, #000 96.35%);
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            
            padding-top: (71 / 375 * 100%);
            background-image: url("/content/dam/gtr/images/webp-gtr/bg-foot-airport-registration.webp");
            background-size: cover;
            background-position: bottom right;
            background-repeat: no-repeat;
        }
        &__wrapper {
            padding-bottom: (71 / 375 * 100%);
        }
        &__item {
            padding-bottom: 70px;
        }
    }
    .bat-section-content > .image {
        padding: 0;
        margin-bottom: 0;
        @media (min-width: 768px) {
            max-width: 100%;
            margin-bottom: 0;
        }
    }
}
[dir=rtl] .gtr-home-register-airport.gtr-em-signup {
    .htmlContainer ul {
        padding-right: 0;
        li span {
            margin-right: 0;
            margin-left: 13px;
        }
    }
}