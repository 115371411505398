// sass-lint:disable no-important
.bat-related-products {
	
	.slick-track {
		display: flex;
	}

	.slick-slide {
		height: inherit;
	}
	
	.bat-productcard {
		max-width: 200px;
	}
}
