// File:           _form.scss
// Description:    Form Styles
.bat-form {
	margin: 0 auto;
	max-width: 768px;

	&-step {
		display: none;

		&.active {
			display: block;
		}
	}

	&-step-head {
		display: flex;
		flex-direction: row;
		margin: 20px 0 50px;

		span {
			&.number {
				&.active {
					background-color: $navy;
					color: $white;
				}
			}

			&.divider {
				&.active {
					background-color: $navy;
				}
			}
		}
	}

	&-field {
		margin-bottom: 30px;

		&-tip {
			@include font-rem($font-xsm, 18px);
			color: $black;
			display: inline-block;
			font-weight: normal;
			position: relative;
			right: unset;
			text-transform: none;
			top: -2px;

			a {
				font-weight: 600;
				text-decoration: underline;
			}

			i {
				&:before {
					color: $navy;
					content: '\e92e';
					font-family: 'icomoon';
					font-size: 20px;
					font-weight: 300;
					margin-left: 12px;
				}
				span {
					@include font-rem(14px, 14px);
					background-color: $light-blue;
					border: unset;
					bottom: 40px;
					box-shadow: unset;
					display: none;
					left: -118px;
					padding: 20px;
					position: absolute;
					text-transform: unset;
					transform: translate(0%, 0%);
					width: 320px;

					&:before {
						border-bottom: 0;
						border-left: solid 16px transparent;
						border-right: solid 16px transparent;
						border-top: solid 16px $light-blue;
						content: '';
						height: 0;
						left: 50%;
						margin-left: -36px;
						position: absolute;
						top: 100%;
						width: 0;
					}
				}
			}
		}

		&--read-only {
			position: relative;

			input {
				border: solid 2px $system-red;
			}

			&:before {
				color: $navy;
				content: '\e91d';
				font-family: 'icomoon';
				font-size: 18px;
				font-weight: 700;
				height: 20px;
				left: 10px;
				pointer-events: none;
				position: absolute;
				top: 8px;
			}
		}

		.bat-message {
			p {
				padding: 0;
			}

			&:before {
				display: none;
			}
		}

		label {
			background-color: transparent;
			color: $navy;
			left: 0;
			margin: 0;
			position: relative;
			top: 0;

			&.blurred {
				// color: $navy;
				top: 0;
			}
		}

		.toggle-visibility {
			align-items: center;
			background-image: url('/content/dam/gtr/images/global/icons-sprite.svg#eye-on');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 24px;
			cursor: pointer;
			display: flex;
			height: 40px;
			justify-content: center;
			position: absolute;
			right: 0;
			top: 18px;
			width: 40px;
			z-index: 9999;
		}
	}

	.bat-message {
		display: none;

		p {
			line-height: normal;
			padding-left: 20px;

			&:before {
				font-size: 12px;
				height: 15px;
				width: 15px;
			}
		}

		&.active {
			display: block;
		}
	}

	//-----------------------------------------------------------------| Default
	&--default {
		@include media-breakpoint-up(sm) {
			&-submit {
				text-align: center;

				@include media-breakpoint-up(sm) {
					text-align: left;
				}
			}

			&-address {
				&-street {
					width: 75%;
				}

				&-apt {
					width: 23%;
				}

				&-zip-code {
					width: 30%;
				}

				&-city {
					width: 43%;
				}

				&-state {
					width: 23%;
				}
			}
		}
	}
	//-----------------------------------------------------------------| Log In
	&--login-zonnic {
		.bat-cta-style {
			@include media-breakpoint-down(xs) {
				display: block;
				margin-top: 1rem;
			}
		}
		@include media-breakpoint-up(sm) {
			&-email {
				width: 100%;
			}

			&-password {
				width: 100%;
			}

			&-submit {
				align-items: center;
				display: flex;

				a {
					padding: 0 0 0 30px;
					text-align: center;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	//-----------------------------------------------------------------| Contact Us
	&--contact-us {
		max-width: 512px;

		.bat-form-heading {
			margin-bottom: 20px;
			text-align: center;
		}

		.bat-form-text {
			margin-bottom: 20px;
			text-align: center;

			p {
				color: $system-red;
				line-height: 1.4rem;
			}

			a {
				color: $navy;
				font-weight: 700;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.bat-form--contact-us-email-submit {
			text-align: center;

			button {
				max-width: 276px;
				width: 100%;
			}
		}

		.submit-status {
			display: none;
			margin: 30px auto 0;
			position: relative;
			width: 40px;

			&:after {
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $system-red;
				border-radius: 50%;
				border-top: 10px solid $system-red;
				bottom: 0;
				content: '';
				height: 40px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 40px;
				z-index: 1;
			}
		}

		.contact-us-error,
		.contact-us-success {
			background-color: $concrete;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-top: 20px;
			min-height: 300px;
			padding: 0 20px 67px;
			text-align: center;

			h4 {
				margin-bottom: 10px;
				position: relative;

				&:before {
					background-color: $navy;
					border-radius: 70px;
					color: $white;
					display: flex;
					flex-direction: column;
					font-family: 'icomoon';
					font-size: 40px;
					height: 70px;
					justify-content: center;
					left: 0;
					margin: auto;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: -80px;
					width: 70px;
				}
			}

			p {
				color: $navy;
				@include font-rem(15px, 20px);
				margin: 0 auto;
				max-width: 323px;
			}
		}

		.contact-us-success {
			h4 {
				&:before {
					content: '\e931';
				}
			}
		}

		.contact-us-error {
			h4 {
				&:before {
					content: '\e912';
				}
			}
		}

		.bat-form--contact-us-email-tos {
			border: solid 1px $system-red;
			padding: 30px;
			position: relative;

			label {
				height: 122px;
				overflow: hidden;
				padding-bottom: 42px;
				padding-left: 1px;

				&.open {
					height: auto;
				}

				p {
					line-height: 1.2rem;
					margin-bottom: 10px;

					.error {
						color: $red;
					}
				}

				a {
					color: $navy;
					font-weight: 700;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}

				.label-toggle {
					background-color: $white;
					bottom: 0;
					color: $navy;
					cursor: pointer;
					display: block;
					left: 0;
					margin: auto;
					padding: 13px;
					position: absolute;
					right: 0;
					text-align: center;
					text-decoration: underline;
					text-transform: uppercase;
					width: 100%;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	//-----------------------------------------------------------------| News Letter
	&--newsletter {
		margin: 0 auto;
		max-width: 512px;
		padding-top: 40px;
		position: relative;

		.velo-logo {
			left: 0;
			margin: 10px auto;
			position: absolute;
			right: 0;
			top: 0;
			width: 52px;
		}

		.bat-form--zonnic-newsletter {
			.zonnic-logo {
				display: block;
				margin: 0 auto;
				margin-bottom: 32px;
				width: 88px;
			}

			.bat-form-heading {
				text-align: center;
			}
			.bat-form-text {
				p {
					@include font-rem(20px, 28px);
					color: $black;
					margin-top: 10px;
				}
			}
			.bat-form-block {
				margin-top: 24px;
			}

			.bat-form-field-set,
			.bat-form-block-text {
				a {
					color: $navy;
					font-weight: 700;
					text-decoration: underline;
				}
			}
		}

		form {
			margin-top: 32px;
		}
		.bat-form-text {
			margin-bottom: 20px;
			text-align: center;

			p {
				color: $navy;
				@include font-rem(20px, 28px);
			}

			a {
				color: $navy;
				font-weight: 700;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.bat-form--newsletter-email-submit {
			display: flex;
			justify-content: center;
			text-align: center;

			button {
				max-width: 276px;
				width: 100%;

				@include media-breakpoint-down(md) {
					max-width: unset;
				}
			}
		}

		&-email-first-name,
		&-email-last-name {
			width: 100%;

			@include media-breakpoint-up(md) {
				width: 47%;
			}
		}

		.newsletter-error,
		.newsletter-success {
			background-color: $concrete;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-top: 20px;
			min-height: 300px;
			padding: 0 20px 67px;
			text-align: center;

			h4 {
				margin-bottom: 10px;
				position: relative;

				&:before {
					background-color: $navy;
					border-radius: 70px;
					color: $white;
					display: flex;
					flex-direction: column;
					font-family: 'icomoon';
					font-size: 40px;
					height: 70px;
					justify-content: center;
					left: 0;
					margin: auto;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: -80px;
					width: 70px;
				}
			}

			p {
				color: $navy;
				@include font-rem(15px, 20px);
				margin: 0 auto;
				max-width: 323px;
			}
		}

		&-email-consent-message {
			.bat-message {
				display: block;

				p {
					color: $system-red;
					margin-bottom: 10px;
					width: 100%;
				}
			}
		}

		&-email-age-verified,
		&-email-data-handling,
		&-email-consent-message,
		&-email-email-opt-in,
		&-email-mobile-opt-in,
		&-email-social-media-opt-in,
		&-email-trial-information-opt-in {
			height: 0;
			margin-bottom: 0;
			overflow: hidden;

			&.open {
				height: auto;
				margin-bottom: 20px;
				overflow: visible;
			}
		}

		.consent-text {
			justify-content: center;
			width: 100%;

			.bat-form-block-text {
				align-items: center;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;

				p {
					color: $system-red;
					margin-bottom: 10px;
					width: 100%;
				}
			}

			.label-toggle {
				background-color: $white;
				color: $navy;
				cursor: pointer;
				display: block;
				margin: auto;
				padding: 13px;
				text-align: center;
				text-decoration: underline;
				text-transform: uppercase;
				width: 100%;

				&:hover {
					text-decoration: none;
				}
			}

			.submit-status {
				display: none;
				margin-top: 30px;
				position: relative;
				width: 40px;

				&:after {
					-webkit-animation: spin 2s linear infinite; /* Safari */
					animation: spin 2s linear infinite;
					border: 10px solid $system-red;
					border-radius: 50%;
					border-top: 10px solid $system-red;
					bottom: 0;
					content: '';
					height: 40px;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					top: 0;
					width: 40px;
					z-index: 1;
				}
			}
		}

		.newsletter-success {
			h4 {
				&:before {
					content: '\e931';
				}
			}
		}

		.newsletter-error {
			h4 {
				&:before {
					content: '\e912';
				}
			}
		}

		.newsletter-disclaimer {
			p {
				color: $mid-gray;
			}

			a {
				color: $navy;
				font-weight: 700;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	// Magento remote navigation overrides
	form {
		fieldset {
			label {
				span {
					@include font-rem(15px, 15px);
					color: $system-red;
					font-weight: normal;
					letter-spacing: normal;
					padding: 1px 0 10px;
					text-transform: initial;
				}

				& > input {
					&[type='checkbox'] {
						overflow: visible;
					}

					+ label {
						display: none;
					}
				}

				&:before {
					content: none;
				}
			}
		}
	}

	&__account__edit-address {
		.bat-form-field-set {
			border: 1px solid $pale-gray;
			padding: 20px;

			label {
				margin-bottom: 0;

				input[type='checkbox'] {
					margin: 0;
					margin-right: 16px;
					width: 24px;

					& + span {
						padding: 0;
						padding-top: 4px;
					}

					&:checked {
						& + span {
							padding: 0;
							padding-top: 4px;
						}
					}
				}
			}
		}

		.address-book-link {
			align-items: center;
			display: flex;
			font-weight: 700;
			text-transform: uppercase;
			@include font-rem(14px, 20px);

			&:before {
				background-image: url('/content/dam/gtr/images/global/icons-sprite.svg#arrow-left');
				content: '';
				height: 28px;
				margin-right: 10px;
				width: 28px;
			}
		}
	}
}

.newsletter-zonnic-interim {
	.hide {
		// sass-lint:disable no-important
		display: none !important;
	}
}

.newsletter-zonnic-interim,
.zonnic-newsletter-modal {
	.zonnic-logo {
		margin: 0 auto;
		margin-top: 48px;
		width: 88px;
	}

	.newsletter-headline {
		font-weight: 600;
		margin-top: 24px;
		text-align: center;

		@include media-breakpoint-up(md) {
			@include font-rem(20px, 24px);
		}
	}

	.newsletter-subheadline {
		p {
			@include font-rem(20px, 28px);
			color: $black;
			font-weight: 600;
			text-align: center;

			@include media-breakpoint-up(md) {
				@include font-rem(18px, 25px);
			}
		}
	}
}

.bat-header-account {
	.bat-form--login-zonnic {
		background: $white;
		opacity: 0;
		padding: 1rem;
		position: absolute;
		right: 0;
		top: 60px;
		visibility: hidden;
		width: 480px;
		z-index: -1;
		&.open {
			opacity: 1;
			visibility: visible;
			z-index: 9999;
		}
		.bat-form-field {
			margin-bottom: 24px;
			input {
				margin-bottom: 0;
			}
		}
		form {
			&:nth-child(3) {
				.bat-form-field {
					background: #ffc0cb;
				}
			}
			.bat-form--login-submit {
				width: 100%;
			}
		}
	}
	.bat-form--login-overlay {
		background: rgba(0, 0, 0, 0.25);
		bottom: 0;
		display: none;
		height: 100vh;
		left: 0;
		position: absolute;
		right: 0;
		top: 81px;
		width: 100%;
		z-index: 998;
		&.show {
			display: block;
		}
	}
}

bat-form-loginzonnic,
bat-form-loginzonniccheckout  {
	.bat-form--login-submit {
		.bat-button {
			max-width: unset;
			width: 100%;
		}	
	}
	.bat-form-block {
		a.bat-cta-style {
			max-width: unset;
			width: 100%;
		}
	}
}


/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
