.bat-tabs {
	border-top: solid 12px $gray;
	position: relative;
	
	&-indicator {
		border-color: $gray transparent transparent;
		border-style: solid;
		border-width: 12px 7px 0;
		height: 0;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: left .25s ease-in, opacity .5s ease-in;
		width: 0;
		
		&.active {
			opacity: 1;
		}
		
	}

	&-list {
		display: flex;
		justify-content: flex-start;
		list-style: none;
		margin: 12px 0 0;
		padding: 0;
		
		@include media-breakpoint-up(lg) {
			justify-content: center;
		}

		&-item {
			flex: 0 0 auto;
			margin: 0;
	
			&-button {
				align-items: center;
				display: flex;
				padding: 10px;
				width: 100%;
				
				i {
					height: 16px;
					margin-left: 20px;
					width: 16px;
					
					&:before {
						font-size: 16px;
					}
				}
				
				.active & {
					border-bottom: solid 1px $gray;
				}
			}
		}
	}
	
	&-panels {
		
		&-panel {
			display: none;
			overflow: hidden;
			
			.authorEditMode & { // sass-lint:disable-line class-name-format
				display: block;
			}

			&.active {
				display: block;
			}
			
			& > div {
				padding: 20px 0;
			}
		}
	}
}
