a {
	color: $black;
	display: inline-block;
	text-decoration: none;
}

.bat-link {
	align-items: center;
	cursor: pointer;
	display: inline-flex;
	text-decoration: none;

	&.center {
		justify-content: center;
	}
	
	&.left {
		justify-content: flex-start;
	}
	
	&.right {
		justify-content: flex-end;
	}
	
	i {
		
		&.bat-icon {
			height: 20px;
			margin: 0 10px;
			width: 20px;
			
			&:before {
				font-size: 13px;
			}
		}
	}
	
	&--dark {
		color: $black;
		
		i {
			svg {
				fill: $black;
			}
			
			&:before {
				font-size: 13px;
			}
		}
	}
	
	&--light {
		color: $white;
		
		i {
			svg {
				fill: $white;
			}
		}
	}
}
