.bat-navigation {
	align-items: center;
	display: flex;
	max-width: 700px;
	width: 100%;
	
	&-group {
		height: 100%;
		width: 100%;
		
		&-list {
			height: 100%;
			width: 100%;
			
			&-item {
				height: 100%;
				padding: 0;
				position: relative;
				
				&:after {
					background-color: $white;
					bottom: 0;
					content: '';
					height: 1px;
					left: 0;
					margin: 0 20px;
					opacity: .1;
					position: absolute;
					right: 0;
				}
				
				@include media-breakpoint-up(lg) {
					
					&:after {
						display: none;
					}
				}
				
				& > a {
					@include font-rem(12px, 12px);
					color: $white;
					font-weight: 700;
					justify-content: flex-start;
					padding: 20px;
					text-transform: uppercase;
					
					i {
						height: 10px;
						margin-left: auto;
						width: 10px;
						
						&:before {
							@include font-rem(10px, 10px);
						}
						
						svg {
							fill: $white;
						}
					}
				
					@include media-breakpoint-up(lg) {
						@include font-rem(12px, 12px);
						background-color: transparent;
						font-weight: 700;
						height: 100%;
						padding: 40px 5%;
						text-transform: uppercase;
						transition: background-color .1s ease-in;
						
						span {
							color: $white;
							margin: 0 auto;
							transition: color .1s ease-in;
						}
						
						&:hover {
							background-color: $white;
							color: $navy;
							
							span {
								color: $navy;
								text-decoration: none;
							}
						}
						
						i {
							display: none;
						}
					}
				}
			
				&--menu {
					background-color: transparent;
					
					& > a {
						position: relative;
						
						&:after {
							color: $white;
							content: '\e926';
							font-family: 'icomoon';
							font-size: 28px;
							font-weight: normal;
							position: absolute;
							right: 20px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					
					&.open  {

						& > a {
							@include media-breakpoint-up(lg) {
								span {
									color: $navy;
								}
							}
							
							&:after {
								content: '\e958';
								@include media-breakpoint-up(lg) {
									color: $navy;
								}
							}
						}
					}
					
					@include media-breakpoint-up(lg) {
						position: static;
						
						&.open  {
							background-color: $white;
						}
						
						& > a {
							position: relative;
							
							span {
								text-decoration: none;
							}
							
							&:after {
								color: $white;
								content: '\e90c';
								font-family: 'icomoon';
								font-size: 14px;
								left: 0;
								position: absolute;
								right: 0;
								top: 66%;
								transition: color .1s ease-in;
							}
							
							&:hover {
								
								&:after {
									color: $navy;
								}
							}
						}
						
						&:focus,
						&:hover {
							
							& > a {
								position: relative;
							}
						}
					}
				}
			}
		}
	}
	
	&-sub-group {
		background-color: transparent;
		padding: 20px;
	
		.open & {
			background-color: $whisper-gray;
		}
	
		@include media-breakpoint-up(lg) {
			background-color: $whisper-gray;
			padding: 40px 0 130px;
		}
		
		&-list {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
			grid-gap: 1rem;
			
			&-item {
				margin: 0;
				padding: $gutter / 4 0;
				text-align: left;
				width: 100%;
				
				@include media-breakpoint-up(lg) {
					width: 50%;
				}
				
				a {
					color: $navy;
					display: inline;
					justify-content: flex-start;
					padding: 0;
					text-transform: uppercase;
					transition: border-bottom .1s ease-in;
					
					&:hover {
						color: $tropic-breeze-dark;
						span {
							text-decoration: none;
						}
					}
				}
			}

			&.level-2 > li > a {
				@include font-rem(20px, 28px);
				font-weight: 600;
				margin-bottom: 0.5rem;
			}

			ul.level-3 li {
				list-style-type: '- ';
			}
		}

		&-close {
			display: none;
			
			@include media-breakpoint-up(lg) {
				display: block;
				position: absolute;
				right: $gutter * 2;
				top: 0;
				
				i {
					svg {
						fill: $white;
						transition: fill .1s ease-in;
					}
				}
				
				&:hover {
					
					i {
						svg {
							fill: $system-red;
						}
					}
				}
			}
		}
		
		&-wrapper {
			
			@include media-breakpoint-up(lg) {
				margin: 0 auto;
				max-width: $max-width-xl;
				padding: 0 $gutter * 2;
				position: relative;
			}
		}
	}
}
