// File:          _sortfilter.scss
// Description:   Sort Filter

bat-sortfilter-avalanche {

	&.bat {
		display: block;
		// sass-lint:disable no-important
		margin-left: -3.5px !important;
		margin-right: -3.5px !important;
		max-width: unset !important;
	}
	.bat-plp-filter-container {
		margin: 0 auto;
		.bat-plp-slot {
			background: $concrete;
		}
	}

	.bat-filter {
		-moz-box-shadow: 7px 6px 11px -1px $dark-30;
		-webkit-box-shadow: 7px 6px 11px -1px $dark-30;
		background-color: $white;
		box-shadow: 7px 6px 11px -1px $dark-30;

		z-index: $modal-zindex;

		&-overlay {
			background: $navy;
			bottom: 0;
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			right: 0;
			top: 0;
			transition: .5s;
			z-index: $modal-zindex;
			&--open {
				opacity: .33;
				pointer-events: all;
			}
		}

		&-wrapper {
			padding: 20px;
		}

		&-closebutton {
			right: 1rem;
		}

		&-applybutton {
			padding: 0;
			button {
				&[data-parameters='applyButton'] {
					background-color: $system-red;
					border-color: $system-red;
					&:hover {
						background-color: $system-red;
						border-color: $system-red;
					}
				}
			}
		}

		&-collapse {
			border-top: 1px solid $concrete;
			padding: 1rem 0;

			&:hover {
				cursor: pointer;
			}

			i {
				height: 24px;
				width: 24px;

				&:before {
					font-size: 24px;
				}
			}

			.title {
				@include font-rem(18px, 18px);
				font-weight: 500;
			}

			&-container {
				.group-title {
					@include font-rem(24px, 24px);
					border-bottom: 0;
					font-weight: 700;
					padding: 1rem 0;
				}
			}

			&-items {
				border: 0;
				padding: 0 0 2rem;

				ul {
					li {
						label {
							@include font-rem(16px, 16px);
							align-content: center;
							align-items: center;
							display: flex;
							margin-bottom: 15px;

							.count {
								@include font-rem(14px, 14px);
								color: $system-red;
								margin-left: 5px;
							}

							input {
								display: inline;
								margin-right: 12px;
								position: relative;
								width: auto;

								&:before {
									background-color: $white;
									color: $navy;
									content: '\e90a';
									display: inline-block;
									font-family: 'icomoon';
									font-size: 22px;
									height: 22px;
									margin: -4px 0 0 -4px;
									position: relative;
									width: 22px;
								}

								&:checked {
									&:before {
										color: $navy;
										content: '\e90b';
										display: inline-block;
									}

									+ span {
										@include font-rem(14px, 14px);
										color: $navy;
									}
								}

								+ span {
									@include font-rem(14px, 14px);
								}
							}
						}
					}
				}
			}
		}
	}

	.reset-button,
	.clear-button {
		align-items: center;
		color: $system-red;
		display: flex;
		font-weight: 700;
		text-transform: uppercase;
	}

	.reset-button {
		i {
			&:before {
				font-size: 24px;
			}
		}
	}

	.bat-plp-button-wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0 auto -10px;
		max-width: $site-max-width;
		padding: 0 10px;
		position: relative;
	}

	.bat-filter-button {
		display: flex;
		margin: 16px;

		[data-parameters='filterButton'] {
			@include font-rem(15px, 15px);
			align-items: center;
			color: $navy;
			display: flex;
			font-family: $primary-font;
			font-weight: 700;
			//transition: color .1s ease-in;

			&:hover {
				color: $system-red;

				i {
					&:before {
						color: $system-red;
					}
				}
			}

			i {
				margin-right: 5px;

				&:before {
					color: $navy;
					font-size: 24px;
				}
			}
		}
	}

	.bat-filter-count {
		display: none;
		position: absolute;
		right: 10px;
	}

	.bat-filter-closebutton {
		button {
			i {
				&:before {
					color: $navy;
					font-size: 30px;
				}
			}
		}
	}

	.bat-sort-button {
		display: flex;
		margin: 16px;

		[data-parameters='sortButton'] {
			@include font-rem(15px, 15px);
			align-items: center;
			color: $navy;
			display: flex;
			font-family: $primary-font;
			font-weight: 700;
			//transition: color .1s ease-in;

			&:hover {
				color: $system-red;

				i {
					&:before {
						color: $system-red;
					}
				}
			}

			i {
				&:before {
					color: $navy;
				}
			}
		}

		i {
			&:before {
				color: $navy;
				font-size: 24px;
			}

			&[id='sortButtonIconOpen'] {
				display: none;
			}

			&[id='sortButtonIconClosed'] {
				display: flex;
			}
		}

		&.open {
			i {
				&[id='sortButtonIconOpen'] {
					display: flex;
				}

				&[id='sortButtonIconClosed'] {
					display: none;
				}
			}
		}
		
		#sortButtonIcon { // sass-lint:disable-line no-ids, id-name-format
			margin-left: 10px;
		}
	}

	.bat-sort-select {
		border: 2px solid $system-red;
		min-width: 170px;
		z-index: 10;

		button {
			color: $navy;
			transition: color .1s ease-in;

			&:hover {
				color: $system-red;
			}
		}
	}

	.bat-price-slider {
		&-output {
			label {
				span {
					text-transform: uppercase;
				}

				input {
					@include font-rem(14px, 14px);
					background-color: $concrete;
					border: 0;
					color: $navy;
					padding: 10px;
				}
			}
		}

		&-range {
			input {
				&[type='range'] {
					&:focus {
						&::-webkit-slider-runnable-track {
							background: $system-red;
						}
					}

					&::-webkit-slider-runnable-track {
						background: $system-red;
					}

					&::-webkit-slider-thumb {
						background: $system-red;
					}

					&::-moz-range-track {
						background: $system-red;
					}

					&::-moz-range-thumb {
						background-color: $system-red;
					}

					&::-ms-track {
						background-color: $system-red;
					}

					&::-ms-thumb {
						background-color: $system-red;
					}
				}
			}
		}
	}

	.sortfilter {
		padding-left: 0;
		padding-right: 0;
	}
}

@include media-breakpoint-up(sm) {
	bat-sortfilter-avalanche {
		.bat-filter-button {
			justify-content: flex-end;
			margin: 20px 20px 20px 0;
		}
		.bat-filter-count {
			display: block;
		}
		.bat-plp-button-wrapper {
			justify-content: flex-start;
		}
		.bat-sort-button {
			justify-content: flex-start;
			margin: 20px 20px 20px 0;
		}
	}
}

@include media-breakpoint-up(lg) {
	bat-sortfilter-avalanche {
		.bat-filter {
			&-wrapper {
				padding: 20px 40px 20px 60px;
			}
		}
	}
}

.sortfilter-category-error {
	@include font-rem(16px, 28px);
	margin-bottom: 20px;
}
