// sass-lint:disable no-important
.bat-header {
	background-color: $navy;
	font-size: 16px;

	header {
		height: $header-height-mobile;
		padding: 0 0 0 20px;
		position: relative;
		z-index: $header-z-index - 2;

		@include media-breakpoint-up(lg) {
			height: auto;
			padding: 0 20px;
		}
	}

	&-logo {
		display: flex;
		flex: 0 0 71px;

		@include media-breakpoint-up(lg) {
			flex: 0 0 94px;
		}
	}

	&-search {
		flex: 0;
		margin: 0 auto;
		padding: 20px 20px 0;

		@include media-breakpoint-up(lg) {
			background-color: transparent;
			flex: 0 0 auto;
			max-width: 28px;
			padding: 27px 10px 34px;
			position: absolute;
			right: 2px;
			transition: all .1s ease-in;
			z-index: 1;

			form {
				input {
					visibility: hidden;
				}
			}

			&:focus-within,
			&:hover {
				background-color: $white;
				max-width: 206px;

				form {
					input {
						background-color: $white;
						border-bottom: solid 1px $navy;
						color: $navy;
						visibility: visible;

						&:-webkit-autofill {
							-webkit-box-shadow: 0 0 0 30px $white inset !important;
							-webkit-text-fill-color: $navy !important;

							&:active,
							&:focus,
							&:hover {
								-webkit-box-shadow: 0 0 0 30px $white inset !important;
								-webkit-text-fill-color: $navy !important;
							}
						}

						&::-webkit-input-placeholder {
							color: $navy;
						}

						&:-moz-placeholder {
							/* FF 4-18 */
							color: $navy;
						}

						&::-moz-placeholder {
							/* FF 19+ */
							color: $navy;
						}

						&:-ms-input-placeholder {
							/* IE 10+ */
							color: $navy;
						}

						&::-ms-input-placeholder {
							/* Microsoft Edge */
							color: $navy;
						}

						&::placeholder {
							/* modern browser */
							color: $navy;
						}
					}

					.bat-header-search-button {
						i {
							&:before {
								color: $navy;
							}
						}

						span {
							display: none;
						}
					}
				}
			}
		}

		form {
			input {
				background-color: $navy;
				border-bottom: solid 1px $white;
				color: $white;
				font-size: 12px;
				line-height: 12px;
				padding: 7px 28px 7px 0;
				transition: all .1s ease-in;

				&:-webkit-autofill {
					-webkit-box-shadow: 0 0 0 30px $system-red inset !important;
					-webkit-text-fill-color: $white !important;

					&:active,
					&:focus,
					&:hover {
						-webkit-box-shadow: 0 0 0 30px $system-red inset !important;
						-webkit-text-fill-color: $white !important;
					}
				}

				&::-webkit-input-placeholder {
					color: $concrete;
				}

				&:-moz-placeholder {
					/* FF 4-18 */
					color: $concrete;
				}

				&::-moz-placeholder {
					/* FF 19+ */
					color: $concrete;
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: $concrete;
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: $concrete;
				}

				&::placeholder {
					/* modern browser */
					color: $concrete;
				}

				&:focus {
					border-bottom: solid 1px $system-red;
				}

				@include media-breakpoint-up(lg) {
					border-bottom: solid 1px transparent;
				}
			}
		}

		&-button {
			height: 28px;
			padding: 0;
			right: 0;
			top: -1px;
			width: 28px;

			i {
				&:before {
					color: $white;
					font-size: $icon-size;
					transition: all .1s ease-in;
				}
			}

			@include media-breakpoint-up(lg) {
				span {
					color: $white;
					display: block;
					font-size: 10px;
					line-height: 10px;
				}

				&:hover {
					i {
						&:before {
							color: $system-red;
						}
					}
				}
			}
		}
	}

	&-support,
	&-stores,
	&-cart,
	&-account {
		height: 58px;
		order: unset;

		@include media-breakpoint-up(sm) {
			padding: 10px;
		}

		a,
		button {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			&:hover {
				border: 0;
			}

			i {
				height: 28px;
				width: 28px;
				
				&:before {
					color: $white;
					font-size: $icon-size;
					transition: background-color .1s ease-in;
				}
			}

			span {
				color: $navy;
				font-family: $primary-font;
				font-size: 10px;
				font-weight: 300;
				letter-spacing: .02rem;
				line-height: 10px;
			}

			@include media-breakpoint-up(lg) {
				&:hover {
					i {
						&:before {
							color: $tropic-breeze-dark;
						}
					}

					span {
						color: $tropic-breeze-dark;
						text-decoration: none;
					}
				}
			}
		}
	}

	&-account {
		&-menu {
			background-color: $white;
			border-radius: 3px;
			box-shadow: 0 5px 10px 0 $mid-gray;
			left: unset;
			right: 0;
			transform: translate(0, 79%);
			a {
				span {
					color: $navy;
				}

				&:hover {
					span {
						color: $tropic-breeze-dark;
					}
				}
			}
		}

		&.open {
			a {
				i {
					&:before {
						color: $tropic-breeze-dark;
					}
				}
			}
		}
	}

	&-cart {
		// sass-lint:disable class-name-format
		&.cartEmpty {
			button {
				i {
					&:before {
						.bat {
							&:not(bat-header-limited) & {
								content: '\e906';
							}
						}
					}
				}
			}
		}

		&-label {
			font-size: 10px;
			font-weight: normal;
			letter-spacing: 0;
			line-height: 10px;
			margin: 0 auto;
			text-transform: capitalize;
			visibility: visible;
		}

		&-count {
			align-items: center;
			background-color: transparent;
			border: solid 2px transparent;
			border-radius: 25px;
			color: $white;
			display: none;
			font-size: 10px;
			font-weight: 700;
			justify-content: center;
			line-height: 10px;
			margin: auto;
			opacity: 0;
			transform: rotate(0deg);
			transition: all .2s ease-in;

			&.loading {
				animation: spin 2s linear infinite;
				border-bottom: 2px solid $system-red;
				border-top: 2px solid $system-red;
				visibility: visible;

				&.small,
				&.medium &.large {
					span {
						opacity: 0;
					}
				}

				& ~ span {
					visibility: hidden;
				}
			}

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}

			&.small,
			&.medium,
			&.large {
				bottom: 8px;
				height: 15px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				width: 15px;

				span {
					opacity: 1;
					transition: opacity .2s ease-in;
				}
			}

			&.active {
				opacity: 1;
			}
		}

		span {
			font-size: 10px;
			line-height: 10px;
		}
	}

	&-social-nav {
		ul {
			li {
				color: $white;
			}
		}
	}

	&-menu {
		background-color: $whisper-gray;
		height: 0;
		//transition: height .2s ease;

		@include media-breakpoint-up(lg) {
			background-color: $navy;
			height: auto;
			position: relative;
			top: auto;
		}

		&__overlay {
			background: $navy;
			bottom: 0;
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			right: 0;
			top: 0;
			z-index: -1;
		}

		&.open {
			height: auto;
			overflow-y: auto;

			@include media-breakpoint-up(lg) {
				height: auto;
				overflow: hidden;
			}

			// show overlay, but only when menu is open on mobile
			@include media-breakpoint-down(md) {
				+ .bat-header-menu__overlay {
					opacity: .33;
					pointer-events: all;
					top: 78px;
					transition: opacity .5s;
				}
			}
		}
	}

	&-nav {
		flex: 0;
		padding: 20px 0;

		.bat-navigation {
			margin: 0 auto;
			max-width: 500px;
			a {
				display: flex;
				justify-content: space-between;
			}
			&-group-list-item--zonnic-mobile {
				@include media-breakpoint-up(lg) {
					display: none;
				}
				a {
					justify-content: flex-start;
					i {
						margin-left: 0;
						margin-right: 0;
						transform: unset;
						transform-origin: center;
						&:before {
							font-size: 1em;
						}
						&:hover {
							transform: unset;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			flex: 1 1 auto;
			justify-content: flex-start;
			padding: 0 20px;

			.bat-navigation {
				margin: 0;
				max-width: 700px;
				width: 94%;
			}
		}
	}

	&-menu-button {
		order: 0;
		// padding: 20px 12px;
		position: relative;

		button {
			border: 0;
			height: auto;
			width: auto;

			i {
				&:before {
					color: $white;
					font-size: $icon-size;
				}
				&.icon-close {
					&:before {
						color: $navy;
					}
				}
			}
		}

		&.active {
			color: $white;
			button {
				i {
					&:before {
						font-size: 28px;
					}
				}
			}
		}

		&-open-label {
			color: $white;
			margin-top: 0;
		}

		&-close-label {
			margin-top: 0;
		}

		&-close-label,
		&-open-label {
			font-size: 10px;
			line-height: 10px;
		}
	}

	&-fixed-spacer {
		height: auto;
	}

	&-content {
		background-color: $white;
		height: 0;
		overflow: hidden;

		.bat-text {
			// hide for belgium
			// [data-country=be] & {
			// 	display: none;
			// }
			background-color: $white;
			bottom: 0;
			display: flex;
			flex-wrap: wrap;
			height: $warning-height-mobile;
			justify-content: flex-start;
			left: 0;
			margin: 0 auto;
			max-width: none;
			position: fixed;
			right: 0;
			top: auto;
			z-index: 800;

			@include media-breakpoint-up(md) {
				height: $warning-height;
			}

			p {
				margin: 0;

				&:nth-child(1) {
					align-items: center;
					background-color: $white;
					border: solid 6px $black;
					color: $black;
					display: flex;
					font-family: $google-lato;
					font-size: 16px;
					font-weight: 700;
					height: $warning-height-mobile;
					justify-content: center;
					line-height: 16px;
					padding: 0 4%;
					text-align: center;
					width: 100%;

					@include media-breakpoint-up(md) {
						font-size: 26px;
						height: $warning-height;
						line-height: 26px;
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					border-top: solid 1px $black;
					color: $black;
					font-family: $open-sans;
					font-size: 10px;
					height: $warning-footer-height;
					letter-spacing: 1px;
					line-height: 10px;
					padding: 8px 10px 0;
					text-align: left;
					width: 50%;

					@include media-breakpoint-up(xs) {
						font-size: 11px;
						line-height: 11px;
					}

					br {
						@include media-breakpoint-up(lg) {
							content: ' ';
							display: none;
						}
					}
				}

				&:nth-child(3) {
					margin-left: auto;
					text-align: right;
				}
			}
		}
	}

	&-message-bar {
		background-color: $system-red;
		padding: 0 20px;

		&-message {
			display: flex;
			flex-flow: row;
			justify-content: space-evenly;
			width: 100%;
			i {
				margin-right: 10px;
			}

			span {
				p {
					font-size: 12px;
					font-weight: bold;
					line-height: 18px;
					margin-bottom: 0;
				}
			}
		}
	}
	&--velo-desktop {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	[class*='flag-circle'] {
		
		svg {
			border-radius: 50%;
			transform: scale(.8);
		}
	}
}

bat-header-zonnic,
bat-header-zonnicheadless {
	.bat-header {
		background-color: $white;
		color: $navy;
		font-size: .75rem;
		letter-spacing: .02rem;
		line-height: .879rem;
		header {
			display: flex;
			flex-direction: column;
			padding: 0 20px;
			@include media-breakpoint-down(lg) {
				padding: 0 20px;
			}
			@include media-breakpoint-down(md) {
				display: flex;
				justify-content: center;
			}
		}
		&-top {
			display: flex;
			max-width: 1260px;
			padding-top: 20px;
			width: 100%;
			@include media-breakpoint-down(md) {
				padding-top: unset;
			}
		}
		&-logo {
			align-items: center;
			flex: 0 0 72px;
			@include media-breakpoint-down(md) {
				align-items: center;
				display: flex;
				flex: unset;
				width: 72px;
			}

			a {
				z-index: 9;

				&:focus {
					outline: 1px solid $navy;
					outline-offset: 6px;
				}
			}
		}

		&-utils {
			align-items: center;
			display: flex;
			flex: 1;
			justify-content: flex-end;
			@include media-breakpoint-down(md) {
				align-items: center;
			}
			& > div {
				&:last-of-type {
					padding-right: 0;
					@include media-breakpoint-up(sm) {
						padding-right: 0;
					}
				}
			}
			i {
				margin-bottom: 10px;
				@include media-breakpoint-down(md) {
					margin-bottom: 0;
				}
				&.flag {
					height: 17px;
					width: 24px;
				}
				&.account {
					height: 17px;
					width: 18px;
				}
				&.stores {
					height: 17px;
					width: 15px;
				}
				&.bag {
					height: 17px;
					position: relative;
					top: 1px;
					width: 16px;
					@include media-breakpoint-down(md) {
						width: 16px;
					}
					&.has-items {
						&:before {
							align-items: center;
							background: #3ec7f4;
							border-radius: 50%;
							bottom: 4px;
							color: $navy-dark;
							content: attr(data-cart-quantity);
							display: flex;
							font-size: 10px;
							font-weight: bold;
							height: 12px;
							justify-content: center;
							left: 10px;
							line-height: 0;
							position: absolute;
							width: 12px;
							&:hover {
								color: $navy-dark; 
							}
						}
					}
				}
				&.zonnic-menu,
				&.close {
					height: 17px;
					width: 20px;
				}
			}
		}
		&-menu {
			background-color: $white;
			color: $navy;
			max-width: 1170px;
			@include media-breakpoint-down(md) {
				background-color: $whisper-gray;
				flex-direction: row;
				padding: 0 8px;
			}
		}
		&-nav {
			color: $navy;
			justify-content: unset;
			@include media-breakpoint-down(md) {
				flex: 1;
			}
			@include media-breakpoint-up(lg) {
				flex: 1 1 auto;
				justify-content: flex-start;
				padding: 0;
	
				.bat-navigation {
					margin: 0;
					max-width: unset;
					width: 100%;
					a {
						color: $navy;
						justify-content: unset;
						&:hover {
							color: $tropic-breeze-dark;
						}
					}
				}
			}
			nav {
				.bat-navigation-group {
					&-list {
						justify-content: space-between;
					}
					&-list-item {
						flex: 0 1 auto;
						&:hover {
							i {
								transform: none;
							}
						}
						> a {
							padding: 20px 0;
							@include media-breakpoint-down(md) {
								border-bottom: 1px solid $navy;
								color: $navy;
								justify-content: unset;
							}
							&:hover {
								span {
									color: inherit;
								}
								&:after {
									color: $tropic-breeze-dark;
									display: none;
									@include media-breakpoint-down(md) {
										display: block;
									}
								}
								@include media-breakpoint-down(md) {
									color: $tropic-breeze-dark;
								}
							}
							&:after {
								color: $navy;

							}
							span {
								color: $navy;
								margin-left: 10px;
							}
							i {
								height: auto;
								width: 25px;
								svg {
									fill: currentColor;
								}
								@include media-breakpoint-down(md) {
									margin-left: unset;
								}
								@include media-breakpoint-up(lg) {
									&:first-of-type {
										display: block;
										margin-left: unset;
									}
								}
								&.fa-chevron-down {
									display: none;
								}

								&.flag {
									svg {
										fill: $system-red;
									}
								}

							}
						}
						> span {
							color: inherit;
						}
						&--menu {
							> a {
								&:after {
									content: '\e959';
									@include media-breakpoint-up(lg) {
										content: '';
									}
								}
							}
							&.open {
								i {
									transform: unset;
								}
								> a {
									&:after {
										content: '\e958';
									}
								}
							}
						}
					}
				}
			}
		}
		&-support,
		&-account,
		&-stores {
			align-items: center;
			display: flex;
			justify-content: center;
			@include media-breakpoint-up(sm) {
				padding: 10px 8px;
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
			&-link {
				>a {
					span {
						color: $navy;
					}
					i {
						&:before {
							color: $navy;
						}
						&[class*='flag'] {
							svg {
								fill: $system-red;
							}
						}
					
					}
					&:hover {
						span {
							color: $tropic-breeze-dark;
						}
						i {
							&:before {
								color: $tropic-breeze-dark;
							}
						}
					}
				}
			}
		}
		&-support {
			&-link {
				>a {
					span {
						opacity: 0;
						text-transform: uppercase;

						
					}

				}
			}
		}
		.show {
			opacity: 1;
		}
		&-cart {
			@include media-breakpoint-up(sm) {
				padding: 10px 8px;
			}
			@include media-breakpoint-down(md) {
				height: auto;
			}
			>button {
				align-items: center;
				color: $navy;
				display: flex;
				justify-content: center;
				&:hover {
					background-color: transparent;
				}
				span {
					color: $navy;
					font-family: $primary-font;
					@include media-breakpoint-down(md) {
						display: none;
					}
					&.bat-header-cart-label {
						position: relative;
						top: 1px;
					}
				}
				i {
					&:before {
						color: $navy;
					}
				}
				&:hover {
					span {
						color: $tropic-breeze-dark;
					}
					i {
						&:before {
							color: $tropic-breeze-dark;
						}
					}
				}
			}
		}
		&-search {
			background-color: $white;
			margin: unset;
			max-width: 206px;
			order: unset;
			padding: 0;
			position: relative;
			right: 0;
			@include media-breakpoint-up(sm) {
				padding: 10px 8px 10px 10px;
			}
			>form {
				button {
					bottom: auto;
					height: 18px;
					top: auto;
					width: 18px;
					span {
						color: $navy;
					}
					i {
						padding: 0;
						&:before {
							color: $navy;
						}
					}
					&:hover {
						span {
							color: $tropic-breeze-dark;
						}
						i {
							&:before {
								color: $tropic-breeze-dark;
							}
						}
					}
				}
				input {
					background-color: $white;
					border-bottom: solid 1px $navy-dark;
					color: $navy;
					visibility: visible;
					@include media-breakpoint-down(md) {
						border-bottom: unset;
					}
					&:focus-within,
					&:hover {
						background-color: unset;
						color: $navy-dark;
						visibility: unset;
					}
					&::placeholder {
						color: $mid-gray;
					}
				}
			}
		}
		&-message-bar {
			background-color: $chill-mint-green-light;
			min-height: 26px;
			&__container {
				display: flex;
				width: 100%;
				@include media-breakpoint-up(lg) {
					margin: 0 auto;
					max-width: $max-width-xxl;
				}
				> button > svg {
					display: block;
					fill: $navy;
				}
			}
			&-message {
				-ms-overflow-style: none;  /* Internet Explorer 10+ */
				justify-content: unset;
				overflow-x: auto;
				scroll-behavior: smooth;
				scroll-snap-type: x mandatory;
				scrollbar-width: none;  /* Firefox */
				&::-webkit-scrollbar { 
					display: none;  /* Safari and Chrome */
				}
			}
			&-item {
				align-items: center;
				display: flex;
				flex-basis: 100%;
				flex-shrink: 0;
				justify-content: center;
				scroll-snap-align: start;
				@include media-breakpoint-up(md) {
					flex-basis: 50%;
				}
				@include media-breakpoint-up(lg) {
					flex-basis: 33.33%;
				}
				i {
					color: $navy-dark;
					height: auto;
					width: 24px;
					&:before {
						font-size: 12px;
					}
				}
			}
		}
		&-account {
			.bat-form-block {
				.bat-form-field	{
					a {
						flex-direction: row;
						span {
							color: $navy;
							@include font-rem(15px, 15px);
						}
					}
				}
			}
		}
		.hide {
			display: none;
		}
	}
}

bat-header-zonnicheadless {
	.bat-header {
		&-utils {
			.bat-header-account {
				position: relative;
				@include media-breakpoint-between(xxs, xs) {
					position: unset;
				}

			}
			.bat-form--login-zonnic {
				background-color: $white;
				border-radius: 3px;
				box-shadow: 0 5px 10px 0 $mid-gray;
				display: none;
				min-width: 400px;
				padding: 30px;
				position: absolute;
				right: 0;
				top: 58px;
				width: 100%;
				z-index: 999;
			}
		}
		&-account {
			display: flex;
			form {
				> div:nth-child(4) {
					margin-bottom: 0;
				}
				> div:nth-child(5) {
					margin-bottom: 0;
					button {
						max-width: unset;
						width: 100%;
					}
					> div:first-of-type {
						button {
							background-color: $navy;
							border: solid 2px $navy;
							color: $white;
							transition: background-color .5s ease;
							span {
								color: $white;
								font-size: 12px;
								font-weight: 700;
								letter-spacing: .075rem;
								line-height: 14px;
							}
							&:hover {
								background-color: $navy-dark;
								border: solid 2px $navy-dark;
								span {
									color: $white;
									font-size: 12px;
									line-height: 14px;
								}
							}
							&.disabled,
							&:disabled {
								// Do not use CSS vars here; breaks 'button dark disabled' style
								background-color: $whisper-gray;
								border: solid 2px $whisper-gray;
								color: $concrete;
							}
						}
					}
				}
				> div:nth-child(6) {
					> div:first-of-type {
						margin-bottom: 0;
						a {
							background-color: transparent;
							border: solid 2px var(--border-color);
							color: var(--text-color);
							max-width: unset;
							transition: border .5s ease, color .5s ease;
							width: 100%;
							span {
								font-size: 12px;
								font-weight: 700;
								letter-spacing: .075rem;
								line-height: 14px;
							}
							i {
								svg {
									fill: var(--text-color);
								}
							}
							
							&:hover {
								background-color: transparent;
								color: var(--text-hover-color);
								
								span {
									color: var(--text-hover-color);
								}
								
								i {
									svg {
										fill: var(--text-hover-color);
									}
								}
							}
					
							&:focus,
							&:focus-visible {
								outline: var(--border-color) solid 1px;
								outline-offset: 5px;
							}
							
							&.disabled,
							&:disabled {
								border: solid 2px var(--disabled-border-color);
								color: var(--disabled-text-color);
							}
						}
					}
				}
				> div:nth-child(3) {
					display: none;
					&.active {
						display: block;
					}
				}
			}
			.bat-header-account-link {
				> button {
					span {
						&.bat-header-account-icon-label,
						&.bat-header-account-icon-label-auth {
							@include media-breakpoint-down(md) {
								display: none;
							}
						}
					}
				}
			}
		}
		&-account-link {
			&.logged-in {
				.bat-header-account-icon-label {
					display: none;
				}
				.bat-header-account-icon-label-auth {
					display: block;
				}
			}
		}
		&.logged-in {
			.bat-header-account-icon-label {
				display: none;
			}
			.bat-header-account-icon-label-auth {
				display: block;
			}
			bat-form-loginzonnic {
				&.logged-in {
					display: none !important;
				}
			}
			.bat-form--login-overlay {
				display: none;
			}
		}
	}
	.bat-form--login-overlay {
		background: rgba(0,0,0,.5);
		display: none;
		height: 100vh;
		position: absolute;
		top: 78px;
		width: 100%;
		z-index: 900;
		body.authorMode & {
			height: 900px;
		}
		main.style-guide & {
			top: 122px;
		}
	}
	&.active {
		.bat-header {
			header {
				z-index: unset;
			}
			.bat-form--login-zonnic {
				animation: slide-down 250ms ease;
				display: block;
				opacity: 1;
				visibility: visible;
			}
			&.logged-in {
				.bat-header-account-menu {
					display: block;
				}
			}
		}
		.bat-form--login-overlay  {
			display: block;
		}

	}
}

@keyframes slide-down {
	0%{
		opacity: 0;
		transform: translateY(-100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fade-out {
	0%{
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0.5);
	}
}

bat-header-zonnicLimited {
	.bat-header {
		background-color: $navy;
		color: $white;
		font-size: .75rem;
		letter-spacing: .02rem;
		line-height: .879rem;
		header {
			height: 66px;
			padding: 0 20px;
			@include media-breakpoint-up(lg) {
				height: 66px;
			}
		}

		&-logo {
			flex: unset;
			margin-right: unset;
			width: 83px;
		}

		&-cart,
		&-back {
			align-items: center;
			height: auto;
			padding: 0;
			@include media-breakpoint-up(sm) {
				padding: 0;
			}
			button {
				color: $white;
				display: flex;
				height: 32px;
				width: 32px;

				&:active {
					color: $light-blue;
				}

				.bat-icon {
					height: 32px;
					width: 32px;
					
					&:before {
						font-size: 32px;
					}
					&:hover {
						border: 0;

						&:before {
							color: $light-blue;
						}
					}
				}
				
				&:hover {
					border: 0;
					color: $light-blue;

					&:disabled {
						color: $white; // no hover on lock icon
						cursor: default;
						
						.bat-icon {
							&:before {
								color: $white;
							}
						}
					}
				}
			}
		}
		&-back {
			margin-right: auto;
		}
		&-cart {
			margin-left: auto;
		}
	}
}
