//TEXT
p {
	
	margin-bottom: $gutter / 2;
	
	.dark,
	&.dark {
		color: $black;
	}
	
	.light,
	&.light {
		color: $white;
	}
	
	a {
		color: inherit;
		text-decoration: underline;
	}
}

ol, 
ul {
	
	margin-bottom: $gutter;
	
	.dark,
	&.dark {
		color: $black;
	}
	
	.light,
	&.light {
		color: $white;
	}
	
	.no-style,
	&.no-style {
		list-style: none;
		margin: none;
		padding: none;
	}
	
	a {
		color: inherit;
	}
}
