.cart-demo {
	width: 100%;
	.cart-demo-header {
		padding: 1rem;
	}
	.cart-demo-columns {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		bat-cartdetail-default {
			width: 70%;
		}
		bat-cartsummary-default {
			margin-left: auto;
			width: 30%;
		}
	}
}

.bat-cartheader {
	display: block;
	&-button {
		display: none;
		&.show {
			display: block;
		}
	}
	&-message {
		display: block;
	}
}

.bat-cartheader-slot {
	display: none;
	&.show {
		display: block;
	}
}

.bat-cartsummary {
	display: none;
	&-details {
		background-color: $off-white;
		display: none;
		padding: 1rem;
		&.show {
			display: block;
		}
	}
	&.show {
		display: block;
	}
	hr {
		border-color: $gray;
	}
	&-label {
		margin-bottom: 1rem;
		.label {
			font-weight: bold;
		}
	}
	&-subtotal {
		display: flex;
		margin-bottom: 1rem;
		.value {
			margin-left: auto;
		}
	}
	&-deductions {
		margin-bottom: 1rem;
		.label {
			font-weight: bold;
			margin-bottom: .25rem;
		}
		.message {
			font-size: .8rem;
			font-style: italic;
			font-weight: 300;
		}
	}
	&-promos {
		margin-bottom: 1rem;
		.promo {
			display: flex;
			margin-bottom: .5rem;
			.value {
				margin-left: auto;
			}
		}
	}
	&-total {
		display: flex;
		margin-bottom: .25rem;
		h4 {
			small {
				font-size: 1rem;
			}
		}
		.label {
			font-weight: bold;
		}
		.value {
			margin-left: auto;
		}
	}
	&-tax-message {
		font-size: .8rem;
		font-style: italic;
		font-weight: 300;
		margin-bottom: 1rem;
		width: 100%;
	}
	&-generic-message {
		font-size: .8rem;
		font-style: italic;
		font-weight: 300;
		margin-bottom: 1rem;
		text-align: center;
		width: 100%;
	}
	&-button {
		text-align: center;
	}
}

.bat-cartsummary-slot {
	display: none;
	&.show {
		display: block;
	}
}

.bat-cartdetail {
	display: none;
	padding: 1rem;
	width: 100%;
	&.show {
		display: block;
	}
	&-item {
		align-items: center;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 1rem;
		width: 100%;
		.image {
			margin-right: 2rem;
			width: auto;
			img {
				height: 100px;
				width: auto;
			}
		}
	}
	&-details {
		align-items: flex-start;
		display: flex;
		justify-content: flex-start;
		width: 100%;
		.right {
			align-items: flex-end;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: auto;
		}
		.quantity {
			align-items: center;
			display: flex;
			justify-content: flex-start;
			.bat-quantity {
				margin: 0;
				margin-left: 1rem;
				padding: 0;
			}
		}
		.prices {
			strike {
				margin-right: .5rem;
			}
		}
	}
}
