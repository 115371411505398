
.order-details {
	h1 {
		margin-top: 30px;
		@include media-breakpoint-down(md) {
			@include font-rem(22px, 26px);
			text-align: center;
		}
	}
	h2 {
		@include font-rem(22px, 26px);
		font-weight: 800;
		margin-top: 40px;

		@include media-breakpoint-down(md) {
			@include font-rem(20px, 20px);
			text-align: center;
		}
	}

	.account-order-details {
		display: block;
		margin-top: 20px;

		p {
			@include font-rem(14px, 20px);
			span {
				color: $navy;
				display: inline-block;
				font-weight: 700;
				width: 104px;
			}
		}
	}

	.bat-cta {

		@include media-breakpoint-down(md) {
			width: 100%;
		}

		.btn-buy-again {
			margin: 16px 0;
	
			@include media-breakpoint-down(md) {
				min-width: 100%;
				width: 100%;
			}
		}
	}
	

	.account-ordered {
		&-items {
			color: $navy;
			
			.product {
				&-ordered {
					align-items: flex-start;
					border-bottom: 2px solid $whisper-gray;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					margin: 24px 0;
					padding-bottom: 16px;
					width: 100%;
					//background-color: bisque;

					&:last-of-type {
						border: 0;
					}
					
				}

				&-image {
					img {
						height: auto;
						width: 122px;

						@include media-breakpoint-down(md) {
							width: 100px;
						}
					}
				}
				&-content {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding-left: 36px;
					width: 100%;

					@include media-breakpoint-down(md) {
						flex-direction: column;
					}
				}

				&-name {
					@include font-rem(20px, 28px);
					font-weight: 600;
					padding-top: 16px;

					span {
						@include font-rem(14px, 20px);
						display: block;
						font-weight: 300;
						text-transform: capitalize;
					}

					@include media-breakpoint-down(md) {
						@include font-rem(18px, 25px);
						padding-top: 0;
					}
				}

				&-qty {
					@include font-rem(12px, 14px);
					font-weight: 700;
					padding-top: 16px;
					white-space: nowrap;
				}

				&-price {
					@include font-rem(20px, 28px);
					font-weight: 700;
					padding-top: 16px;
					@include media-breakpoint-down(md) {
						@include font-rem(18px, 25px);
						padding-top: 8px;
					}
				}	
			}
		}
	}

	.totals-and-taxes {
		background-color: $whisper-gray;
		margin: 40px auto;
		padding: 24px;
		width: 80%;

		@include media-breakpoint-down(md) {
			width: 100%
		}

		p {
			@include font-rem(12px, 14px);
			color: $navy;
			display: flex;
			font-weight: 700;
			justify-content: space-between;
			text-transform: uppercase;

			span {
				@include font-rem(14px, 20px);
				font-weight: 300;
				text-align: right;
			}

			&.order-discount {
				color: $tropic-breeze-dark;

				span {
					font-weight: 700;
				}
			}

			&.grand-total {
				@include font-rem(22px, 26px);
				font-weight: 800;

				span {
					@include font-rem(22px, 26px);
					font-weight: 800;
				}
			}
		}

		hr {
			border-top: 2px solid $navy;
			margin: 16px 0;

			&.hr-taxes {
				border-top: 2px solid $pale-gray;
				margin-bottom: 24px;
			}
		}
	}

	.order-information {
		display: flex;
		margin-top: 40px;

		@include media-breakpoint-down(md) {
			display: block;
			margin-top: 0;
		}

		div {
			padding-right: 32px;

			@include media-breakpoint-up(md) {
				width: 25%
			}
			@include media-breakpoint-down(md) {
				padding-right: 0;
				padding-top: 25px;
				width: 100%
			}
		}

		p {
			@include font-rem(14px, 20px);
			font-weight: 300;
		}
		.heading {
			color: $navy;
			font-weight: 700;
			margin-bottom: 16px;
		}
	}
}
