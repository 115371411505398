.bat-contentfragment-em {
    &__wrapper {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) -0.47%, rgba(0, 0, 0, 0.00) 10.06%), linear-gradient(180deg, rgba(255, 255, 255, 0.00) 1.06%, rgba(215, 219, 229, 0.72) 100%), #FFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
        padding: 33px 10px 90px;
    }
    .form-block {

        padding-left: 12px;
        padding-right: 12px;
    }
    .form-title {
        color: #2B363B;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 140.063%;
        margin-bottom: 31px;
    }
    &__countries {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 51px;
		width: 299px;
		max-width: 100%;
		height: 45px;
		border: 1px solid #CFCFCF;
		border-radius: 5px;
		background: #FFFFFF;
		outline: none;
		font-size: 16px;
		line-height: 24px;
		padding: 4px 35px 4px 8px;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-repeat: no-repeat;
		background-position: right 20px center;
		background-image: url('data:image/svg+xml, <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.01453 4.81251L6.56438 3.1324C7.28751 2.34907 8.01086 1.56595 8.73443 0.783037C8.96099 0.538544 9.2594 0.470285 9.55881 0.583223C9.8482 0.692128 10.0337 0.94748 9.99489 1.24441C9.97632 1.38654 9.91545 1.52106 9.81912 1.63286C8.40918 3.17366 6.99456 4.71022 5.57525 6.24254C5.25412 6.58942 4.76758 6.58384 4.44679 6.23757C3.02258 4.69429 1.59972 3.15008 0.178187 1.60494C-0.0854672 1.31763 -0.0520509 0.898457 0.248696 0.663273C0.564814 0.415057 0.993211 0.45322 1.27157 0.754802C2.46052 2.04345 3.64914 3.33242 4.83742 4.62169C4.88955 4.68126 4.94469 4.73804 5.01453 4.81251Z" fill="rgb(98,97,97)"/></svg>');
		color: #000;
		&:focus {
			outline: none;
		}
		option {
			font-size: 16px;
		}
		
	}
	&__item {
		display: none;
		&.active {
			display: block;
		}
	}
    .block-item {
        padding: 32px 19px;
        border-top: 1px solid #D0D0D0;
        &__logo {
            margin-bottom: 28px;
        }
        &__desc {
            color: #000;
            font-size: 15px;
            font-weight: 400;
            line-height: 136%;
            letter-spacing: 0.225px;
            margin-bottom: 26px;
        }
        &__buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            a {
                word-break: break-word;
            }
            a + a {
                margin-left: 5px;
            }
            svg {
                margin-left: 6px;
                flex-shrink: 0;
            }
            .bat-cta-style {
                margin-right: 10px;
                padding: 10px 20px;
                line-height: 20px;
                min-height: 40px;
                white-space: nowrap;
                flex-shrink: 0;
            }
            .learn-more {
                margin-left: auto;
                color: #0047B8;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-decoration-line: underline;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                svg {
                    transition: transform 0.4s ease;
                }
                &:hover {
                    svg {
                        transform: translateX(5px);
                    }
                }
            }
        }
    }
}

[dir=rtl] {
    .bat-contentfragment-em .block-item__buttons {
        svg {
            margin-right: 6px;
            margin-left: 0;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
    }
    .bat-contentfragment-em .block-item__buttons .bat-cta-style {
        margin-right: 0;
        margin-left: 10px;
    }
    .bat-contentfragment-em .block-item__buttons .learn-more {
        margin-left: 0;
        margin-right: auto;
    }
}