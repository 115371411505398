bat-productlisting-zonnic {
	.bat-productlisting--zonnic {
		background-color: $whisper-gray;
		margin: 0 auto;
		max-width: unset;
		padding: 0 0 20px;
		position: relative;
		width: 100%;

		.bat-productlisting-cards {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			max-width: $site-max-width;

			&__error-text {
				margin: 20px;
				text-align: center;
			}

			.errors-wrapper {
				ul {
					display: none;
				}
			}

			.productcard {
				background-color: $white;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-bottom: 7px;
				max-width: unset;
				padding: 20px 0;
				position: relative;
				width: 100%;

				&-content {
					display: flex;
					flex-direction: row;
					margin: 0 auto 20px;
					max-width: calc(100% - 40px);
					width: 100%;

					.productcard-image {
						flex: 1 0 115px;
						height: auto;
						margin: 0;

						a,
						img {
							display: block;
							height: auto;
							width: 100%;
						}
					}

					.productcard-text {
						display: flex;
						flex-direction: column;
						max-width: 375px;
						padding-left: 26px;
						position: relative;
						width: auto;

						&-description {
							display: block;
							text-align: left;

							* {
								@include font-rem(12px, 18px);
								letter-spacing: 0;
							}

							a {
								color: $navy;
								font-weight: 700;
								text-decoration-thickness: 1px; // sass-lint:disable-line no-misspelled-properties
								text-underline-offset: 1px; // sass-lint:disable-line no-misspelled-properties
							}

							p {
								color: $mid-gray;
								font-weight: 300;
								margin-bottom: 10px;
								margin-top: 0;

								&:last-of-type {
									margin-bottom: 0;
								}
							}
						}

						&-name {
							@extend .headline4-subtitle;
							color: $navy;
							margin-bottom: 8px;
							text-align: left;
							text-transform: capitalize;
						}

						&-out-of-stock {
							text-align: left;
						}

						&-price {
							@extend .headline4;
							color: $navy;
							margin: 0 0 11px;
							text-align: left;
							vertical-align: middle;

							.base-price {
								color: $navy;
							}

							.sale-price {
								color: $system-red;
								display: none;
								margin-right: 10px;
							}

							&.on-sale {
								.sale-price {
									display: inline;
								}

								.base-price {
									@include font-rem(18px, 28px);
									text-decoration: line-through;
								}

								/* Render a slash across the price
								position: relative;						
								&:before {
									-moz-transform: rotate(-5deg);
									-ms-transform: rotate(-5deg);
									-o-transform: rotate(-5deg);
									-webkit-transform: rotate(-5deg);
									border-color: $dark-gray;
									border-top: 2px solid;
									content: '';
									left: 0;
									position: absolute;
									right: 0;
									top: 50%;
									transform: rotate(-5deg);
								} */
							}
						}

						&-promo {
							background-color: rgba($navy, .1); // sass-lint:disable-line no-color-literals
							color: $navy;
							margin-bottom: 10px;
							padding: 10px 5px 8px;
							position: relative;
							text-align: center;

							// * {
							// 	display: block;
							// }

							// hide icon by default
							.icon-info {
								background-color: transparent;
								display: none;
								line-height: 100%;
								position: absolute;
								right: 15px;
								top: 6px;

								&:hover {
									cursor: pointer;
								}
							}

							.content-wrap {
								margin-right: 24px;
								position: relative;
							}

							.text {
								@include font-rem(12px, 18px);
								display: block;
								font-weight: 300;
							}

							.title {
								@include font-rem(12px, 14px);
								display: block;
								font-weight: 700;
								letter-spacing: .5px;
								text-transform: uppercase;
							}
						}

						&-quantity {
							display: none;
						}

						// Start of tool tip style
						&-tooltip {
							@include font-rem(12px, 18px);
							background-color: $light-blue;
							bottom: 120%;
							display: none;
							left: 0;
							padding: 20px;
							position: absolute;
							width: 100%;

							&.tooltip-active {
								display: block !important;
							}

							&:before {
								border: solid transparent;
								border-bottom-color: $light-blue;
								border-width: 16px;
								bottom: -28px;
								content: '';
								margin-right: -6px;
								position: absolute;
								right: 20px;
								transform: rotate(180deg);
								z-index: 1;
							}
						}

					}
				}

				&-controls {
					border-top: 1px solid $pale-gray;
					display: flex;
					flex-direction: column;
					margin: 0 auto;
					max-width: calc(100% - 40px);
					padding-top: 20px;
					width: 100%;

					.bat-quantity {
						@extend .bat-button;
						@extend .bat-button--secondary--dark;
						display: flex;
						justify-content: space-between;
						margin: 0 0 10px;
						max-width: unset;
						padding: 7px 20px;
						width: 100%;

						&-button {
							background-color: transparent;

							&:hover i::before {
								// sass-lint:disable-line force-element-nesting force-pseudo-nesting
								color: $navy;
							}

							&:focus {
								outline: 1px solid $navy;
							}
						}

						&-field {
							pointer-events: unset;
						}
					}

					.productcard-ctas {
						margin: 0;
						width: 100%;

						a,
						button {
							max-width: unset;
							padding: 15px 20px;
							width: 100%;

							&.arrow-link-dark {
								justify-content: flex-start;
							}
						}
					}
				}
			}
		}

		.loading-wrapper {
			align-items: center;
			display: flex;
			height: 110px;
			position: relative;

			p {
				font-size: 12px;
				text-align: center;
				width: 100%;
			}

			&:after {
				-webkit-animation: spin 2s linear infinite;
				/* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $concrete;
				border-radius: 50%;
				border-top: 10px solid $system-blue;
				bottom: 0;
				content: '';
				height: 100px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 100px;
				z-index: 1;
			}
		}

	}
}

// viewport 576+
@include media-breakpoint-up(sm) {
	bat-productlisting-zonnic {
		.bat-productlisting--zonnic {

			.bat-productlisting-cards {

				.productcard {

					&-content {
						.productcard-image {
							flex: 1 0 160px;
						}

						.productcard-text {
							max-width: 520px;

							&-promo {
								padding: 10px 15px 8px;
								text-align: left;
							}
						}
					}

					&-controls {
						flex-direction: row;

						.bat-quantity {
							margin: 0 5px 0 0;
							width: 50%;
						}

						.productcard-ctas {
							flex: 1 0 50%;
							margin: 0 0 0 5px;

							a {
								max-width: unset;
								padding: 15px;
							}
						}
					}

				}

			}

		}
	}
}

// viewport 769+
@media (min-width: ($breakpoint-md + 1)) {
	bat-productlisting-zonnic {
		.bat-productlisting--zonnic {

			.bat-productlisting-cards {
				flex-direction: row;

				.productcard {
					margin: 0 3.5px 7px;
					width: calc(33.3333% - 7px);

					&-content {
						flex-direction: column;

						.productcard-image {
							margin-bottom: 10px;
							width: 100%;
						}

						.productcard-text {
							padding-left: 0;
							width: 100%;

							.icon-info {
								display: none;
							}
						}
					}

					&-controls {
						flex-direction: column;

						.bat-quantity {
							display: flex;
							justify-content: space-between;
							margin: 0 0 10px;
							max-width: unset;
							padding: 7px 20px;
							width: 100%;

							&-button {
								background-color: transparent;

								&:hover i::before {
									// sass-lint:disable-line force-element-nesting force-pseudo-nesting
									color: $navy;
								}
							}
						}

						.productcard-ctas {
							margin: 0;
							width: 100%;

							a {
								max-width: unset;
								padding: 15px 20px;
								width: 100%;
							}
						}
					}
				}

			}

		}
	}
}

// viewport 992+
@include media-breakpoint-up(lg) {
	bat-productlisting-zonnic {
		.bat-productlisting--zonnic {
			.bat-productlisting-cards {
				.productcard {
					width: calc(25% - 7px);

					&-text-promo {
						.icon-info {
							display: block;
						}
					}

					&-text-tooltip {
						padding: 20px;
					}
				}
			}
		}
	}
}

// viewport 1500+
@include media-breakpoint-up(xl) {
	bat-productlisting-zonnic {
		.bat-productlisting--zonnic {
			.bat-productlisting-cards {
				.productcard {
					&-content {
						.productcard-text-promo {
							.icon-info {
								display: block;
							}
						}
					}

					&-controls {
						flex-direction: row;

						.bat-quantity {
							margin: 0 5px 0 0;
							width: 50%;
						}

						.productcard-ctas {
							flex: 1 0 50%;
							margin: 0 0 0 5px;

							a,
							button {
								max-width: unset;
								padding: 15px;
							}
						}
					}
				}
			}
		}
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
