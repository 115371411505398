//FORMS
form {
	
	label {
		@include font-rem(14px, 14px);
		margin-bottom: 20px;
		margin-top: 0;
	}
	
	.error-msg {
		@include font-rem(14px, 14px);
		color: $red;
		padding: 10px 0 0;
	}
	
	//INPUTS
	input {
		border: solid 2px $gray;
		height: 57px;
		padding: 7px 15px;
		width: 100%;

		&::-webkit-input-placeholder {
			color: $gray;
		}
		
		&:-moz-placeholder {
			/* FF 4-18 */
			color: $gray;
		}
		
		&::-moz-placeholder {
			/* FF 19+ */
			color: $gray;
		}
		
		&:-ms-input-placeholder {
			/* IE 10+ */
			color: $gray;
		}
		
		&::-ms-input-placeholder {
			/* Microsoft Edge */
			color: $gray;
		}
		
		&::placeholder {
			/* modern browser */
			color: $gray;
		}

		& + .error-msg {
			display: none;
		}
		
		&.active,
		&:focus {
			border: solid 2px $blue;
			outline: none;
		}
		
		&.complete {
			border: solid 2px $black;
		}
		
		&.error {
			border: solid 2px $red;
			
			& + .error-msg {
				display: inline-block;
			}
		}
		
		&:disabled {
			cursor: not-allowed;
			opacity: .5;
			pointer-events: none;
		}
	}
	
	//TEXTAREA
	textarea {
		@include font-rem(14px, 14px);
		
		border: solid 2px $gray;
		height: 150px;
		padding: 10px;
		width: 100%;
	
		&::-webkit-input-placeholder { 
			color: $black;
		}
		  
		&:-ms-input-placeholder {
			color: $black;
		}
		  
		&::placeholder {
			color: $black;
		}
		
		& + .error-msg {
			display: none;
		}
		
		&:focus {
			border: solid 2px $blue;
			outline: none;
		}
		
		&.error {
			border: solid 2px $red;
			
			& + .error-msg {
				display: block;
			}
		}
		
		&.complete {
			border: solid 2px $black;
		}
		
		&:disabled {
			opacity: .5;
			pointer-events: none;
		}

	}
	
	//SELECT MENUS
	.select-wrapper {
		overflow: hidden;
		position: relative;
		
		select {
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			background: none; 
			border: solid 2px $gray;
			height: 57px;
			padding: 13px;
			position: relative;
			width: 100%;
			
			&::-ms-expand {
				display: none;
			}
			
			& + .error-msg {
				display: none;
			}
			
			&.disabled {
				opacity: .5;
				pointer-events: none;
			}
			
			&.active {
				border: solid 2px $blue;
			}
			
			&.error {
				border: solid 2px $red;
				
				& + .error-msg {
					display: block;
				}
			}
			
			&.complete {
				border: solid 2px $black;
			}
		}
		
		&:after {
			color: $black;
			content: '\f078';
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			height: 34px;
			pointer-events: none;
			position: absolute;
			right: 14px;
			top: 22px;
		}
	}
	
	//RADIO and CHECKBOX
	fieldset {
		border: 0;
		
		label {
			display: flex;
			margin-bottom: 5px;
			
			&.disabled {
				opacity: .5;
				pointer-events: none;
			}
			
			& > input {
				
				&[type=checkbox],
				&[type=radio] {
					
					display: inline;
					height: auto;
					margin: 0 20px 0 0;
					position: relative;
					width: auto;
					
					&:before {
						background-color: $white;
						color: $dark-50;
						content: '';
						display: inline-block;
						font-family: 'Font Awesome 5 Free';
						font-size: 22px;
						height: 22px;
						position: relative;
						width: 22px;
					}
					
					&:checked {
						
						&:before {
							color: $black;
							content: '';
							display: inline-block;
							font-family: 'Font Awesome 5 Free';
						}
						
						+ span {
							@include font-rem(14px, 14px);
							color: $black;
							display: inline-block;
							padding-top: 4px;
						}
					}
					
					+ span {
						@include font-rem(14px, 14px);
						color: $dark-50;
						display: inline-block;
						padding: 4px 0;
					}
				}
				
				&[type=checkbox]  {
					
					&:before {
						content: '\f0c8';
					}
					
					&:checked {
						&:before {
							content: '\f14a';
						}
					}
				}
				
				&[type=radio] {
					
					&:before {
						content: '\f111';
					}
					
					&:checked {
						&:before {
							content: '\f192';
						}
					}
				}
			}
		}
		
		& + .error-msg {
			display: none;
		}
		
		&.error {
			
			label {
				
				& > input {
						
					&[type=checkbox],
					&[type=radio] {
				
						&:before {
							color: $red;
						}
					}
					
					+ span {
						color: $red;
					}
				}
			}
			
			& + .error-msg {
				display: block;
			}
		}
	}
}
