$warning-height: 40px;

.bat-header {
	position: relative;
	
	.bat-wrapper {
		position: relative;
	}

	header {
		align-content: center;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: $gutter;
		position: relative;
		z-index: 1;
		
		@include media-breakpoint-up(lg) {
			margin: 0 auto;
			max-width: $max-width-xxl;
        }
	}
	
	&-logo {
		flex: 0 0 auto;
		margin-right: auto;
	
		a {
			display: inline-block;
		}
	}
	
	&-menu {
		background-color: $white;
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		justify-content: flex-start;
		left: 0;
		max-height: 0;
		overflow: hidden;
		position: absolute;
		width: 100%;
	
		&.open {
			display: flex;
			max-height: 1000px;
			z-index: $mobile-menu;
		}
		
		@include media-breakpoint-up(lg) {
			align-content: center;
			align-items: center;
			display: flex;
			flex-direction: row;
			left: auto;
			max-height: none;
			overflow: visible;
			padding: 0;
			position: relative;
			top: auto;
		}
	}
	
	&-nav {
		flex: 1 1 auto;
		order: 2;
		padding: $gutter;
		
		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: center;
			order: 1;
			padding: 0;
		}
	}
	
	&-social-nav {
		align-items: center;
		display: flex;
		flex: 0 0 auto;
		justify-content: center;
		order: 3;
		padding: $gutter;
		
		ul {
			align-items: center;
			display: flex;
			justify-content: center;
			list-style: none;
			margin: 0;
			padding: 0;
			
			li {
				margin: 0;
				
				a {
					display: block;
					padding: 0 $gutter / 2;

					svg {
						height: 30px;
						width: 30px;
					}
				}
			}
		}
		
		@include media-breakpoint-up(lg) {
			display: none;
			order: 4;
		}
	}
	
	&-search {
		margin: 0 auto;
		max-width: 340px;
		order: 1;
		padding: $gutter;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			flex: 1 1 auto;
			order: 2;
			padding: 0 $gutter;
		}
		
		form {
			align-items: center;
			display: flex;
			position: relative;
			
			input {
				height: auto;
				padding: 5px 30px 5px 10px;
				width: 100%;
			}
		}
		
		&-button {
			background: none;
			border: 0;
			cursor: pointer;
			height: 24px;
			padding: 2px;
			position: absolute;
			right: 5px;
			top: 3px;
			width: 24px;
			
			i {
				font-size: 20px;
				
				&:before {
					font-size: 20px;
				}
			}
			
			span {
				display: none;
			}
		}
	}
	
	&-support,
	&-stores,
	&-cart,
	&-account {
		flex: 0 0 auto;
		margin: 0;
		order: 3;
		padding: $gutter / 2;
		text-align: center;
		
		a {
			text-align: center;
				
			i {
				margin: 0 auto;
				
				&:before {
					color: $gray;
				}
				
				svg {
					fill: $gray;
				}
			}
			
			span {
				@include font-rem(10px, 10px);
			}
		}
		
		@include media-breakpoint-up(lg) {
			margin: 0;
		}
	}
	
	&-account { 
		position: relative;
		
		&.open {
			
			a {
			
				i {
					&:before {
						color: $black;
					}
					
					svg {
						fill: $black;
					}
				}
			}
		}
		
		&-menu {
			background-color: $white;
			display: none;
			left: 50%;
			margin: 0;
			padding: 20px;
			position: absolute;
			transform: translate(-50%, 3%);
			width: 136px;
			z-index: $account;
			
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				
				li {
					margin: 0;
					
					a {
						padding: 10px 0;
					}
				}
			}
			
			.open & {
				display: block;
			}
		}

		&-icon-label-loading {
			display: none;

			.loading & {
				display: inline;
			}
			
			.authenticated & {
				display: none;
			}
		}
		
		&-icon-label {
			display: inline;

			.loading & {
				display: none;
			}
			
			.authenticated & {
				display: none;
			}
		}
		
		&-icon-label-auth {
			display: none;
			
			.loading & {
				display: none;
			}

			.authenticated & {
				display: inline;
			}
		}
	}
	
	// sass-lint:disable class-name-format
	
	&-cart {
		position: relative;
		
		&-label {
			visibility: hidden;
			
			.cartEmpty & {
				visibility: visible;
			}
		}
		
		&-count {
			bottom: 10px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			visibility: visible;
			
			.cartEmpty & {
				visibility: hidden;
			}
		}
		
		span {
			@include font-rem(10px, 10px);
		}
	}
	
	&-fixed-spacer {
		height: $warning-height;
	}
	
	&-content {
		
		.bat-text {
			align-items: center;
			background-color: $white;
			display: flex;
			flex-wrap: wrap;
			height: $warning-height;
			justify-content: center;
			left: 0;
			margin: 0 auto;
			max-width: $max-width-xxl;
			position: fixed;
			right: 0;
			top: 0;
			z-index: $header-z-index;
		}
	}
	
	&-menu-button {
		align-content: center;
		align-items: center;
		display: flex;
		flex: 0 0 auto;
		flex-direction: column;
		order: 4;
		padding: $gutter / 2;
		
		@include media-breakpoint-up(lg) {
			display: none;
		}
		
		&.active {
				
			button {
				
				span {
					background-color: $white;
					
					&:before {
						top: 0;
						transform: rotate(45deg);
					}
					
					&:after {
						top: 0;
						transform: rotate(-45deg);
					}
				}
				
				i {
				
					&:first-child {
						display: none;
					}
					
					&:last-child {
						display: block;
					}
				}
			}
		}
		
		button {
			display: flex;
			flex: 0 0 auto;
			height: 24px;
			width: 24px;
			
			span {
				align-self: center;
				background-color: $black;
				display: inline-block;
				height: 3px;
				margin: 10px 0;
				position: relative;
				width: 100%;
			
				&:before {
					background-color: $black;
					content: '';
					height: 3px;
					left: 0;
					position: absolute;
					top: -8px;
					width: 100%;
				}
				
				&:after {
					background-color: $black;
					content: '';
					height: 3px;
					left: 0;
					position: absolute;
					top: 8px;
					width: 100%;
				}
			}
			
			
			i {
				
				&:first-child {
					display: block;
				}
				
				&:last-child  {
					display: none;
				}
			}
		}
		
		&-close-label,
		&-open-label {
			@include font-rem(10px, 10px);
			display: block;
			height: auto;
			margin-top: 3px;
		}
		
		&-open-label {
			
			.active & {
				display: none;
			}
		}
		
		&-close-label {
			display: none;
			
			.active & {
				display: block;
			}
		}
	}
	
	&-message-bar {
		align-content: center;
		align-items: center;
		background-color: $off-white;
		display: flex;
		justify-content: center;
		padding: 8px 20px;
		
		&-message {
			align-content: center;
			align-items: center;
			display: flex;
			justify-content: center;
			
			i {
				margin-right: 16px;
			}
			
			span {
				
				p {
					@include font-rem(14px, 14px);
					margin-bottom: 0;
				}
			}
		}
	}
}
