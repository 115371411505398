.bat-form--signup {

	.bat-form-field-tip i span {
		font-size: 12px;
		line-height: 18px;
	}

	.field-note{
		padding-top: 10px;
		#province-selector-link {
			cursor: pointer;
		}
	}
	
	.field-note a:hover {
		cursor: pointer;
	}

	form {
		fieldset {
			border: 1px solid #dedede;
			padding: 20px;

			label {
				align-items: flex-start;
				margin-bottom: 0;

				& > input[type='checkbox'] {
					margin: unset;
					margin-right: 22px;
					top: 5px;
					width: 64px;

					& + span,
					&:checked + span {
						padding-bottom: 0;
					}
				}

				p {
					font-size: 12px;
					line-height: 18px;
					margin-bottom: 12px;

					&:last-child {
						margin-bottom: 0;
					}
				}
				
			}

		}
	}

	p {
		a {
			color: $navy-dark;
			font-weight: 600;
			text-decoration-thickness: 1px;
			text-underline-offset: 2px;
		}
	}

	&-recaptcha {
		input[name='recaptcha'] {
			border-bottom: 0;
			height: 0;
			margin-bottom: 0;
			padding: 0;
			position: absolute;
			width: 0;
			z-index: -1;
		}
	}

	&-submit {
		text-align: center;
		
		@include media-breakpoint-up(sm) {
			text-align: left;
		}
	}
}

#terms-conditions-modal { // sass-lint:disable-line no-ids, id-name-format

	.bat-modal-content {
		max-width: 668px;
		padding-left: 20px;
		padding-right: 20px;

		@include media-breakpoint-up(sm) {
			padding-left: 80px;
			padding-right: 80px;
		}

		@include media-breakpoint-up(xxl) {
			padding-left: 120px;
			padding-right: 120px;
		}
	}
}
