// File:           _variables.scss
// Description:   Variables Styles
/*! Style Variables
sm
sm light
sm dark-gray
sm dark
dark
lg
lg light
lg dark-gray
lg dark
xl
xl light
xl dark-gray
xl dark
subtitle
subtitle light
subtitle dark-gray
headline6 light
headline6-color1
bullet--numbered
bullet--icon-place
bullet--icon-embrace
*/

//Colors
// $black: #000;
// $orange: #ef7831;
// $orange-2: #f19c1a;
// $orange-3: #f5b90e;
// $orange-10: rgba(239, 123, 49, .1);
// $yellow: #f9d602;
// $green: #007955;
// $green-10: rgba(0, 121, 85, .1);
// $teal: #008996;
// $teal-light: #1ecad3;
// $teal-dark: #018993;
// $blue-light: #00aed6;
// $white: #fff;

//Gray Palette
// $gray-dark: rgba(59, 59, 60, 1);
// $gray-dark-10: rgba(59, 59, 60, .1);
// $gray-dark-30: rgba(59, 59, 60, .3);
// $gray-dark-50: rgba(59, 59, 60, .5);
// $gray-dark-90: rgba(59, 59, 60, .9);
// $gray-medium: #d1d3d4;
// $gray-light: #f2f2f2;
// $gray-dark: #8b8b8b;
// $button-text: #3b3b3c;
// $font-color: #333;

//Gradients
// $teal-gradient: linear-gradient(90deg, $teal-dark, $teal-light);
// $main-gradient: linear-gradient(90deg, $orange-2 0%, $yellow 100%);
// $tooltip-stroke-gradient: linear-gradient(90deg, $orange-2 0%, $orange-3 100%);

//2.0 colors
// $light-blue: #00a2c7;
// $purple: #5862bf;
// $purple-hover: #5058a6;
// $green: #4f868e;
// $red: #d12c38;
// $dark-blue-hover: #143564;
// $dark-blue-90: rgba(4, 30, 66, .9);
// $dark-blue-80: rgba(4, 30, 66, .8);
// $extra-dark-blue: #001734;
// $bronze: #e98852;
// $silver: #b4c7d8;
// $gold:  #fcb549;
// $age-gate-black: #2a2c2c;

// Zonnic
$navy: #182465;
$navy-dark: #141e53;
$zonnic-blue: #c0c3d2;
$light-blue: #eef5ff;
$white: #fff;
$white-50: rgba($white, 0.5);
$black: #000000;
$chill-mint-green-light: #a0ff9d;
$chill-mint-green-dark: #009e9e;
$tropic-breeze-light: #f9ab18;
$tropic-breeze-dark: #ad1f8c;
$berry-frost-light: #3ec7f4;
$berry-frost-dark: #9980b7;
$sustainability-dark: #8ecd00;
$sustainability-light: #ccff09;
// Zonnic Gradients
$chill-mint-green-gradient: linear-gradient(
	180deg,
	$chill-mint-green-dark 15%,
	$chill-mint-green-light 85%
);
$tropic-breeze-gradient: linear-gradient(
	180deg,
	$tropic-breeze-dark 15%,
	$tropic-breeze-light 85%
);
$berry-frost-gradient: linear-gradient(
	180deg,
	$berry-frost-dark 15%,
	$berry-frost-light 85%
);
$sustainability-gradient: linear-gradient(
	180deg,
	$sustainability-dark 15%,
	$sustainability-light 85%
);
// GTR Gradients
$blue-green-gradient: linear-gradient(315deg, #009689 0%, #0047b8 100%);
$green-gradient: linear-gradient(315deg, #b4ffdf 0%, #028472 50.31%);
$blue-gradient: linear-gradient(315deg, #b4faff 0%, #0047b8 50.31%);
$gray-gradient: linear-gradient(315deg, #ffffff 0%, #f4f4f4 100%);
// Zonnic Neutrals
$gray: #cccccc;
$light-gray: #efefef;
$dark-gray: #8e8e8e;
$mid-gray: #616069;
$concrete: #9a9ca8;
$pale-gray: #dedede;
$whisper-gray: #f6f6f6;
$whisper-gray-50: rgba(#f6f6f6, 0.5); //sass-lint:disable-line no-color-literals
// Zonnic System Colors
$system-blue: #0047b8;
$system-green: #028472;
$system-red: #da4136;
$system-dark-mode-red: #ff6161;
$system-light-blue: #dae4fd;
$system-light-green: #00c7b1;
$system-light-red: #fdf5f5;
// Zonnic Component Color
$button-background: $system-red;
$cart-banner: #e3ffe2;
$vape-purple: #7d3679;

// opacity
$navy-10: rgba($navy, 0.1);
$black-10: rgba($black, 0.1);
$black-50: rgba($black, 0.5);
$black-60: rgba($black, 0.6);
$chill-mint-green-light-30: rgba($chill-mint-green-light, 0.3);

//2.0 Tints
$tint-error: $system-red;
$tint-points: $system-red;
$tint-multibuy: $system-red;

//2.0 Gray Palette
// $light-gray: #dedede;
// $gray: #aaa;
// $dark-gray: #686868;
// $dark-gray-10: rgba(107, 107, 107, .1);
// $dark-gray-20: rgba(107, 107, 107, .2);
// $dark-gray-30: rgba(107, 107, 107, .3);
// $dark-gray-40: rgba(107, 107, 107, .4);
// $dark-gray-50: rgba(107, 107, 107, .5);
// $dark-gray-60: rgba(107, 107, 107, .6);
// $dark-gray-70: rgba(107, 107, 107, .7);
// $dark-gray-80: rgba(107, 107, 107, .8);
// $dark-gray-90: rgba(107, 107, 107, .9);

//2.0 Gradients
// $bronze-gradient-1: #b3481a;
// $bronze-gradient-2: rgba(244, 140, 56, 0);
// $bronze-gradient: linear-gradient(217deg, $bronze-gradient-1 40.9%, $bronze-gradient-2 85.02%);
// $silver-gradient-1: #808aab;
// $silver-gradient-2: rgba(204, 226, 234, 0);
// $silver-gradient: linear-gradient(217deg, $silver-gradient-1 40.9%, $silver-gradient-2 85.02%);
// $gold-gradient-1: #f48c38;
// $gold-gradient-2: rgba(244, 140, 56, 0);
// $gold-gradient:  linear-gradient(217deg, $gold-gradient-1 40.9%, $gold-gradient-2 85.02%);
// $purple-gradient: linear-gradient(90deg, $purple-hover, $purple);

// Typography
$base-font: 16px; // 1rem = 16px;
$font-color: $mid-gray;

//Z-indexs
$mini-cart: 999;
$mobile-menu: 9999;
$account: $mobile-menu + 1;
$header-z-index: 900;
$modal-zindex: 999999;

//Header Heights
$header-height-mobile: 78px;
$warning-height: 108px;
$warning-height-mobile: 70px;
$warning-footer-height: 0;
$mobile-menu-height: calc(100vh - #{$header-height-mobile});
$icon-size: 24px;

// Typography
$font-xlg: $base-font; // no xlg font size
$font-lg: $base-font; // no lg font sizev
$font-md: 18px; //16px
$font-sm: 15px;
$font-xsm: 12px;
$primary-font: 'VELO Sans', sans-serif;
$secondary-font: $primary-font;
$google-lato: Lato, Arial, Helvetica, sans-serif;
$open-sans: Open Sans, Arial, sans-serif;

// Breakpoints
$grid-breakpoints: (
	xxs: 320px,
	xs: 376px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: $site-max-width,
);

$breakpoint-xxs: map-get($grid-breakpoints, 'xxs');
$breakpoint-xs: map-get($grid-breakpoints, 'xs');
$breakpoint-sm: map-get($grid-breakpoints, 'sm');
$breakpoint-md: map-get($grid-breakpoints, 'md');
$breakpoint-lg: map-get($grid-breakpoints, 'lg');
$breakpoint-xl: map-get($grid-breakpoints, 'xl');
$breakpoint-xxl: map-get($grid-breakpoints, 'xxl');

// Used only in sweeps
$bp-small-mobile-min: 350px;
$bp-small-mobile-max: 576px;
$bp-mobile-min: 576px;
$bp-mobile-max: 768px;
$bp-tablet-min: 768px;
$bp-tablet-max: 992px;
$bp-small-desktop-min: 992px;
$bp-small-desktop-max: 1200px;
$bp-desktop-min: 1200px;

// Dark Mode Settings
// Mixin to get past sass-lint bug
@mixin root-prop($prop: null, $value: null) {
	@if ($prop and $value) {
		#{$prop}: $value;
	}
}

// Default variables
:root {
	@include root-prop(--bg-color, #{$white});
	@include root-prop(--border-color, #{$pale-gray});
	@include root-prop(--text-color, #{$navy});
}

// Display dark mode based on operating system settings
// @media (prefers-color-scheme: dark) {
// 	:root {
// 		@include root-prop(--bg-color, #{$black});
// 		@include root-prop(--text-color, #{$white});
// 	}
// }

// Display dark mode via a class
.dark-mode {
	@include root-prop(--bg-color, #{$black});
	@include root-prop(--text-color, #{$white});
	background-color: var(--bg-color);
}


$letter-spacing_base: 0.04em;
$letter-spacing_s: 0.02em;