//BUTTONS
%button-base {
	@include font-rem(16px, 16px);
	align-items: center;
	background: none;
	border: 0;
	cursor: pointer;
	display: inline-flex;
	font-size: $base-font;
	justify-content: center;
	max-width: 300px;
	padding: 12px 30px;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	
	&.icon-left {
		padding: 12px 30px 12px 20px;
		
		i {
			margin-right: 5px;
		}
	}
	
	&.icon-right {
		padding: 12px 20px 12px 30px;
		
		i {
			margin-left: 5px;
		}
	}
	
	i,
	span {
		position: relative;
		z-index: 1;
	}
	
	
	i {
		height: 13px;
		width: 13px;
		
		&:before {
			font-size: 13px;
		}
	}
}

button {
	background: none;
	border: 0;
	cursor: pointer;
	padding: 0;
	outline: none;
	&:focus {
		outline: none;
	}
}

.bat-button {
	@extend %button-base;

	&.left {
		justify-content: flex-start;
	}
	
	&.right {
		justify-content: flex-end;
	}
	
	i {
		height: 20px;
		width: 20px;
	}
	
	&:hover {
		text-decoration: none;
	}
	
	&.disabled,
	&:disabled {
		cursor: not-allowed;
		opacity: .5;
		pointer-events: none;
	}
	
	&--dark {
		border: solid 1px $black;
		color: $black;
		
		&:hover {
			background: $gray;
		}
	}
	
	&--light {
		border: solid 1px $white;
		color: $white;
		
		&:hover {
			background: $gray;
		}
	}
	
	&--secondary {
		background: $gray;
		
		&:hover {
			background: transparent;
		}
	}
}
