bat-producthero-avalancheza {
	.bat-producthero {
		&--avalanche-za {
			background-color: $white;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			opacity: 0;
			padding: 0 0 20px;
			transition: opacity .2s ease-in;
			
			&.loaded {
				opacity: 1;
			}
			
			.bat-producthero-content {
				background-color: $white;
				width: 100%;
			}

			.bat-producthero-gallery {
				background-color: $concrete;
				display: flex;
				flex-direction: column;
				margin-bottom: 0;
				
				.slick-dots {
					display: none !important; // sass-lint:disable-line no-important
				}
				
				.slick-arrow {
					background-color: $system-red;
					border-radius: 30px;
					color: transparent;
					font-size: 0;
					height: 30px;
					margin-top: -15px;
					position: absolute;
					top: 50%;
					width: 30px;
					

					&:before {
						color: $white;
						content: '';
						display: inline-block;
						font-size: 20px;
						vertical-align: middle;
					}
					
					&.slick-prev {
						left: 20px;
						
						&:before {
							content: '\e90d';
							font-family: 'icomoon';
						}
					}
					
					&.slick-next {
						right: 20px;
						
						&:before {
							content: '\e90e';
							font-family: 'icomoon';
						}
					}
				}					
				
				&-images {
					line-height: 0;
					padding: 46px 0;
					.large {						
						background-position: calc(-40px + (-25 - (-40)) * ((100vw - 320px) / (575 - 320))) center;
						// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
						background-repeat: no-repeat;	
						background-size: contain;
						img {
							display: block;
							height: auto;
							margin: 0 auto;
							max-height: unset;
							position: relative;
							top: 2px;
							width: calc(225px + (345 - 225) * ((100vw - 320px) / (575 - 320)));
						}
					}
				}
				
				&-thumbs {
					display: none;
					flex-wrap: wrap;
					margin-top: 20px;
					padding: 0;
					
					.thumb {

						background-color: $white;
						background-position: 0 center;

						background-repeat: no-repeat;	
						background-size: auto 65%;
						border-top: 4px solid $white;
						height: 95px;
						margin: 2.5px;
						width: 95px;
						
						&.active {
							border-top: 4px solid $system-red;
							outline: none;
						}

						&.hide {
							visibility: hidden;
						}
						
						img {
							display: block;
							margin: 0 auto;
							width: 80%;
						}
					}
				}
			}
			
			.bat-producthero-configurator {
				display: flex;
				flex-direction: column;
				padding: 0;
				width: 100%;

				& > * {
					padding-left: 20px;
					padding-right: 20px;
				}
				
				&-bottom {
					flex-direction: row;
					justify-content: flex-start;
					margin-bottom: 43px;
					padding: 0 20px;
					text-align: left;
				}

				&-cta-list {
					flex: 1 1 auto;
					a,
					button {
						display: none;
						max-width: 300px;
						padding: 11px 0;
						width: 100%;
						&.active {
							display: flex;
						}
					}
				}

				&-price {
					flex: 0 1 140px;
					margin-bottom: 0;
					text-align: left;
				}
				
				&-subheading {
					@include font-rem(20px, 28px);
					font-weight: 500;
					letter-spacing: 1.5px;
					margin-bottom: 8px;
					padding-bottom: 0;
					padding-top: 0;
					text-align: left;
					text-transform: capitalize;
				}
				
				&-short-description {
					
					h6 {
						@include font-rem(14px, 14px);
						font-weight: 700;
						margin-bottom: 15px;
					}
				}
				
				&-description {
					@include font-rem(12px, 14px);
					color: $system-red;
					
					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						color: inherit;
						font-size: inherit;
						line-height: inherit;
						margin-bottom: 7px;
					}
					
					p {
						color: inherit;
						font-size: inherit;
						line-height: inherit;
						margin-bottom: 15px;
					}
					
					ul {
						margin-bottom: 15px;
						
						li {
							color: inherit;
							font-size: inherit;
							line-height: inherit;
						}
					}
				}
				
				&-stock {
					display: block;
					font-weight: 700;
					margin-bottom: 20px;
				}
				
				&-subscription {
					background-color: $concrete;
					margin: 0 auto;
					max-width: 325px;
					
					&-text {
						@include font-rem(12px, 12px);
						margin-top: 2px;
						
						.formatted-price {
							font-weight: normal;
						}
						
						&-note {
							@include font-rem(12px, 12px);
							display: inline-block;
							font-weight: normal;
							margin: 7px 0 0;
							text-transform: none;
						}
						
						.discount-savings {
							@include font-rem(12px, 12px);
							background-color: $navy;
							color: $white;
							display: inline-block;
							padding: 2px 5px;
						}
					}
					
					&-max-message {
						margin-top: 0;
					}
					
					&-details {
						
						button {
							color: $navy;
							font-weight: 700;
						}
					}
					
					fieldset {
						padding: 0;
						
						label {
							align-content: flex-start;
							align-items: flex-start;
							height: auto;
							min-height: 29px;

							input {
								margin-top: 2px;
							}
						}
					}
				}
				
				&-title {
					@extend h3;
					@include font-rem(32px, 32px);
					letter-spacing: -1.25px;
					margin-bottom: 4px;
					max-width: unset;
					padding-bottom: 0;
					padding-top: 0;
				}
				
				&-options {
					z-index: 1;
					
					&-option {
						flex: 0 1 auto;
						margin-right: 32px;
						padding: 0 0 10px;
						text-align: center;
						
						p {
							@include font-rem(14px, 14px);
							font-weight: 700;
							margin-bottom: 5px;
						}
						
						&-input {
							
							.select-wrapper {
								display: inline-block;
								
								select {
									@include font-rem(13px, 13px);
									font-weight: 700;
								}
							}
							
							fieldset {
								
								label {
									cursor: pointer;
									display: inline-flex;
									margin-bottom: 0;
									margin-right: 5px;
									
									input {
										
										&[type=radio] {
											display: none;
											
											+ span {
												@include font-rem(13px, 13px);
												background-color: $concrete;
												color: $navy;
												display: block;
												padding: 14px;
												text-transform: uppercase;
											}
											
											&:checked {
											
												+ span {
													@include font-rem(13px, 13px);
													background: $navy;
													color: $white;
													display: block;
													font-weight: 700;
													padding: 14px;
													text-transform: uppercase;
												}
											}
											
											&:disabled {
												
												+ span {
													position: relative;
													
													&::after {
														background: $navy;
														content: '';
														height: 2px;
														left: -5px;
														position: absolute;
														top: 46%;
														transform: rotate(-34deg);
														width: 114%;
													}
												}
											}
										}
									}
								}
							}	
						}
					}
				}

				.bat-quantity {
					margin-bottom: 26px;
					padding-bottom: 0;
					padding-top: 0;
					&-label {
						@extend h5;
						@extend .headline5-small;
						@include font-rem(12px, 15px);
						flex: 0 1 140px;
						letter-spacing: 1px;
					}
					.bat-quantity-button {
						flex: 0 0 32;
						height: 32px;
						min-width: 32px;
					}		
				}

				.bat-producthero-deliveryinfo {
					align-items: center;
					background-color: $concrete;
					display: flex;
					padding: 24px 20px;
					&-icon {
						padding-left: 4px;
						padding-right: 14px;
						.bat-icon {
							height: 34px;
							width: 34px;
							&::before {
								font-size: 34px;
							}
						}
					}
					&-text {
						.heading {
							@extend h5;
							@extend .headline5-small;
							@include font-rem(12px, 15px);
							display: block;
							letter-spacing: 1px;
							margin-bottom: 4px;
						}
						p {
							@include font-rem(12px, 14.4px);
							color: $dark-gray;
							letter-spacing: .25px;
							margin: 0;
						}
					}
				}

				.bat-producthero-desktop {
					display: none;
					padding-bottom: 0;
					padding-top: 0;
				}
				
				.bat-producthero-strengths {
					margin-top: 18px;
					padding-bottom: 0;
					padding-top: 0;
					&-label {
						@extend h5;
						@extend .headline5-small;
						@include font-rem(12px, 15px);
						letter-spacing: 1px;
						margin-bottom: 11px;
						padding: 0;
					}
	
					&-buttons {
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 15px;
						padding-bottom: 0;
						padding-top: 0;
						.bat-indicator {
							align-items: center;
							border: 1px solid $system-red;
							border-left-width: 0;
							flex-direction: column;
							font-family: $primary-font;
							justify-content: center;
							margin: 0;
							min-width: 75px;
							padding: 7px 6px 11px;
							width: 25%;
							&:first-child {
								border-left-width: 1px; 
							}
							span {
								@include font-rem(10px, 10px);
								font-weight: 700;
								margin-bottom: 5px;
							}
						}
					}

				}
			}

			.bat-producthero-mobile {
				display: block;
				margin-bottom: 31px;
				margin-top: 60px;
				padding: 0 20px;
			}
		}
		&-skuerror {
			@include font-rem(16px, 28px);
			margin-bottom: 20px;
		}
	}
}

@include media-breakpoint-up(sm) { // 576+
	bat-producthero-avalancheza {
		.bat-producthero {			
			&--avalanche-za {
				.bat-producthero-gallery {
					&-images {

						// calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
						.large {
							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							background-position: calc(-25px + (10 - (-25)) * ((100vw - 576px) / (767 - 576))) center;

							/* sasslint-disable-next-line nesting-depth */
							img {
								width: calc(350px + (415 - 350) * ((100vw - 576px) / (767 - 576)));
							}
						}

					}
				}			
			}
		}
	}
}

@include media-breakpoint-up(md) { // 768+
	bat-producthero-avalancheza {
		.bat-producthero {			
			&--avalanche-za {
				background-color: $white;
				flex-direction: row;
				padding: 0;

				> * {
					min-height: 100%;
				}

				.bat-producthero-content {
					width: 52%;
				}

				.bat-producthero-gallery {
					min-height: 100%;
					padding: 0 0 16px;

					&-images {
						margin-bottom: 40px;
						padding: 120px 0 0;
						.large {

							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							background-position: calc(-60px + (-85 - (-60)) * ((100vw - 768px) / (991 - 768))) center;

							padding: 12px 0;		
							img {
								width: calc(260px + (350 - 260) * ((100vw - 768px) / (991 - 768)));
							}
						}
					}

					&-thumbs {
						display: flex;
						margin-top: 0;
					}
				
					.slick-arrow {
						margin-top: 28px;
						
						&.slick-prev {
							left: 30px;
						}
						
						&.slick-next {
							right: 30px;
						}
					}
				}

				.bat-producthero-configurator {
					$container-padding: 5.105vw;
					padding: 0;
					position: relative;
					width: 48%;

					& > * {
						padding-left: $container-padding;
						padding-right: $container-padding;
					}

					&-bottom {
						margin-bottom: 0;
						.bat-cta-style {
							&.button-dark,
							&.button-dark-disabled {
								flex-basis: 50%;
								padding: 11px 0;
							}
						}

						.slash-price {
							color: $gray;
							display: inline-block;
							&:after {
								background: currentColor;
								height: 2px;
							}
						}
					}

					&-description {
						font-size: .94rem;
						height: auto;
						line-height: 16px;
						margin-bottom: 15px;
						max-height: 110px;
						overflow-y: auto;
					}

					&-title {
						@include font-rem(40px, 36px);
						letter-spacing: -.5px;
						margin-bottom: 24px;
						padding: 0;
					}
				
					&-subheading {
						letter-spacing: .05px;
						margin-bottom: 14px;
						padding: 0;
					}

					&-price {
						flex-basis: 150px;
						letter-spacing: .25px;
						margin-bottom: 0;
						text-align: left;
					}

					&-short-description {
						h6 {
							@include font-rem(20px, 20px);
						}
					}

					&-subscription {
						margin: 0;
						max-width: none;
						padding: 20px;
					}

					&-options {
						&-option {
							padding: 0 0 20px;
							text-align: left;
						}
					}

					.bat-producthero-deliveryinfo {
						left: 0;
						margin-top: 26px;
						padding: 26px $container-padding;
						position: sticky;
						top: 100%;
						&-icon {
							padding-left: 0;
							padding-right: 8px;

							.bat-icon {
								height: 41px;
								width: 41px;
								&::before {
									font-size: 41px;
								}
							}
						}
						&-text {
							.heading {
								letter-spacing: 1.25px;
								margin-bottom: 5px;
							}
						}
					}

					.bat-producthero-desktop {
						display: block;
						margin-top: 60px;
					}

					.bat-quantity {
						margin-bottom: 30px;
						&-label {
							flex-basis: 150px;
						}
					}
				
					.bat-producthero-strengths {	
						margin-bottom: 15px;	
						&-buttons {
							margin-bottom: 0;
						}
						&-label {
							letter-spacing: .95px;
						}
	
					}
				}

				.bat-producthero-mobile {
					display: none;
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) { // 992+
	bat-producthero-avalancheza {
		.bat-producthero {			
			&--avalanche-za {

				.bat-producthero-gallery {
					&-images {
						.large {
							background-position: -85px center;
							img {
								// --| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
								width: calc(350px + (415 - 350) * ((100vw - 992px) / (1199 - 992)));
							}
						}
					}
				}

				.bat-producthero-configurator {

					&-description {
						max-height: 185px;
					}

					&-options {
						form {
							flex-direction: row;
						}
					}

					&-bottom {
						flex-direction: row;
						text-align: left;
						.bat-quantity {
							justify-content: flex-start;
							margin: 0 20px 0 0;
						}
					}

					.bat-producthero-desktop {
						margin-bottom: 15px;
					}
				}
				
			}
		}
	}
}

@include media-breakpoint-up(xl) { // 1200+
	bat-producthero-avalancheza {
		.bat-producthero {			
			&--avalanche-za {
				
				.bat-producthero-gallery {
					&-images {
						min-height: 560px; // -----| Prevent flickering when loading new images into carousel
						.large {
							background-position: calc(-90px + (-25 - (-90)) * ((100vw - 1200px) / (1439 - 1200))) center;
							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							img {
								width: 415px;
							}
						}
					}
				}

				.bat-producthero-configurator {

					&-description {
						max-height: 250px;
					}

					&-options {
						form {
							flex-direction: row;
						}
					}

					&-bottom {
						flex-direction: row;
						text-align: left;
						.bat-quantity {
							justify-content: flex-start;
							margin: 0 20px 0 0;
						}
					}
				}
				
			}
		}
	}
}

@media (min-width: 1440px) {
	bat-producthero-avalancheza {
		.bat-producthero {			
			&--avalanche-za {
				
				.bat-producthero-content {
					.bat-producthero-gallery {
						&-images {
							.large {
								background-position: -25px center;
							}
						}
					}
				}
				
			}
		}
	}
}
