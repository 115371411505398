.bat-footer-large {
	
	background-color: $off-white;
	
	footer {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: $gutter;

		@include media-breakpoint-up(lg) {
			align-items: stretch;
			flex-direction: row;
			flex-wrap: wrap;
			margin: 0 auto;
			max-width: $max-width-xxl;
        }
	}
	
	&-logo {
		align-items: center;
		display: flex;
		justify-content: center;
		order: 2;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			order: 1;
			width: 50%;
		}
		
		a {
			display: inline-block;
		}
	}
	
	&-nav {
		order: 1;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			order: 2;
			width: 50%;
		}
		
		&-content {
			color: $black;
			height: 108px;
		}
		
		&-menus {
			
			@include media-breakpoint-up(lg) {
				display: flex;
			}
		
			&-menu {
				flex: 1 1 auto;
				
				button {
					align-items: center;
					border-top: solid 1px $gray;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					padding: 10px $gutter 10px  0;
					text-align: left;
					width: 100%;
					
					i {
						margin-left: auto;
						transition: all .1s ease-in;
					}
					
					@include media-breakpoint-up(lg) {
						border-top: 0;
						display: block;
						padding: 0;
						pointer-events: none;
						width: auto;
						
						i {
							display: none;
						}
					}
				}
				
				div {
					display: none;
					margin-bottom: $gutter;
					
					ul {
						list-style: none;
						margin: 0;
						padding: 0;
						
						li {
							margin-bottom: 0;
						}
					}
					
					@include media-breakpoint-up(lg) {
						display: block;
						margin-bottom: 0;
					}
				}
				
				&.open {
					
					button {
						
						i {
							transform: rotate(-180deg);
						}
					}
					
					div {
						display: block;
					}
				}
				
				@include media-breakpoint-up(lg) {
					padding-right: $gutter;
				}
			}
		}
	}
	
	&-copyright {
		order: 3;
		padding: $gutter;
		text-align: center;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			align-self: flex-end;
			padding: 0;
			text-align: left;
			width: 50%;
		}
	}
	
	&-social-nav {
		order: 4;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			align-self: flex-end;
			width: 50%;
		}
		
		ul {
			align-items: center;
			display: flex;
			justify-content: center;
			list-style: none;
			margin: 0;
			padding: 0;
			
			@include media-breakpoint-up(lg) {
				justify-content: flex-start;
			}
			
			li {
				margin: 0;
				
				a {
					display: block;
					padding: 0 $gutter / 2;
					
					@include media-breakpoint-up(lg) {
						padding: 0 $gutter 0 0;
					}
				}
			}
		}
	}
}
