/* maximum amount of grid cells to be provided */
$max_col: 12 !default;

$grid-gutter-width: $grid-gutter-size/2;

/* default breakpoint */
.aem-Grid {
	@include generate-grid(default, $max_col);
	width: auto;
	
	&.aem-GridNoGutter {
		
		.aem-GridColumn {
			padding: 0;
		}
	}
}
@media (min-width: ($breakpoint-lg + 1)) {
	.aem-Grid {
		@include generate-grid-hide(default)
	}
}

/* tablet breakpoint */
@media (min-width: ($breakpoint-sm + 1)) and (max-width: $breakpoint-md) {
	.aem-Grid {
		@include generate-grid(tablet, $max_col);
		@include generate-grid-hide(tablet)
	}
}

/* phone breakpoint */
@media (max-width: $breakpoint-sm) {
	.aem-Grid {
		@include generate-grid(phone, $max_col);
		@include generate-grid-hide(phone)
	}
}

.aem-GridColumn {
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
	
	&.no-gutter {
		padding-left: 0;
		padding-right: 0;
	}
	
	&.no-gutter-left {
		padding-left: 0;
		padding-right: $grid-gutter-width;
	}
	
	&.no-gutter-right {
		padding-left: $grid-gutter-width;
		padding-right: 0;
	}
}


//Strip padding from template aem grid
.root {
	&.responsivegrid,
	&.rootTemplateGrid {
		
		> .aem-Grid {
		 
			> .aem-GridColumn {
				padding-left: 0;
				padding-right: 0;
				
				> .aem-Grid {
					margin-left: -$grid-gutter-width;
					margin-right: -$grid-gutter-width;
					
					.responsivegrid {
						margin-left: auto;
						margin-right: auto;
						max-width: $max-width-xxl;
					}
					
					//AEM author mode 
					.authorEditMode & { // sass-lint:disable-line class-name-format
						margin-left: 0;
						margin-right: 0;
					}
				}
			}
		}
	}
}

.responsivegrid.aem-GridColumn.aem-GridColumn--default--12 {
	padding-left: 0;
	padding-right: 0;
}

.responsivegrid.aem-GridColumn.aem-GridColumn--default--16 {
	padding-left: 0;
	padding-right: 0;
}

.responsivegrid.aem-GridColumn.aem-GridColumn--default--16 > .aem-GridColumn.aem-GridColumn--default--16 {
	padding-left: 0;
	padding-right: 0;
}
