bat-magentoaddressbook-zonnic {
	.addressbook {
		&__grid {
			display: grid;
			gap: 1rem;
			grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
			margin: 1rem 0;
		}

		&__address {
			border: 2px solid $pale-gray;
			flex-grow: 1;
			padding: 1rem;
			position: relative;

			&__container {
				display: flex;
				flex-direction: column;
			}

			&__label {
				color: $navy;
				font-weight: 600;
				margin-bottom: 1rem;
				@include font-rem(20px, 28px);
				text-transform: none;

				&_overview {
					color: $black;
				}
			}

			&__name {
				color: $black;
				font-weight: 700;
			}

			p {
				@include font-rem(14px, 20px);
			}

			&__edit-buttons {
				display: flex;
				gap: 14px;
				position: absolute;
				right: 1rem;
				top: 1rem;

				a {
					cursor: pointer;

					svg {
						fill: $navy;
						height: 14px;
						width: 14px;

						&.edit {
							transform: scale(1.5);
							transform-origin: right center;
						}
					}
				}
			}

			&__makeDefaultShipping {
				// sass-lint:disable-line class-name-format
				align-items: center;
				color: $navy;
				display: flex;
				font-weight: 700;
				letter-spacing: 1.2px;
				margin-top: 24px;
				text-transform: uppercase;
				@include font-rem(12px, 14px);

				&:focus {
					outline: none;
				}

				svg {
					height: 24px;
					margin-right: 12px;
					width: 24px;
				}

				.checkbox-on {
					display: none;
				}

				&.selected {
					.checkbox-on {
						display: block;
						fill: $navy;
					}

					.checkbox-off {
						display: none;
					}
				}
			}
		}
	}
}
