.bat-form {
	
	&-field {
				
		&.bat-form--checkout-shipping {
			margin-bottom: 10px;
		}
	}
	
	//-----------------------------------------------------------------| Checkout
	&--checkout {
		padding-bottom: 70px;
		position: relative;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 108px;
		}
		
		&.bat-form {
			max-width: none;
		}
		
		&-indicator {
		
			@include media-breakpoint-up(lg) {
				padding: 20px;
				width: 65%;
			}
			
			&-steps {
				display: flex;
				justify-content: center;
				list-style: none;
				margin: 0 auto;
				max-width: 716px;
				padding: 0;
				
				&-step {
					align-items: center;
					color: $pale-gray;
					display: flex;
					font-weight: 700;
					justify-content: center;
					@include font-rem(12px, 14px);
					margin: 0;
					padding: 10px 14px;
					text-align: center;
					text-transform: uppercase;
					width: 33%;
					
					@include media-breakpoint-up(lg) {
						border-bottom: 2px solid $pale-gray;
						color: $mid-gray;
					}
					
					&.active {
						border-bottom: 3px solid $navy;
						color: $navy;
					}
				}
			}
		}
		
		&-form {
			padding: 20px;
			width: 100%;
			
			@include media-breakpoint-up(lg) {
				width: 65%;
			}
			
			.bat-form-step {
				margin: 0 auto;
				max-width: 514px;
			}
		}
		
		&-summary {
			align-items: center;
			background-color: $whisper-gray;
			display: flex;
			flex-direction: column;
			padding: 16px 20px;
			width: 100%;
			
			@include media-breakpoint-up(lg) {
				bottom: 0;
				min-height: 100vh;
				padding: 50px 3vw;
				position: absolute;
				right: 0;
				top: 0;
				width: 35%;
				
				//AEM author mode 
				.authorEditMode & { // sass-lint:disable-line class-name-format
					display: block;
					min-height: 0;
				}
			}
			
			@include media-breakpoint-up(xl) {
				padding: 50px 66px;
			}
		}
		
		&-payment-moneris-cc {
			
			&-hidden-cc-key,
			&-hidden-cc-type {
				height: 0;
				margin-bottom: 0 !important; // sass-lint:disable-line no-important
				overflow: hidden;
			}
			
			&-iframe-cc-number {
			
				.iframe-cc-number {
					height: 40px;
					width: 100%;
				}
			}
			
			&-card-cvv,
			&-card-expiration-date {
				width: 51%;
				
				input {
					width: 114px !important; // sass-lint:disable-line no-important
				}
			}
		}
		
		&-payment-moneris-cc,
		&-payment-moneris-cc-vault {
			
			form {
				display: none;
				margin-top: 20px; 
			}
			
			&.open {
				
				form {
					display: block;
				}
				
				.toggle-payment-button {
					pointer-events: none;
				
					&:after{
						content: '\e958'; 
					}
				}
			}
		}
		
		&-payment-moneris-cc-payment-tos,
		&-payment-moneris-cc-vault-payment-tos {
			
			fieldset {
				border: 1px solid $pale-gray !important; // sass-lint:disable-line no-important
				padding: 20px;

				label {
					align-items: flex-start;
					margin-bottom: 0 !important; // sass-lint:disable-line no-important
					
					a,
					button {
						color: $navy;
						font-weight: 700;
					}

					& > input {
						
						&[type='checkbox'] {
							margin: unset;
							margin-right: 16px;
							width: 160px;
							
							@include media-breakpoint-up(lg) {
								width: 88px;
							}
	
							& + span,
							&:checked + span {
								padding-bottom: 0;
							}
						}
					}

					p {
						@include font-rem(12px, 18px);
						margin-bottom: 12px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		
		.billing-address-display {
			background-color: $whisper-gray;
			padding: 15px 20px;
			
			p {
				@include font-rem(14px, 20px);
			}
		}
	
		.toggle-payment-button {
			align-content: center;
			align-items: center;
			border-bottom: solid 1px $whisper-gray;
			color: $navy;
			display: flex;
			@include font-rem(12px, 14px);
			font-weight: 700;
			padding: 20px;
			position: relative;
			text-align: left;
			text-transform: uppercase;
			width: 100%;
			
			&:after{
				color: $navy;
				content: '\e959';
				font-family: 'icomoon';
				font-size: 20px;
				margin: 0 0 0 auto;
			}
			
			&.checkout-payment-moneris-cc-vault-toggle {
				
				&:before{
					color: $navy;
					content: '\e92b';
					font-family: 'icomoon';
					font-size: 28px;
					margin: 0 10px 0 0;
				}
			}
			
			&.checkout-payment-moneris-cc-toggle {
				
				&:before{
					color: $navy;
					content: '\e92b';
					font-family: 'icomoon';
					font-size: 28px;
					margin: 0 10px 0 0;
				}
			}
		}
		
		.shipping-address {
			
			.bat-form-block {
				margin-bottom: 0;
				
				&-text {
					margin-bottom: 10px;
				}
			}
			
			&-display {
			
				.subtitle {
					@include font-rem(20px, 28px);
				}
				
				p {
					@include font-rem(14px, 20px);
				}
			}
			
			a {
				
				&.arrow-link-dark {
					padding-left: 0;
				}
			}
		}
		
		.previously-used-addresses {
			display: none;
			
			&.active {
				display: block;
			}
			
			button {
				
				&.add-new-address {
					align-content: center;
					align-items: center;
					display: flex;
					font-weight: 700;
					margin: 10px 0 20px;
					
					i {
						margin-left: 0;
						
						&:before {
							font-size: 24px;
						}
					}
				}
			}
		}
		
		.checkout-shipping-method {
			margin-bottom: 0;
			
			.bat-form-block-text {
				margin-bottom: 10px;
			}
			
			&-options {
				margin-bottom: 0;
				
				&-option {
					
					&-amount {
						color: $black;
						font-weight: 700;
						margin: 0 0 0 auto;
						padding: 5px 0 0;
					}
				}
			}
		}
		
		.payment-voucher {
			
			.bat-form-block-text {
				margin-bottom: 10px;
			}
			
			.bat-form--checkout-payment-voucher-code {
				flex: 1 1 calc(100% - 45px);
				margin-bottom: 10px;
			}
			
			.bat-form--checkout-payment-voucher-submit {
				flex: 0 0 40px;
				margin: 0 auto 0 0;
				width: 20%;
				
				button {
					border-radius: 0;
					height: 40px;
					margin-left: 5px;
					padding: 0;
					width: 40px;
					
					i {
						
						&:before {
							font-size: 24px;
						}
					}
				}
			}
		}
		
		.checkout-payment-total {
			
			&-display {
				align-content: center;
				align-items: center;
				background-color: $whisper-gray;
				display: flex;
				min-height: 60px;
				padding: 15px 20px;
				
				&-icon {
					padding-right: 20px;
					
					i {
						&:before {
							color: $navy;
						}
					}
				}
				
				&-label {
					display: flex;
					flex-direction: column;
					justify-content: center;
					
					h6 {
						@include font-rem(12px, 12px);
						font-weight: 700;
						letter-spacing: 1.2px;
						margin-bottom: 0;
					}
					
					span {
						@include font-rem(12px, 12px);
					}
				}
				
				&-amount {
					@include font-rem(20px, 28px);
					color: $navy;
					font-weight: 700;
					margin: 0 0 0 auto;
				}
			}
		}
	}
	
	//-----------------------------------------------------------------| Signup
	.signup {
	
		&-submit {
						
			p {
				@include font-rem(12px, 18px);
				
				a {
					color: $navy;
					font-weight: 700;
				}
			}
		}
		
		form {
			
			fieldset {
				border: 1px solid $pale-gray;
				padding: 20px;

				label {
					align-items: flex-start;
					margin-bottom: 0;
					
					a {
						color: $navy;
						font-weight: 700;
					}

					& > input {
						
						&[type='checkbox'] {
							margin: unset;
							margin-right: 16px;
							width: 160px;
							
							@include media-breakpoint-up(lg) {
								width: 88px;
							}
	
							& + span,
							&:checked + span {
								padding-bottom: 0;
							}
						}
					}

					p {
						@include font-rem(12px, 18px);
						margin-bottom: 12px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
