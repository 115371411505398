.bat-icon {
	display: block;
	height: 24px;
	width: 24px;
	
	&:before {
		font-size: 24px;
	}
	
	svg {
		display: block;
		width: 100%;
	}
}
