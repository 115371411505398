.bat-offers {

	@include media-breakpoint-up(lg) {
		display: flex;
	}
	
	&-content {
		
		&-login,
		&-logout {
			display: none;
			padding: 70px 20px;

			&.active {
				display: block;
			}
			
			@include media-breakpoint-up(lg) {
				padding: 70px 20px 70px 17.2%;
			}
		}
		
		&-login {
			
			&-heading {
				display: none;
				
				&.active {
					display: block;
				}
			}
			
			&-cta-list {
				display: block;
				padding: 20px 0 10px;
				
				a {
					margin-bottom: 10px;
					margin-right: 10px;
				}
				
				@include media-breakpoint-up(sm) {
					display: none;
				}
			}
			
			&-savings {
				padding: 10px 0; 
				
				&-count {
					color: $green;
					display: block;
					
					@include media-breakpoint-up(sm) {
						display: inline;
					}
				}
			}
		}
		
		&-logout {
			
			&-cta-list {
				display: block;
				padding: 20px 0 10px;
				
				a {
					margin-bottom: 10px;
					margin-right: 10px;
				}
				
				@include media-breakpoint-up(sm) {
					display: none;
				}
			}
		}
	
		@include media-breakpoint-up(lg) {
			width: 66.666667%;
		}
	}
	
	&-image {
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: 33.333333%;
			
			picture {
				height: 100%;
				overflow: hidden;
				position: relative;
				width: auto;
				
				img {
					-o-object-fit: cover;
					height: 100%;
					left: 0;
					max-width: none;
					min-width: 100%;
					object-fit: cover;
					position: absolute;
					top: 0;
					width: auto;
				}
			}
		}
	}
}
