.register-successful-section {
  background:  url('/content/dam/gtr/images/webp-global/bg-main-content.webp');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 70px;

  @include media-breakpoint-up(md) {
    display: flex !important;
  }

  .register-successful-container {
    text-align: center;
    max-width: 966px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    background: #fff;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
    padding: 67px 50px 120px;
    height: 100%;

    &.thank-you {
      padding-bottom: 200px;

    }

    > div {
      max-width: 415px;
      margin-left: auto;
      margin-right: auto;
    }

    .image {
      margin-bottom: 30px;

      img {
        width: auto;
        margin: 0 auto;
      }
    }
    .headline {
      .bat-headline {
        margin-bottom: 16px;
      }
      & + .text {
        margin-bottom: 26px;
      }
      & + .text + .text {
        margin-bottom: 27px;
      }
      & + .text + .text + .text {
        margin-bottom: 44px;
      }
      & + .text + .text + .text + .text {
        margin-bottom: 30px;
      }
    }
    .text {
      margin-bottom: 25px;
      .bat-text {
        *:last-child {
          margin-bottom: 0;
        }
      }
      a {
        color: $system-blue;
        font-size: 18px;
        text-decoration: none;
      }

      .sm {
        p {
          font-size: 13px;
        }
      }

      .email-txt {
        p {
          color: $system-blue;
          font-size: 18px;
          text-decoration: none;
        }
      }
    }
  }
  bat-form-registrationgtr {
    .bat-form {
      .bat-form-block {
        margin-bottom: 0;
      }
      .bat-form--verify-email-submit {
        .bat-button {
          width: 175px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .bat-cta {
    align-items: center;

    a {
      min-width: 175px;
    }
  }
}

//responsive
@include media-breakpoint-down(sm) {
  .register-successful-section {
    padding-top: 40px;

    .register-successful-container {
      padding: 40px 20px 50px;
      width: calc(100% - 40px);

      &.thank-you {
        padding-bottom: 50px;
      }
    }
  }
}