//-----------------------------------------------------------------| Dropdown build for button styles
bat-cta-verticalnav {
	background-color: $concrete;
	font-size: 2rem;
	.bat-cta-list--vertical {
		ul {
			list-style: none;
			padding: 40px 60px;
			width: 100%;

			li {
				align-items: center;
				border-bottom: 2px solid $system-red;
				display: none;
				justify-content: space-between;
				margin: 0;
				min-height: 60px;
				padding-left: 25px;
				padding-right: 25px;
				
				@include media-breakpoint-up(lg) {
					display: flex;
				}

				&:last-child {
					border-bottom: 0;
				}

				&.selected {
					display: flex;

					@include media-breakpoint-up(lg) {
						background-color: $system-red;
					}
				}

				a {
					@extend .cta-link;
					align-items: center;
					height: 100%;
					margin-bottom: 0;
				}

				.icon-plus {
					cursor: pointer;

					@include media-breakpoint-up(lg) {
						display: none;
					}
				}
			}
		}
	}

	// when plus is clicked all items become visible in mobile mode
	&.expanded {
		.bat-cta-list--vertical {
			ul {
				li {
					display: flex;

					.icon-plus {
						display: none;
					}
				}
			}
		}
	}
}
