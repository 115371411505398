.gtr-product-hero-section {
	// &__image {
	//     padding-top: (503 / 375 * 100%);
	//     position: relative;
	//     z-index: 5;
	//     img {
	//        position: absolute;
	//        top: 0;
	//        left: 0;
	//        object-fit: cover;
	//        height: 100%;
	//     }
	// }
	&__content {
		overflow: hidden;
		position: relative;
		z-index: 10;
		text-align: center;
		padding: 102px 20px 0;
		padding-bottom: (54 / 375 * 100%);
		margin-top: -22%;
		background: linear-gradient(
			180deg,
			rgba(48, 48, 45, 0) 3.18%,
			#30302d 10.77%
		);
		color: #fff;
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding-top: (54 / 375 * 100%);
			background-image: url('/content/dam/gtr/images/webp-gtr/rectangle-bottom.webp');
			background-repeat: no-repeat;
			background-size: cover;
		}
        &:before {
            content: "";
            z-index: -1;
            height: 698px;
            max-height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: linear-gradient(180deg,rgba(48,48,45,0) 3.18%,#30302d 10.77%);
        }
	}
	&__logo {
		max-width: 100%;
        margin-bottom: 17px;
		img {
			width: auto;
            max-width: 100%;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__headline {
		margin-bottom: 16px;
        .bat-headline {
            text-align: center;
        }
		.bat-headline-style,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: #fff;
            font-size: 20px;
            line-height: 1.5;
            letter-spacing: 0.04em;
            
		}
	}
	&__topsubtitle {
		margin-bottom: 17px;
        .bat-text {
            * {
                text-align: center !important;
            }
        }
	}
	&__text {
		margin-bottom: 42px;
        .bat-text {
            * {
                line-height: 1.2;
                text-align: center !important;
            }
        }
	}
	&__cta-list {
		padding-bottom: 59px;
		.bat-cta-style {
			text-align: center;
			width: 275px;
			max-width: 100%;
			margin: 0 auto;
		}
	}
    body:not(.airport-view) & {

        @include media-breakpoint-up(md) {
            position: relative;
            overflow: hidden;
            padding: 0 40px;
            padding-bottom: (121 / 1920 * 100%);
            z-index: 0;
            &:after {
                z-index: 10;
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-image: url('/content/dam/gtr/images/webp-gtr-instore/rectangle-bottom.webp');
                background-repeat: no-repeat;
                background-size: cover;
                padding-bottom: (121 / 1920 * 100%);
            }
            &__image {
                padding-top: 0;
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                img {
                    position: absolute;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    height: 100%;
                    object-position: top right;
                }
            }
            &__content {
                margin-top: 0;
                background: transparent;
                max-width: 387px + 20;
                width: 50%;
                padding: 82px 0 18px;
                &:before,
                &:after {
                    content: none;
                }
            }
            &__headline {
                margin-bottom: 15px;
                .bat-headline {
                    text-align: left;
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        font-size: 20px;
                    }
                }
            }
            &__topsubtitle {
                margin-bottom: 52px;
                .bat-text {
                    * {
                        text-align: left !important;
                    }
                }
            }
            &__text {
                p {
                    text-align: left !important;
                    &,
                    .sm {
                        line-height: 130%;
                        letter-spacing: 0.04em;
                        font-size: 11px;
                    }
                }
            }
            &__logo {
                margin-bottom: 22px;
                img {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
            &__cta-list {
                padding-bottom: 98px;
                .bat-cta {
                    text-align: left;
                }
                .bat-cta-style {
                    width: 258px;
                    max-width: 100%;
                }
            }
            .bat-section {
                overflow: hidden;
                display: flex;
                align-items: center;
                &:before {
                    content: '';
                    float: left;
                    width: 1px;
                    margin-left: -1px;
                    padding-top: 599 / (1440 - 126 * 2) * 100%;
                }
                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
                .bat-section-content {
                    flex-grow: 1;
                }
            }
        }
        @include media-breakpoint-up(xl) {
            padding-left: 104px;
            padding-right: 104px;
        }
    }

	&--velo {
        background-color: #3143B5;
		.gtr-product-hero-section {
            &__content {
                padding-top: 306px;
                margin-top: -306px;
                background: linear-gradient(
                    0deg,
                    #011e46 28.35%,
                    #263b9c 67.51%,
                    rgba(41, 61, 161, 0) 78.39%
                );
                &:before {
                    background: linear-gradient(
                        0deg,
                        #011e46 28.35%,
                        #263b9c 67.51%,
                        rgba(41, 61, 161, 0) 78.39%
                    );  
                }
            }
		}
        
        body:not(.airport-view) & {
            @media (min-width: 768px) {
                &::before {
                    z-index: 2;
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    background: linear-gradient(
                        270deg,
                        #011e46 0%,
                        rgba(1, 30, 70, 0) 60.31%
                    );
                }
                .gtr-product-hero-section {
                    
                    &__image img {
                        object-position: top left;
                    }
                    &__content {
                        padding: 161px 0 140px;
                        margin-left: auto;
                        background: transparent;
                    }
                
                }
            }
        }
	}
	&--glo {
		.gtr-product-hero-section {
			&__content {
                background: linear-gradient(
                    180deg,
                    rgba(45, 33, 69, 0) 0%,
                    #2d2145 12%
                );
                &::before {
                    content: "";
                    z-index: -1;
                    height: 630px;
                    max-height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        180deg,
                        rgba(45, 33, 69, 0) 0%,
                        #2d2145 12%
                    );
                }
                
			}
		}
        
        body:not(.airport-view) & {

            @media (min-width: 768px) {
                &::before {
                    z-index: 2;
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 53%;
                    background: linear-gradient(
                        269.79deg,
                        #1c162d 59.89%,
                        rgba(57, 42, 84, 0) 97.35%
                    );
                }
                .gtr-product-hero-section {
                    &__image img {
                        object-fit: cover;
                        object-position: top left;
                        width: 100%;
                        height: 100%;
                    }
                    &__content {
                        padding: 161px 0 140px;
                        margin-left: auto;
                        background: transparent;
                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }
	}

	&.in-store {
		padding-left: 153px;
		padding-right: 153px;
		.gtr-product-hero-section {
			&__content {
				width: 621px;
				max-width: 100%;
				padding: 78px 0 10px;
			}
			&__logo {
				margin-bottom: 42px;
			}
			&__headline {
				margin-bottom: 38px;
				.bat-headline {
					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						font-size: 32px;
					}
				}
			}
			&__subtitle {
				margin-bottom: 42px;
			}
			&__text {
				margin-bottom: 22px;
                * {
                    &,
                    .sm {
                        font-size: 16px;
                    }
                }
			}
			&__cta-list {
				padding-bottom: 115px;
				.bat-cta-style {
					width: 425px;
				}
			}
		}
		.bat-section {
			&:before {
				padding-top: 51.9826517968%;
			}
		}
		&.gtr-product-hero-section--glo {
			.gtr-product-hero-section {
				&__content {
					width: 553px;
					padding: 142px 0 176px;
				}
			}
		}
		&.gtr-product-hero-section--velo {
			.gtr-product-hero-section {
				&__content {
					width: 637px;
					padding: 162px 0 137px;
				}
			}
		}
	}
}
.global-title {
	.bat-headline {
		h4 {
			font-size: 20px;
		}
	}
}
.exclusive-offer-hero {
    background-position: top center;
    .bat-section {
        padding: 35px 18px 0;
        
        position: relative;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            
            padding-top: (71 / 375 * 100%);
            background-image: url("/content/dam/gtr/images/webp-mclaren-airport/bg-orange-foot-registration.webp");
            background-size: cover;
            background-position: bottom right;
            background-repeat: no-repeat;
        }
    }
    &__title {
        .bat-headline {
            margin-bottom: 27px;
            * {
                text-align: center;
                color: #fff;
                letter-spacing: 0.04em;
            }
        }
    }
    .contentwrapper {
        padding: 51px 34px 20.7%; 
        background-color: #fff;
    }
    &__text {
        .bat-text {
            margin-bottom: 19px;
            & > * {
                text-align: center;
                letter-spacing: 0.04em;
            }
        }
    }
    &__image {
        .bat-image {
            width: 140px;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            margin-bottom: 26px;
        }
    }
}
@media (min-width: 1900px) {
    body:not(.airport-view) {
        .gtr-product-hero-section:not(.in-store) {

            .gtr-product-hero-section__headline .bat-headline  {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
    
                    font-size: 24px;
                }
            }
            .gtr-product-hero-section__topsubtitle .bat-text * {
                font-size: 18px;
            }
            .gtr-product-hero-section__text {
                .bat-text {
                    * {
                        &,
                        .sm {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}
[dir="rtl"] {
    @media (min-width: 768px) {
        body:not(.airport-view) {
            .gtr-product-hero-section__content {
                margin-right: auto;
                margin-left: 0;
            }
            .gtr-product-hero-section--velo .gtr-product-hero-section__content,
            .gtr-product-hero-section--glo .gtr-product-hero-section__content {
                margin-right: 0;
                margin-left: auto;
            }
            .gtr-product-hero-section__cta-list .bat-cta,
            .gtr-product-hero-section__headline .bat-headline {
                text-align: right;
            }
            .gtr-product-hero-section__logo img {
                margin-right: 0;
                margin-left: auto;
            }
            .gtr-product-hero-section__topsubtitle,
            .gtr-product-hero-section__text {
                .bat-text {
                    * {
                        text-align: right !important;
                    }
                }
            }

        }
    }
}