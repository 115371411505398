.bat-productcard {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	max-width: 210px;
	visibility: visible;

	&.loaded {
		visibility: visible;
	}
	
	&-tile {
		margin: auto;
		width: 100%;
	}
	
	&-content {
		
		&-headline {
			margin-bottom: $gutter;
			text-align: center;
			
			h6 {
				@include font-rem(25px, 25px);
				
				.slash {
					@include font-rem(18px, 18px);
					color: $red;
					position: relative;
					
					&:before {
						-moz-transform: rotate(-5deg);
						-ms-transform: rotate(-5deg);
						-o-transform: rotate(-5deg);
						-webkit-transform: rotate(-5deg);
						border-color: $red;
						border-top: 2px solid;
						content: '';
						
						left: 0;
						position: absolute;
						right: 0;
						top: 50%;
						transform: rotate(-5deg);
					}
				}
			}
		}
		
		&-text {
			margin: 0 auto $gutter;
			text-align: center;
			
			p {
				text-align: center;
			}
		}
		
		&-cta-list {
			text-align: center;
			
			a,
			button {
				display: block;
				margin: 0 auto $gutter;
			}
		}
		
		&-out-of-stock {
			color: $red;
			margin: 10px 0 20px;
		}
	}
}
