// sass-lint:disable class-name-format, no-qualifying-elements, force-element-nesting

/* ==========================================================================
   Styles specific to authoring (these should be limited)
   ========================================================================== */

$authoring-label-background: #affeff;
$authoring-label-color: #323737;

.authoring-component-label {
	background: $authoring-label-background;
	color: $authoring-label-color;
	font-family: adobe-clean, Helvetica, Arial, sans-serif;
	font-size: 14px;
	min-width: 66%;
	text-align: center;

	.aem-Grid .aem-Grid .cmp-grid-container > &,
	.aem-Grid .aem-Grid .aem-Grid .cmp-grid-column > & {
		margin-left: -15px;
		margin-right: -15px;
		min-width: 100%;
		width: auto !important; // sass-lint:disable-line no-important
	}
}

.authoring-component-outline {
	height: 100%;
	left: 0;
	outline: $authoring-label-background solid 3px;
	position: absolute;
	top: 0;
	width: 100%;
}
