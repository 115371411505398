// File:          _productfeatures.scss
// Description:   Productfeatures Styles
.bat-productfeatures {
	&-description {
		&-headline {
			margin-bottom: 20px;
		}

		&-text {
			@include media-breakpoint-up(lg) {
				max-width: 440px;
			}
		}
	}

	&-list {
		max-width: 360px;

		@include media-breakpoint-up(lg) {
			max-width: none;
			padding: 0;
		}

		&-item {
			@include media-breakpoint-up(lg) {
				padding-right: 16px;
			}

			picture {
				position: relative;

				img {
					margin: 0 auto;
					width: 80%;
				}

				&:before {
					background-color: $system-red;
					border-radius: 40px;
					content: '';
					height: 75px;
					left: 0;
					position: absolute;
					top: 0;
					width: 75px;
					z-index: -1;
				}

				&:after {
					background-color: $system-red;
				}
			}

			&-description {
				h5 {
					@include font-rem(20px, 20px);
					font-style: normal;
					font-weight: 700;
					margin-bottom: 10px;
					position: relative;
					text-transform: capitalize;

					@include media-breakpoint-up(lg) {
						@include font-rem(20px, 25px);
						margin-bottom: 20px;

						&:before {
							border-bottom: 3px solid $system-red;
							bottom: -10px;
							content: '';
							display: block;
							left: 0;
							position: absolute;
							right: 0;
						}

						&:after {
							border: 3px solid $system-red;
							border-radius: 50%;
							bottom: -16px;
							box-sizing: border-box;
							content: '';
							display: block;
							height: 16px;
							position: absolute;
							right: -15px;
							width: 16px;
						}
					}
				}

				p {
					@include font-rem(13px, 15px);
				}
			}

			//Waypoint for viewport in-view event
			&.bat-waypoint {
				left: -600px;
				opacity: 0;
				transition: left 1s ease-in calc(var(--animation-order) * 50ms),
					opacity 1s ease-in calc(var(--animation-order) * 50ms);

				@include media-breakpoint-up(lg) {
					left: -400px;
					transition: left 1s ease-in
							calc(var(--animation-order) * 50ms),
						opacity 2s ease-in calc(var(--animation-order) * 50ms);

					.authorEditMode & {
						// sass-lint:disable-line class-name-format
						left: 0;
						transition: none;
					}
				}
			}

			&.bat-waypoint--in-view {
				left: 0;
				opacity: 1;
			}

			@include media-breakpoint-up(lg) {
				max-width: 220px;
			}

			@include media-breakpoint-up(xl) {
				margin-left: calc(var(--animation-order) * 15%);
				max-width: 240px;
			}
		}
	}
}
.gtr-product-feature-section {
	.bat-section {
		position: relative;
		padding: 30px 19px (25 / 375 * 100%);
		background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 1.15%,
				rgba(215, 219, 230, 0.36) 108.36%
			),
			#ffffff;
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding-top: (25 / 375 * 100%);
			background-image: url('/content/dam/gtr/images/webp-gtr/rectangle-features.webp');
			background-size: cover;
		}
		@include media-breakpoint-up(md) {
			padding-bottom: (49 / 1440 * 100%);
			&:after {
				padding-top: (49 / 1440 * 100%);
				background-image: url('/content/dam/gtr/images/webp-global/rectangle-features-global.webp');
			}
		}
		.responsivegrid {
			.card {
				@media (max-width: 767px) {
					margin-bottom: 35px;
				}
			}
		}
	}
	.bat-section-content {
		padding-bottom: 45px;
		@include media-breakpoint-up(md) {
			padding-bottom: 20px;
			max-width: 1340px;
			margin-left: auto;
			margin-right: auto;
		}

		.headline {
			margin-bottom: 29px;
			width: 173px;
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
			@include media-breakpoint-up(md) {
				width: 100%;
				margin-bottom: 79px;
			}
			> bat-headline-default {
				margin-bottom: 0;
			}
		}

		.card {
			margin-bottom: 13px;
			@include media-breakpoint-up(md) {
				margin-bottom: 60px;
			}

			@media (max-width: 480px) {
				margin-bottom: 35px;
			}

			@media screen and (orientation: landscape) {
				margin-bottom: 30px;
			}
		}
	}
	
	&--vuse {
		@media (min-width: 1200px) {
			.bat-card-gtr-feature__headline h4 {
				min-height: 63px;
				display: flex;
				align-items: center;
			}
		}
	}
	&--velo {
		@media (min-width: 1200px) {
			.bat-card-gtr-feature__headline h4 {
				min-height: 93px;
				display: flex;
				align-items: center;
			}
		}
	}
	&.in-store {
		.bat-section {
			@include media-breakpoint-up(md) {
				padding-bottom: (66 / 1920 * 100%);
				&:after {
					padding-top: (66 / 1920 * 100%);
					background-image: url('/content/dam/gtr/images/webp-in-store/rectangle-features-in-store.webp');
				}
			}
		}
		.bat-section-content {
			padding-bottom: 25px;
			max-width: 1716px + 20px;
			.headline {
				margin-bottom: 106px;
			}
		}
		&.gtr-product-feature-section--velo,
		&.gtr-product-feature-section--vuse {
			.bat-card-gtr-feature__headline h4 {
				min-height: 96px;
				display: flex;
				align-items: center;
			}

		}
	}
	@media (max-width: 1199px) {
		.responsivegrid {
			.aem-Grid.aem-Grid--default--12
				> .aem-GridColumn.aem-GridColumn--default--3 {
				width: 50%;
			}
		}
	}
	@media (max-width: 767px) {
		.responsivegrid {
			.aem-Grid.aem-Grid--default--12
				> .aem-GridColumn.aem-GridColumn--default--3 {
				width: 100%;
			}
		}
	}
}
