.bat {
	p {
		.cta-account-email {
			font-weight: 700;
			text-decoration: none;
			text-decoration-line: none;
		}
	}
}

@include media-breakpoint-down(md) {
	bat-magentoaccountoverview-zonnic,
	bat-magentoorderhistory-zonnic,
	bat-magentoaddressbook-zonnic {
		h1,
		h2 {
			text-align: center;
		}

	}
}


.magentoaccountoverview {
	h2 {
		padding-top: 40px;

		@include media-breakpoint-down(md) {
			padding-top: 30px;
		}
	}
	
	.account-overview-heading {
		@include font-rem(20px, 28px);
		color: $black !important;
		font-weight: 600 !important;
		text-transform: none !important;

		@include media-breakpoint-down(md) {
			border-bottom: 2px solid $pale-gray;
			padding-bottom: 30px;
			text-align: center;
			width: 100%;
		}
	}

	.cta-account-email {
		color: $navy;
	}
	.bat-cta {
		margin-top: 20px;
		@include media-breakpoint-down(md) {
			max-width: 100%;
			width: 100%;
		}

		.bat-cta-style {
			margin-bottom: 20px 0;
			@include media-breakpoint-down(md) {
				max-width: 100%;
				width: 100%;
			}
		}
	}
}

.magentoorderhistory {
	.bat-account-table {
		margin-bottom: 20px;
		margin-top: 20px;
	}

	@include media-breakpoint-down(md) {
		.bat-account-table {
			margin: 20px 0;
		}
		.bat-cta {
			max-width: 100%;
			width: 100%;
		}
		.bat-cta-style {
			max-width: 100%;
			width: 100%;
		}
	}
}
