// Create default variables
.bat-tabs {
	@include root-prop(--border-bottom, #{$pale-gray});
	@include root-prop(--border-bottom-hover, #{$navy});
	@include root-prop(--border-bottom-selected, #{$navy-dark});
	@include root-prop(--text-color-tabs, #{$mid-gray});
	@include root-prop(--text-color-tabs-hover, #{$black});
	@include root-prop(--text-color-tabs-selected, #{$navy-dark});
}

// Create dark mode variables
.dark-mode {
	.bat-tabs {
		@include root-prop(--border-bottom, #{$whisper-gray});
		@include root-prop(--border-bottom-hover, #{$pale-gray});
		@include root-prop(--border-bottom-selected, #{$white});
		@include root-prop(--text-color-tabs, #{$concrete});
		@include root-prop(--text-color-tabs-hover, #{$pale-gray});
		@include root-prop(--text-color-tabs-selected, #{$white});
	}
}

// These properties should match those in .dark-mode
// @media (prefers-color-scheme: dark) {
// 	.bat-tabs {
// 		@include root-prop(--border-bottom, #{$whisper-gray});
// 		@include root-prop(--border-bottom-hover, #{$pale-gray});
// 		@include root-prop(--border-bottom-selected, #{$white});
// 		@include root-prop(--text-color-tabs, #{$concrete});
// 		@include root-prop(--text-color-tabs-hover, #{$pale-gray});
// 		@include root-prop(--text-color-tabs-selected, #{$white});
// 	}
// }

bat-tabs-default {
	.bat-tabs {
		border: 0;
		
		&-indicator {
			display: none;
		}
		
		&-list {
			justify-content: center;

			&-item {
				border-bottom: 2px solid var(--border-bottom);
				
				&.active {
					border-bottom: 2px solid var(--border-bottom-selected);

					span {
						color: var(--text-color-tabs-selected);
					}

					.bat-tabs-list-item-button {
						border: 0;
					}
				}
				&:hover {
					border-bottom: 2px solid var(--border-bottom-hover);

					span {
						color: var(--text-color-tabs-hover);
					}
				}

				&:not(:last-of-type) {
					border-right: 0;
				}
				
				@include media-breakpoint-up(lg) {
					&:not(:last-of-type) {
						border-right: 0;
					}
				}

				&-button {
					padding: 12px 30px;
					
					span {
						@include font-rem(12px, 14px);
						color: var(--text-color-tabs);
						font-family: $primary-font;
						font-weight: 700;
						letter-spacing: 0;
						position: relative;
						text-transform: uppercase;
						
						@include media-breakpoint-up(lg) {
							&:after {
								border-bottom: 0;
								bottom: 0;
								content: '';
								left: 0;
								position: absolute;
								transition: width .25s ease-in;
								width: 0;
							}
						}

						@include media-breakpoint-down(md) {
							span {
								display: block;
							}
						}
					}
					
					.active & {
						border-bottom: 0;
						
						@include media-breakpoint-up(lg) {
							color: var(--text-color-tabs);
						}
						
						span {
							color: var(--text-color-tabs);
							
							@include media-breakpoint-up(lg) {
								color: var(--text-color-tabs);
								
								&:after {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}

		&-panels {
			&-panel {
				& > div {
					padding: 8px 0;
				}

				.aem-GridColumn {
					&.image {
						padding-left: 0;
						padding-right: 0;
					}

					&.headline,
					&.text {
						padding-left: 25px;
						padding-right: 25px;
					}
				}
			}
		}
	}
}

bat-tabs-zonniccarouselsync {
	.bat-tabs {
		border: 0;
		
		&-indicator {
			display: none;
		}
		
		&-list {
			flex-wrap: wrap;
			justify-content: flex-start;
			margin: 0;
			
			&-item {
				border: 2px solid $navy;
				margin-top: 4px;

				
				&.active {
					background-color: $navy;

					span {
						color: $white;
					}

					.bat-tabs-list-item-button {
						border: 0;
					}
				}
				&:hover {
					span {
						color: $concrete;
					}
				}

				&:not(:last-of-type) {
					//border-right: 0;
					margin-right: 4px;
				}

				&-button {
					padding: 16px;

					span {
						@include font-rem(12px, 14px);
						color: var(--text-color-tabs);
						font-family: $primary-font;
						font-weight: 700;
						letter-spacing: 0;
						position: relative;
						text-transform: uppercase;
						
						@include media-breakpoint-up(lg) {
							&:after {
								border-bottom: 0;
								bottom: 0;
								content: '';
								left: 0;
								position: absolute;
								transition: width .25s ease-in;
								width: 0;
							}
						}

						span {
							display: block;
						}
					}
					
					.active & {
						border-bottom: 0;
						
						@include media-breakpoint-up(lg) {
							color: var(--text-color-tabs);
						}
						
						span {
							color: var(--text-color-tabs);
							
							@include media-breakpoint-up(lg) {
								color: var(--text-color-tabs);
								
								&:after {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}

		.bat-text {
			p {
				@include font-rem($font-sm, 20px);
			}
		}
	}
}


// ----------------------- AVALANCHE 
body {
	.bat-wrapper {
		bat-tabs-avalanche {
			background-color: $navy;
			padding-bottom: 40px;
			
			bat-section-default {
				.card {
					display: none;
					&:first-child {
						display: block; // seems to fix weird Tabs bug where multiple Cards are showing
					}
				}
			}

			&.bat {
				@include media-breakpoint-up(lg) {
					height: 525px;
					margin-left: -3.5px;
					min-height: 500px;
					padding-bottom: 120px;
					padding-top: 120px;
				}
			}
		
			.bat-tabs {
				border-top: 0;
				
				&-list {
					justify-content: center;
					margin-top: 0;
			
					&-item {
						
						&-button {
							border-bottom: 2px solid $system-red;
							box-shadow: none;
							padding: 12px;
							
							@include media-breakpoint-up(md) {
								padding: 12px 20px;
							}
		
							@include media-breakpoint-up(lg) {
								padding: 12px 30px;
							}
							
							span {
								color: $white;
								font-family: $primary-font;
								font-size: 12px;
								font-weight: 700;
								letter-spacing: .04rem;
								line-height: 14px;
								position: relative;
								text-transform: uppercase;
							}
						}
						&.active {
							button {
								border-bottom: 2px solid $system-red;
							
								@include media-breakpoint-up(lg) {
									background-color: transparent;
									color: $system-red;
								}
								
								span {
									color: $system-red;
									
									@include media-breakpoint-up(lg) {
										color: $system-red;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


// gtr custom
bat-tabs-default {
	.bat-tabs {
		.bat-tabs-list {
			margin-bottom: 30px;
			border-bottom: 1px solid  rgba(0, 0, 0, 0.2);
		}
		.bat-tabs-list-item {
			margin-bottom: -2px;
			border-bottom-width: 3px;
			border-color: transparent;
			margin: 0 18px;
			
			.bat-tabs-list-item-button span {
				text-transform: none;
				font-size: 18px;
				line-height: 150%;
				color: #000;
				font-weight: normal;
			}
			&.active {
				border-bottom-width: 3px;
				border-color: #0047B8;
				span {
					font-weight: 700;
					color: #0047B8;
				}
			}
			&-button {
				padding: 17px 0;
			}
		}
		.bat-tabs-panels {
			@include media-breakpoint-up(md) {
				border-bottom:  1px solid rgba(0, 0, 0, 0.2);
			}
		}
	}
}

// gtr tab velo global
.gtr-velo-product-global{
	.bat-tabs-default{
	 	.bat-tabs{
			.bat-tabs-list-item{
				@include media-breakpoint-up(md) {
					margin: 0 40px;
				}
			}
	 	}
	}
}

.gtr-flavour-tabs {
	padding: 23px 0 28px;
	.bat-tabs-panels-panel > div {
		padding: 0;
	}
	@media (min-width: 768px) {
		padding: 35px 0 45px;
		.bat-tabs {
			max-width: 934px;
			margin-left: auto;
			margin-right: auto;
			.bat-tabs-panels-panel .aem-GridColumn.headline, 
			.bat-tabs-panels-panel .aem-GridColumn.text {
				padding: 0;
			}
			.responsivegrid > .aem-Grid {
				& > .image {
					width: 51.2847965739%;
				}
				& > .contentwrapper {
					width: (100% - 51.2847965739%);
				}
				& > .text {
					width: (100% - 51.2847965739%);
					& + .text {
						width: 100%	
					}
				}
			}
		}
	}
	&__content {
		@media (min-width: 768px) {
			padding-left: 49px;
			.bat-text {

				max-width: 406px;
			}
		}
	}
	&__cta-text {
		padding-top: 50px;
		font-size: 22px;
		line-height: 150%;
		margin-bottom: 39px;
		.bat-text {
			p {
				font-size: 22px;
				line-height: 150%;
			}
		}
	}
	&__cta-button {
		.bat-cta-style {
			margin: 0 5px;
			width: 253px;
			max-width: 100%;
		}
	}
	.bat-tabs {
		.bat-tabs-list {
			visibility: hidden;
			opacity: 0;
			overflow: hidden;
			height: 0;
			margin: 0;
			padding: 0;
		}
		.bat-tabs-panels {
			border: none;
		}
	}
	&.in-store {
		padding: 30px 0 60px;
		.bat-tabs {

			max-width: 1482px;
			margin-left: auto;
			margin-right: auto;
			.bat-cta {
				.bat-cta-style {
					width: 425px;
					max-width: 100%;
				}
			}
			.responsivegrid > .aem-Grid {
				& > .image {
					width: (723 / 1482 * 100%);
				}
				& > .text {
					margin-left: auto;
					width: (722 / 1482 * 100%);
					padding-left: 10px;
					padding-right: 10px;
					.bat-text {
						padding-left: 10px;
						padding-right: 10px;
					}
				}
				& > .contentwrapper {
					margin-left: auto;
					width: (722 / 1482 * 100%);
				}
			}
			.bat-image {
				img {
					max-width: 723px;
					margin-left: auto;
					margin-right: auto;
				}
			}
			.cta.aem-GridColumn,
			.bat-tabs-panels-panel .aem-GridColumn.headline, 
			.bat-tabs-panels-panel .aem-GridColumn.text {
				padding: 0;
			}
			
		}
	}
}
[dir="rtl"] {
	@media (min-width: 768px) {
		.gtr-flavour-tabs__content {
			padding-right: 49px;
			padding-left: 0;
		}
	}
}