.bat-productfeatures {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	
	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}
	
	&-description {
		margin-bottom: 20px;
		
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			order: 1;
			padding-right: 60px;
			width: 50%;
		}
		
	}
	
	&-image {
		margin: 0 auto;
		max-width: 380px;
		
		@include media-breakpoint-up(lg) {
			order: 3;
		}
	}
	
	&-list {
		margin: 40px auto;
		max-width: 545px;
		overflow: hidden;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			order: 2;
			padding-right: 40px;
			width: 30%;
		}
		
		ul {
			list-style: none;
			margin: 0;
			padding: 0;	
		}
		
		&-item {
			align-items: center;
			display: flex;
			justify-content: flex-start;
			margin-bottom: 45px;
			position: relative;
			
			@include media-breakpoint-up(lg) {
				margin-bottom: 35px;
				max-width: 300px;
			}
			
			picture {
				align-items: center;
				display: flex;
				flex: 0 0 115px;
				height: 75px;
				padding: 0 40px 0 0;
				position: relative;
				width: auto;
		
				&:after {
					background-color: $black;
					border-radius: 3px;
					bottom: 0;
					content: '';
					display: block;
					height: 100%;
					position: absolute;
					right: 20px;
					top: 0;
					width: 2px;
					
					@include media-breakpoint-up(lg) {
						display: none;
					}
				}
				
				@include media-breakpoint-up(lg) {
					display: none;
					
				}
			}
			
			&-description {
				align-items: center;
				display: flex;
				flex: 1 1 auto;
				flex-wrap: wrap;
				
				@include media-breakpoint-up(lg) {
					padding: 0;
				}
				
				h5 {
					width: 100%;
				}
				
				p {
					margin-bottom: 0;
					width: 100%;
				}
			}
			
			//Waypoint for viewport in-view event
			&.bat-waypoint {
				opacity: 0;
				transition: opacity .5s ease-in calc(var(--animation-order) * 350ms);
				
				.authorEditMode & { // sass-lint:disable-line class-name-format
					opacity: 1;
					transition: none;
				}
			}
			
			&.bat-waypoint--in-view {
				opacity: 1;
			}
		}
	}
}
