.bat-form--create-new-password {

	padding: 0 20px;

	.bat-form-block-text {
		text-align: center;
	}

	.bat-form--password-messages {
		.bat-message {
			display: block;
			p {
				padding-left: 0;
				text-align: center;
				&::before {
					content: none;
				}
			}
		}
	}

	.bat-form--password-submit {
		button {
			max-width: unset;
			width: 100%;		
			@include media-breakpoint-up(sm) {
				max-width: 300px;
			}
		}		
		@include media-breakpoint-up(sm) {
			text-align: center;	
		}			
	}
	
}
