// background-color: pink;
// @include media-breakpoint-up (lg) {
// 	background-color: lightblue;
// }

// @include media-breakpoint-up (xl) {
// 	background-color: lightcoral;
// }

// @include media-breakpoint-up (xxl) {
// 	background-color: lightgreen;
// }

// @include font-rem($font-sm, $font-sm);

.bat-hero--plp {
	align-items: center;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	position: relative;
	width: 100%;
	img {
		-o-object-fit: unset;
		height: 100%;
		left: unset;
		max-width: none;
		min-width: 100%;
		object-fit: unset;
		position: unset;
		top: unset;
		transform: unset;
		width: 100%;
	}
	p {
		color: $white;
	}
}

.bat-hero--plp-content {
	padding-bottom: 60px;
	padding-left: 20px;
	padding-top: 10px;
	text-align: right;
}
