//HEADLINE 
h1,
h2, 
h3, 
h4, 
h5, 
h6 {
	margin-bottom: 10px;
	
	&.dark {
		color: $black;
	}
	
	&.light {
		color: $white;
	}
}
