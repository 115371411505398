.bat-producthero {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	visibility: hidden;
	
	&-mobile {
		display: block;
		
		@include media-breakpoint-up(md) {
			display: none;
		}
	}
	
	&-desktop {
		display: none;
		
		@include media-breakpoint-up(md) {
			display: block;
		}
	}
	
	&.loaded {
		visibility: visible;
	}
	
	@include media-breakpoint-up(md) {
		flex-direction: row;
	}
	
	&-content {
		width: 100%;
		
		@include media-breakpoint-up(md) {
			width: 50%;
		}
	}
	
	&-gallery {
		margin-bottom: 40px;
		width: 100%;
		
		.slick-track {
			align-items: center;
			display: flex;
		}
		
		.slick-arrow {
			position: absolute;
			top: 50%;
			z-index: 1;
			
			&.slick-prev {
				left: 20px;
			}
			
			&.slick-next {
				right: 20px;
			}
		}
		
		.slick-dots {
			align-items: center;
			bottom: -40px;
			display: flex;
			flex-wrap: wrap;
			height: 40px;
			justify-content: center;
			left: 0;
			list-style: none;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			
			li {
				display: flex;
				flex: 0 0 auto;
				padding: 5px;
				
				button {
					border: solid 1px $black;
					border-radius: 20px;
					height: 10px;
					text-indent: -99999999px;
					width: 10px;
				
					&:hover {
						background-color: $black;
					}
				}
				
				&.slick-active {
					
					button {
						background-color: $black;
					}
				}
			}
		}
		
		&-images {
			
			.large {
		
				img {
					display: block;
					height: auto;
					margin: 0 auto;
					max-height: 400px;
					max-width: 100%;
					width: auto;
				}
			}
		}
		
		&-thumbs {
			display: none;
			padding: 0 40px;
			
			@include media-breakpoint-up(sm) {
				display: flex;
				justify-content: center;
			}
			
			&.active {
				border: 2px solid $black;
			}
			
			.thumb {
				align-items: center;
				display: flex;
				height: 90px;
				margin: 0 10px;
				width: 90px;
				
				img {
					display: block;
					margin: 0 auto;
					max-height: 90px;
					max-width: 100%;
					width: auto;
				}
			}
		}
	}
	
	&-configurator {
		width: 100%;
		
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		
		&-title {
			margin-bottom: 20px;
		}
		
		&-price {
			margin-bottom: 20px; 
			
			.formatted-price {
				@include font-rem(32px, 32px);
				font-weight: 700;
				position: relative;
				
				@include media-breakpoint-up(lg) {
					@include font-rem(46px, 46px);
				}
				
				&.slash-price {
					color: $red;
					
					@include font-rem(20px, 20px);
					
					@include media-breakpoint-up(lg) {
						@include font-rem(32px, 32px);
					}
					
					&::after {
						background: $red;
						content: '';
						height: 2px; 
						left: 0; 
						position: absolute;
						top: 50%; 
						transform: rotate(-7deg);
						width: 100%; 
					}
				}
			}
			
			.suffix {
				@extend p;
			}
		}
		
		&-short-description {
			
			h6 {
				@include font-rem(20px, 20px);
			}
		}
		
		&-description {
			margin-bottom: 20px;
			
			h1, 
			h2,
			h3,
			h4,
			h5,
			h6 {
				@include font-rem(16px, 16px);
				font-weight: 700;
			}
			
			p {
				@include font-rem(16px, 16px);
			}
			
			ul {
				
				li {
					@include font-rem(16px, 16px);
					margin-bottom: 0;
				}
			}
		}
		
		&-stock {
			@extend p;
			margin-bottom: 20px;
			
			&.out-of-stock {
				color: $red;
			}
		}
		
		
		&-subscription {
			padding: 20px 0;
			
			&-text {
				@include font-rem(14px, 14px);
				
				&-note {
					
					p {
						@include font-rem(10px, 10px);
						margin: 5px 0 0;
					}
				}
				
				&-price {
					font-weight: 700;
					
					.discount-savings {
						color: $red;
					}
				}
			}
			
			&-max-message {
				background-color: $red;
				color: $white;
				display: none;
				margin-top: 20px;
				max-width: 480px;
				padding: 20px 40px;
				text-align: center;
				text-transform: uppercase;
				
				&.active {
					display: block;
				}
			}
			
			&-details {
				
				display: inline;
				
				button {
					
					@include font-rem(10px, 10px);
					text-decoration: underline;
					
					&:hover {
						text-decoration: none;
					}
				}
			}
			
			fieldset {
				padding-bottom: 20px;
				
				label {
					cursor: pointer;
					height: 25px;
					margin-bottom: 5px;
					margin-right: 5px;
					
					input {
						height: 22px;
						width: 22px;
					}
				}
			}	
		}
		
		&-options {
			
			form {
				display: flex;
				flex-direction: column;
			}
			
			&-option {
				padding: 10px 0;
				
				p {
					margin-bottom: 5px;
				}
				
				&-input {
					
					.select-wrapper {
						display: inline-block;
						
						select {
							padding-right: 40px;
						}
					}
					
					fieldset {

						label {
							cursor: pointer;
							display: inline-flex;
							margin-bottom: 0;
							margin-right: 5px;
							
							input {
								height: 22px;
								width: 22px;
							}
						}
					}	
				}
			}
		}
		
		&-bottom {
			align-items: center;
			display: flex;
			justify-content: flex-start;
			
			.bat-quantity {
				margin: 0 20px 0 0;
				padding: 0;
			}
			
			button {
				
				&[data-cta-type='update'] {
					display: none;
					
					&.active {
						display: block;
					}
				}
				
				&[data-cta-type='cart'] {
					
					&.in-active {
						display: none;
					}
				}
			}
		}
	}
}
