.bat-contentfragment-store {
	padding: 64px 0 47px;
	position: relative;

	&__selection-label {
		text-align: center;
		margin-bottom: 10px;
	}

	&__selection {
		margin-left: 23px;
		margin-right: 23px;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 330px;
		max-width: 100%;
		height: 55px;
		border: 1px solid #CFCFCF;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		background: #FFFFFF;
		outline: none;
		font-size: 16px;
		line-height: 24px;
		top: -27.5px;
		padding: 15px 35px 15px 20px;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-repeat: no-repeat;
		background-position: right 20px center;
		background-image: url('data:image/svg+xml, <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.01453 4.81251L6.56438 3.1324C7.28751 2.34907 8.01086 1.56595 8.73443 0.783037C8.96099 0.538544 9.2594 0.470285 9.55881 0.583223C9.8482 0.692128 10.0337 0.94748 9.99489 1.24441C9.97632 1.38654 9.91545 1.52106 9.81912 1.63286C8.40918 3.17366 6.99456 4.71022 5.57525 6.24254C5.25412 6.58942 4.76758 6.58384 4.44679 6.23757C3.02258 4.69429 1.59972 3.15008 0.178187 1.60494C-0.0854672 1.31763 -0.0520509 0.898457 0.248696 0.663273C0.564814 0.415057 0.993211 0.45322 1.27157 0.754802C2.46052 2.04345 3.64914 3.33242 4.83742 4.62169C4.88955 4.68126 4.94469 4.73804 5.01453 4.81251Z" fill="rgb(98,97,97)"/></svg>');
		color: #000;
		&:focus {
			outline: none;
		}
		option {
			font-size: 16px;
		}
		
	}
	&__target-item {
		display: none;
		&.active {
			display: block;
		}
	}

	&__top {
		padding: 0 12px;
		@include media-breakpoint-up(md) {
			padding: 0 18px;
		}
		&__title {
			text-align: center;
			margin-bottom: 22px;
			font-size: 20px;
			@include media-breakpoint-up(md) {
				font-size: 24px;
				margin-bottom: 60px;
			}
		}
		&__logo {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: -12px;
			margin-right: -12px;
			flex-wrap: wrap;
			justify-content: center;
			@include media-breakpoint-up(md) {
				margin-left: -18px;
				margin-right: -18px;
			}
			&__item {
				margin-bottom: 29px;
				padding: 0 12px;
				@include media-breakpoint-up(md) {
					padding: 0 18px;
					margin-bottom: 38px;
				}
				&__img {
					position: relative;
					background-color: #fff;
					border-radius: 50%;
					width: 94px;
					height: 94px;
					margin: 0 auto;
					max-width: 100%;

					@include media-breakpoint-up(md) {
						width: 141px;
						height: 141px;
					}
				}
				//img {
				//	position: absolute;
				//	top: 50%;
				//	left: 50%;
				//	transform: translate(-50%, -50%);
				//	max-width: 90%;
				//	max-height: 40px;
				//}
			}
		}
		&__description {
			text-align: center;
			padding: 0 10px;
			margin-bottom: 40px;
		}
	}
	&__list {
		margin-bottom: 48px;

		@include media-breakpoint-up(md) {
			max-width: 1160px;
			margin: 0 auto 34px;
			display: flex;
			flex-wrap: wrap;
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
		}

		& + .bat-contentfragment-store__list {
			@include media-breakpoint-up(md) {
				flex-direction: row-reverse;
			}
		}
		&__banner {
			position: relative;
			.bat-contentfragment-store__list__content__headline {
				font-weight: 400;
				font-size: 28px;
				line-height: 34px;
				color: #FFFFFF;
				position: absolute;
				margin-bottom: 0;
				* {
					color: #FFFFFF;
					font-weight: 400;
					font-size: 28px;
					line-height: 34px;
				}
				b {
					font-weight: 400;
					font-size: 20px;
					display: block;
				}
			}
			&--duty {
				
				.bat-contentfragment-store__list__content__headline {
					left: 29px;
					bottom: 10px;
				}
			}
			&--online {
				.bat-contentfragment-store__list__content__headline {
					width: 50%;
					right: 28px;
					bottom: 18px;
					text-align: right;
				}
			}
		}
		&__banner,
		&__content {
			@include media-breakpoint-up(md) {
				width: 50%
			}
		}
		
		&__content {
			margin-top: 17px;
			padding: 0 29px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include media-breakpoint-up(lg) {
				padding: 0 70px;
			}

			&__headline {
				margin-bottom: 10px;
				font-weight: 700;
				font-size: 32px;
				line-height: 38px;
				* {
					font-weight: 700;
					font-size: 32px;
					line-height: 38px;
				}
				b {
					display: block;
				}
			}

			&__item {
				padding: 12px 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #ebebeb;

				&__title {
					padding-right: 10px;
					font-weight: 700;
					font-size: 15px;
					line-height: 23px;
					color: #000000;
				}
				a {
					flex-shrink: 0;
					white-space: nowrap;
					display: flex;
					align-items: center;
					text-decoration: underline;
					font-weight: 400;
					font-size: 13px;
					line-height: 20px;
					text-transform: uppercase;

					color: #0047b8;

					span {
						display: block;
						margin-right: 6px;
					}
				}
			}
		}
	}
	&__popup {
		padding: 15px 23px 0;


		&__title {
			text-align: center;
			font-weight: 700;
			font-size: 28px;
			line-height: 34px;
			margin-bottom: 14px;
		}
		&__desc {
			font-weight: 400;
			font-size: 15px;
			line-height: 136%;
			text-align: center;
			letter-spacing: 0.015em;
			color: #000000;
            margin-bottom: 18px;
		}
		&__img {
            max-width: 660px;
            margin: 0 auto;
			margin-bottom: 18px;
		}
		&__address {
			font-weight: 400;
			font-size: 18px;
			line-height: 150%;
			text-align: center;
			letter-spacing: 0.04em;
			color: #000000;
			h3 {
				font-size: 13px;
				line-height: 1.5;
				font-weight: normal;
			}
		}
	}
}

.bat-contentfragment-store.bat-contentfragment-store__countries-section,
.global .bat-contentfragment-store {
	
	position: relative;
	padding: 10px 20px 50px;

	&:before {
		content: "";
		position: absolute;
		top: 55px;
		left: 0;
		right: 0;
		padding-top: calc(372/1920 * 100%);
		background-image: url('/content/dam/gtr/images/webp-in-store/rectangle-right.webp');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		z-index: -1;
	}

	.bat-contentfragment-store__selection {
		top: 0;
		position: static;
		display: block;
		margin: 0 auto 80px;
		max-width: 690px;
		width: 100%;

		@include media-breakpoint-down(sm) {
			margin-bottom: 40px;
		}
	}
	@media (max-width: 767px) {
		padding-left: 0;
		padding-right: 0;
		
	}
}

.bat-contentfragment-countries {
	&__container {
		max-width: $site-max-width__content;
		margin: 0 auto;
	}

	&__headline {
		text-align: center;
		margin-bottom: 40px;
		font-size: 24px;
	}
}
.bat-contentfragment-country {
	&__items {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		width: calc(100%/3);
		display: flex;
      	align-items: center;
		margin-bottom: 20px;

		@include media-breakpoint-down(sm) {
			width: calc(100%/2);
		}

		&-logo {
			margin-right: 15px;

			img {
				width: 51px;
			}
		}
		&-name {
			word-break: break-word;
		}
	}
}

.bat-contentfragment-store.bat-contentfragment-store__countries-section {
	.bat-contentfragment-store {
		&__selection {
			height: 88px;
			font-size: 20px;

			&-label {
				font-size: 24px;
			}
		}
	}
}

.global {
	.bat-contentfragment-store {
		&:before {
			transform: scaleX(-1);
			top: 20px;
		}
	}


	.bat-contentfragment-store {
		.bat-contentfragment-store__selection {
			height: 45px;
			padding-top: 0;
			padding-bottom: 0;
			line-height: 45px;
			font-size: 13px;
			max-width: 437px;

			&-label {
				font-size: 15px;
			}
			@media (max-width: 767px) {
				width: 330px;
				max-width: 90%;
			}
		}
	}
}

// .global {
// 	.hide-global {
// 		display: none;
// 	}
// }

// .airport {
// 	.hide-airport {
// 		display: none;
// 	}
// }
.hide-global {
	@include media-breakpoint-up (md) {
		display: none;
	}
}
.hide-airport {
	@include media-breakpoint-down (sm) {
		display: none;
	}
}

.where-to-buy__headline {
	padding-top: 65px;
	padding-bottom: 20px;

	&.in-store {
		padding-top: 81px;
		padding-bottom: 32px;
	}
}
[dir="rtl"] {
	.bat-contentfragment-country__item-logo {
		margin-left: 15px;
		margin-right: 0;
	}
	.bat-contentfragment-store__list__content__item__title {
		padding-right: 0;
		padding-left: 10px;
	}
	.bat-contentfragment-store__list__content__item a {
		span {
			margin-right: 0;
			margin-left: 6px;
		}
		svg {
			transform: rotate(180deg);
		}
	}
	
}