// File:           _productlisting.scss
// Description:   Productlisting Styles

.productlisting {
	padding-left: 0;
	padding-right: 0;
}

.bat-productlistings {
	background-color: $concrete;
	margin: 0 auto;
	max-width: $site-max-width;
	min-height: 400px;
	padding: 20px 10px;
	
	&-cards {
		
		@include media-breakpoint-up(md) {
			justify-content: center;
		}
	}
	
	&-card {
		background-color: $white;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin: 10px;
		min-height: 278px;
		overflow: hidden;
		padding: 20px;
		position: relative;
		width: 100%;
		
		@include media-breakpoint-up(sm) {
			width: 460px;
		}
		
		&-image {
			flex: 1 0 auto;
			height: auto;
			left: 20px;
			max-height: 280px;
			position: absolute;
			top: 20px;
			width: 46%;
			z-index: 1;
			
			@include media-breakpoint-up(sm) {
				width: 50%;
			}
			
			img {
				height: auto;
				padding-right: 0;
				width: 100%;
				
				@include media-breakpoint-up(sm) {
					max-height: 88%;
					padding-right: 0;
					width: 100%;
				}
			}
		}
		
		&-details {
			display: flex;
			flex: 1 0 100%;
			flex-direction: column;
			text-align: right;
			
			p {
				display: none;	
			}
		}
		
		&-prices {
			@include font-rem(16px, 16px);
			color: $navy;
			font-weight: 700;
			margin-bottom: 1rem;
			vertical-align: middle;
			
			.base-price {
				margin-right: .5rem;
				
				&.has-sale-price {
					@include font-rem(18px, 18px);
					color: $system-red;
					position: relative;
					text-decoration: none;
					
					&:before {
						-moz-transform: rotate(-5deg);
						-ms-transform: rotate(-5deg);
						-o-transform: rotate(-5deg);
						-webkit-transform: rotate(-5deg);
						border-color: $system-red;
						border-top: 2px solid;
						content: '';
						left: 0;
						position: absolute;
						right: 0;
						top: 50%;
						transform: rotate(-5deg);
					}
				}
			}
		}
		&-name {
			@include font-rem(18px, 18px);
			border-bottom: 1px solid $system-red;
			font-weight: 700;
			margin: 10px auto 20px;
			padding: 0 0 10px 55%;
			
			a {
				text-decoration: none;
			}
		} 
		
		&-quantity {
			margin: 0 0 0 auto;
			width: 90px;
		}
		
		&-variants {
			margin: 10px 0 20px auto;
			max-width: 45%;
			
			form {
				.select-wrapper {
					
					select {
						padding: 11px 30px 13px 13px;
						text-overflow: ellipsis;
					}
				}
			}
		}
		
		&-buttons {
			margin: auto 0 0;
			
			.notify-button,
			.options-button,
			.cart-button {
				margin-bottom: 0;
				width: auto;
			}
			
			.notify-button {
				display: none;
			}
		}
		
		&-stock {
			@include font-rem(20px, 20px);
			color: $system-red;
			font-style: normal;
			padding: 0;
			text-transform: uppercase;
		}
		
		&-message {
			background-color: transparent;
			color: $system-red;
			display: none;
			justify-content: center;
			padding: 5px;
			
			button {
				background-color: transparent;
				color: $error;
			}
			
			.message {
				padding: 0 1rem;
			}
		}
	}
	
	.loading-wrapper {
		align-items: center;
		display: flex;
		height: 110px;
		position: relative;
		width: 100px;
		
		p {
			text-align: center;
			width: 100%;
		}
		
		&:after {
			-webkit-animation: spin 2s linear infinite; /* Safari */
			animation: spin 2s linear infinite;
			border: 10px solid $system-red;
			border-radius: 50%;
			border-top: 10px solid $system-red;
			bottom: 0;
			content: '';
			height: 100px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
			width: 100px;
			z-index: 1;
		}
	}
	
	.errors-wrapper {
		ul {
			display: none;
		}
	}
}


.bat-sort-button {

	.bat-cta-style {
		
		&.button-dark {
			border-radius: 0;
		}
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% { 
		-webkit-transform: rotate(0deg); 
	}
	100% { 
		-webkit-transform: rotate(360deg);
	 }
}
  
@keyframes spin {
	0% { 
		transform: rotate(0deg); 
	}
	100% { 
		transform: rotate(360deg); 
	}
}
