//Links
.bat {
	// AEM text editor
	p {
		// sass-lint:disable no-misspelled-properties
		a {
			text-decoration-color: inherit;
			text-decoration-line: underline;
			text-decoration-style: solid;
			text-decoration-thickness: 2px;
			text-underline-offset: 5px;
			outline: none;
			// &:focus {
			// 	background: $light-blue;
			// 	border-radius: 6px;
			// 	outline: none;
			// 	padding: 4px;
			// }
		}
	}

	// all other links
	a,
	.bat-link {
		color: inherit;
		display: inline-flex;
		font-size: inherit;
		text-decoration: none;
		transition: color 0.5s ease;

		@include media-breakpoint-up(lg) {
			&:hover {
				span {
					color: inherit;
				}
			}
		}

		&--dark {
			color: $navy;

			i {
				svg {
					fill: $navy;
				}

				&:before {
					font-size: 13px;
				}
			}
			&:focus {
				background: $concrete;
			}
		}

		&--light {
			color: $white;

			i {
				svg {
					fill: $white;
				}
			}
		}

		&--arrow--dark,
		&--arrow--light {
			@include font-rem(13px, 19px);
			@include media-breakpoint-up(lg) {
				@include font-rem(15px, 1.3);
			}
			align-items: center;
			display: flex;
			font-family: $primary-font;
			justify-content: center;
			letter-spacing: 1px;
			padding: 8px;
			position: relative;
			text-transform: uppercase;
			width: fit-content;

			&:after {
				content: '';
				font-family: 'icomoon';
				font-size: 12px;
				font-weight: normal;
				margin-left: 7px;
				position: relative;
				transform: translateX(0);
				background-image: url('/content/dam/gtr/images/webp-gtr-airport/Vector.webp');
				width: 10px;
				height: 10px;
				background-position: center center;
				background-repeat: space;
			}

			&:hover {
				font-weight: 700;

				&:after {
					-moz-animation: bounce-arrow 1s infinite ease-in-out;
					-webkit-animation: bounce-arrow 1s infinite ease-in-out;
					animation: bounce-arrow 1s infinite ease-in-out;
				}
			}

			&:focus {
				border: $navy solid 1px;
				border-radius: 15px;
				outline: none;
			}

			&--back {
				@include font-rem(15px, 15px);
				align-items: center;
				display: flex;
				font-family: $primary-font;
				font-weight: 700;
				justify-content: center;
				letter-spacing: 1px;
				padding: 8px;
				position: relative;
				text-transform: uppercase;
				width: fit-content;

				&:after {
					display: none;
				}

				&:before {
					bottom: 1px;
					content: '\e941';
					font-family: 'icomoon';
					font-size: 26px;
					font-weight: normal;
					margin-right: 3px;
					position: relative;
					transform: translateX(0);
				}

				&:hover {
					&:before {
						-moz-animation: bounce-arrow 1s infinite ease-in-out;
						-webkit-animation: bounce-arrow 1s infinite ease-in-out;
						animation: bounce-arrow 1s infinite ease-in-out;
					}
				}
			}
		}

		&--arrow--dark {
			color: $system-blue;
			span {
				text-decoration: underline;
			}
			&:hover {
				color: $navy;
				span {
					color: inherit;
				}
			}

			&:after {
				color: inherit;
				line-height: 1;
			}

			&--back {
				color: $navy;

				&:hover {
					span {
						color: $navy;
					}
				}

				&:before {
					color: $navy;
				}
			}
		}

		&--arrow--light {
			color: $white;

			&:hover {
				span {
					color: $white;
				}
			}

			&:after {
				color: $white;
			}

			&--back {
				color: $white;

				&:hover {
					span {
						color: $white;
					}
				}

				&:before {
					color: $white;
				}
			}
		}

		&.center {
			justify-content: center;
		}

		&.left {
			justify-content: flex-start;
		}

		&.right {
			justify-content: flex-end;
		}

		i {
			height: 20px;
			width: 20px;

			&:before {
				font-size: 13px;
			}
		}
	}
}

@-webkit-keyframes bounce-arrow {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(15%);
	}
	100% {
		transform: translateX(0);
	}
}

@-moz-keyframes bounce-arrow {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(15%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes bounce-arrow {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(15%);
	}
	100% {
		transform: translateX(0);
	}
}

.bat-edu-banner {
	.arrow-link-light span{
		@media (max-width: 767px) {
			text-decoration: underline;
		}
	}
}
