// For AEM 
body {
	.bat-wrapper {
		bat-pagination-zonnic {
			align-items: center;
			display: flex !important;// sass-lint:disable-line no-important
			font-weight: 700;
			justify-content: flex-start;
			margin-top: 50px; 
		
			.prev {
				margin-right: 8px;
			}
		
			.next {
				margin-left: 8px;
			}
		
			.wrapper { 
				align-items: flex-start;
				display: flex; 
				justify-content: flex-start;
				margin-bottom: 4px;
		
				button {
					margin: 0 4px;
					padding: 0 8px;
		
					.page {
						box-sizing: border-box;
						margin: inherit 8px;
						padding: 0 8px;
					}
				}
				
				.ellipsis {
					align-self: flex-end;
				}
				
			}
			
			button {
				background-color: transparent;
				border: 0;
				border-radius: 50%;
				color: $navy;
				cursor: pointer;
				height: 30px;
				width: 30px;
		
				&.current-page {
					background-color: $navy;
					color: $white;
					font-size: 14px;
		
					&:hover {
						background-color: $navy;
						color: $white;
					}
				}
		
				&:hover {
					background-color: $light-blue;
				}	
		
				&:disabled {
					color: $concrete;
				}
		
				&:focus {
					outline: 1px solid $navy;
					outline-offset: 4px;
				}
		
				// Prev Page Button
				.icon-chevron-left {
					font-size: 22px;
					&:before {
						padding-right: 2px;
						padding-top: 2px;
					}
				}
		
				// Next Page Button
				.icon-chevron-right {
					font-size: 22px;
					&:before {
						display: block;
						padding-left: 3px;
						padding-top: 2px;
					}
				}
			}
		}
	}
}
