.bat-hero-narrow {
	align-items: baseline;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height:  60vh;
	overflow: hidden;
	padding: 46px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(md) {
		align-items: flex-start;
		justify-content: flex-start;
	}

	img {
		bottom: 0;
		height: 100%;
		left: 0;
		max-width: none;
		min-width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		right: 0;
		top: 0;
		width: auto; 
	}
	
	&-img {
		
		&__left {

			img {
				left: auto;
			}
			
		}

		&__center {
			img {
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
	
		p {
			@include font-rem($font-sm, $font-sm);
			font-weight: 700;
			
			@include media-breakpoint-up (sm) {
				@include font-rem($font-md, $font-md);
			}
			
			@include media-breakpoint-up (lg) {
				@include font-rem($font-lg, $font-lg);
			}
		}

		.bat-button {
			margin-top: 1.5rem;
		}

		&-firstheader,
		&-secondheader {
			text-transform: uppercase;
			
			// sass-lint:disable no-important
			h1, 
			h2, 
			h3, 
			h4, 
			h5, 
			h6 {
				font-size: 6.8vw !important;
				line-height: 6.8vw !important;
				
				@include media-breakpoint-up (sm) {
					font-size: 5.8vw !important;
					line-height: 5.8vw !important;
				}
				
				@include media-breakpoint-up (lg) {
					font-size: 4.5vw !important;
					line-height: 4.5vw !important;
				}
				
				@include media-breakpoint-up (xl) {
					font-size: 5.5vw !important;
					line-height: 5.5vw !important;
				}
				
				@include media-breakpoint-up (xxl) {
					font-size: 4.86667rem !important;
					line-height: 4.86667rem !important;
				}
			}
		}

		&__left {
			align-items: flex-start;
		}

		&__right {
			align-items: flex-end;
		}

		&__center {
			align-items: center;
		}
	}

	&-main {
		width: 100%;

		@include media-breakpoint-up(lg) {
			width: 60%;
		}
	}
}
