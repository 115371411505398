.bat-productcard {
	
	&--velo {
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: flex-end;
		margin-bottom: 20px;
		max-width: none;
		opacity: 0;
		padding: 20px;
		position: relative;
		transition: opacity .2s ease-in;
		width: 100%;
	
		@include media-breakpoint-up(sm) {
			max-width: 460px;
		}
		
		&.loaded {
			opacity: 1;
		}
		
		.bat-quantity {
			justify-content: center;
			margin: 0 0 0 auto;
			width: 90px;
		}
		
		.bat-productcard-tile {
			flex: 1 0 auto;
			height: auto;
			left: 20px;
			max-height: 280px;
			position: absolute;
			top: 20px;
			width: 46%;
			z-index: 1;
			
			@include media-breakpoint-up(sm) {
				width: 50%;
			}
			
			&-image {
				padding: 10px;
			}
			
			img {
				height: auto;
				padding-right: 0;
				width: 100%;
				
				@include media-breakpoint-up(sm) {
					max-height: 88%;
					padding-right: 0;
					width: 100%;
				}
			}
		}
		
		.bat-productcard-content {
			align-content: flex-end;
			display: flex;
			flex-direction: column;
			width: 100%;
			
			&-headline {
				
				h6 {
					@include font-rem(16px, 16px);
					border-bottom: 1px solid $system-red;
					font-weight: 700;
					margin: 10px auto 0;
					padding: 0 0 10px 55%;
					text-align: right;
					
					@include media-breakpoint-up(sm) {
						@include font-rem(14px, 14px);
					}
					
					@include media-breakpoint-up(md) {
						@include font-rem(13px, 13px);
					}
					
					@include media-breakpoint-up(lg) {
						@include font-rem(16px, 16px);
					}
					
					@include media-breakpoint-up(xl) {
						@include font-rem(18px, 18px);
					}
				}
			}
			
			&-text {
				@include font-rem(16px, 16px);
				color: $navy;
				font-weight: 700;
				margin: 0 0 10px auto;
				vertical-align: middle;
		
				.slash {
					@include font-rem(16px, 16px);
					color: $dark-gray;
					position: relative;
					
					&:before {
						-moz-transform: rotate(-5deg);
						-ms-transform: rotate(-5deg);
						-o-transform: rotate(-5deg);
						-webkit-transform: rotate(-5deg);
						border-color: $system-red;
						border-top: 2px solid;
						content: '';
						left: 0;
						position: absolute;
						right: 0;
						top: 50%;
						transform: rotate(-5deg);
					}
				}
			}
			
			&-cta-list {
				align-items: flex-end;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				margin: auto 0 0 auto;
				text-align: right;
				width: 100%;
				
				a, 
				button {
					margin: 0 0 10px;
				}
			}
		}
	}
}
