.bat-hero {

	&-content {
		margin-left: auto;
		margin-right: auto;
		max-width: $max-width-xxl;

		ul {
			margin-top: 1rem;
		}

		.bat-button {
			margin-top: 1.5rem;
		}
		
		&.light {
			p,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $white;
			}
		}

		@include media-breakpoint-down(sm) {
			.bat-cta-style {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	//-----------------------------------------------------------------| Product Detail Page
	&--pdp {
		align-items: flex-start;
		background-color: $navy;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height:  auto;
		justify-content: flex-end;
		padding: 0;
		position: relative;
		width: 100%;

		&-bottomsubheader {
			p {
				line-height: 1.25;
				margin-bottom: 0;
			}
		}

		&-content {
			margin-top: calc(-75px + ((-200) - (-75)) * ((100vw - 375px) / (768 - 375)));
			padding: 0 5% 5%;
			position: relative;

			&.light {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				li,
				p {
					color: $white;
				}
			}

			.bat-button {
				margin-top: 1.5rem;
			}

			p {
				margin-bottom: 0;
				
				& + p {
					margin-top: 10px;
				}
			}
		}

		&-cta {
			display: flex;
			width: 100%;

			&.right {
				justify-content: flex-end;
			}
		}

		&-headline {
			margin-bottom: 1.75rem;
		}

		&-topsubheader {
			ol {
				counter-reset: listCounter;
				list-style: none;
				margin-bottom: 1.75rem;
				padding: 0;
				li {
					margin-bottom: 48px;
					&:last-child {
						margin-bottom: 0;
					}
					span {
						align-items: center;
						display: flex;
						&[class*='bullet'] {
							&::before {
								flex-grow: 0;
								flex-shrink: 0;
								line-height: 1;
								margin-right: 2.25rem;
							}
						}
						&.bullet--numbered {
							&::before {
								background-color: $white;
								border-radius: 50%;
								color: $navy;
								content: counter(listCounter);
								counter-increment: listCounter;
								font-size: 2.25rem;
								font-weight: 700;
								height: 64px;
								padding: 16px;
								text-align: center;
								width: 64px;
							}
						}
						&.bullet--icon-place {
							&::before {
								content: url('/content/dam/zonnic/images/pdp-hero/velo_lozenges_place-icon.webp');
							}
						}
						&.bullet--icon-embrace {
							&::before {
								content: url('/content/dam/zonnic/images/pdp-hero/velo_lozenges_embrace-icon.webp');
							}
						}
					}
				}
			}
			p {
				line-height: 1.25;
				margin-bottom: 3.375rem;
			}
		}

		.headline6-color1 {
			@extend .bat-headline-style.headline6-color1;
		}

		img {			
			clip-path: polygon(0 0, 100% 0, 100% 80%, 0 55%);
			left: auto;
			position: static;
			top: auto;
			transform: none;
		}

		@include media-breakpoint-up(md) {
			flex-direction: row;
			height:  672px;
			justify-content: flex-start;

			&-content {
				flex: 1 2 670px;
				margin-top: 0;
				max-width: none;
				min-width: 490px;
				& > * {
					max-width: 490px;
				}
			}

			&-headline {
				margin-bottom: 1.75rem;
			}
	
			&-topsubheader {
				ol {
					margin-bottom: 3rem;
					max-width: 370px;
					li {
						margin-bottom: 40px;
					}
				}
				p {
					line-height: 1.25;
					margin-bottom: 3.375rem;
				}
			}

			& .padding-left-medium {
				padding-left: 44px;
			}
	
			& .padding-right-small {
				padding-right: 32px;
			}
	
			& .padding-top-large {
				padding-top: 80px;
			}

			picture {
				flex: 0 1 830px;
				height: 100%;
				max-width: 830px;
				img {
					
					// The calc() function makes that the clip-path scale and remain at an angle; otherwise, @ 769px, the angle is vertical
					// For more info about the formula/equation see: https://css-tricks.com/snippets/css/fluid-typography/
					clip-path: polygon(0 0, 100% 0, calc(145px + (639 - 145) * ((100vw - 769px) / (1920 - 769))) 100%, 0 100%);

					left: auto;
					position: static;
					top: auto;
					transform: none;
				}
			}
		}
	}
}
// custom
.bat-edu-banner {
	.bat-hero {	
		overflow: hidden;
		min-height: 276px;
		@media screen and (orientation:landscape) {
			min-height: 575px;
		 }
		&-content {
			padding: 65px 34px 82px;
			@media screen and (orientation:landscape) {
				padding: 266px 34px 82px;
			}
		}
		
		.bat-headline {
			max-width: 50%;
			margin-bottom: 32px;
		}
		.bat-cta-style  {
			text-transform: initial;
		}
		position: relative;
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding-top: (70 / 375 * 100%);
			background-image: url("/content/dam/gtr/images/webp-gtr/foot-try-before-buy.webp");
			background-size: cover;
			background-position: bottom right;
			background-repeat: no-repeat;
		}
	}
}
.bat-shop-hero {
	.bat-hero {
		padding: 50px 15px 60px;
		min-height: 181px;
		.bat-headline {
			margin-bottom: 5px;
			text-align: center;
		}
	}
}
.gtr-home-hero {
	.bat-hero {
		padding: 24px 23px 30px;
		background-image: url('/content/dam/gtr/images/webp-gtr/bg-cta.webp');
		background-size: cover;
		background-position: bottom center;
		img {
			position: static;
			transform: none;
			width: auto;
			height: auto;
			max-width: 100%;
			margin: 0 auto 43px;
			object-fit: initial;
			display: block;
		}
		.bat-hero-content {
			padding: 0;
			text-align: center;
			h3 {
				font-weight: 400;
				font-size: 20px;
				line-height: 150%;
			}
			h2 {
				font-weight: 700;
				font-size: 32px;
				line-height: 150%;
				text-align: center;
				letter-spacing: 0.04em;
			}
		}
	}
}
bat-hero-default {
	// text-right class causes text to begin at center of component instead of left
	&.text-right {
		.bat-hero-content {
			@include media-breakpoint-up(md) {
				padding-left: 50%;
			}
		}
	}
	//masthead-right class causes text to align more to the right side
	&.masthead-right {
		.bat-hero {
			height: 501px;

			@include media-breakpoint-up(md) {
				height: 537px;
			}

		}
		.bat-hero-content {
			bottom: 5%;
			padding: 20px;
			position: absolute;

			.bat-headline-style {
				padding-bottom: 20px;
			}

			@include media-breakpoint-up(md) {
				bottom: auto;
				padding-left: 75%;
				top: 10%;
			}
			@include media-breakpoint-up(lg) {
				padding-left: 67%;
			}
		}
	}
}

//-----------------------------------------------------------------| Zonnic Homepage Hero
// Background color will be added to component via bg classes
.bat-hero-zonnic {
	align-items: center;
	display: flex;
	position: relative;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		padding-bottom: 54px;
	}

	&--tile {
		display: flex;
		width: 40%;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		img {
			height: auto;
			object-fit: cover;
			width: 100%;
		}
	}

	&--content {
		padding-left: 140px;
		padding-right: 140px;
		width: 60%;

		@include media-breakpoint-down(lg) {
			padding-left: 96px;
		padding-right: 96px;
		}
		@include media-breakpoint-down(md) {
			padding-left: 40px;
		padding-right: 40px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 24px;
			padding-left: 20px;
			padding-right: 20px;
			width: 100%;
		}

		.headline1-light {
			color: $white;
		}

		p {
			color: $navy;
			margin-top: 24px;
			
			@include media-breakpoint-down(lg) {
				margin-top: 16px;
			}
			@include media-breakpoint-down(md) {
				@include font-rem(14px, 20px);
				letter-spacing: 0;
			}
		}

		&-cta-list {
			padding-top: 24px;

			@include media-breakpoint-down(lg) {
				padding-top: 16px;
			}

			.bat-cta-style {
				@include media-breakpoint-down(sm) {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}
}

//IN-STORE
//home
@include media-breakpoint-up(md) {
	.gtr-home-hero .bat-hero {
		position: relative;
		padding: 6% 0;
		display: flex;
		background-position: top left;

		&:after {
			content: '';
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			padding-top: (177 / 1920 * 100%);
			background-image: url('/content/dam/gtr/images/webp-in-store/home-hero-divider.webp');
			background-repeat: no-repeat;
			background-size: cover;
			width: 100%;
		}

		img {
			margin: 0;
			min-width: auto;
			max-width: 40%;
			margin-left: 10%;
		}

		.bat-hero-content-container {
			justify-content: center;
			a span {
				text-decoration: underline;
			}
		}

		.bat-hero-content {
			padding: 0 30px;
			width: 45%;
			margin-left: auto;
			margin-top: 0;
			background: none;
			position: absolute;
			left: 55%;
			top: 50%;
			transform: translateY(-50%);
			max-width: 350px;

			.bat-headline {
				> * {
					font-size: 40px;
				}
			}

			> p {
				font-size: 20px;

				b {
					font-size: 24px;
				}
			}
		}
	}
}
