.bat-form--request-password-reset {

	padding: 0 20px;

	.bat-form-block-text {
		text-align: center;
	}

	.bat-form-field {
		a.center {
			margin: 0 auto;
		}
	}

	.bat-form--password-email {
		margin-bottom: 0;
	}

	.bat-form--password-submit {
		button {
			max-width: unset;
			width: 100%;		
			@include media-breakpoint-up(sm) {
				max-width: 300px;
			}
		}		
		@include media-breakpoint-up(sm) {
			text-align: center;	
		}			
	}
	
}
