.bat-navigation {
	
	&-group {
		
		&-list {
			display: flex;
			flex-direction: column;
			list-style: none;
			margin: 0;
			padding: 0;
		
			@include media-breakpoint-up(lg) {
				align-content: center;
				align-items: center;
				flex-direction: row;
				justify-content: flex-start;
			}
			
			&-item {
				flex: 1 1 auto;
				margin: 0;
				padding: 0 $gutter;
				position: relative;
				text-align: center;
				
				a {
					align-items: center;
					display: flex;
					justify-content: center;
					padding: $gutter / 2;
					text-decoration: none;
					
					i {
						height: 16px;
						margin-left: 10px;
						transform-origin: center;
						transition: all .1s ease-in;
						width: 16px;
						
						&:before {
							font-size: 16px;
						}
					}
				}
				
				&--menu {
					background-color: $white;
					
					&.open {
						
						a {
							i {
								transform: rotate(180deg);
							}	
						}
						
						.bat-navigation-sub-group {
							display: block;
						}
					}
				}
				
				@include media-breakpoint-up(lg) {
					
					&:hover {
						
						a {
							i {
								transform: rotate(180deg);
							}	
						}
						
						.bat-navigation-sub-group {
							display: block;
						}
					}
					
					&.close {
						
						&:hover {
							
							.bat-navigation-sub-group {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	
	&-sub-group {
		background-color: $white;
		display: none;
		margin: 0;
		padding: $gutter;
		
		@include media-breakpoint-up(lg) {
			left: 0;
			position: absolute;
			right: 0;
		}
		
		&-list {
			list-style: none;
			margin: 0;
			padding: 0;
			
			&-item {
		
				a {
					text-decoration: none;
				}
			}
		}
	}
}
