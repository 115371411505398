//-----------------------------------------------------------------| Dropdown build for button styles
.bat-cta-style {
	//-----------------------------------------------------------------| Buttons

	&.button-dark {
		@extend .bat-button--dark;
	}
	&.button-dark-disabled {
		@extend
		.bat-button,
		.bat-button.disabled,
		.bat-button--dark.disabled;
	}
	&.button-light {
		@extend .bat-button--light;
	}
	&.button-secondary-dark {
		@extend .bat-button--secondary--dark;
	}
	&.button-secondary-light {
		@extend .bat-button--secondary--light;
	}

	//-----------------------------------------------------------------| Links
	&.link-dark {
		@extend .bat-link;
		@extend .bat-link--dark;
	}
	&.link-light {
		@extend .bat-link;
		@extend .bat-link--light;
	}
	&.arrow-link-dark {
		@extend .bat-link;
		@extend .bat-link--arrow--dark;
	}
	&.arrow-link-light {
		@extend .bat-link;
		@extend .bat-link--arrow--light;
	}
	&.arrow-link-dark-back {
		@extend .bat-link;
		@extend .bat-link--arrow--dark--back;
	}
	&.arrow-link-light-back {
		@extend .bat-link;
		@extend .bat-link--arrow--light--back;
	}
}

// Allow authors to center CTAs within container by applying "center-cta" class
.center-cta, 
.center-ctas {
	.bat-cta {
		&-list {
			&--vertical {
				align-content: center;
			}
			&--horizontal {
				justify-content: center;
			}
		}
	}
}

bat-cta-steps {
	width: 100%;
	.bat-cta--steps {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		text-align: center;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		&-item {
			@include font-rem(45px, 45px);
			flex: 1 1 0;

			@include media-breakpoint-down(sm) {
				padding: 0 2rem;
				width: 100%;
			}

			div {
				@extend p;
				@include font-rem(20px, 20px);
				line-height: 24px;
				margin-top: 1rem;
				text-transform: none;
			}

			&-divider {
				background-image: url('/content/dam/zonnic/images/global/step_divider.webp');
				background-repeat: no-repeat;
				height: 40px;
				margin-top: 2rem;

				@include media-breakpoint-down(sm) {
					background-image: url('/content/dam/zonnic/images/global/step_divider_vertical.webp');
					background-position: center;
					height: 70px;
				}
			}
			
			&:last-child {
				display: none;
			}
		}
	}
}

bat-cta-default {
	
	&.m-align-left {
		
		.bat-cta-list--vertical {
			align-items: flex-start;
		}
		
		.bat-cta-list--horizontal {
			justify-content: flex-start;
		}
	}
	
	&.m-align-right  { 
		
		.bat-cta-list--vertical {
			align-items: flex-end;
			
			@include media-breakpoint-up(md) {
				align-items: flex-start;
			}
		}
		
		.bat-cta-list--horizontal {
			justify-content: flex-end;
			
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
		}
	}
	
	&.m-align-center { 
		
		.bat-cta-list--vertical {
			align-items: center;
			
			@include media-breakpoint-up(md) {
				align-items: flex-start;
			}
		}
		
		.bat-cta-list--horizontal {
			justify-content: center;
			
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
		}
	}
	
	&.d-align-left { 
		
		.bat-cta-list--vertical {

			@include media-breakpoint-up(md) {
				align-items: flex-start;
			}
		}
		
		.bat-cta-list--horizontal {
			
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
		}
	}
	
	&.d-align-right { 
		
		.bat-cta-list--vertical {

			@include media-breakpoint-up(md) {
				align-items: flex-end;
			}
		}
		
		.bat-cta-list--horizontal {
			
			@include media-breakpoint-up(md) {
				justify-content: flex-end;
			}
		}
	}
	
	&.d-align-center { 
		
		.bat-cta-list--vertical {

			@include media-breakpoint-up(md) {
				align-items: center;
			}
		}
		
		.bat-cta-list--horizontal {
			
			@include media-breakpoint-up(md) {
				justify-content: center;
			}
		}
	}
}

bat-cta-icons {
	width: 100%;
	
	&.m-align-left {
		
		.bat-cta--icons {
			justify-content: flex-start;
		}
	}
	
	&.m-align-right  { 
		
		.bat-cta--icons {
			justify-content: flex-end;
			
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
		}
	}
	
	&.m-align-center { 
		
		.bat-cta--icons {
			justify-content: center;
			
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
		}
	}
	
	&.d-align-left { 
		
		
		.bat-cta--icons {
			
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
		}
	}
	
	&.d-align-right { 
		
		
		.bat-cta--icons {
			
			@include media-breakpoint-up(md) {
				justify-content: flex-end;
			}
		}
	}
	
	&.d-align-center { 
		
		.bat-cta--icons {
			
			@include media-breakpoint-up(md) {
				justify-content: center;
			}
		}
	}
	
	.bat-cta--icons {
		
		a,
		.button,
		button {
			margin: 0;
			padding-bottom: 14px;
			width: 40px;
			
			@include media-breakpoint-up(md) {
				width: 48px;
			}
		
			&.bat-cta-style {
				
				&.link-dark,
				&.link-light {
					
					
					i {
						
						&.bat-icon {
							margin: 0;	
						}
					
						&::before {
							font-size: 26px;
							transition: color .5s ease;
						}
					}
				}
			}
			
			&:hover {
				
				&.bat-cta-style {
					
					&.link-dark {
						i {
							
							&::before {
								color: $gray;
							}
						}
					}
					
					&.link-light {
						
						i {
							
							&::before {
								color: $light-blue;
							}
						}
					}
				}
			}
		}
	}
}

//----------------- Logged in/out buttons 
// used in Zonnic Newsletter section
bat-cta-loggedin {
	// sass-lint:disable no-important
	&.cta-show {
		display: block !important; 
	}

	&.cta-hide {
		display: none !important;
	}
}

//----------------- Account links Zonnic
.cta-account-zonnic {
	padding-top: 40px;
	
	@include media-breakpoint-down(md) {
		padding-top: 10px;
	}

	.link-dark {
		//@include font-rem($font-xsm, $font-xsm + 2 );
		align-items: center;
		background-color: $white;
		border-bottom: 1px solid $navy-10;
		color: $navy !important;
		font-size: $font-xsm !important;
		font-weight: 700;
		height: 60px;
		line-height: $font-xsm + 2;
		margin: 0 auto;
		padding: 0;
		padding-left: 24px;
		text-transform: uppercase;
		transition: none;
		width: 80%;

		@include media-breakpoint-down(sm) {
			height: 48px;
			width: 100%;
		}

		&.active,
		&:hover {
			background-color: $berry-frost-dark;
			color: $white !important;

			@include media-breakpoint-down(sm) {
				background-color: $pale-gray;
				color: $mid-gray;
			}
		}

		

	}
} 
