.bat-footer {
	
	footer {
		align-content: center;
		align-items: center;
		background-color: $off-white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: 64px;
		padding: $gutter;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			margin: 0 auto;
			max-width: $max-width-xxl;
			min-height: 77px;
        }
	}
	
	&-logo {
		flex: 0 0 auto;
		order: 2;

		@include media-breakpoint-up(lg) {
			order: 1;
		}
		
		a {
			display: inline-block;
		}
	}
	
	&-nav {
		display: flex;
		flex-direction: column;
		justify-content: center;
		order: 1;
		padding: $gutter;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			flex: 1 1 100%;
			order: 2;
		}
		
		ul {
			line-height: 1rem;
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;
			
			@include media-breakpoint-up(lg) {
				order: 2;
				text-align: left;
			}
			
			li {
				display: inline-block;
				margin: 0;
				
				a {
					display: inline;
					padding: 0 $gutter / 4;
				}
			}
		}
	}
	
	&-social-nav {
		align-items: center;
		display: flex;
		flex: 0 0 auto;
		order: 3;
		padding: $gutter;
		
		ul {
			align-items: center;
			display: flex;
			justify-content: center;
			list-style: none;
			margin: 0;
			padding: 0;
			
			li {
				margin: 0;
				
				a {
					display: block;
					padding: 0 $gutter / 2;

					svg {
						height: 30px;
						width: 30px;
					}
				}
			}
		}
	}
}
