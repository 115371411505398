.bat-section {
	.border-all & {
		border: solid 1px $system-red;
	}

	.border-top & {
		border-top: solid 1px $system-red;
	}

	.border-bottom & {
		border-bottom: solid 1px $system-red;
	}
}

body {
	.bat-wrapper {
		bat-section-default {
			height: 100%;

			// Nested
			&.steps {
				.responsivegrid {
					.responsivegrid {
						@include media-breakpoint-up(md) {
							border-right: 1px solid $white;
						}

						&:last-child {
							border: 0;
						}
					}
				}
			}

			&.steps-inverted {
				.responsivegrid {
					.responsivegrid {
						@include media-breakpoint-up(md) {
							border-right: 1px solid $black;
						}

						&:last-child {
							border: 0;
						}
					}
				}
			}

			&.tabs-section {
				margin-bottom: 0;

				@include media-breakpoint-up(md) {
					height: 525px;
					margin-right: -3.5px;
					min-height: 500px;
				}

				.bat-section-content {
					@include media-breakpoint-down(md) {
						padding: 40px 25px;
					}
				}
			}

			// News Section HP
			&.bat-news-wrapper {
				margin-bottom: 45px;
				padding-top: 62px;
			}

			// Article / Blog Styling
			&.blog-wrapper {
				.bat-section-content {
					@include media-breakpoint-up(md) {
						background-color: $white;
						margin: 0 auto;
						max-width: 50%;
						padding: 3.5rem 100px;
					}
				}

				p {
					color: $system-red;
					font-size: 15px;
					line-height: 22px;
				}
			}

			&.blog-wrapper-with-image {
				background-color: $pale-gray;

				@include media-breakpoint-down(md) {
					background-color: $white;
				}

				.bat-section {
					padding-bottom: 56px;
					padding-top: 260px;
					position: relative;

					@include media-breakpoint-down(md) {
						padding-bottom: 0;
						padding-top: 0;
					}
				}

				.bat-section-content {
					background-color: $white;
					margin: -428px auto 0;
					max-width: 50%;
					padding: 3.5rem 100px;

					@include media-breakpoint-down(lg) {
						margin: -368px auto 0;
					}

					@include media-breakpoint-down(md) {
						margin: 0 auto;
						max-width: 56%;
						padding: 3.5rem 20px;
					}

					@include media-breakpoint-down(sm) {
						margin: 0 auto;
						max-width: 100%;
						padding: 3.5rem 20px;
					}
				}

				.bat-headline-style {
					color: $navy;
					text-align: left;
				}

				p {
					@include font-rem($font-sm, 20px);
					color: $black;
				}

				.error,
				.error-msg {
					p {
						color: $system-red;
					}
				}

				.text-highlight {
					background-color: $pale-gray;
					margin-bottom: 40px;
					margin-top: 8px;
					padding: 30px 40px;

					p {
						@include font-rem(20px, 28px);
						color: $black;
						font-weight: 600;
					}
				}
			}

			&.newsletter-zonnic-interim {
				// language switcher like on age gate but within components
				.bat-cta-list--horizontal {
					display: flex;
					justify-content: center;

					& a {
						margin: 0;
						padding: 0;
					}

					// Active like state based on language
					// sass-lint:disable force-pseudo-nesting
					[lang='en'] & a:first-of-type,
					[lang='fr-CA'] & a:last-of-type {
						span {
							border-bottom: 1px solid $black;
						}
					}

					// Default styling
					span {
						@include font-rem(12px, 18px);
						border-bottom: 1px solid transparent;
						color: $navy;
						font-weight: 300;
						margin: 0 10px 26px;

						&:hover {
							border-bottom: 1px solid $black;
						}
					}
				}

				.bat-form {
					margin-top: 30px;
					padding: 0 4px;

					form {
						margin-top: 32px;
					}
				}

				.bat-section {
					padding-bottom: 100px;

					&-content {
						padding-bottom: 8px;
						padding-top: 3.5rem;
					}
				}

				.bat-headline {
					margin-top: 30px;

					&-style {
						text-align: center;
					}
				}
			}

			&.plp-hero {
				// sass-lint:disable-block no-important
				align-items: center;
				background-color: #001f4a !important; // sass-lint:disable-line no-color-literals
				background-repeat: no-repeat;
				background-size: contain !important;
				display: flex !important;
				max-height: 336px;

				@include media-breakpoint-down(lg) {
					background-size: cover !important;
				}

				@include media-breakpoint-up(lg) {
					height: 336px !important;
				}

				.bat-section {
					.hero {
						display: flex;
						height: 100%;
					}
				}
			}

			&.news-from-section {
				background-color: $white;

				@include media-breakpoint-down(md) {
					background-color: $whisper-gray;
				}

				.bat-headline-news {
					@include media-breakpoint-down(md) {
						margin-bottom: 4px;
					}
				}
			}
		}
	}
}

//-----------------------------------------------------------------| Error PAge
.error-page-section {
	background-repeat: no-repeat;
	color: $white;

	@include media-breakpoint-down(sm) {
		background-position: 100% 95%;
		// sass-lint:disable no-important
		background-size: 75% !important;
		margin-bottom: 90%;

		.padding-left-medium {
			padding-left: 0;
		}

		.padding-right-medium {
			padding-right: 0;
		}

		.bat-section {
			margin: 0 auto;
			max-width: 80%;

			.sm {
				font-size: 15px;
			}
		}
	}

	@media (min-width: 768px) {
		align-items: center;
		background-position: 100% 70%;
		// sass-lint:disable no-important
		background-size: 50% !important;
		display: flex;
		min-height: 600px;
	}

	@media (min-width: 1024px) {
		.bat-section {
			padding-left: 16.66%;
		}
	}
}

//-----------------------------------------------------------------| BlogHub
.bat-blog-hub {
	.padding-left-medium {
		padding-left: 0;
	}

	.padding-right-medium {
		padding-right: 0;
	}

	.margin-medium {
		@include media-breakpoint-down(xs) {
			margin-bottom: 0;
		}
	}
}

//-----------------------------------------------------------------| Diagonal grey background (2 sections alignment of top and bottom)
%diagonal-base {
	max-width: auto !important;
	position: relative;

	&:before {
		background: $concrete;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
	}
}

.top-diagonal {
	@extend %diagonal-base;

	&:before {
		clip-path: polygon(0 0, 100% 0%, 100% 70%, 0 60%);

		@include media-breakpoint-up(md) {
			clip-path: polygon(0 0, 66% 0, 33% 98.5%, 0% 98.5%);
		}
	}
}

.bottom-diagonal {
	@extend %diagonal-base;

	&:before {
		clip-path: polygon(0 0, 100% 0%, 100% 60%, 0 70%);

		@include media-breakpoint-up(md) {
			clip-path: polygon(0 0, 33% 0, 66% 100%, 0% 100%);
		}
	}
}

//-----------------------------------------------------------------| PLP "hero" overrides
.plp-hero-overrides {
	// Client asked for a specific layout at a larger screen size
	@media only screen and (min-width: $bp-tablet-min) and (max-width: ($bp-desktop-min - 1)) {
		height: 620px;
		position: relative;

		.text-wrap {
			bottom: 10px;
			padding-left: 0;
			position: absolute;
			right: 10px;
			width: 100%;
		}
	}

	@media only screen and (min-width: $bp-desktop-min) {
		min-height: 336px;

		.bat-headline {
			span {
				display: block;
			}
		}

		.text-wrap {
			float: right;
			width: 32%;
		}
	}
}

//-----------------------------------------------------------------| tab carousel synced section
.tab-carousel-sync {
	.bat-headline-style {
		@include media-breakpoint-down(md) {
			@include font-rem(22px, 26px);
		}
	}

	.bat-cta {
		margin-bottom: 32px;

		@include media-breakpoint-down(md) {
			.bat-cta-style {
				width: 100%;
			}
		}
	}
}

//-----------------------------------------------------------------| Newsletter Subscription Bar
.newsletter-subscription-bar {
	.bat-text {
		p {
			color: $navy;
		}
	}
}

//-----------------------------------------------------------------| Meet Zonnic, tiles same height
.meet-zonnic-cards {
	.card-masthead-product {
		@include media-breakpoint-up(sm) {
			height: 100%;
		}
	}
	.bat-card--masthead-zonnic-main {
		margin-bottom: 0;
	}
}

.gtr-home-register-airport {
	background-size: 100% auto !important;
	.bat-section-content {
		padding-top: 25px;
		& > .image {
			margin-bottom: 15px;
			position: relative;
			z-index: 5;
			padding: 0 23px;
			@media (min-width: 768px) {
				max-width: 529px;
				margin: 0 auto 15px;
			}
		}
		& > .text {
			position: relative;
			z-index: 5;
			color: #fff;
			padding: 0 23px 31px;
			.bat-text {
				padding-bottom: 3px;
				p {
					font-weight: 400;
					font-size: 20px;
					line-height: 150%;
					margin-bottom: 0;
				}
				& > p {
					margin-bottom: 5px;
					& + h2 + p {
						margin-top: -5px;
						margin-bottom: 0;
					}
				}
				h2 {
					font-size: 32px;
					line-height: 150%;
					text-align: center;
					letter-spacing: 0.04em;
					text-transform: uppercase;
					color: #fff;
				}
			}
		}
	}
}
.gtr-vuse-carbon {
	padding: 25px 24px 0;
	overflow: hidden;
	.bat-headline {
		margin-bottom: 15px;
	}
	.bat-text {
		ol {
			font-weight: 400;
			font-size: 13px;
			line-height: 150%;
			color: #6b6b6b;
		}
	}
	.image {
		margin-left: -24px;
		margin-right: -24px;
	}
	.contentwrapper {
		.aem-GridColumn {
			padding: 0;
		}
	}

	body:not(.airport-view) & {
		@include media-breakpoint-up(sm) {
			.bat-headline {
				margin-bottom: 33px;
			}
		}

		@include media-breakpoint-up(md) {
			padding: 0;
			.bat-section {
				max-width: 1288px;
				margin-left: auto;
				&-content {
					display: flex;
					align-items: flex-start;
				}
			}
			.contentwrapper {
				padding-top: 57px;
				padding-bottom: 67px;
				padding-right: 70px;
				padding-left: 20px;
				width: (582 / 1268 * 100%);
			}
			.image {
				width: (686 / 1268 * 100%);
				margin-left: 0;
				margin-right: 0;
			}
			.bat-headline {
				margin-bottom: 15px;
			}
			.bat-text {
				ol {
					font-weight: 400;
					font-size: 13px;
					line-height: 150%;
					color: #6b6b6b;
				}
			}
		}
		@media (min-width: 1920px) {
			.bat-section {
				max-width: 1687 + 20px;
			}
			.contentwrapper {
				width: 59%;
			}
			.image {
				width: 41%;
			}
		}
		&.in-store {
			padding-bottom: 55px;
			.bat-section {
				max-width: 1687 + 20px;
			}
			.contentwrapper {
				padding-top: 32px;
				padding-bottom: 47px;
				padding-right: 130px;
				padding-left: 20px;
				width: ((659 + 129 + 20) / (1687 + 20) * 100%);
			}
			.image {
				width: (899 / (1687 + 20) * 100%);
			}
			.bat-headline {
				margin-bottom: 37px;
			}

			.bat-text {
				ol {
					font-size: 20px;
				}
			}
		}
	}
}
.gtr-flavour-slider {
	.bat-section {
		padding: 53px 0 25px;
		.headline {
			.bat-headline {
				margin-bottom: 17px;
				padding: 0 10px;
			}
		}
		.text {
			.bat-text {
				margin-bottom: 31px;
				padding: 0 36px;
			}
		}
		@include media-breakpoint-up(md) {
			padding: 79px 0 35px;
			.headline {
				.bat-headline {
					margin-bottom: 33px;
				}
			}

			.text {
				.bat-text {
					max-width: 555px;
					margin-left: auto;
					margin-right: auto;
					padding: 0 10px;
					margin-bottom: 58px;
				}
			}
		}
	}
	&.in-store {
		.bat-section {
			padding: 87px 0 45px;
			.headline {
				.bat-headline {
					margin-bottom: 64px;
				}
			}
			.text {
				.bat-text {
					max-width: 875px;
				}
			}
		}
	}
}

.gtr-how-to-use-section {
	.bat-section {
		padding: 28px 0 25px;
		@include media-breakpoint-up(md) {
			padding: 45px 0 90px;
		}
	}
	.headline {
		.bat-headline {
			margin-bottom: 17px;
			@include media-breakpoint-up(md) {
				margin-bottom: 50px;
			}
		}
	}
	&.in-store {
		.bat-section {
			padding: 28px 0 105px;
		}
		.bat-headline {
			margin-bottom: 84px;
		}
	}
	body.airport-view & {
		@include media-breakpoint-up(md) {
			.bat-section {
				padding: 45px 0;
			}
		}
	}
}

.gtr-velo-product-section {
	.bat-section {
		padding: 53px 0 25px;
	}
	&__headline {
		.bat-headline {
			margin-bottom: 10px;
			padding: 0 25px;
		}
	}
	&__text {
		.bat-text {
			padding: 0 25px;
			margin-bottom: 37px;
		}
	}
	.contentwrapper {
		margin-bottom: 19px;
		padding: 0 20px;
		.aem-Grid {
			margin-left: -10px;
			margin-right: -10px;
		}
		.bat-card--gtr {
			margin-bottom: 13px;
		}
		.text {
			.bat-text {
				padding-top: 13px;
				padding-bottom: 17px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
				*:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.tabs {
		.bat-tabs {
			margin-bottom: 25px;
			.bat-tabs-list {
				margin-top: 0;
				margin-bottom: 37px;
			}
			.bat-tabs-panels-panel > div {
				padding: 0;
			}
			.bat-card--gtr {
				margin-bottom: 13px;
			}
		}
	}

	&__text-bottom {
		padding: 0 25px;
		.bat-text {
			*:last-child {
				margin-bottom: 0;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.bat-section {
			padding: 39px 0 45px;
		}
		&__headline {
			.bat-headline {
				margin-bottom: 28px;
				padding: 0 25px;
			}
		}
		&__text {
			.bat-text {
				max-width: 601px;
				margin-left: auto;
				margin-right: auto;
				padding: 0 10px;
				margin-bottom: 39px;
			}
		}
		.tabs {
			.bat-tabs {
				margin-bottom: 22px;
				max-width: 1165px;
				margin-left: auto;
				margin-right: auto;
				.bat-tabs-list {
					margin-bottom: 39px;
				}
				.bat-tabs-list-item {
					margin: 0 40px;
				}
				.bat-tabs-panels {
					padding-bottom: 30px;
				}
			}
		}
		.contentwrapper {
			margin-bottom: 22px;
			width: 1185px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding: 0 10px;
			.text .bat-text {
				padding: 0 0 12px;
			}
		}
		&__text-bottom {
			padding: 0 10px;
			.bat-text {
				max-width: 1165px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	&.in-store {
		.bat-section {
			padding: 88px 0 54px;
		}
		.gtr-velo-product-section {
			&__headline {
				.bat-headline {
					margin-bottom: 70px;
				}
			}
			&__text {
				.bat-text {
					margin-bottom: 78px;
					max-width: 1134px;
					margin-left: auto;
					margin-right: auto;
				}
			}
			&__text-bottom {
				.bat-text {
					max-width: 1561px;
					margin-left: auto;
					margin-right: auto;
					p .sm {
						font-size: 20px;
					}
				}
			}
		}
		.contentwrapper {
			width: 1581px;
			.card .bat-card {
				margin-bottom: 41px;
			}
			.bat-text {
				padding-bottom: 18px;
				p .sm {
					font-size: 20px;
				}
			}
		}
		.tabs {
			.bat-tabs {
				max-width: 1561px;
				margin-left: auto;
				margin-right: auto;
				.bat-tabs-list {
					margin-bottom: 98px;
				}

				.bat-tabs-list-item {
					margin: 0 70px;
				}
				.bat-tabs-panels {
					padding-bottom: 36px;
				}
				.bat-tabs-list-item-button {
					padding: 22px 0;
				}
			}
			.bat-tabs-list-item .bat-tabs-list-item-button span {
				font-size: 32px;
				line-height: 150%;
			}
		}
	}
}
.gtr-design-section {
	.bat-section {
		padding: 53px 25px 30px;
	}
	.headline {
		.bat-headline {
			margin-bottom: 10px;
		}
	}
	.text {
		.bat-text {
			margin-bottom: 39px;
		}
	}
	.card {
		.bat-card {
			margin-bottom: 13px;
		}
	}
	@include media-breakpoint-up(md) {
		.bat-section {
			padding: 75px 0 47px;
			.bat-section-content {
				max-width: 1193px + 20px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.headline {
			.bat-headline {
				margin-bottom: 24px;
			}
		}
		.text {
			.bat-text {
				max-width: 626px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 17px;
			}
		}
		.card {
			.bat-card {
				margin-bottom: 10px;
			}
		}
	}
	&.in-store {
		.bat-section {
			.bat-section-content {
				max-width: 1701px + 20px;
			}
		}
		.bat-section {
			padding: 88px 0 85px;
		}

		.headline {
			.bat-headline {
				margin-bottom: 70px;
			}
		}
		.text {
			.bat-text {
				max-width: 1006px;
				margin-bottom: 70px;
			}
		}
	}
}
@keyframes scrolldown {
	0% {
		transform: translateY(20%);
		opacity: 0.7;
	}
	50% {
		transform: translateY(0%);
		opacity: 0.2;
	}
	100% {
		transform: translateY(20%);
		opacity: 0.7;
	}
}
.scroll-down-section {
	.bat-section {
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		bottom: 104px;
		z-index: 100;
		.bat-section-content {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			margin-left: 245px;
			.image {
				.bat-image {
					animation: scrolldown 1.2s ease-in-out infinite 0.15s;

					width: 50px;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: auto;
					}
				}
			}
			.headline {
				padding-left: 29px;
				.bat-headline {
					h4 {
						color: #fff;
						font-weight: 400;
						font-size: 24px;
						line-height: 150%;
					}
				}
			}
		}
	}
}
.gtr-registration-products-airport {
	background: linear-gradient(
			360deg,
			rgba(0, 0, 0, 0.15) -6.07%,
			rgba(0, 0, 0, 0) 65.66%
		),
		#f3f3f3;
	.bat-section {
		padding: 39px 14px 30px;
	}
	&__section-title {
		.bat-headline {
			margin-bottom: 13px;
		}
	}
	&__desc {
		.bat-text {
			margin-bottom: 29px;
		}
	}
	&__cta {
		.bat-cta {
			margin-bottom: 46px;
			.bat-cta-style {
				width: 275px;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 0;
			}
		}
	}

	&__title {
		.bat-headline {
			margin-bottom: 25px;
		}
	}
	.responsivegrid {
		& > .aem-Grid {
			margin-left: -10px;
			margin-right: -10px;
		}
	}
	@include media-breakpoint-up(md) {
		.bat-section {
			padding: 68px 20px 104px;
		}

		&__title {
			.bat-headline {
				margin-bottom: 50px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.bat-section {
			padding: 68px 90px 104px;
		}
	}
	@include media-breakpoint-down(xs) {
		&__section-title {
			.bat-headline {
				max-width: 274px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		&__desc {
			.bat-text {
				max-width: 304px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		&__title {
			.bat-headline {
				max-width: 252px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

// mclaren landing
.gtr-mclanren-landing-section {
	background-position: top center;
	padding: 94px 22px 47px;
	&__logo {
		width: 303px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		img {
			width: auto;
			max-width: 100%;
			display: block;
			margin: 0 auto;
			max-height: 41px;
		}
	}
	&__text {
		padding-top: 110.334346505%;
		@media (min-width: 576px) {
			padding-top: 115%;
		}
		@media (min-width: 992px) {
			margin-top: 25px;
		}
		.bat-text {
			width: 260px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 9px;
			color: #fff;
			* {
				font-family: 'Gotham';
				font-weight: 500;
			}
		}
	}
	&__title {
		.bat-headline {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: 'Gotham';
				font-weight: 900;
				font-size: 36px;
				line-height: (43 / 36);
				text-align: center;
				color: #ffffff;
			}
		}
	}
	&__subtitle {
		.bat-headline {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: 'Gotham';
				font-weight: 800;
				font-size: 20px;
				line-height: (24 / 20);
				text-align: center;
				text-transform: uppercase;
				color: #ffffff;
			}
			margin-top: -3px;
			margin-bottom: 9px;
		}
	}
	&__cta {
		.bat-cta {
			align-items: center;
			justify-content: center;
			a {
				margin-bottom: 0;
				width: 195px;
				max-width: 100%;
				& + a {
					margin-top: 15px;
				}
				&.link-light {
					line-height: 1.6;
					text-decoration: underline;
				}
			}
		}
	}
	@media (min-width: 768px) {
		.gtr-mclanren-landing-section__logo img {
			max-height: 60px;
		}
		.gtr-mclanren-landing-section__text .bat-text {
			width: 80%;
			* {
				font-size: 18px;
			}
		}
		&__title {
			.bat-headline {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-size: 43px;
				}
			}
		}
		&__subtitle {
			.bat-headline {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-size: 24px;
				}
			}
		}
	}
}

.gtr-mclaren-static-section {
	.bat-section {
		padding: 75px 20px 0;
		z-index: 0;
		position: relative;
		background-image: url('/content/dam/gtr/images/webp-mclaren-airport/bg-age-gate-airport.webp');
		background-position: top center;
		background-size: 100% auto;
		background-repeat: no-repeat;
		&:before {
			z-index: -1;
			content: '';
			position: absolute;
			top: 325px;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(
				180deg,
				rgba(239, 129, 11, 0) 0%,
				#ef7e0b 3.24%
			);
		}
	}
	.gtr-mclaren-static-section__page-title {
		.bat-headline {
			margin-bottom: 16px;
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: 'Gotham';
				font-style: normal;
				font-weight: 900;
				font-size: 30px;
				line-height: 130%;
				text-align: center;
				color: #ffffff;
			}
		}
	}
	.gtr-mclaren-static-section__page-desc {
		margin-top: -9px;
		.bat-text {
			margin-bottom: 18px;
			* {
				font-family: 'Gotham';
				font-size: 18px;
				line-height: 130%;
				text-align: center;
				color: #ffffff;
			}
		}
	}
	.contentwrapper {
		background-color: #fff;
		padding: 45px 14px 55px;
	}
}
.gtr-registration-airport-mclaren {
	background-size: 100% auto !important;
	background-position: top center;
	background-color: #ee7d21;
	.bat-section {
		padding-top: (312 / 375 * 100%);
	}
	
	.text {
		position: relative;
		z-index: 2;
		.bat-text {
			padding: 0 20px;
			width: 300px;
			max-width: 100%;
			margin-bottom: 10px;
			margin-left: auto;
			margin-right: auto;
			* {
				color: #fff;
				font-weight: 500;
				font-family: 'Gotham';
				text-transform: capitalize;
				text-align: center;
			}
		}
	}
	.headline {
		.bat-headline {
			position: relative;
			z-index: 2;
			padding: 0 20px;
			* {
				color: #fff;
			}
			h2 {
				font-family: 'Gotham';
				font-weight: 900;
				font-size: 36px;
				line-height: (43 / 36);
				text-align: center;
				color: #ffffff;
			}
			h3 {
				font-family: 'Gotham';
				font-weight: 900;
				font-size: 20px;
				line-height: (24 / 20);
				text-align: center;
				text-transform: uppercase;
				color: #ffffff;
				margin-top: -5px;
				margin-bottom: 28px;
			}
		}
	}
	.gtr-home-register {
		&:after {
			background-image: url('/content/dam/gtr/images/webp-mclaren-airport/bg-orange-foot-registration.webp');
		}
		.bat-form-field .bat-button--dark {
			min-height: 50px;
			@include media-breakpoint-up(lg) {
				min-height: 45px;
			}
			background: #d7490d;
			color: $white;
			position: relative;
			z-index: 0;
			overflow: hidden;
			border: none;
			&:after {
				content: '';
				width: auto;
				transform: none;
				position: absolute;
				pointer-events: none;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: opacity 0.5s ease;
				background: linear-gradient(
					140deg,
					rgba(215, 73, 13, 1) 0%,
					rgba(215, 73, 13, 1) 70%,
					rgba(255, 206, 136, 1) 88%,
					rgba(255, 255, 255, 1) 100%
				);
			}
			span {
				transition: color 0.5s ease;
			}

			i {
				svg {
					fill: var(--text-color);
				}
			}
			&:hover {
				background: #d7490d;
				&:after {
					opacity: 0;
				}
				span {
					color: #fff;
				}

				i {
					svg {
						fill: #fff;
					}
				}
			}
			&:focus,
			&:focus-visible {
				outline: var(--border-color) solid 1px;
				outline-offset: 5px;
			}

			&.disabled,
			&:disabled {
				// Do not use CSS vars here; breaks 'button dark disabled' style
				background: $whisper-gray;
				color: $concrete;
				border: solid 2px #cfcfcf;
				&:after {
					opacity: 0;
				}
			}
		}
	}
	
	@media (min-width: 768px) {
		
		.text .bat-text {
			width: 80%;
			* {
				font-size: 18px;
			}
		}
		.headline {
			.bat-headline {
				h2 {
					font-size: 43px;
				}
				
				h3 {
					font-size: 24px;
				}
			}
		}
	}
}
.raffle-winner-section {
	background-position: top left;
	background-size: 100% auto !important;
	.bat-section {
		.bat-section-content {
			padding: 33px 18px 0;
			.raffle-winner-logo {
				.bat-image {
					padding: 0 17px;
					margin-bottom: 38px;
					img {
						display: block;
						max-width: 100%;
						width: auto;
						margin: 0 auto;
					}
				}
			}
			.headline {
				.bat-headline {
					text-align: center;
					h1 {
						font-family: 'Gotham';
						color: #FFF;
						text-align: center;
						font-size: 18px;
						font-weight: 900;
						line-height: 183.333%;
						letter-spacing: 0.36px;
					}
					h2 {
						font-family: 'Gotham';
						color: #FFF;
						font-size: 26px;
						font-weight: 900;
						line-height: (33 / 26);
						text-transform: uppercase;
					}
				}
			}
			.raffle-winner-icon {
				.bat-image {
					margin-top: 29px;
					width: 173px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 46px;
					img {
						max-width: 100%;
						width: auto;
						margin: 0 auto;
					}
				}
			}
			.form {
				margin-left: -18px;
				margin-right: -18px;
				.gtr-home-register.gtr-home-register--mclaren {
					padding-top: 0;
					margin-top: 0;
				}
			}
		}
	}

}
[dir='rtl'] {
	@media (min-width: 768px) {
		body:not(.airport-view) .gtr-vuse-carbon .bat-headline {
			text-align: right;
		}
	}
	.scroll-down-section .bat-section .bat-section-content {
		margin-right: 245px;
		margin-left: 0;
		.headline {
			padding-left: 0;
			padding-right: 29px;
		}
	}
}
