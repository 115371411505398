bat-producthero-zonnic {
	.bat-producthero--zonnic {
		background-color: $white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		opacity: 0;
		padding: 0 0 20px;
		transition: opacity .2s ease-in;
		
		&.loaded {
			opacity: 1;
		}

		.producthero-gallery {
			display: flex;
			flex-direction: column;

			.slick-dots {
				display: none !important; // sass-lint:disable-line no-important
			}
			
			.slick-arrow {
				background-color: rgba($black, .3); // sass-lint:disable-line no-color-literals
				border-radius: 30px;
				color: transparent;
				font-size: 0;
				height: 30px;
				margin-top: -15px;
				position: absolute;
				top: 50%;
				width: 30px;

				&:before {
					color: $white;
					content: '';
					display: inline-block;
					font-size: 20px;
					vertical-align: middle;
				}
				
				&.slick-prev {
					left: 20px;
					&:before {
						content: '\e93e';
						font-family: 'icomoon';
					}
				}
				
				&.slick-next {
					right: 20px;
					&:before {
						content: '\e93d';
						font-family: 'icomoon';
					}
				}
			}					
			
			&-images {
				line-height: 0;
				padding: 46px 0;
				img {
					display: block;
					height: auto;
					margin: 0 auto;
					max-height: unset;
					max-width: 80vw;
					position: relative;
					top: 2px;
				}
			}
			
			&-thumbs {
				display: none;
				flex-wrap: wrap;
				margin-top: 20px;
				padding: 0;
				
				.thumb {

					background-color: transparent;
					background-position: 0 center;

					background-repeat: no-repeat;	
					background-size: auto 65%;
					border: 1px solid $white;
					height: 95px;
					margin: 2.5px;
					width: 95px;
					
					&.active {
						border-top: 4px solid $navy;
						outline: none;
					}

					&.hide {
						visibility: hidden;
					}
					
					img {
						display: block;
						margin: 0 auto;
						width: 80%;
					}
				}
			}
		}
		
		.producthero-content {
			display: flex;
			flex-direction: column;
			padding: 30px 20px 0;
			width: 100%;

			.add-to-cart {
				align-content: center;
				align-items: center;
				display: flex;
				flex-direction: row;
				.cta {
					flex: 1 0 0;
					max-width: 300px;
				}
				button {
					padding: 15px 0;
					width: 100%;
				}
				.producthero-price {
					flex: 0 1 auto;
					margin: 0 20px 0 0;
					text-align: left;
					vertical-align: middle;
	
					.base-price { color: $navy; }
					.final-price {
						color: $system-red;
						display: none;
						margin-right: 5px;
					}
	
					&.on-sale {
						.final-price { display: inline; }
						.base-price {
							font-weight: 700;
							text-decoration: line-through;
						}
						/* Render a slash across the price
						position: relative;						
						&:before {
							-moz-transform: rotate(-5deg);
							-ms-transform: rotate(-5deg);
							-o-transform: rotate(-5deg);
							-webkit-transform: rotate(-5deg);
							border-color: $dark-gray;
							border-top: 2px solid;
							content: '';
							left: 0;
							position: absolute;
							right: 0;
							top: 50%;
							transform: rotate(-5deg);
						} */
					}

					& > span {
						@include font-rem(22px, 26px);
						font-weight: 800;
						letter-spacing: .5px;
					}
				}
			}

			hr {
				border-top: 1px solid $pale-gray;
				margin: 0 0 20px;
			}
			
			.producthero-description {
				@include font-rem(14px, 22px);
				display: block;
				font-weight: 300;
				height: auto;
				line-height: 22px;
				margin-bottom: 22px;
				overflow-y: hidden;

				&.collapsed {
					height: 85px;
				}

				a {
					text-decoration-thickness: 1px; // sass-lint:disable-line no-misspelled-properties
					text-underline-offset: 1px; // sass-lint:disable-line no-misspelled-properties
					&:focus {
						margin: -4px; // ---| Default style in links.scss applies padding on focus. Negative margin prevents shifting
					}
				}

				ol,
				p,
				ul {
					@include font-rem(14px, 22px);
					font-weight: 300;
					margin-bottom: 5px;
				}

				ol,
				ul {	
					padding-left: 16px;
				}

			}
			
			&-name {
				margin-bottom: 10px;
				max-width: unset;
				padding-bottom: 0;
				padding-top: 0;
			}

			.producthero-readmore {
				display: none;
				margin-bottom: 30px;
				a {
					@include font-rem(14px, 20px);
					font-weight: 700;
					text-transform: uppercase;
					&:focus {
						margin: -4px; // ---| Default style in links.scss applies padding on focus. Negative margin prevents shifting
					}
					&.less-link {
						display: none;
					}
				}
			}
			
			&-subheading {
				@include font-rem(20px, 28px);
				font-weight: 500;
				letter-spacing: 1.5px;
				margin-bottom: 8px;
				padding-bottom: 0;
				padding-top: 0;
				text-align: left;
				text-transform: capitalize;
			}
			
			&-stock {
				display: block;
				font-weight: 700;
				margin-bottom: 20px;
			}
			
			&-subscription {
				background-color: $concrete;
				margin: 0 auto;
				max-width: 325px;
				
				&-text {
					@include font-rem(12px, 12px);
					margin-top: 2px;
					
					.formatted-price {
						font-weight: normal;
					}
					
					&-note {
						@include font-rem(12px, 12px);
						display: inline-block;
						font-weight: normal;
						margin: 7px 0 0;
						text-transform: none;
					}
					
					.discount-savings {
						@include font-rem(12px, 12px);
						background-color: $navy;
						color: $white;
						display: inline-block;
						padding: 2px 5px;
					}
				}
				
				&-max-message {
					margin-top: 0;
				}
				
				&-details {
					
					button {
						color: $navy;
						font-weight: 700;
					}
				}
				
				fieldset {
					padding: 0;
					
					label {
						align-content: flex-start;
						align-items: flex-start;
						height: auto;
						min-height: 29px;

						input {
							margin-top: 2px;
						}
					}
				}
			}

			a {
				color: $navy;
				font-weight: 700;
			}

			.bat-quantity {
				margin-bottom: 26px;
				padding-bottom: 0;
				&-label {
					@extend h5;
					@extend .headline5-small;
					@include font-rem(12px, 15px);
					letter-spacing: 1px;
					margin-right: 57px;
				}
				&-button {
					min-height: 46px;
					min-width: 46px;
					&,
					&.disabled {
						background-color: $pale-gray;
					}
					&:hover {
						i:before { // sass-lint:disable-line force-pseudo-nesting
							color: $navy;
						}
					}

					&:focus {
						outline: 1px solid $navy;
					}
				}
				&-field {
					pointer-events: unset;
				}
			}
		}

		.controls {
			border-top: 1px solid $pale-gray;
			padding: 15px 20px 0;
		}

		.producthero-skuerror {
			@include font-rem(16px, 28px);
			margin-bottom: 20px;
		}
	}
}

@include media-breakpoint-up(lg) { // 992+
	bat-producthero-zonnic {

		// Make product-hero component full-width
		// body .bat-wrapper &.bat { max-width: unset; } // sass-lint:disable-line force-element-nesting

		.bat-producthero--zonnic {
			align-items: flex-start;
			flex-direction: row;
			padding: 0;

			> * {
				min-height: 100%;
			}

			.producthero-gallery {
				min-height: 100%;
				padding: 0 0 16px;
				width: 52%;

				&-images {
					margin-bottom: 40px;
					padding: 120px 0 0;
				}

				&-thumbs {
					display: flex;
					justify-content: center;
					margin-top: 0;
				}
			
				.slick-arrow {
					margin-top: 28px;
					
					&.slick-prev {
						left: 30px;
					}
					
					&.slick-next {
						right: 30px;
					}
				}
			}

			.producthero-content {
				padding: 80px 8.5vw;
				position: relative;
				width: 48%;

				& > * {
					flex: unset;
				}

				.add-to-cart {
					.cta {
						flex: 1 1 auto;
						max-width: 200px;
					}
					.producthero-price {
						flex: 1 1 auto;
						span {
							@include font-rem(22px, 26px);
							letter-spacing: .5px;
						}
					}
				}

				.bat-quantity {
					margin-bottom: 30px;
					&-button {
						min-height: 28px;
						min-width: 28px;

						&:focus {
							outline-offset: 6px;
						}
					}
					&-label {
						margin-right: 32px;
					}
				}

				&-name {
					margin-bottom: 24px;
					padding: 0;
				}
			
				&-subheading {
					letter-spacing: .05px;
					margin-bottom: 14px;
					padding: 0;
				}

				&-subscription {
					margin: 0;
					max-width: none;
					padding: 20px;
				}

			}
		}

	}
}

@include media-breakpoint-up(xl) { // 1500+
	bat-producthero-zonnic {
		.bat-producthero--zonnic {
			.producthero-content {
				padding: 80px 130px;
			}
		}
	}
}

//-----------------------------------------------------------------| Add class to Product Hero component to provide image gallery with correct gradient
.gallery-berry-frost-gradient {
	.producthero-gallery {
		background: $berry-frost-gradient;
		background-color: $berry-frost-gradient;
	}
}

.gallery-chill-mint-green-gradient {
	.producthero-gallery {
		background: $chill-mint-green-gradient;
		background-color: $chill-mint-green-gradient;
	}
}

.gallery-sustainability-gradient {
	.producthero-gallery {
		background: $sustainability-gradient;
		background-color: $sustainability-gradient;
	}
}

.gallery-tropic-breeze-gradient {
	.producthero-gallery {
		background: $tropic-breeze-gradient;
		background-color: $tropic-breeze-gradient;
	}
}

.bat-productstickybar {
	align-items: center;
	animation-duration: 0.5s;
	animation-fill-mode: both;
	background-color: $whisper-gray;
	display: none;
	height: 90px;
	justify-content: center;
	padding: 0 20px 0;
	position: relative;
	width: 100%;
	z-index: 999;
	&.sticky {
		bottom: 0;
		display: flex;
		left: 0;
		position: fixed;
		right: 0;
	}
	
	&--product-name {
		color: inherit;
		display: none;
		font-weight: 900;
		letter-spacing: 0.5px;
		@include media-breakpoint-up(lg) {
			display: block;
			@include font-rem(22px, 26px);
		}
	}

	&--default {
		color: $navy;
		display: flex;
		max-width: $site-max-width;
		width: 100%;
		@include media-breakpoint-up(lg) {
			align-items: center;
			justify-content: space-between;
		}
		.productcard-text-price {
			display: none;
			margin-right: 20px;
			@include media-breakpoint-up(lg) {
				display: block;
			}
			.final-price {
				color: $system-red;
				display: none;
				font-weight: 900;
				letter-spacing: 0.5px;
				margin-right: 5px;
				position: relative;
				top: 4px;
				@include media-breakpoint-up(lg) {
					@include font-rem(22px, 26px);
				}
			}
			.base-price {
				color: $navy;
				display: none;
				font-weight: 900;
				letter-spacing: 0.5px;
				position: relative;
				top: 4px;
				@include media-breakpoint-up(lg) {
					display: inline-block;
					@include font-rem(22px, 26px);
				}
			}
			&.on-sale {
				.final-price {
					@include media-breakpoint-up(lg) {
						display: inline-block;
					}
				}
				.base-price {
					font-weight: 700;
					text-decoration: line-through;
				}
			}
		}
		.add-to-cart {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 100%;
			@include media-breakpoint-up(lg) {
				width: auto;
			}
			.producthero-content-ctas {
				width: 100%;
				@include media-breakpoint-up(lg) {
					width: auto;
				}
			}
			button {
				width: 100%;
				@include media-breakpoint-up(lg) {
					width: auto;
				}
				&[data-cta-type="cart"],
				&[data-cta-type="internal"],
				&[data-cta-type="button"] {
					max-width: unset;
					width: 100%;
					@include media-breakpoint-up(lg) {
						max-width: 300px;
						width: auto;
					}
				}
				.button-dark-disabled {
					max-width: unset;
					width: 100%;
				}
			}
		}
		.bat-quantity {
			display: none;
			padding-bottom: 0;
			pointer-events: unset;
			@include media-breakpoint-up(lg) {
				display: flex;
			}
			.bat-quantity-label {
				display: none;
			}
			.bat-quantity-field {
				background-color: transparent;
				pointer-events: unset;
			}
		}
	}
	.producthero-content-ctas {
		button {
			@include media-breakpoint-up(lg) {
				&:last-of-type {
					margin-left: 20px;
				}
			}
		}
	}
}

@keyframes slideInUp {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}
	
	to {
		transform: translate3d(0, 0, 0);
	}
}
	
.slideInUp {
	animation-name: slideInUp;
}


