.bat-form--my-details {
	
	&.bat-form {
		margin: 0;
		max-width: none;
	}
	
	.bat-form-field {
		input {
			
			&:-webkit-autofill {
				&:disabled {
					-webkit-box-shadow: 0 0 0 30px $concrete inset !important;
				}
			}
		}
		
		@include media-breakpoint-up(lg) {
			max-width: 75%;
		}
		
		.toggle-visibility {
			display: none;
		}
		
		.edit-button {
			position: absolute;
			right: 20px;
			top: 35px;
			z-index: 10;
			
			&.in-active {
				display: none;
			}
		}
		&.bat-form--my-details-email-current-password,
		&.bat-form--my-details-new-password,
		&.bat-form--my-details-confirm-new-password {
			display: none;
			
			&.active {
				display: block;
			}
		}
	}
}
