.gtr-static-page {
    padding: 33px 18px 0;
    // z-index: 0;
    // position: relative;
    // background-size: 100% auto !important;
    // background-repeat: no-repeat;
    // &:after {
    //     z-index: -1;
    //     content: "";
    //     position: absolute;
    //     top: 494px;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(32, 14, 140, 0.6) 20.83%, rgba(14, 140, 49, 0.6) 63.02%, rgba(0, 0, 0, 0.6) 98.96%);

    // }
    // &:before {
    //     z-index: -2;
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     background: linear-gradient(180deg, #BABA65 0%, #65BC90 11.98%, #25448F 30.21%, #156058 61.98%, #010102 100%);
    // }
    
    .bat-section-content {
        padding: 44px 25px;
        background: linear-gradient(359.91deg, rgba(0, 0, 0, 0.1) -0.47%, rgba(0, 0, 0, 0) 10.06%), linear-gradient(180deg, rgba(255, 255, 255, 0) 1.06%, rgba(215, 219, 230, 0.72) 100%), #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        a {
            display: inline;
            word-break: break-word;
            text-decoration: underline;
        }
        .headline {
            .bat-headline {
                .bat-headline-style {
                    word-break: break-word;
                }
            }
        }
    }
    @media (max-width: 767px) {
        background: linear-gradient(180deg, #BABA65 0%, #65BC90 11.98%, #25448F 30.21%, #156058 61.98%, #010102 100%) !important;
    }
    @media (min-width: 768px) {
        z-index: 0;
        position: relative;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        padding: 75px 70px 0;
        .bat-section-content  {
            max-width: 966px;
            margin: 0 auto;
            padding: 58px 74px 83px;
        }
        overflow: hidden;
        background-color: #27244A;
        &:before {
            z-index: -2;
            content: "";
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(67, 60, 130, 0) 0%, #373269 10.42%, #27244A 100%);
            height: 3037px;
            
            @media (min-width: 992px) {
                top: 200px;
            }
            
            @media (min-width: 1200px) {
                top: 200px;
            }
        }
    }

    .text {
        bat-text-default {
            margin-bottom: 50px;
        }

        h3 {
            font-size: 15px;

            @include media-breakpoint-up(md) {
                font-size: 18px;
                margin-bottom: 30px;
            }
        }
    }

    &.in-store {
        &::after,
        &::before {
            content: none;
        }
        background: linear-gradient(180deg, #DADD8B 0%, #2C5E7C 52.6%, #1A183A 100%) !important;
        padding: 70px 70px 0;
        .bat-section-content  {
            max-width: 1350px;
            margin: 0 auto;
            padding: 78px 100px;
        }

        .text {
            h3 {
                font-size: 32px;
            }
        }
    }
}