// font size mixin

//Had to turn off rem sizes for Magento compatibility
@mixin font-rem($font-size, $line-height: 0) {
	font-size: $font-size; 
	
	@if ($line-height > 0) {
		line-height: $line-height;
	}
}
