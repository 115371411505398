.bat-icon {
	align-items: center;
	display: flex;
	height: 28px;
	justify-content: center;
	width: 28px;
	
	&::before {
		font-size: 28px;
	}
	
	svg {
		fill: $dark-gray;
		width: 100%;
	}
}
