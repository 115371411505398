//BACKGROUND COLORS
.bg-light-blue {
	background: $light-blue;
}

.bg-zonnic-blue {
	background: $zonnic-blue;
}

.bg-red {
	background: $red;
}

.bg-navy {
	background: $navy;
}

.bg-navy-dark {
	background: $navy-dark;
}

.bg-gray {
	background: $gray;
}
.bg-light-gray {
	background: $light-gray;
}
.bg-dark-gray {
	background: $dark-gray;
}

.bg-concrete {
	background: $concrete;
}

.bg-mid-gray {
	background: $mid-gray;
}

.bg-pale-gray {
	background: $pale-gray;
}

.bg-whisper-gray {
	background: $whisper-gray;
}

.bg-chill-mint-green {
	background: $chill-mint-green-dark;
}

.bg-chill-mint-green-light {
	background: $chill-mint-green-light;
}

.bg-tropic-breeze-dark {
	background: $tropic-breeze-dark;
}

.bg-tropic-breeze-light {
	background: $tropic-breeze-light;
}

.bg-berry-frost-dark {
	background: $berry-frost-dark;
}

.bg-berry-frost-light {
	background: $berry-frost-light;
}

.bg-black {
	background: $black;
}

.bg-white {
	background: $white;
}

.bg-chill-mint-green-gradient {
	background: $chill-mint-green-gradient;
	background-color: $chill-mint-green-gradient;
}

.bg-tropic-breeze-gradient {
	background: $tropic-breeze-gradient;
	background-color: $tropic-breeze-gradient;
}

.bg-berry-frost-gradient {
	background: $berry-frost-gradient;
	background-color: $berry-frost-gradient;
}

.bg-sustainability-gradient {
	background: $sustainability-gradient;
	background-color: $sustainability-gradient;
}

.bg-system-blue {
	background: $system-blue;
}

.bg-system-green {
	background: $system-green;
}

.bg-system-red {
	background: $system-red;
}

.bg-system-dark-mode-red {
	background: $system-dark-mode-red;
}

.bg-system-light-blue {
	background: $system-light-blue;
}

.bg-system-light-green {
	background: $system-light-green;
}

.bg-system-light-red {
	background: $system-light-red;
}

// GTR
.bg-blue-green-gradient {
	background: $blue-green-gradient;
	background-color: $blue-green-gradient;
}
.bg-green-gradient {
	background: $green-gradient;
	background-color: $green-gradient;
}
.bg-blue-gradient {
	background: $blue-gradient;
	background-color: $blue-gradient;
}
.bg-gray-gradient {
	background: $gray-gradient;
	background-color: $gray-gradient;
}
