.bat-message {
	
	p {
		line-height: 22px;
	}
	
	&-text {
	
		p {
			padding-left: 0;
			position: relative;
			
			&:before {
				content: '';
				display: inline-block;
				font-family: 'icomoon';
				font-size: 18px;
				font-weight: 700;
				left: 0;
				margin-right: 5px;
				margin-top: -2px;
				position: absolute;
				text-align: center;
				top: 1px;
				vertical-align: middle;
			}
		}
	}
	
	&.error {
	
		p {
			&:before {
				content: '';
			}
		}
		
		p, 
		a {
			color: $system-red;
		}
	}
	
	&.warning {
		
		p {
			&:before {
				content: '';
			}
		}
		
		p, 
		a {
			color: $system-red;
		}
	}
	
	&.confirmation {
		
		p {
			&:before {
				content: '';
			}
		}
	
		p, 
		a {
			color: $navy;
		}
	}
	
	&.info {

		p {
			&:before {
				content: '';
			}
		}

		p, 
		a {
			color: $dark-gray;
		}
	}


	&-box {
		cursor: pointer;
		margin-bottom: 20px;
		padding: 20px 40px;
		position: relative;
		text-align: center;
		
		p {
			margin: 0;
		}
		
		&:after {         
			color: $dark-gray;
			content: '\e912';
			display: inline-block;
			font-family: 'icomoon';
			font-size: 24px;
			position: absolute;
			right: 10px;
			text-align: center;
			top: 10px;
			vertical-align: middle;
		}
	
		&.error {
			background: $tint-error;
			
			&:after {         
				color: $red;
			}
		}
		
		&.warning {
			background: $system-red;
			
			&:after {         
				color: $system-red;
			}
		}
		
		&.confirmation {
			background: $tint-multibuy;
			
			&:after {         
				color: $navy;
			}
		}
		
		&.info {
			background: $system-red;
			
			&:after {         
				color: $dark-gray;
			}
		}
		
		.velo-message-text {
			p {
				margin-bottom: 0;
			}
		}
	}
}
