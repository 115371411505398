bat-header-gtr:not(.author-mode) {
	.bat-header.bat-header--gtr {
		z-index: 100;
		position: fixed;
		left: 0;
		right: 0;
	}
}
.bat-header.bat-header--gtr {
	background-color: #fff;
	color: #000;

	a,
	button {
		outline: none;
		&:focus {
			outline: none;
		}
	}
	header {
		display: block;
		padding: 0;
		height: auto;
	}
	.bat-header-navigation {
		border-bottom: 1px solid #efefef;
		display: flex;
		align-items: stretch;
		& > ul {
			list-style: none;
			padding: 0;
			margin-bottom: 0;
			display: flex;
			flex-grow: 1;
			flex-wrap: wrap;
			& > li {
				border-right: 1px solid #efefef;
			}
			& > li {
				margin-bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 60px;
				flex-grow: 1;
				&:first-child {
					width: 60px;
					flex-grow: initial;
					a img{
						@media (min-width: 768px) {
							max-width: 47px;
							max-height: 24px;
							width: 87%;
						}
					}
				}
				img {
					max-width: 47px;
					max-height: 24px;

					@include media-breakpoint-up(md) {
						max-width: none;
						max-height: none;
						width: 65%;
						margin: 0 auto;
					}
				}
				a {
					display: flex;
					align-items: center;
					.icon {
						flex-shrink: 0;
						margin-left: 4px;
					}
				}
			}
			li.bat-navigation-has-child {
				ul {
					background-color: #fff;
					display: none;
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					list-style: none;
					padding: 0;
					right: 0;
					margin-bottom: 0;
					li {
						margin-bottom: 0;
						padding: 15px 10px;
						& + li {
							border-top: 1px solid rgba(0, 0, 0, 0.1);
						}
						a {
							display: block;
							font-weight: 400;
							font-size: 15px;
							line-height: 23px;
							text-align: center;
							color: #000000;
							.sub-nav-image {
								display: none;
							}
						}
					}
				}
			}
		}
		.bat-header-nav-toggle {
			margin-left: auto;
			flex-shrink: 0;
			width: 59px;
			height: 60px;
			align-self: center;
		}
	}
	.bat-header-mob {
		transition: transform 0.4s ease;
		transform: translateX(-100%);
		padding-bottom: 44px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		overflow: auto;
		background-color: #fff;
		padding: 20px 20px 30px;
		display: flex;
		flex-direction: column;
		&.show {
			transform: translateX(0);
		}
		// @supports (-webkit-overflow-scrolling: touch) {
		// 	padding-bottom: 128px;
  		// }
		
		// @media (max-width: 767px) {
		// 	padding-bottom: 90px;
		// }
		z-index: 100;
		.btn-close-nav {
			display: block;
			width: 30px;
			height: 30px;
			margin-left: auto;
			svg {
				margin: 0 auto;
				display: block;
			}
		}
		ul {
			margin: 0;
		}
		& > ul {
			padding: 0 35px;
			list-style: none;

			margin-bottom: 10px;
			& > li + li {
				border-top: 1px solid #ebebeb;
			}
			& > li {
				&:last-child {
					border-bottom: 1px solid #ebebeb;
				}
			}
			li {
				margin-bottom: 0;
				padding: 10px 0;

				a {
					text-align: center;
					font-weight: 400;
					font-size: 18px;
					line-height: 150%;

					text-align: center;

					color: #000000;
					display: block;
					min-height: 52px;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						max-height: 45px;
						display: block;
					}
				}
				ul {
					margin-top: 15px;
					list-style: none;
					padding: 0;
					margin-bottom: 10px;
					li {
						padding: 0;

						a {
							font-weight: 400;
							font-size: 15px;
							line-height: 200%;
							min-height: auto;

							text-align: center;

							color: #000000;
						}
					}
				}
			}
		}
		.bat-header-mob-lang {
			padding-top: 10px;
			margin-top: auto;
			position: relative;
			display: flex;
			align-items: center;
			.btn-lang-toggle {
				margin: 0 auto;
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 15px;
				line-height: 150%;

				letter-spacing: 0.02em;

				color: #000000;
				.bat-icon:before {
					font-size: 25px;
				}
				.icon-chevron-down:before {
					font-size: 16px;
				}
				span {
					display: block;
					margin: 0 10px;
				}
			}
			ul {
			    background-color: #fff;
			    padding: 5px;
			    text-align: center;
			    position: absolute;
			    bottom: 70%;
			    list-style: none;

			    margin-bottom: 0;
			    left: 50%;

			    transform: translateX(-50%);
			    border-radius: 4px;
			    box-shadow: 1px 1px 5px #ebebeb;
			    width: 100px;
			    opacity: 0;
			    visibility: hidden;
			    transition: all 0.4s ease;
			    &.show {
			        bottom: 100%;
			        opacity: 1;
			        visibility: visible;

			    }
			    li {
			        margin-bottom: 0;
			        a {
			            text-align: center;
			            font-weight: 400;
			            font-size: 15px;
			            line-height: 150%;
			        }
			    }
			}
		}
	}
}
bat-header-gtr {
	.header-empty {
		height: 71px;
		@media (max-width: 767px) {
			height: 61px;
		}
	}
	
	&.in-store {
		.header-empty {
			display: none;
		}
	
	}
}

.in-store-scroll-down {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	pointer-events: none;
	bottom: 0;
	&__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		span {
			text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
		}
	}
}
//In-store
.in-store {
	.in-store-scroll-down {
		display: block;
	}
}
@include media-breakpoint-down(sm) {
	.bat-nav-cta,
	.bat-header.bat-header--gtr
		.bat-header-navigation
		> ul
		> li.bat-header-mob-lang.visible-nav {
		display: none;
	}
}
@media (min-width: 1200px) {
	.bat-header-nav-toggle {
		display: none;
	}
}
@include media-breakpoint-up(md) {
	.bat-header.bat-header--gtr {
		.bat-header-navigation {
			> ul {
				flex-grow: unset;

				> li {
					padding: 0 15px;
					height: 70px;
					min-width: 91px;

					&:first-child {
						width: auto;

						> a {
							height: 100%;
							width: 100%;
							justify-content: center;
							//background: $system-blue;
							//color: $white;
							position: relative;
							z-index: 0;
							overflow: hidden;
							border: none;
							padding: 0 15px;

							//&:after {
							//  content: '';
							//  position: absolute;
							//  pointer-events: none;
							//  top: -6px;
							//  right: -23px;
							//  width: 60px;
							//  z-index: -1;
							//  bottom: -17px;
							//  background: radial-gradient(
							//                  90.43% 217.86% at 90.91% 119.15%,
							//                  rgba(180, 250, 255, 0.9) 0%,
							//                  rgba(100, 158, 250, 0.8) 36.82%,
							//                  rgba(0, 71, 184, 0) 100%
							//  ),
							//  #0047b8;
							//  transform: rotate(23deg);
							//  transition: opacity 0.5s ease;
							//}
						}
					}

					&:nth-child(5) {
						min-width: 200px;
					}

					@media (min-width: 1200px) {
						min-width: 105px;

						&:nth-child(5) {
							min-width: 277px;
						}

						&:nth-child(6) {
							min-width: 152px;
						}
					}
				}
			}
		}

		.bat-header-mob-lang {
			&.visible-nav {
				padding-top: 0;
				.btn-lang-toggle {
					.icon-language {
						flex-shrink: 0;
						margin-right: 7px;
						width: 24px;
						&::before {
							font-size: 24px;
						}
					}
					.icon-chevron-down {
						flex-shrink: 0;
						margin-left: 11px;
						&::before {
							font-size: 14px;
							width: auto;
						}
					}
					
				}
			}
		}

		.bat-nav-cta {
			padding: 0 15px;
			display: flex;
			align-items: center;
			text-align: right;
			min-width: 280px;
			margin-left: auto;

			a {
				color: #fff;
				max-width: 245px;
				width: 100%;
				display: block;
				margin-left: auto;

				&:focus {
					outline: $system-green solid 1px;
				}

				&:hover {
					background: $system-green;
					color: #fff;

					span {
						color: #fff;
					}
				}
			}
		}
	}
}
body .bat-wrapper bat-header-gtr.bat {
	max-width: 100%;
}

.airport-header,
.global-header {
	.bat-header.bat-header--gtr .bat-nav-cta {
		display: none !important;
	}
}

bat-header-gtr.in-store:not(.author-mode) {
	.bat-header.bat-header--gtr {
		position: fixed;
		top: 0;
		left: 0;
		width: 245px;
		bottom: 0;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
		overflow: auto;
		overflow-x: hidden;
		transition: width 0.4s ease;
		background-color: #f9f9f9;
		.bat-header-wrapper {
			height: 100%;
			header {
				height: 100%;
				.bat-header-navigation {
					height: 100%;
				}
			}
		}
		&.show-child {
			width: 525px;
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar {
			width: 5px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #0047b8;
		}
		.bat-header-navigation {
			display: flex;
			flex-direction: column;
			border: none;
			& > ul {
				display: block;
			}
		}
		.bat-nav-cta {
			flex-grow: 1;
			background-color: #fff;
			position: relative;
			z-index: 10;
			width: 245px;
			margin-left: 0;
			min-width: auto;
			padding-top: 58px;
			padding-bottom: 20px;
			border-top: 1px solid #efefef;
			display: block;
			a {
				min-height: 64px;
				padding: 0 10px;
				line-height: 64px;
				border-radius: 40px;
				margin: 0;
				max-width: 197px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.bat-header-navigation > ul > li {
			width: 245px;
			display: block;
			height: auto;
			border: none;
			min-width: auto;
			padding: 0;
			& + li {
				border-top: 1px solid #efefef;
			}
			& > a {
				font-size: 26px;
				line-height: 130%;
				background-color: #fff;
				position: relative;
				z-index: 10;
				text-align: center;
				padding: 10px;
				min-height: 119px;
				justify-content: center;
				picture {
					width: auto;
					img {
						height: 61px;
						max-height: 61px;
						width: auto;
						max-width: 120px;
					}
				}
			}
			&:first-child {
				a {
					min-height: 206px;
					display: flex;
					flex-direction: column;
					picture {
						margin-bottom: 10px;
						img {
							height: 37px;
							max-height: none;
							width: auto;
						}
					}
				}
			}

			&:nth-child(5) {
				min-width: auto;
			}

			&:nth-child(6) {
				min-width: auto;
			}

			&.bat-navigation-has-child {
				& > a {
					line-height: 130%;
					.icon {
						transform: rotate(-90deg);
						width: 11px;
						margin-left: 14px;
					}
					
					
				}
				ul {
					height: 100vh;
					opacity: 0;
					visibility: hidden;
					transform: translateX(-100%);
					transition: all 0.3s ease;
					width: 280px;
					display: block;
					top: 0;
					right: 0;
					left: auto;
					background-color: #f9f9f9;
					display: flex;
					flex-direction: column;
					li {
						flex: 0 0 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						a {
							font-size: 24px;
							line-height: 1.3;
							.sub-nav-image {
								margin-top: 28px;
								display: block;
								max-height: 205px;
								img {
									max-height: 205px;
									width: auto;
									max-width: 100%;
								}
							}
						}
					}
					&.show {
						opacity: 1;
						visibility: visible;
						transform: translateX(0);
					}
				}
			}
			&.bat-header-mob-lang {
				position: static;
				.btn-lang-toggle {
					font-size: 24px;
					line-height: 130%;
					letter-spacing: 0.02em;
					.icon-chevron-down {
						transform: rotate(-90deg);
					}
					.icon-language:before {
						font-size: 32px;
					}
					.icon-language {
						width: 33px;
						height: 33px;
					}
					.icon-language + span {
						margin-left: 12px;
						padding-top: 2px;
					}
				}
				& > ul {
					justify-content: center;
					& > li {
						flex: unset;
						& + li {
							margin-top: 40px;
							border-top: 0;
						}
					}
				}
			}
		}
	}
}

//global
@media (max-width: 1199px) {
	.bat-header.bat-header--gtr {
		.bat-nav-cta {
			display: none;
		}
		.bat-header-navigation {
			> ul {
				> li {
					&.bat-header-mob-lang,
					&:nth-child(5),
					&:nth-child(6) {
						display: none;
					}
				}
			}
		}
	}
}
@import './_gtr-header-landing';


.airport-view {
	.bat-header--landing {
		max-width: 767px;
		margin-left: auto;
		margin-right: auto;
	}
	bat-header-gtr .bat-header.bat-header--gtr {
		max-width: 767px;
		margin-left: auto;
		margin-right: auto;
		.bat-header-nav-toggle {
			@media (min-width: 1200px) {
				display: block;
			}
		}
		.bat-header-mob {
			width: 767px;
			max-width: 100%;
			margin-right: auto;
			&.show {
				margin-left: auto;
			}
		}
	}
	.bat-header.bat-header--gtr .bat-header-navigation > ul > li:nth-child(5), 
	.bat-header.bat-header--gtr .bat-header-navigation > ul > li:nth-child(6),
	.bat-header.bat-header--gtr .bat-header-navigation > ul > li.bat-header-mob-lang.visible-nav {
		display: none;
	}
}
@media (min-width: 1200px) {
	bat-header-gtr:not(.in-store) {
		.bat-header .bat-header-navigation ul > li.bat-navigation-has-child {
			position: relative;
		}
	}
}
[dir='rtl'] {
	.bat-header.bat-header--gtr .bat-header-nav-toggle {
		margin-right: auto;
		margin-left: 0;
	}
	.bat-header.bat-header--gtr .bat-header-navigation > ul > li {
		border-left: 1px solid #efefef;
		border-right: none;
		a .icon {
			margin-left: auto;
			margin-right: 4px;
		}
	}
	bat-header-gtr.in-store:not(.author-mode) {
		.bat-header.bat-header--gtr .bat-header-navigation > ul > li.bat-navigation-has-child ul {
			transform: translateX(100%);
			right: 245px;
			&.show {
				transform: translateX(0);
			}
		}
		.bat-header.bat-header--gtr .bat-header-navigation > ul > li.bat-header-mob-lang .btn-lang-toggle .icon-chevron-down {
			transform: rotate(90deg);
			margin: 0;
			svg {
				display: none;
			}
		}
		.bat-header.bat-header--gtr .bat-header-navigation > ul > li.bat-navigation-has-child > a .icon {
			transform: rotate(90deg);
			margin-right: 14px;
			margin-left: 0;
		}
	} 
}