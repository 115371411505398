.demo-GridContainer {
	padding: 0px;
}

.demo-GridRowExample {
	margin: 16px 0;
}

.demo-GridRowExample-title {
	height: 32px;
	width: 64px;
	line-height: 32px;
	margin-left: -72px;
	padding-right: 10px;
	text-align: right;
	float: left;
}

.demo-Grid {
	background: #fafafa;
}

.demo-GridColumn {
	background: #eee;
	min-height: 32px;
	line-height: 32px;
	padding-right: 10px;
	text-align: right;
}

.demo-GridColumn--separator {
	border-right: 5px solid #fafafa;
}

.demo-GridColumn--multiline {
	margin: 8px 0;
}

.demo-GridColumn--image {
	height: 128px;
	text-align: center;
	border: 5px solid #fafafa;
	display: flex;
	align-items: center;
	justify-content: center;
}