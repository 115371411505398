
.bat .bat-form {
	&-field {
		margin-bottom: 16px;
	}
	&-block {
		.bat-form-field:last-child {
			margin-bottom: 0;
		}
	}
	.bat-form-block-text {
		a {
			text-decoration: underline;
			display: inline;
		}
	}
	label a {
		display: inline;
	}
}

.gtr-home-register {
	padding: 0 18px;
	padding-top: 108px;
	margin-top: -108px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #25224D 13.54%, #009681 47.4%, #0047B8 69.27%, #000000 96.35%);
	
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		
		padding-top: (71 / 375 * 100%);
		background-image: url("/content/dam/gtr/images/webp-gtr/bg-foot-airport-registration.webp");
		background-size: cover;
		background-position: bottom right;
		background-repeat: no-repeat;
	}
	position: relative;
	overflow: hidden;
	
	legend {
		font-weight: 400;
		font-size: 15px;
		line-height: 20px;
		color: #2B363B;
		margin-bottom: 13px;
	}
	.bat-form {
		background: linear-gradient(
				359.91deg,
				rgba(0, 0, 0, 0.1) -0.47%,
				rgba(0, 0, 0, 0) 10.06%
			),
			linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 1.06%,
				rgba(215, 219, 230, 0.72) 100%
			),
			#ffffff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		padding: 44px 20px 18.7830687831%;
		.bat-form-heading {
			text-align: center;
			margin-bottom: 23px;
		}
		.bat-form-container {
			padding-bottom: 46px;
		}
		.bat-form-step + .bat-form-step {
			margin-top: -46px;
		}
		.bat-form-block:last-child {
			margin-bottom: 0;
		}
		.bat-form-text {
			text-align: center;
			margin-bottom: 33px;
			a {
				color: #0047B8;
			}
		}
		.bat-form-field {
			&.bat-form--newsletter-email-subscribe {
				label {
					margin-bottom: 4px;
				}
			}
			&.bat-form--newsletter-email-products,
			&.bat-form--newsletter-email-contact-preferred {
				.bat-form-field-set  {
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;
					margin-left: -7px;
					margin-right: -7px;
					legend {
						padding: 0 7px;
						width: 100%;
					}
					label {
						padding: 0 7px;
						margin-bottom: 4px;
						max-width: 103px;
						width: (100%/3);
						span {
							word-break: break-word;
						}
					}
				}
			}
		}
		fieldset label > input[type='checkbox'], 
		fieldset label > input[type='radio'] {
			&:checked + span,
			& + span {
				padding-top: 2px;
			}
		}
		.bat-form--newsletter-email-selectAll,
		.bat-form--newsletter-email-policy-accept,
		.bat-form--newsletter-email-term,
		.bat-form--newsletter-email-subscribe {
			label {
				margin-bottom: 4px;
			}
			fieldset label > input[type='checkbox'],
			fieldset label > input[type='radio'] {
				height: 22px;
			}
			fieldset label > input[type='checkbox']:checked + span,
			fieldset label > input[type='radio']:checked + span,
			fieldset label > input[type='checkbox'] + span, 
			fieldset label > input[type='radio'] + span {
				line-height: 22px;
				padding-top: 0;
			}
		}
		.bat-form--sign-in-submit,
		.bat-form--newsletter-email-submit {
			text-align: center;
			margin-bottom: 24px;
			.bat-button {
				width: 100%;
				max-width: 275px;
				margin: 0 auto;
			}
		}
		.bat-form--newsletter-email-submit {
			margin-bottom: 0;
		}

	}
	.bat-form-block {
		&:first-child {
			justify-content: flex-start;
			margin-left: -7px;
			margin-right: -7px;
			.bat-form--newsletter-email-last-name,
			.bat-form--newsletter-email-first-name {
				width: 50%;
			}
			.bat-form-field {
				padding-left: 7px;
				padding-right: 7px;
			}
			.bat-form--newsletter-email-day {
				label {
					white-space: nowrap;
				}
			}
			.bat-form--newsletter-email-day,
			.bat-form--newsletter-email-month,
			.bat-form--newsletter-email-year {
				width: (100% / 3);
			}
			.bat-form--newsletter-email-month,
			.bat-form--newsletter-email-year {
				label {
					opacity: 0;
				}
			}
		}
	}
	.bat-form-block-text {
		p .sm {
			@include media-breakpoint-down(sm) {
				font-size: 13px;
				line-height: 1.5;
			}
		}
	}
	&.gtr-signin-airport {
		background: linear-gradient(179.97deg, rgba(0, 0, 0, 0) 0.02%, #222C4D 43.7%, #009681 147.14%);
		.bat-form-step:not(:first-child) {
			.bat-form-text {
				margin-bottom: 0;
			}
		}
		.bat-form-block {
			margin-bottom: 33px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.bat-form-block-text {
			text-align: center;
			margin-bottom: 0;
			p {
				margin-bottom: 0;
				a {
					color: #0047B8;
					text-decoration: underline;
				}
			}
		}
	}
	@media (min-width: 768px) {
		// &::after {
		// 	content: none;
		// }
		.bat-form {
			width: 966px;
			max-width: 100%;
			padding: 58px 100px 18.93333%;
			background-color: #fff;
			.bat-form-step {
				width: 623px;
				max-width: 100%;
				margin: 0 auto;
			}
		}
	}
}
.gtr-register-instore {
	background-image: url('/content/dam/gtr/images/webp-gtr/bg-resgiter-instore.webp');
	background-size: cover;
	background-position: top center;
	padding: 112px 100px 0;
	max-width: 100% !important;
	position: relative;
	z-index: 0;
	background-color: #1A1739;
	&:before {
		content: "";
		position: absolute;
		top: 246px;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1A1739 72.92%);
		z-index: -1;
	}
	.bat-form {
		max-width: 1350px;
		padding: 61px 258px 73px;
		background: linear-gradient(359.91deg, rgba(0, 0, 0, 0.1) -0.47%, rgba(0, 0, 0, 0) 10.06%), linear-gradient(180deg, rgba(255, 255, 255, 0) 1.06%, rgba(215, 219, 230, 0.72) 100%), #FFFFFF;
		label {
			font-weight: 400;
			font-size: 24px;
			line-height: 32px;
			margin-bottom: 11px;
			color: #2B363B;
		}
		input,
		.select-wrapper select,
		textarea {
			height: 70px;
			padding: 10px 20px;
			font-size: 20px;
		}
		.select-wrapper select {
			padding-right: 45px;
			background-size: 10px;
		}
		.bat-form-heading {
			text-align: center;
			margin-bottom: 60px;
			color: #000000;
			.bat-headline-style  {
				font-weight: 700;
				font-size: 40px;
				line-height: 150%;
				text-align: center;
				span {
					font-size: 55px;
					display: inline-block;
				}
			}
		}
		.bat-form-field {
			margin-bottom: 27px;
		}
		.bat-form-text {
			text-align: center;
			margin-bottom: 17px;
			font-weight: 400;
			font-size: 24px;
			line-height: 130%;
			
		}
		form fieldset label > input[type='checkbox'] + span, 
		form fieldset label > input[type='radio'] + span {
			font-weight: 400;
			font-size: 24px;
			line-height: 29px;
			color: #2B363B;
		}
		
		fieldset label > input[type='checkbox'], 
		fieldset label > input[type='radio'] {
			margin-right: 21px;
			height: 29px;
			width: 28px;
			margin-left: 1px;
			border-radius: 3px;
		}
		fieldset label > input[type='checkbox']:before, 
		fieldset label > input[type='radio']:before {
			background-size: 13px;
			width: 30px;
			height: 30px;
			margin-top: -15px;
			margin-left: -15px;
		}
		fieldset label > input[type='radio']:after {
			width: 22px;
			height: 22px;
			margin-top: -11px;
			margin-left: -11px;
		}
		form .bat-form--newsletter-email-assisted-trial-checkbox label {
			align-items: center;
		}
		.bat-form--newsletter-email-product-preferred,
		.bat-form--newsletter-email-contact-preferred {
			.bat-form-field-set  {

				display: flex;
				flex-wrap: wrap;
				legend {
					color: #2B363B;
					width: 100%;
					font-weight: 400;
					font-size: 24px;
					line-height: 32px;
					margin-bottom: 26px;
				}
				label {
					width: auto;
					width: 133px;
					max-width: 33%;
					margin-right: 15px;
				}
			}

		}
		.bat-form--newsletter-email-submit {
			padding-top: 40px;
			margin-bottom: 0;
			button {
				width: 283px;
				margin: 0 auto;
				max-width: 100%;
				display: block;
			}
		}
		.bat-form--newsletter-email-term,
		.bat-form--newsletter-email-policy,
		.bat-form--newsletter-email-subscribe {
			label {
				margin-bottom: 0;
			}
			fieldset label > input[type='checkbox'], 
			fieldset label > input[type='radio'] {
				height: 30px;
			}
			fieldset label > input[type='checkbox']:checked + span,
			fieldset label > input[type='radio']:checked + span,
			fieldset label > input[type='checkbox'] + span, 
			fieldset label > input[type='radio'] + span {
				line-height: 1.5;
			}
			span {
				color: #000 !important;
				font-size: 20px !important;
			}
		}
	}
	.bat-form-block {
		&:last-child {
			margin-bottom: 0;
		}
		&:first-child {
			justify-content: flex-start;
			display: flex;
			flex-wrap: wrap;
			margin-left: -9px;
			margin-right: -9px;
			.bat-form-field {
				padding: 0 9px;
			}
			.bat-form--newsletter-email-last-name,
			.bat-form--newsletter-email-first-name {
				width: 25%;
			}
			.bat-form--newsletter-email-country,
			.bat-form--newsletter-email-gender,
			.bat-form--newsletter-email-email {
				width: 50%;
			}
			.bat-form--newsletter-email-day,
			.bat-form--newsletter-email-month,
			.bat-form--newsletter-email-year {
				width: 25%;
			}
		}
	}
	.bat-form--newsletter-email-day {
		label {
			white-space: nowrap;
		}	
	}
	.bat-form--newsletter-email-month,
	.bat-form--newsletter-email-year {
		label {
			opacity: 0;
		}
	}
	@media (max-width: 1199px) {
		
		padding-left: 35px;
		padding-right: 35px;
		.bat-form {
			padding: 50px;
		}
	}
	@media (max-width: 991px) {
		.bat-form {
			padding: 50px;
			.bat-form-container {
				display: block;
				.bat-form-qr {
					max-width: 260px;
					margin: 0 auto;
				}
				
			}
		}
	}
	
    @include media-breakpoint-down(md) {
        padding: 75px 50px 0;
        .bat-form {
            padding: 50px;
        }
        
        .bat-form-block {
            &:first-child {
                .bat-form--newsletter-email-last-name,
                .bat-form--newsletter-email-first-name {
                    width: 50%;
                }
                .bat-form--newsletter-email-country {
                    width: 100%;
                }
            }
        }

    }
    @include media-breakpoint-down(sm) {
        padding: 50px 20px 0;
        .bat-form {
            padding: 50px 20px 120px;
        }
        
        .bat-form-block {
            &:first-child {
                
                .bat-form--newsletter-email-last-name,
                .bat-form--newsletter-email-first-name,
                .bat-form--newsletter-email-country,
                .bat-form--newsletter-email-gender,
                .bat-form--newsletter-email-email {
                    width: 100%;
                }
				
				.bat-form--newsletter-email-day,
				.bat-form--newsletter-email-month,
				.bat-form--newsletter-email-year {
					width: (100% / 3);
				}
            }
        }

    }
}
.gtr-register-global {
    background-image: url('/content/dam/gtr/images/webp-global/bg-registration-global.webp');
	background-size: cover;
	background-position: top center;
	padding: 75px 100px 0;
    .bat-form {
        width: 966px;
        max-width: 100%;
        padding: 58px 100px 140px;
        background-color: #fff;
        .bat-form-step {
            width: 623px;
            max-width: 100%;
            margin: 0 auto;
        }
		fieldset label > input[type='checkbox']:checked + span, 
		fieldset label > input[type='radio']:checked + span,
		fieldset label > input[type='checkbox'] + span, 
		fieldset label > input[type='radio'] + span {
			line-height: (22 / 15);
		}
		input,
		.select-wrapper select {
			font-size: 13px;
		}
		
		.bat-form--registration-global-subscribe,
		.bat-form--registration-global-policy {
			fieldset label > input[type='checkbox']:checked + span,
			fieldset label > input[type='radio']:checked + span,
			fieldset label > input[type='checkbox'] + span, 
			fieldset label > input[type='radio'] + span {
				line-height: 1.5;
			}
		}
    }

    .bat-form-heading {
        text-align: center;
        margin-bottom: 22px;
    }
    .bat-form-text {
        margin-bottom: 49px;
        text-align: center;
        span {
            font-size: 18px;
            display: inline-block;
            font-weight: bold;
        }
    }
    .bat-form-block {
		&:last-child {
			margin-bottom: 0;
		}
        &:first-child {
			justify-content: flex-start;
            display: flex;
            flex-wrap: wrap;
            margin-left: -6px;
            margin-right: -6px;
            .bat-form-field {
                padding: 0 6px;
            }
            .bat-form--registration-global-last-name,
            .bat-form--registration-global-first-name {
                width: 25%;
            }
            .bat-form--registration-global-country,
            .bat-form--registration-global-gender,
            .bat-form--registration-global-email {
                width: 50%;
            }
			.bat-form--registration-global-day,
			.bat-form--registration-global-month,
			.bat-form--registration-global-year {
				width: 25%;
			}
		}
    }
	.bat-form--registration-global-day {
		margin-bottom: 26px;
		label {
			white-space: nowrap;
		}	
	}
	.bat-form--registration-global-month,
	.bat-form--registration-global-year {
		margin-bottom: 26px;
		label {
			opacity: 0;
		}
	}
    .bat-form--registration-global-products,
    .bat-form--registration-global-contact-preferred {
        .bat-form-field-set  {
            display: flex;
            flex-wrap: wrap;
            legend {
                color: #2B363B;
                width: 100%;
                font-weight: 400;
                font-size: 15px;
                line-height: (22 / 15);
                margin-bottom: 9px;
            }
            label {
				margin-bottom: 4px;
                width: auto;
                width: 69px;
                max-width: 33%;
                margin-right: 15px;
            }
        }

    }
	.bat-form-field-set {
		label {
			margin-bottom: 4px;
		}
	}
    .bat-form--registration-global-submit {
        margin-bottom: 0;
		padding-top: 20px;
        button {
            width: 150px;
            margin: 0 auto;
            max-width: 100%;
            display: block; 
        }
    }
    @include media-breakpoint-down(md) {
        padding: 75px 50px 0;
        .bat-form {
            padding: 50px;
        }
        
        .bat-form-block {
            &:first-child {
                .bat-form--registration-global-last-name,
                .bat-form--registration-global-first-name {
                    width: 50%;
                }
                .bat-form--registration-global-country {
                    width: 100%;
                }
            }
        }

    }
    @include media-breakpoint-down(sm) {
        padding: 50px 20px 0;
        .bat-form {
            padding: 50px 20px 120px;
        }
        
        .bat-form-block {
            &:first-child {
                
                .bat-form--registration-global-last-name,
                .bat-form--registration-global-first-name,
                .bat-form--registration-global-country,
                .bat-form--registration-global-gender,
                .bat-form--registration-global-email {
                    width: 100%;
                }
				
				.bat-form--registration-global-day,
				.bat-form--registration-global-month,
				.bat-form--registration-global-year {
					width: (100% / 3);
				}
            }
        }

    }
}
.gtr-qr-instore {
	.bat-section {
		position: fixed;
		top: 316px;
		right: 0;
		z-index: 60;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		padding: 23px 32px;
		background-color: #fff;
		width: 228px;
		max-width: 100%;
		.bat-text {
			margin-bottom: 18px;
		}
	}
}
.gtr-home-register.gtr-home-register--mclaren {
	padding-top: 191px;
	margin-top: -191px;
	background: linear-gradient(180deg, rgba(239, 129, 11, 0) 5.14%, #EF7E0B 8.22%);
	&--sign-in {
		background: linear-gradient(180deg, rgba(239, 129, 11, 0) 14.65%, #EF7E0B 32.05%, #EF7E0B 100%)
	}
	&:after {
		background-image: url("/content/dam/gtr/images/webp-mclaren-airport/bg-orange-foot-registration.webp");
	}
	
	.bat-form-field .bat-button--dark {
		min-height: 50px;
		@include media-breakpoint-up(lg) {
			min-height: 45px;
		}
		background: #d7490d;
		color: $white;
		position: relative;
		z-index: 0;
		overflow: hidden;
		border: none;
		&:after {
			content: '';
			width: auto;
			transform: none;
			position: absolute;
			pointer-events: none;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: opacity 0.5s ease;
			background: linear-gradient(
				140deg,
				rgba(215, 73, 13, 1) 0%,
				rgba(215, 73, 13, 1) 70%,
				rgba(255, 206, 136, 1) 88%,
				rgba(255, 255, 255, 1) 100%
			);
		}
		span {
			transition: color 0.5s ease;
		}

		i {
			svg {
				fill: var(--text-color);
			}
		}
		&:hover {
			background: #d7490d;
			&:after {
				opacity: 0;
			}
			span {
				color: #fff;
			}

			i {
				svg {
					fill: #fff;
				}
			}
		}
		&:focus,
		&:focus-visible {
			outline: var(--border-color) solid 1px;
			outline-offset: 5px;
		}

		&.disabled,
		&:disabled {
			// Do not use CSS vars here; breaks 'button dark disabled' style
			background: $whisper-gray;
			color: $concrete;
			border: solid 2px #cfcfcf;
			&:after {
				opacity: 0;
			}
		}
	}
}
.gtr-register-instore .bat-form .bat-form--newsletter-email-contact-preferred.nic-user-tc .bat-form-field-set label,
.gtr-register-global .bat-form--registration-global-contact-preferred.nic-user-tc .bat-form-field-set label,
.gtr-home-register .bat-form .bat-form-field.bat-form--newsletter-email-contact-preferred.nic-user-tc .bat-form-field-set label {
	
	width: 100%;
	max-width: 100%;
}
[dir='rtl'] {
	.bat form fieldset label > input[type='checkbox'], 
	.bat form fieldset label > input[type='radio'] {
		margin-right: 0;
		margin-left: 16px;
	}
	.gtr-register-instore .bat-form .bat-form--newsletter-email-product-preferred .bat-form-field-set label, 
	.gtr-register-instore .bat-form .bat-form--newsletter-email-contact-preferred .bat-form-field-set label,
	.gtr-register-global .bat-form--registration-global-products .bat-form-field-set label, 
	.gtr-register-global .bat-form--registration-global-contact-preferred .bat-form-field-set label {
		margin-left: 15px;
		margin-right: 0;
	}
	.gtr-qr-instore {
		.bat-section {
			right: auto;
			left: 0;
		}
	}
}
//
.raffle-winner-section {	
	.gtr-home-register .bat-form {
		padding-top: 39px;
		padding-left: 21px;
		padding-right: 21px;
		.bat-form-heading {
			margin-bottom: 38px;
		}
		.bat-form--newsletter-email-subscribe {
			label {
				margin-bottom: 0;
			}
			a {
				color: #0047B8;
			}
		}
		fieldset label > input[type='checkbox'], 
		fieldset label > input[type='radio'] {
			margin-right: 10px;
		}
		#step1 .bat-form-container {
			padding-bottom: 77px;
		}
		#step2 .bat-form-container,
		#step3 .bat-form-container,
		#step4 .bat-form-container {
			padding-bottom: 0;
		}
		
		.bat-form--newsletter-email-submit {
			padding-top: 38px;
		}
	}
}