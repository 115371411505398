// ----| #myModal is for localhost testing
// ----| #locationselector is used for server testing

// #myModal { // sass-lint:disable-line no-ids, id-name-format
#locationselectormodal { // sass-lint:disable-line no-ids, id-name-format

	.bat-modal-content {
		padding-left: 20px;
		padding-right: 20px;

		@include media-breakpoint-up(sm) {
			padding-left: 8vw;
			padding-right: 8vw;
		}

		@include media-breakpoint-up(xxl) {
			padding-left: 120px;
			padding-right: 120px;
		}
	}
}

.bat-location {

	&--zonnic {

		.bat-modal {
			align-items: center;
			background-color: $black-50;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			justify-content: center;

			.bat-modal-content {
				max-height: 100%;
				overflow: auto;
				top: 0;

			}
		}

		&-content {
			position: relative;
			text-align: left;

			@include media-breakpoint-up(sm) {
				max-width: 428px;
			}

			&-greeting {
				position: relative;
				z-index: 2;

				&-headline {
					margin-bottom: 25px;

					h2 {
						color: $navy;
						@include font-rem(22px, 26px);

						@include media-breakpoint-up(sm) {
							@include font-rem(32px, 40px);
						}
					}
				}

				&-text {
					color: $black;
					margin-bottom: 34px;
					text-align: left;

					@include media-breakpoint-up(sm) {
						margin-bottom: 34px;
					}

					p {
						@include font-rem(14px, 20px);
					}
				}

				&-cta-list {
					text-align: center;

					.bat-button {
						padding: 13px 103px;

						@include media-breakpoint-down(xs) {
							width: 100%;
						}
					}
				}

			}

			&-input {
				margin-bottom: 24px;

				@include media-breakpoint-up(sm) {
					margin-bottom: 10px;
				}
			}

			&-yourlocation {
				@include font-rem(16px, 24px);
				color: $black;
				margin-bottom: 10px;
			}

			&-message {
				background-color: $chill-mint-green-light-30;
				color: $black;
				display: none;
				margin-bottom: 10px;
				margin-top: 5px;
				padding: 23px 20px 22px;
				position: relative;
				text-align: center;
				z-index: 2;

				p {
					@include font-rem(14px, 20px);
					font-weight: bold;
				}

				&.active {
					display: block;
				}

				&.in-active {
					display: none;
				}
			}

			&-language {
				margin-top: 28px;
			}

			&-language-list {
				margin-bottom: 27px;
				margin-top: 12px;
			}

		}

		label {
			align-items: center;
			color: $navy !important; // sass-lint:disable-line no-ids
			display: flex;
			font-family: $primary-font;
			font-weight: bold;
			letter-spacing: 1px;
			margin-bottom: 8px;
			text-transform: uppercase;
		}

		input[type=radio]+label, // sass-lint:disable-line force-attribute-nesting, no-qualifying-elements
		input[type=checkbox]+label { // sass-lint:disable-line force-attribute-nesting, no-qualifying-elements
			p {
				line-height: 24px !important;  // sass-lint:disable-line no-ids
			}
		}

	}

	.active {
		display: block;
	}

	.in-active {
		display: none;
	}

	.radio-set {
		display: inline-block;
		white-space: nowrap;
		width: auto;
	}

	// label for radio buttons
	.radio {
		align-items: center;
		color: $black;
		display: inline-flex;
		height: 24px;
		margin-right: 57px;
		text-transform: none;
		width: auto;
	}

	.radio-input {
		clip: unset;
		height: 24px;
		margin: 0 1rem;
		overflow: unset;
		// transform: scale(1.6);
		vertical-align: middle;
		width: auto;

		@include media-breakpoint-up(lg) {
			width: 21px;
		}

		&:focus {
			//border-radius: 15px;
			box-shadow: 0 0 0 5px $white, 0 0 0 6px $navy;
			outline: none;
			outline-offset: 0;
		}

		&:before {
			background-color: $white;
			border: 2px solid $navy;
			border-radius: 50%;
			content: '';
			display: inline-block;
			height: 24px;
			left: -1px;
			position: absolute;
			top: -2px;
			visibility: visible;
			width: 24px;
		}

		&:checked {
			&:before {
				border: 6px solid $navy;
			}
		}
	}
}

@supports ((-webkit-backdrop-filter: blur(.5em)) or (backdrop-filter: blur(.5em))) {
	.location-blurred {
		.bat-modal {
			-webkit-backdrop-filter: blur(.5em);
			backdrop-filter: blur(.5em);
			background-color: $whisper-gray;
		}
	}
}

// Targets iframe of province/language locator
// long titles do not cover distance anymore
.store-list {
	a {
		.title {
			h3 {
				padding-right: 88px;
			}
		}
	}
}
