// AEM
.magentobasketsummary {
	width: 100%;
}

// Nested in section flex align center 
bat-magentobasketsummary-zonnic {
	width: 100%;
}

.bat-basket-summary {
	margin-bottom: 20px;
	text-align: left;

	&-headline {
		@include font-rem(20px, 24px);
		margin-bottom: 20px;

		@include media-breakpoint-up(md) {
			@include font-rem(22px, 26px);
		}
	}
	
	&-loader,
	&-wrapper-loader-overlay {
		background-color: $white;
		min-height: 200px;
		position: relative;

		&:after {
			-webkit-animation: spin 2s linear infinite;
			/* Safari */
			animation: spin 2s linear infinite;
			border: 10px solid $whisper-gray;
			border-radius: 50%;
			border-top: 10px solid $navy;
			bottom: 0;
			content: '';
			height: 80px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
			width: 80px;
			z-index: 1;
		}
	}
	
	&-wrapper {
		position: relative;
		
		&-loader-overlay {
			background-color: $white-50;
			bottom: 0;
			display: none;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			
			&.active {
				display: block;
			}
		}
	}

	&-items {
		background-color: $white;
		margin-bottom: 5px;

		&-header {
			align-items: center;
			display: flex;
			justify-content: right;
			margin: 0;
			min-height: 56px;
			padding: 14px 0 10px 20px;

			&-label {
				align-items: center;
				color: $black;
				flex: 1 0;
				font-style: normal;
				@include font-rem(12px, 14px);
				font-weight: 700;
				letter-spacing: .2px;
				margin: 0 auto 0 0;
				text-transform: uppercase;

				&-total,
				&-suffix {
					display: inline-block;
				}

				&-prefix {
					display: inline-block;

					@include media-breakpoint-up(md) {
						display: none;
					}
				}
			}

			&-total {
				align-items: center;
				color: $black;
				flex: 0;
				font-style: normal;
				@include font-rem(12px);
				font-weight: 700;
				letter-spacing: .2px;
				margin: 0;
				text-transform: uppercase;
			}

			&-toggle {
				align-items: center;
				display: flex;
				flex: 0;
				margin: 0;
				padding: 4px 20px 8px;

				i {
					height: 20px;
					width: 20px;

					&.icon-plus,
					&.icon-minus {

						&:before {
							color: $navy-dark;
							font-size: 20px;
						}
					}

					&.icon-minus {
						display: none;
					}

					&.icon-plus {
						display: block;
					}
				}

				&.active {

					i {

						&.icon-minus {
							display: block;
						}

						&.icon-plus {
							display: none;
						}
					}
				}
			}
		}

		&-list {
			display: none;
			padding: 0;

			&.active {
				display: block;
			}

			@include media-breakpoint-up(md) {
				padding: 0 20px;
			}

			&-item {
				border-top: 2px solid $pale-gray;
				display: flex;
				padding: 20px;

				@include media-breakpoint-up(md) {
					padding: 20px;
				}

				&-content {
					display: flex;
					flex-direction: column;
				}

				&-title {
					@include font-rem(18px, 25px);

					@include media-breakpoint-up(md) {
						@include font-rem(16px, 20px);
					}

					@include media-breakpoint-up(lg) {
						@include font-rem(20px, 28px);
					}
				}

				&-text {
					align-items: flex-end;
					display: flex;
					height: 100%;

					&-quantity {
						@include font-rem(12px, 14px);
						color: $navy;
						font-weight: 700;
						text-transform: uppercase;

						&-total {
							@include font-rem(14px, 20px);
							color: $navy;
							font-weight: 700;
							margin: 0 0 0 auto;
						}
					}
				}

				&-image {
					flex: 0 0 35%;
					max-width: 35%;
					padding: 0 20px 0 0;
					position: relative;

					img {
						width: 100%;
					}
				}
			}
		}
	}

	&-voucher-container {
		background-color: $white;
		margin-bottom: 5px;
		min-height: 56px;
		padding: 10px 20px;

		form {
			input {
				border-bottom: 0;
				margin: 0;
				padding-right: 40px;

				&:focus {
					border-radius: 0;
					box-shadow: none;
				}

				&:active {
					border-bottom: 0;
				}
			}
		}

		&-form {
			position: relative;

			&-error {

				p {
					color: $system-red;
					@include font-rem(12px, 14px);
					margin: 10px 0;
				}
			}

			&-submit {
				align-items: center;
				background-color: $navy;
				display: flex;
				height: 40px;
				justify-content: center;
				position: absolute !important; // sass-lint:disable-line no-important
				right: 0;
				top: 0;
				width: 40px;
				z-index: 1;

				&:disabled {
					background-color: $whisper-gray;
				}

				&:after {
					border-top: 5px solid $navy  !important; // sass-lint:disable-line no-important
				}

				i {
					.active & {
						display: none;
					}

					&:before {
						color: $white;
						font-size: 20px;
					}
				}
			}
		}
	}

	&-breakdown {
		background-color: $white;
		min-height: 56px;
		padding: 0 20px;

		&-adjustments {
			border-bottom: 2px solid $pale-gray;
			padding: 10px 0;

			&-shipping,
			&-subtotal {
				display: flex;
				margin: 10px 0;

				&-label {
					@include font-rem(12px, 14px);
					letter-spacing: 1.2px;
					
					em {
						font-size: 8px;
						font-style: italic;
						font-weight: normal;
					}
				}

				&-value {
					color: $black;
					@include font-rem(12px, 14px);
					font-weight: normal;
					letter-spacing: 0;
					margin: 0 0 0 auto;
				}
			}

			&-discounts {

				&-discount {
					color: $vape-purple;
					display: flex;
					margin: 10px 0;

					&-label {
						align-items: center;
						display: flex;
						@include font-rem(12px, 14px);
						letter-spacing: 1.2px;

						&-remove {
							align-items: center;
							background-color: $pale-gray;
							border-radius: 16px;
							display: flex;
							height: 16px;
							justify-content: center;
							margin-left: 5px;
							margin-top: -3px;
							width: 16px;

							i {
								height: 12px;
								width: 12px;

								&:before {
									color: $navy;
									font-size: 12px;
								}
							}

							&.active {
								position: relative;

								i {
									display: none;
								}

								&:after {
									-webkit-animation: spin 2s linear infinite;
									/* Safari */
									animation: spin 2s linear infinite;
									border: 1px solid $whisper-gray;
									border-radius: 50%;
									border-top: 1px solid $navy;
									bottom: 0;
									content: '';
									height: 12px;
									left: 0;
									margin: auto;
									position: absolute;
									right: 0;
									top: 0;
									width: 12px;
									z-index: 1;
								}
							}
						}
					}

					&-value {
						@include font-rem(12px, 14px);
						letter-spacing: 0;
						margin: 0 0 0 auto;
					}
				}
			}
		}

		&-taxes {
			border-bottom: 2px solid $mid-gray;
			padding: 20px 0 10px;

			&-tax {
				display: flex;
				margin: 0 0 10px;

				&-label {
					@include font-rem(12px, 14px);
					letter-spacing: 1.2px;
				}

				&-value {
					color: $black;
					@include font-rem(12px, 14px);
					font-weight: normal;
					letter-spacing: 0;
					margin: 0 0 0 auto;
				}
			}
		}

		&-total {
			padding: 20px 0;

			&-amount {
				align-items: center;
				display: flex;

				&-value {
					margin: 0 0 0 auto;
				}

				&-value,
				&-label {
					@include font-rem(20px, 24px);
					font-weight: 800;
					letter-spacing: .5px;

					@include media-breakpoint-up(md) {
						@include font-rem(22px, 26px);
					}
				}
			}
		}
	}

	&--boxless {
		background-color: transparent;
	}
}

//-----------------------------------------------------------------| Hide Items List Mobile Modifier
.bat-basket-summary--hide-items-mobile {

	@include media-breakpoint-down(md) {
		.bat-basket-summary-items {
			display: none;
		}
	}
}

//-----------------------------------------------------------------| Alternative Design Modifier
.bat-basket-summary--alt-design {

	@include media-breakpoint-down(md) {
		
		.bat-basket-summary-loader,
		.bat-basket-summary-wrapper-loader-overlay  {
			background-color: transparent;
			min-height: 100px;

			&:after {
				-webkit-animation: spin 2s linear infinite;
				/* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $white;
				border-radius: 50%;
				border-top: 10px solid $navy;
				bottom: 0;
				content: '';
				height: 40px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 40px;
				z-index: 1;
			}
		}
		
		.bat-basket-summary-headline {
			display: none;
		}

		.bat-basket-summary-items {
			background-color: transparent;

			&-header {
				padding: 14px 0 10px;

				&-toggle {
					padding: 4px 0 8px 12px;
				}

				&-label {
					color: $navy;

					&-prefix {
						@include font-rem(18px, 25px);
						display: inline-block;
						width: 100%;
					}

					&-total,
					&-suffix {
						@include font-rem(12px, 14px);
					}
				}

				&-total {
					color: $navy;
					@include font-rem(20px, 28px);
				}
			}

			&-list {
				background-color: $white;

				&-item {

					&:first-child {
						border-top: 0;
					}
				}
			}
		}

		.bat-basket-summary-breakdown {
			background-color: transparent;
			display: none;
			padding: 0;

			&.active {
				display: block;
			}

			&-adjustments-shipping,
			&-adjustments-subtotal-label,
			&-taxes-tax-label {
				color: $black;
			}
		}
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
