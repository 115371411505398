.bat-country {
	&-selector {
		padding: 8% 0 5% 200px;

		@include media-breakpoint-down(lg) {
			padding: 10% 0 5% 100px;
		}

		@include media-breakpoint-down(md) {
			padding: 8% 0 5% 20px;
		}

		@include media-breakpoint-down(sm) {
			padding: 10% 4%;
		}

		p {
			line-height: 1.5rem;
		}

		// bat-country-selector--main
		&--main {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			@include media-breakpoint-up(md) {
				flex-direction: row;
				justify-content: space-between;
			}
		}

		// bat-country-selector--form
		&--form {
			flex-basis: 38%;

			@include media-breakpoint-down(sm) {
				padding-bottom: 10%;
			}

			@include  media-breakpoint-up(sm) {
				flex-basis: 53%;
			}

			@include media-breakpoint-down(lg) {
				flex-basis: 48%;
			}

			@include media-breakpoint-up(lg) {
				flex-basis: 40%;
			}

			&header {
				color: $navy;
				margin-bottom: 30px;

				h3 {
					padding-bottom: 30px;
				}

				span {
					color: $system-red;
				}
			}

			&content {
				.input-wrapper,
				.cta-wrapper {
					padding: 2.5% 0;
				}

				.input-wrapper {
					.select-wrapper {
						margin-top: 5px;
					}
				}

				.cta-wrapper {
					a {
						&:first-child {
							margin-right: 20px;

							@include media-breakpoint-down(xs) {
								margin-bottom: 4%;
								margin-right: 0;
							}

							@include media-breakpoint-up(sm) {
								margin-bottom: 0;
							}
						}
					}
				}

				
			}

			.bat-cta-style {
				
				@include media-breakpoint-down(xs) {
					display: block;
					max-width: initial;
				}

				@include media-breakpoint-up(sm) {
					display: inline-block;
				}
				
			}

			.change-lang {
				
				@include media-breakpoint-down(xs) {
					border: 1px solid $system-red;
					display: block;
					max-width: initial;
				}

				@include media-breakpoint-up(sm) {
					display: inline-block;
				}
			}
		}

		// bat-country-selector--info
		&--infowrapper {
			flex-basis: 30%;
		}

		&--info {
			display: none;
			transition: display .2s ease-in-out;

			@include media-breakpoint-down(xs) {
				padding-top: 20%;
			}

			&.show {
				display: block;
			}

			h4 {
				margin-bottom: 30px;
				padding-left: 15px;

				@include media-breakpoint-up(md) {
					padding-left: 0;
				}

				@include media-breakpoint-down(sm) {
					margin-bottom: 0;
				}

				@include media-breakpoint-down (xxs) {
					padding-left: 15px;
				}
 			}

			&header {
				position: relative;

				@include media-breakpoint-down(md) {
					align-items: center;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					margin-bottom: 30px;
				}

				@include media-breakpoint-up (md) {
					margin-bottom: 0;
				}

				.icon-wrapper {
					height: 41px;
					left: -65px;
					position: absolute;
					top: -10px;
					width: 41px;

					.bat-icon {
						height: auto;
						width: auto;
					}

					svg {
						border-radius: 50%;
					}


					@include media-breakpoint-down(sm) {
						left: 0;
						position: relative;
						top: 0;
					}

				}
			}
		}

		&--select-language {
			margin-bottom: 25px;
		}
	}
}

.countryselect-wrapper {

	@include media-breakpoint-down(xxl) {
		background: linear-gradient(90deg, $white 60%, $concrete 40%);
	}

	@include media-breakpoint-down(sm) {
		background: linear-gradient(180deg, $white 73%, $concrete 50%);
	}

	@include media-breakpoint-down(xxs) {
		background: linear-gradient(180deg, $white 75%, $concrete 50%);
	}
}

body {
	.bat-wrapper {
		.bat {
			&.countryselect-wrapper {
				max-width: none;
			}
		}
	}
}
