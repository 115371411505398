.bat-carousel {
	.bat-card {
		.bat-card--default {
			margin-right: 20px;
			max-width: none;

			@include media-breakpoint-up(sm) {
				margin-right: 40px;
			}
		}
	}

	.bat-productcard {
		opacity: 1;
	}

	&-slides {
		opacity: 0;
		transition: opacity 0.2s ease-in;

		//AEM author mode
		.authorEditMode & {
			// sass-lint:disable-line class-name-format
			opacity: 1;
		}

		&.loaded {
			opacity: 1;
		}
	}

	.slick-dots {
		justify-content: center;
		margin-left: 0;

		li {
			button {
				background-color: $concrete;
				border: 0;
				height: 10px;
				width: 10px;
			}

			&.slick-active {
				button {
					background-color: $navy;
					z-index: 9;
				}
			}
		}
	}

	&.product-carousel {
		.slick-dots {
			display: flex;
			justify-content: center;
			margin: 0 auto;
		}
	}

	&.card-carousel {
		.slick-dots {
			margin-left: 0;
		}

		.slick-list {
			padding: 0 20px 0 0;

			@include media-breakpoint-up(md) {
				padding: 0 40px 0 0;
			}

			@include media-breakpoint-up(lg) {
				padding: 0 80px 0 0;
			}
		}
	}

	.slick-prev {
		display: none;
		left: 0;
		position: absolute;
		top: 49%;
		z-index: 20;
	}

	.slick-next {
		display: none;
		position: absolute;
		right: 0;
		top: 49%;
		z-index: 20;
	}

	.slick-arrow {
		&.slick-prev {
			display: block;
			left: 0;
			position: absolute;
			top: 50%;
			z-index: 20;
			transform: translateY(-50%);

			&.slick-hidden {
				display: none;
			}
		}

		&.slick-next {
			display: block;
			position: absolute;
			right: 0;
			top: 50%;
			z-index: 20;
			transform: translateY(-50%);

			&.slick-hidden {
				display: none;
			}
		}
	}

	.slick-prev,
	.slick-next {
		.bat-icon {
			color: $black;
		}
	}

	//---------------------- Zonnic Carousel
	&.zonnic-carousel {
		.flex-wrap {
			display: flex;
			justify-content: space-between;
		}

		.slick-prev {
			align-items: flex-end;
			bottom: unset;
			display: flex;
			left: unset;
			position: relative;
			right: unset;
			top: unset;
			z-index: 20;
		}

		.slick-next {
			align-items: flex-end;
			bottom: unset;
			display: flex;
			left: unset;
			position: relative;
			right: unset;
			top: unset;
			z-index: 20;
		}

		.bat-icon {
			color: $black;
			width: auto;

			&::before {
				font-size: 20px;
			}
		}

		.slick-dots {
			bottom: -36px;

			li {
				&.slick-active {
					button {
						background-color: $berry-frost-dark;
					}
				}

				button {
					background-color: $pale-gray;
				}
			}
		}
	}

	&.zonnic-bloglist {
		// card comp style adjustments
		.bat-cta-style {
			&.arrow-link-dark {
				padding: 8px 0;
			}
		}

		.slick-slide {
			.bat-card {
				margin-bottom: 30px;
			}
		}

		.bat-bloglist {
			&-notslick {
				display: flex;
				flex-wrap: wrap;
			}

			&-slides {
				margin-left: -1%;
				margin-right: -1%;

				.card {
					padding: 0 15px;
					width: 33.33%;
				}
			}

			&-actionbtns {
				align-items: center;
				display: flex;
				justify-content: center;
				margin: 0 auto;
			}

			&-slides {
				&-dots {
					margin-top: -35px;
					overflow: hidden;
					width: 106px;
				}
			}
		}

		.slick-prev,
		.slick-next {
			position: relative;
		}

		.slick-prev {
			left: auto;
		}

		.slick-next {
			right: auto;
		}

		.bat-icon {
			color: $black;
			width: auto;

			&::before {
				font-size: 20px;
			}
		}

		.bat-card {
			&--blog {
				&-text {
					a {
						display: -webkit-box;
						overflow: hidden;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
		}

		.slick-dots {
			bottom: 0;
			flex-wrap: nowrap;
			justify-content: flex-start;
			position: relative;
			transition: all 0.25s;

			li {
				border-radius: 100%;
				height: 30px;
				margin-right: 8px;
				padding: 0;
				width: 30px;

				&:last-child {
					margin-right: 0;
				}

				&.nextpage,
				&.prevpage {
					display: block;
				}

				&.slick-active {
					background-color: $navy-dark;
					color: $white;

					button {
						color: $white;
					}
				}

				button {
					background-color: transparent;
					font-size: $font-sm;
					height: auto;
					margin: 0 auto;
					text-indent: 0%;
					width: 100%;
				}
			}
		}
	}
}

//---------------------- Zonnic Carousel that syncs with tabs
bat-carousel-zonnictabsync {
	.bat-carousel {
		.flex-wrap {
			display: flex;
			justify-content: space-between;
			padding-top: 8px;
			position: relative;
		}

		.slick-arrow {
			width: unset;
		}

		.slick-prev,
		.slick-next {
			display: block;
			left: unset;
			position: relative;
			right: unset;
			top: unset;
			transform: unset;
			width: unset;

			.bat-icon {
				color: $navy;
				width: unset;

				&:before {
					font-size: 16px;
					width: unset;
				}
			}
		}

		.slick-dots {
			align-content: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			padding-top: 12px;

			@include media-breakpoint-down(md) {
				padding-top: 0;
			}
		}
	}
}

.gtr-product-flavours {
	// .slick-track {
	// 	min-width: 100%;
	// }
	.slick-slide {
		padding-left: 1px;
		padding-right: 1px;
	}
	.bat-carousel {
		padding-left: 24px;
		padding-right: 24px;
		.slick-arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				display: block;
			}
		}
	}
	.bat-card {
		.bat-card--category {
			margin: 0;
			max-width: none;
			.bat-card--category-tile {
				margin-bottom: 5px;
				position: relative;
				padding-bottom: 22px;
				&:after {
					content: '';
					position: absolute;
					bottom: 0px;
					left: 0;
					right: 0;
					height: 13px;
					width: 105px;
					margin: 0 auto;
					opacity: 0;
					max-width: 100%;
					transition: opacity 0.4s ease;
					background: radial-gradient(
						50% 50% at 50% 50%,
						#d9d9d9 0%,
						rgba(217, 217, 217, 0) 100%
					);
				}

				a {
					display: block;
				}
				.bat-card--category-tile-image {
					height: 84px;
					display: flex;
					align-items: center;
					justify-content: center;
					picture {
						display: block;
						width: auto;
					}
					img {
						display: block;
						transition: max-width 0.4s ease, max-height 0.4s ease;
						max-width: 44px;
						max-height: 44px;
					}
				}
			}
			.bat-card--category-content {
				.bat-card--category-content-headline {
					padding: 0 10px;
					h4 {
						font-weight: normal;
						font-size: 15px;
						line-height: 1.2;
						transition: font-weight 0.4s ease;
						word-break: break-word;
						color: #2a2c2c;
					}
				}
				.bat-card--category-content-cta-list {
					margin: 0;
				}
			}
		}
	}
	.is-current {
		.bat-card {
			.bat-card--category {
				.bat-card--category-tile {
					&:after {
						opacity: 1;
					}
					.bat-card--category-tile-image {
						img {
							max-width: 84px;
							max-height: 84px;
						}
					}
				}

				.bat-card--category-content {
					.bat-card--category-content-headline {
						h4 {
							font-weight: 700;
						}
					}
				}
			}
		}
	}
	body:not(.airport-view) & {
		.slick-slide {
			@media (min-width: 992px) {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
		.bat-carousel {
			@include media-breakpoint-up(md) {
				max-width: 1265 + 80px;
				padding-left: 40px;
				padding-right: 40px;
				margin-left: auto;
				margin-right: auto;
				.slick-arrow {
					top: 56px;
					svg {
						width: 17px;
						height: auto;
						display: block;
					}
				}
			}
		}
		.bat-card {
			.bat-card--category {
				.bat-card--category-tile {
					@media (min-width: 768px) {
						padding-bottom: 29px;
						margin-bottom: 15px;
						&:after {
							width: 108px;
							height: 14px;
						}
					}
					.bat-card--category-tile-image {
						@media (min-width: 768px) {
							height: 112px;
							width: 112px;
							max-width: 100%;
							margin: 0 auto;
							img {
								max-width: 64px;
								max-height: 64px;
							}
						}
					}
				}
				.bat-card--category-content {
					.bat-card--category-content-headline {
						h4 {
							@media (min-width: 768px) {
								font-size: 18px;
								line-height: 22px;
							}
						}
					}
				}
			}
		}
		.is-current {
			.bat-card {
				.bat-card--category {
					.bat-card--category-tile {
						.bat-card--category-tile-image {
							img {
								@media (min-width: 768px) {
									max-width: 112px;
									max-height: 112px;
								}
							}
						}
					}
				}
			}
		}
	}
	body:not(.airport-view) & {

		&.in-store {
			.bat-carousel {
				max-width: 1711 + 80px;
				.slick-arrow {
					top: 75px;
					&.slick-prev {
						left: 10px;
					}
					&.slick-next {
						right: 10px;
					}
				}
			}
			.bat-card {
				.bat-card--category {
					.bat-card--category-tile {
						margin-bottom: 24px;
						padding-bottom: 39px;
	
						&:after {
							width: 150px;
							height: 25px;
						}
	
						.bat-card--category-tile-image {
							height: 150px;
							width: 150px;
							display: flex;
							align-items: center;
							justify-content: center;
							picture {
								display: block;
								width: auto;
							}
							img {
								display: block;
								transition: max-width 0.4s ease,
									max-height 0.4s ease;
								max-width: 86px;
								max-height: 86px;
							}
						}
					}
					.bat-card--category-content {
						.bat-card--category-content-headline {
							h4 {
								font-size: 24px;
								line-height: 29px;
							}
						}
					}
				}
			}
			.is-current {
				.bat-card {
					.bat-card--category {
						.bat-card--category-tile {
							.bat-card--category-tile-image {
								img {
									max-width: 150px;
									max-height: 150px;
								}
							}
						}
					}
				}
			}
		}
	}
}
.gtr-how-to-use {
	padding-left: 18px;
	@media (min-width: 577px) {
		padding-left: 0;
	}
	.bat-carousel {
		@media (min-width: 768px) {
			padding-left: 40px;
			padding-right: 40px;
		}
		@media (min-width: 1200px) {
			width: 1216px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding-left: 61px;
			padding-right: 61px;
		}
	}
	.slick-slide {
		padding-left: 7px;
		padding-right: 7px;
		@media (min-width: 1200px) {
			padding-left: 14px;
			padding-right: 14px;
		}
	}
	@media (max-width: 767px) {
		.card {
			width: 320px;
		}
	}
	@media (max-width: 576px) {
		.card {
			width: 242px;
		}
	}
	.bat-carousel .slick-arrow {
		top: (145px / 2);
		@include media-breakpoint-up(md) {
			top: 101px;
		}
	}
	.bat-card {
		.bat-card--category {
			margin: 0;
			max-width: none;
			.bat-card--category-tile {
				margin-bottom: 15px;
				a {
					display: block;
				}
				.bat-card--category-tile-image {
					height: 145px;
					position: relative;
					picture {
						display: block;
						width: auto;
					}
					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						display: block;
					}
				}
			}
			.bat-card--category-content {
				display: flex;
				.bat-card--category-content-headline {
					text-align: left;
					flex-shrink: 0;
					width: 22px;
					h4 {
						font-weight: 400;
						font-size: 15px;
						line-height: 150%;
					}
				}
				.bat-card--category-content-text {
					text-align: left;
					flex-grow: 1;
					ul,
					p,
					h1,
					h2,
					h3 {
						font-size: 15px;
						line-height: 1.5;
					}
				}
			}
			.bat-card--category-content-cta-list {
				margin-top: 0;
			}
			@media (min-width: 577px) {
				.bat-card--category-tile {
					.bat-card--category-tile-image {
						height: 192px;
					}
				}
			}
			@include media-breakpoint-up(md) {
				.bat-card--category-tile {
					margin-bottom: 20px;
					.bat-card--category-tile-image {
						height: 202px;
					}
				}
				.bat-card--category-content {
					.bat-card--category-content-headline {
						width: 29px;
					}
					h1,
					h2,
					h3 {
						margin-bottom: 11px;
					}
				}
			}
		}
	}
	body.airport-view & {
		@include media-breakpoint-up(md) {
			.bat-card
				.bat-card--category
				.bat-card--category-tile
				.bat-card--category-tile-image {
				height: 195px;
			}
		}
	}
}

.gtr-how-to-use.in-store,
.in-store .gtr-how-to-use {
	.bat-carousel {
		@media (min-width: 1920px) {
			width: 1642px + 120px;

			max-width: 100%;
			padding-left: 40px;
			padding-right: 40px;
			margin-left: auto;
			margin-right: auto;
		}
		.slick-arrow {
			top: 155.5px;
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
		}
	}
	.slick-slide {
		padding-left: 20px;
		padding-right: 20px;
	}
	.bat-card {
		.bat-card--category {
			.bat-card--category-tile {
				margin-bottom: 35px;
				.bat-card--category-tile-image {
					height: 311px;
					padding-top: 0;
					img {
						object-position: left center;
					}
				}
			}
			.bat-card--category-content {
				.bat-card--category-content-headline {
					width: 38px;
					h4 {
						font-size: 24px;
					}
				}
				.bat-card--category-content-text {
					h1,
					h2,
					h3 {
						margin-bottom: 19px;
						font-size: 24px;
						letter-spacing: 0.04em;
					}
					p,
					ul {
						font-size: 24px;
						letter-spacing: 0.04em;
					}
				}
			}
		}
	}
}

.slick-arrow {
	.bat-icon {
		margin: 0 auto;
	}
}
.velocard-carousel {
	.slick-arrow {
		@media (min-width: 768px) {
			svg {
				width: 17px;
				height: auto;
			}
		}
	}
}
.slick-arrow.slick-disabled {
	cursor: not-allowed;
	color: #cccccc;
	svg path {
		fill: #cccccc
	}
}

[dir='rtl'] {
	.bat-carousel .slick-arrow.slick-next {
		right: auto;
		left: 0;
		svg {
			transform: rotate(180deg);
		}
	}
	.bat-carousel .slick-arrow.slick-prev {
		left: auto;
		right: 0;
		svg {
			transform: rotate(180deg);
		}
	}
	.gtr-how-to-use.in-store .bat-carousel .slick-arrow.slick-next, 
	.in-store .gtr-how-to-use .bat-carousel .slick-arrow.slick-next {
		right: auto;
		left: 10px;
	}
	.gtr-how-to-use.in-store .bat-carousel .slick-arrow.slick-prev, 
	.in-store .gtr-how-to-use .bat-carousel .slick-arrow.slick-prev {
		left: auto;
		right: 10px;
	}
	.gtr-how-to-use .bat-card .bat-card--category .bat-card--category-content {
		.bat-card--category-content-headline,
		.bat-card--category-content-text {
			text-align: right;
		}
	}
}