//Variables
@import './global/variables';
@import './global/authoring';

//Grid
@import './grid/aem-grid';

//Base
@import './base/velo-fonts';
@import './base/gotham-fonts';
@import './base/icomoon';
@import './base/typography';

//Partials
@import './partials/headline';
@import './partials/text';
@import './partials/links';
@import './partials/buttons';
@import './partials/form';
@import './partials/backgrounds';
@import './partials/message';
@import './partials/modal';
@import './partials/navigation';
@import './partials/icon';
@import './partials/pagination';
@import './partials/quantity';
@import './partials/labels';
@import './partials/indicator';

//Components
// age-gate at the bottom, since form.scss was added
@import './components/account/account-overview';
@import './components/account/address-book';
@import './components/account/create-new-password';
@import './components/account/my-details';
@import './components/account/order-details';
@import './components/account/order-history';
@import './components/account/request-password-reset';
@import './components/account/saved-payments';
@import './components/batbreadcrumb';
@import './components/card';
@import './components/carousel';
@import './components/carousel-avalanche';
@import './components/checkout/basket-checkout';
@import './components/checkout/basket-summary';
@import './components/checkout/basket';
@import './components/countryselector';
@import './components/cta';
@import './components/cta-verticalNav';
@import './components/expander';
@import './components/footer';
@import './components/footer-zonnic';
@import './components/footer-gtr';
@import './components/footer-limited';
@import './components/footer-large';
@import './components/form';
@import './components/header';
@import './components/gtr-header';
@import './components/headline';
@import './components/hero';
@import './components/gtr-hero';
@import './components/gtr-em-signup';
@import './components/gtr-store-content';
@import './components/hero-exclusive';
@import './components/hero-narrow';
@import './components/hero-plp';
@import './components/htmlcontainer-pl-live-chat';
@import './components/locationselector-zonnic';
@import './components/minicart';
@import './components/messagebar';
@import './components/offers';
@import './components/pagination';
@import './components/productcard';
@import './components/productcard-avalanche';
@import './components/productcard-avalancheza';
@import './components/productcard-velo';
@import './components/productcard-zonnic';
@import './components/productfeatures';
@import './components/producthero';
@import './components/producthero-avalanche';
@import './components/producthero-avalancheza';
@import './components/producthero-zonnic';
@import './components/productlisting';
@import './components/productlisting-avalanche';
@import './components/productlisting-avalancheza';
@import './components/productlisting-zonnic';
@import './components/related-products';
@import './components/search';
@import './components/section';
@import './components/signup';
@import './components/sortfilter';
@import './components/sortfilter-zonnic';
@import './components/tabs';
@import './components/text';
@import './components/video';
@import './components/agegate';
@import './components/static-page';
@import './components/not-found';
@import './components/gtr-hero-section';
@import './components/gtr-card';
@import './components/gtr-form';
@import './components/gtr-image';
@import './components/gtr-em';

@import './pages/in-store_exclusive';
@import './pages/in-store_education';

@import 'pages/global-register-successful';

.hidden {
	display: none !important;
}

html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	background-color: $white;
	color: $black;
	text-rendering: optimizeLegibility;

	&.airport-view {
		max-width: 767px;
		margin-left: auto;
		margin-right: auto;
	}
	&:after {
		content: "";
		position: absolute;
		z-index: 50;
		background-color: #fff;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 100vh;
		display: none;
	}
	&.show-menu:after {
		display: block;
	}
	&:not(.authorMode).in-store {
		padding-left: 245px;
	}
	.bat-wrapper {
		overflow: hidden;

		@include media-breakpoint-up(lg) {

			.bat {
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: $max-width-xxl;
				width: 100%;

				&.full-width {
					max-width: none;
				}
			}

			bat-header-default,
			bat-header-avalanche,
			bat-header-limited,
			bat-footer-velo {

				&.bat {
					max-width: none;
				}
			}
		}

		@include media-breakpoint-up(lg) {

			.bat {
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: $max-width-xxl;

				&.full-width {
					max-width: none;
				}
			}

			bat-header-default,
			bat-header-avalanche,
			bat-header-limited,
			bat-footer-velo {

				&.bat {
					max-width: none;
				}
			}
		}
	}

	&.publishMode.exclusive-offer {
		display: none;
	}
	i {
		font-style: italic;
	}
	u {
		text-decoration: underline;
	}
}
*:focus {
	outline: none;
}
a[href="javascript:void(0)"] {
	cursor: auto;
}
a {
	outline: none;
	&:focus {
		outline: none;
	}
}


//content full height
@include media-breakpoint-up(md) {
	.bat-wrapper {
		> .root {
			> .aem-Grid {
				display: flex;
				flex-direction: column;
				min-height: 100vh;

				> .responsivegrid {
					flex-grow: 1;
					display: flex;
					flex-direction: column;

					> .aem-Grid {
						flex-grow: 1;

						> .aem-GridColumn:first-child  {
							display: flex;
							flex-direction: column;
							flex-grow: 1;

							> .content-full-height-section {
								display: flex !important;

								> .bat-section {
									display: flex;
									flex-grow: 1;

									> .bat-section-content {
										width: 100%;

										> .contentwrapper {
											height: 100%;
										}
									}
								}
							}
						}
					}
				}

				> .aem-GridColumn:last-child {
					margin-top: auto;
				}
			}
		}
	}
}
[dir="rtl"] {
	body:not(.authorMode).in-store {
		padding-left: 0;
		padding-right: 245px;
	}
}