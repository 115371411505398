//BUTTONS
// Create default variables

// sass-lint:disable space-after-comma
.bat-button--primary {
	@include root-prop(--text-color, #{$white});
	@include root-prop(--bg-color, #{$navy});
	@include root-prop(--border-color, #{$navy});
	@include root-prop(--bg-hover-color, #{$navy-dark});
	@include root-prop(--border-hover-color, #{$navy-dark});
	@include root-prop(--disabled-text-color, #{$concrete});
	@include root-prop(--disabled-bg-color, #{$whisper-gray});
	@include root-prop(--disabled-border-color, #{$whisper-gray});
}
.bat-button--dark {
	@include root-prop(--text-color, #{$white});
	@include root-prop(--bg-color, #{$navy});
	@include root-prop(--border-color, #{$navy});
	@include root-prop(--bg-hover-color, #{$navy-dark});
	@include root-prop(--border-hover-color, #{$navy-dark});
	@include root-prop(--disabled-text-color, #{$concrete});
	@include root-prop(--disabled-bg-color, #{$whisper-gray});
	@include root-prop(--disabled-border-color, #{$whisper-gray});
}

// sass-lint:disable space-after-comma
.bat-button--light {
	@include root-prop(--text-color, #{$navy});
	@include root-prop(--bg-color, #{$white});
	@include root-prop(--border-color, #{$white});
	@include root-prop(--bg-hover-color, #{$light-blue});
	@include root-prop(--border-hover-color, #{$light-blue});
	@include root-prop(--disabled-text-color, #{$mid-gray});
	@include root-prop(--disabled-bg-color, #{$mid-gray});
	@include root-prop(--disabled-border-color, #{$pale-gray});
}

// sass-lint:disable space-after-comma
.bat-button--secondary--dark {
	@include root-prop(--text-color, #{$navy});
	@include root-prop(--bg-color, transparent);
	@include root-prop(--border-color, #{$navy});
	@include root-prop(--bg-hover-color, #{$navy-dark});
	@include root-prop(--disabled-text-color, #{$concrete});
	@include root-prop(--disabled-bg-color, transparent);
	@include root-prop(--disabled-border-color, #{$concrete});
}

// sass-lint:disable space-after-comma
.bat-button--secondary--light {
	@include root-prop(--text-color, #{$white});
	@include root-prop(--bg-color, transparent);
	@include root-prop(--border-color, #{$white});
	@include root-prop(--text-hover-color, #{$light-blue});
	@include root-prop(--border-hover-color, #{$light-blue});
	@include root-prop(--disabled-text-color, #{$concrete});
	@include root-prop(--disabled-bg-color, transparent);
	@include root-prop(--disabled-border-color, #{$concrete});
}

// Create dark mode variables
.dark-mode {
	.bat-button--dark {
		@include root-prop(--text-color, #{$navy});
		@include root-prop(--bg-color, #{$white});
		@include root-prop(--border-color, #{$white});
		@include root-prop(--bg-hover-color, #{$light-blue});
		@include root-prop(--border-hover-color, #{$light-blue});
		@include root-prop(--disabled-text-color, #{$white});
		@include root-prop(--disabled-bg-color, #{$concrete});
		@include root-prop(--disabled-border-color, #{$concrete});
	}
	.bat-button--light {
		@include root-prop(--text-color, #{$navy});
		@include root-prop(--bg-color, #{$white});
		@include root-prop(--border-color, #{$white});
		@include root-prop(--bg-hover-color, #{$light-blue});
		@include root-prop(--border-hover-color, #{$light-blue});
		@include root-prop(--disabled-text-color, #{$white});
		@include root-prop(--disabled-bg-color, #{$concrete});
		@include root-prop(--disabled-border-color, #{$concrete});
	}
	.bat-button--secondary--dark {
		@include root-prop(--text-color, #{$white});
		@include root-prop(--bg-color, transparent);
		@include root-prop(--border-color, #{$white});
		@include root-prop(--text-hover-color, #{$light-blue});
		@include root-prop(--border-hover-color, #{$light-blue});
		@include root-prop(--disabled-text-color, #{$concrete});
		@include root-prop(--disabled-bg-color, transparent);
		@include root-prop(--disabled-border-color, #{$concrete});
	}
}

// These properties should match those in .dark-mode
// @media (prefers-color-scheme: dark) {
// 	.bat-button--dark {
// 		@include root-prop(--text-color, #{$navy});
// 		@include root-prop(--bg-color, #{$white});
// 		@include root-prop(--bg-hover-color, #{$light-blue});
// 		@include root-prop(--border-color, #{$white});
// 		@include root-prop(--border-hover-color, #{$light-blue});
// 		@include root-prop(--disabled-text-color, #{$white});
// 		@include root-prop(--disabled-bg-color, #{$concrete});
// 		@include root-prop(--disabled-border-color, #{$concrete});
// 	}
// 	.bat-button--light {
// 		@include root-prop(--text-color, #{$white});
// 		@include root-prop(--bg-color, #{$navy});
// 		@include root-prop(--bg-hover-color, #{$navy-dark});
// 		@include root-prop(--border-color, #{$navy});
// 		@include root-prop(--border-hover-color, #{$navy-dark});
// 		@include root-prop(--disabled-text-color, #{$mid-gray});
// 		@include root-prop(--disabled-bg-color, #{$pale-gray});
// 		@include root-prop(--disabled-border-color, #{$pale-gray});
// 	}
// 	.bat-button--secondary--dark {
// 		@include root-prop(--text-color, #{$white});
// 		@include root-prop(--bg-color, transparent);
// 		@include root-prop(--border-color, #{$white});
// 		@include root-prop(--text-hover-color,#{$light-blue});
// 		@include root-prop(--border-hover-color, #{$light-blue});
// 		@include root-prop(--disabled-text-color, #{$concrete});
// 		@include root-prop(--disabled-bg-color, transparent);
// 		@include root-prop(--disabled-border-color, #{$concrete});
// 	}
// }

%button-base {
	@include font-rem(15px, 16px);
	border-radius: 40px;
	font-family: $primary-font;
	letter-spacing: 0.075rem;
	max-width: 300px;
	overflow: hidden;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	padding: 15px 30px;
	@include media-breakpoint-up(lg) {
		padding: 13px 30px;
		line-height: normal;
	}
	&.icon-left {
		padding: 13px 38px 13px 28px;

		i {
			margin-right: 5px;
		}
	}

	&.icon-right {
		padding: 13px 28px 13px 38px;

		i {
			margin-left: 5px;
		}
	}

	i,
	span {
		position: relative;
		z-index: 1;
	}

	span {
		display: inline-block;
		vertical-align: top;
	}

	&:hover {
		text-decoration: none;

		span {
			text-decoration: none;
		}
	}

	i {
		height: 18px;
		width: 18px;

		&:before {
			font-size: 18px;
		}
	}
}

.bat-button {
	@extend %button-base;

	//-----------------------------------------------------------------| Button Dark (Default Color Dark)

	&--dark {
		min-height: 50px;
		@include media-breakpoint-up(lg) {
			min-height: 45px;
		}
		background: $system-blue;
		color: $white;
		position: relative;
		z-index: 0;
		overflow: hidden;
		border: none;
		&:after {
			content: '';
			position: absolute;
			pointer-events: none;
			top: -6px;
			right: -23px;
			width: 60px;
			z-index: -1;
			bottom: -17px;
			background: radial-gradient(
					90.43% 217.86% at 90.91% 119.15%,
					rgba(180, 250, 255, 0.9) 0%,
					rgba(100, 158, 250, 0.8) 36.82%,
					rgba(0, 71, 184, 0) 100%
				),
				#0047b8;
			transform: rotate(23deg);
			transition: opacity 0.5s ease;
		}
		span {
			transition: color 0.5s ease;
		}

		i {
			svg {
				fill: var(--text-color);
			}
		}
		&:hover {
			background: $system-blue;
			&:after {
				opacity: 0;
			}
			span {
				color: var(--text-color);
			}

			i {
				svg {
					fill: var(--text-color);
				}
			}
		}
		&:focus,
		&:focus-visible {
			outline: var(--border-color) solid 1px;
			outline-offset: 5px;
		}
		&--green {
			background-color: $system-green;

			&.bat-cta-style {
				background-color: $system-green !important;
				transition: none;
			}

			&:after {
				background: linear-gradient(
						116.74deg,
						rgba(0, 150, 129, 0) 68.98%,
						rgba(65, 230, 200, 0.65) 85.81%,
						rgba(180, 255, 224, 0.9) 102.47%
					),
					#028472;
				transform: none;
				right: -16px;
				bottom: 0;
				width: auto;
				left: 0;
				top: 0;
			}
			&:hover {
				background-color: #028472;
			}
		}
		&.disabled,
		&:disabled {
			// Do not use CSS vars here; breaks 'button dark disabled' style
			background: $whisper-gray;
			color: $concrete;
			border: solid 2px #cfcfcf;
			&:after {
				opacity: 0;
			}
		}
	}
	//-----------------------------------------------------------------| Button Light
	&--light {
		min-height: 50px;
		@include media-breakpoint-up(lg) {
			min-height: 45px;
		}
		background: #d7490d;
		color: $white;
		position: relative;
		z-index: 0;
		overflow: hidden;
		border: none;
		&:after {
			content: '';
			position: absolute;
			pointer-events: none;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: opacity 0.5s ease;
			background: linear-gradient(
				140deg,
				rgba(215, 73, 13, 1) 0%,
				rgba(215, 73, 13, 1) 70%,
				rgba(255, 206, 136, 1) 88%,
				rgba(255, 255, 255, 1) 100%
			);
		}
		span {
			transition: color 0.5s ease;
		}

		i {
			svg {
				fill: var(--text-color);
			}
		}
		&:hover {
			background: #d7490d;
			&:after {
				opacity: 0;
			}
			span {
				color: #fff;
			}

			i {
				svg {
					fill: #fff;
				}
			}
		}
		&:focus,
		&:focus-visible {
			outline: var(--border-color) solid 1px;
			outline-offset: 5px;
		}

		&.disabled,
		&:disabled {
			// Do not use CSS vars here; breaks 'button dark disabled' style
			background: $whisper-gray;
			color: $concrete;
			border: solid 2px #cfcfcf;
			&:after {
				opacity: 0;
			}
		}
	}

	//-----------------------------------------------------------------| Button Dark Secondary
	&--secondary--dark {
		background-color: transparent;
		border: solid 2px var(--border-color);
		color: var(--text-color);
		transition: border 0.5s ease, color 0.5s ease;

		i {
			svg {
				fill: var(--text-color);
			}
		}
		&:after {
			content: none;
		}
		&:hover {
			background-color: transparent;
			color: var(--text-hover-color);

			span {
				color: var(--text-hover-color);
			}

			i {
				svg {
					fill: var(--text-hover-color);
				}
			}
		}

		&:focus,
		&:focus-visible {
			outline: var(--border-color) solid 1px;
			outline-offset: 5px;
		}

		&.disabled,
		&:disabled {
			border: solid 2px var(--disabled-border-color);
			color: var(--disabled-text-color);
		}
	}

	//-----------------------------------------------------------------| Button Light Secondary
	&--secondary--light {
		background-color: #fff;
		border: solid 2px #fff;
		color: #ff8000;
		transition: background-color 0.5s ease, border 0.5s ease,
			color 0.5s ease;
		&:after {
			content: none;
		}
		i {
			svg {
				fill: $white;
			}
		}
		span {
			color: inherit;
		}

		&:hover {
			background-color: #fff;
			border: solid 2px #ff8000;

			color: #ff8000;
			i {
				svg {
					fill: #ff8000;
				}
			}
			span {
				color: inherit;
			}
		}

		&:focus,
		&:focus-visible {
			outline: var(--border-color) solid 1px;
			outline-offset: 5px;
		}

		&.disabled,
		&:disabled {
			border-color: #fff;
			color: #ff8000;
			background-color: #fff;
			opacity: 0.5 !important;
			cursor: not-allowed;
		}
	}

	//-----------------------------------------------------------------| Button Disabled
	&.disabled,
	&:disabled {
		opacity: 1;
	}
}

.bat-cta-center {
	.bat-cta {
		text-align: center;
	}
}
.bat-cta-button-big {
	.bat-cta-style {
		width: 275px;
		max-width: 100%;

		@include media-breakpoint-up(md) {
			width: 100%;
			max-width: 400px;
		}
	}
}

.in-store {
	.bat-button {
		font-size: 24px;
	}
	.bat-button--dark {
		min-height: 70px;
		line-height: 40px;
	}
}
