//grid content under card
.grid-content-edu__section {
  .acs-commons-resp-colctrl-row {
    .acs-commons-resp-colctrl-col-50 {
      text-align: center;
      padding: 0 15px;

      .text.section {
        letter-spacing: $letter-spacing_base;

        & + .image.section {
          margin: 40px auto;

        }
      }

    }
  }

  .bat-section-content {
    [class*="logo-"] {
      margin: 0 auto;
    }

    .logo-velo {
      max-width: 206px;

      @include media-breakpoint-down(sm) {
        max-width: 151px;
      }
    }

    .logo-glo {
      max-width: 119px;

      @include media-breakpoint-down(sm) {
        max-width: 90px;
      }
    }

    .logo-vuse {
      max-width: 213px;

      @include media-breakpoint-down(sm) {
        max-width: 145px;
      }
    }
  }


  &.in-store {
    .bat-section-content {
      .logo-velo {
        max-width: 243px;
      }

      .logo-glo {
        max-width: 146px;
      }

      .logo-vuse {
        max-width: 232px;
      }
    }
  }
}

//below hero banner
.heading-edu-content__section {
  .bat-section-content {
    .title {
      margin-bottom: 20px;
    }

    .text {
      letter-spacing: $letter-spacing_base;
    }
  }
}

@include media-breakpoint-up(md) {
  //grid content under card
  .grid-content-edu__section {
    .acs-commons-resp-colctrl-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-width: 1130px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      .acs-commons-resp-colctrl-col-50 {
        width: 60%;

        & + .acs-commons-resp-colctrl-col-50 {
          width: 40%;

        }

        .text.section {
          & + .image.section {
            margin: 40px auto;
          }
        }
      }
    }

    &.revert {
      .acs-commons-resp-colctrl-row {
        flex-direction: row-reverse;
      }
    }

    .bat-section-content {
      .bat-cta-button-big .bat-cta-style {
        max-width: 365px;
      }
    }

    &.in-store {
      .acs-commons-resp-colctrl-row {
        max-width: 1590px;
      }

      .bat-cta-button-big .bat-cta-style {
        max-width: 442px;
      }
    }
  }


  //below hero banner
  .heading-edu-content__section {
    padding: 67px 0 50px;

    .bat-section-content {
      max-width: 615px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    &.in-store {
      padding: 144px 0 40px;

      .bat-section-content {
        max-width: 920px;
      }
    }
  }
  
}

@include media-breakpoint-down(sm) {
  .heading-edu-content__section {
    padding: 45px 0;

    .bat-section-content {
      width: 100%;
    }
  }
}