.bat-video {
	&-content {
		margin: 0 auto;

		&-video {
			display: block;
			max-width: 100%;
			position: relative;

			picture {
				&:after {
					color: $white;
					content: '\f144';
					font-family: 'Font Awesome 5 Free';
					@include font-rem(96px, 96px);
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					transition: color .5s ease-in;
				}
				
				&:before {
					background-color: $black;
					border-radius: 50%;
					color: $black;
					content: '';
					height: 89px;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					transition: background-color.5s ease-in;
					width: 89px;
				}

				&:hover {
					cursor: pointer;
					
					&:after {
						color: $black;
					}
					
					&:before {
						background-color: $white;
					}
				}
			}

			&-wrapper {
				padding-top: 56.25%;
			}
        }
	}
}
