.bat-quantity {
	justify-content: flex-start;
	margin: 0;
	padding: 0 0 10px;

	&-label {
		font-weight: 700;
		@include font-rem(15px, 15px);
		margin: 0 10px 0 0;
	}

	&-field {
		@include font-rem(12px, 12px);
		border: 0;
		color: $navy;
		height: 20px;
		padding: 15px 0;
		width: 35px;
	}

	&-button {
		align-items: center;
		background-color: $concrete;
		border-radius: 100px;
		display: flex;
		flex: 0 0 28px;
		height: 28px;
		justify-content: center;
		margin: 0;
		min-width: 28px;
		padding: 0;
		transition: background-color .5s ease;

		&:hover {
			background-color: $navy;

			i {

				&:before {
					color: $white;
				}
			}
		}

		&--minus {
			border: 0;
			position: relative;
			z-index: 1;
		}

		&--plus {
			border: 0;
			position: relative;
			z-index: 1;
		}

		i {

			&:before {
				color: $navy;
				font-size: 18px;
				transition: color .5s ease;
			}
		}

		//-----------------------------------------------------------------| Button Disabled
		&.disabled,
		&:disabled {
			background-color: $concrete;
			pointer-events: none;

			i {

				&:before {
					color: $gray;
				}
			}
		}
	}
}
