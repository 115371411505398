.bat-headline {
	
	&-style {
		
		&.headline1-large,
		&.headline1-large-light,
		&.headline1-large-color1 {
			@extend .headline1-large;
		}
		
		&.headline1,
		&.headline1-light,
		&.headline1-color1 {
			@extend .headline1;
		}
		
		&.headline2,
		&.headline2-light,
		&.headline2-color1  {
			@extend .headline2;
		}
		
		&.headline3,
		&.headline3-light,
		&.headline3-color1  {
			@extend .headline3;
		}
		
		&.headline4,
		&.headline4-light,
		&.headline4-color1  {
			@extend .headline4;
		}
		
		&.headline5,
		&.headline5-light,
		&.headline5-color1  {
			@extend .headline5;
		}
		
		&.headline6,
		&.headline6-light,
		&.headline6-color1  {
			@extend .headline6;
		}
		
		&.headline1-large-light,
		&.headline1-light,
		&.headline2-light,
		&.headline3-light,
		&.headline4-light,
		&.headline5-light,
		&.headline6-light {
			color: $white;
		}
		
		&.headline1-large-color1,
		&.headline1-color1,
		&.headline2-color1,
		&.headline3-color1,
		&.headline4-color1,
		&.headline5-color1,
		&.headline6-color1 {
			color: $gray;
		}
	}
}
