.bat-gtr-footer {
	background-color: #000000;
	color: #fff;
	padding: 10px 10px 16px;
	position: relative;
	z-index: 0;
	max-width: 100% !important;
	@media (max-width: 767px) {
		padding-top: 22px;
		padding-bottom: 15px;
	}
	&::before {
		z-index: -1;
		content: '';
		left: 0;
		background-color: #000;
		right: 0;
		top: -29px;
		height: 30px;
		position: absolute;
		clip-path: polygon(100% 0, 0% 100%, 100% 100%);
	}
	ul {
		list-style: none;
		padding: 0;
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: center;
		margin-bottom: 10px;
		
		li {
			padding: 0 10px;
			margin: 5px 0 0;

			& + li {
				border-left: 1px solid #fff;
			}
			a {
				display: block;
				font-weight: 400;
				font-size: 15px;
				line-height: 18px;
				text-align: center;
				letter-spacing: $letter-spacing_s;
				@media screen and (orientation: landscape) {
					font-size: 12px;
				}
				@media (max-width: 767px) {
					font-size: 12px;
					line-height: 14px;
				}
			}
		}
		@media (max-width: 767px) {
			margin-bottom: 12px;
			li {
				margin-top: 0;
			}
		}
	}
	.copyright {
		font-weight: 700;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: $letter-spacing_s;
		color: #ffffff;
		margin-bottom: 0;
		text-align: center;
		p {
			@media screen and (orientation: landscape) {
				font-size: 12px;
			}
			@media (max-width: 767px) {
				text-align: center !important;
				font-size: 12px;
				line-height: 14px;
				margin-left: 30px;
				margin-right: 30px;
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

//IN-STORE & GLOBAL

body .bat-wrapper bat-footer-gtr.bat {
	max-width: 100%;
}
body:not(.airport-view) {
	@include media-breakpoint-up(md) {
		.bat-gtr-footer {
			.in-store & {
				padding-top: 0;
				padding-bottom: 22px;
			}

			&-container {
				max-width: 1920px;
				margin: 0 auto;
				display: flex;
				align-items: flex-end;

				> ul {
					margin-left: auto;
					margin-bottom: 0;
					text-align: left;

					li {
						& + li {
							border-left: none;
						}
					}
				}

				.copyright {
					order: -1;
					padding-right: 10px;
					text-align: left;

					p {
						margin-bottom: 0;
					}
				}

				> ul li a,
				.copyright,
				.copyright p {
					font-size: 13px;
					line-height: 15.6px;
				}
			}

			&:before {
				clip-path: polygon(0 0, 0% 100%, 100% 100%);
			}
		}

		.in-store {
			.bat-gtr-footer {
				padding-left: 70px;
				padding-right: 70px;
				ul {
					li {
						padding: 0 20px;

						a {
							font-size: 16px;
							line-height: 19px;
							letter-spacing: $letter-spacing_base;
						}
					}
				}

				.copyright {
					&,
					p {
						font-size: 16px;
						line-height: 19px;
						letter-spacing: 0.04em;
					}

					p {
						letter-spacing: $letter-spacing_base;
					}
				}
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.bat-gtr-footer {
			padding-left: 50px;
			padding-right: 50px;

			&.in-store {
				padding-left: 70px;
				padding-right: 70px;
			}
			.copyright {
				max-width: 42%;
			}
		}
	}
	.in-store {
		.bat-gtr-footer {
			.copyright {
				max-width: 54%;
			}
		}
	}
}
.active {
	.footer {
		margin-top: auto;
		width: 100%;
		.in-store {
			.bat-gtr-footer-container {
				ul li a {
					font-size: 16px;
					line-height: 19px;
				}
				.copyright p {
					font-size: 16px;
					line-height: 19px;
				}
			}
		}
		.bat-gtr-footer {
			position: relative;
			ul li a {
				@media (max-width: 767px) {
					font-size: 12px;
				}
			}
			&::before {
				z-index: -1;
				content: '';
				left: 0;
				background-color: #000;
				right: 0;
				top: -29px;
				height: 30px;
				position: absolute;
				clip-path: polygon(0% 0, 0% 100%, 100% 100%);
				@media (max-width: 767px) {
					clip-path: polygon(100% 0, 0% 100%, 100% 100%);
				}
			}
			.bat-gtr-footer-container {
				.copyright {
					p {
						@media (max-width: 767px) {
							font-size: 12px;
							margin-left: 30px;
							margin-right: 30px;
						}
					}
				}
			}
		}
	}
}

body:not(.airport-view) {
	.active {
		.footer {
			.bat-gtr-footer {
				&::before {
					@media (max-width: 767px) {
						clip-path: polygon(100% 0, 0% 100%, 100% 100%);
					}
				}
			}
		}
	}
}
.footer .mclaren-footer .bat-gtr-footer {
	padding: 20px 10px 18px;
	&:before {
		height: 19px;
		top: -18px;
	}
	ul {
		li {
			a {
				font-family: 'Gotham';
				font-size: 13px;
				line-height: 16px;
			}
		}
	}
	.copyright {
		letter-spacing: normal;
		p {
			font-family: 'Gotham';
			font-style: normal;
			
			font-size: 13px;
			line-height: 18px;
			b {
				font-family: 'Gotham';
				font-size: 11px;
				line-height: 13px;
				display: inline-block;
			}
			margin-bottom: 8px;
			&:last-child {
				margin-bottom: 0;
			}
			
		}
	}

}
[dir='rtl'] {
	.bat-gtr-footer {
		ul {
			li + li {
				border-left: none;
				border-right: 1px solid #fff;
			}
		}
	}
	body:not(.airport-view) {
		@media (min-width: 768px) {
			.bat-gtr-footer-container > ul {
				margin-left: 0;
				margin-right: auto;
			}
			.bat-gtr-footer-container .copyright {
				text-align: right;
				padding-left: 10px;
				padding-right: 0;
			}
		}
	}
}
