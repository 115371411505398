
.bat-productlistings {
	display: flex;
	flex-direction: row;
	justify-content: center;
	&.carousel {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	&-pagination {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		&.carousel {
			align-items: center;
			width: initial;
		}
		&-buttons {
			align-items: center;
			display: flex;
			flex-basis: 50%;
			flex-grow: 1;
			flex-wrap: wrap;
			justify-content: center;
			.pagination-button {
				margin: 0 .5rem .5rem;
				&.active {
					background-color: $gray;
				}
			}
		}
		&-label {
			font-size: .85rem;
		}
	}
	&-cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}
	&-card {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 2rem;
		position: relative;
		width: 400px;
		&-image {
			text-align: center;
			img {
				height: 10rem;
				padding-right: 1rem;
				width: auto;
			}
		}
		&-details {
			text-align: center;
			.selected-color {
				font-size: .85rem;
				font-style: italic;
				line-height: 1.5;
				margin-bottom: 1rem;
			}
		}
		&-name {
			font-size: 1rem;
			line-height: 1;
			margin-bottom: 1rem;
			a {
				text-decoration: none;
			}
		}
		&-prices {
			line-height: 1;
			margin-bottom: 1rem;
			vertical-align: middle;
			.base-price {
				margin-right: .5rem;
				&.has-sale-price {
					font-size: .85rem;
					text-decoration: line-through;
				}
			}
		}
		&-description {
			font-size: .85rem;
			font-style: italic;
			line-height: 1.5;
			margin-bottom: 1rem;
			text-align: left;
		}
		&-buttons {
			.notify-button,
			.options-button,
			.cart-button {
				display: initial;
				margin-bottom: 1rem;
				max-width: initial;
				width: 100%;
			}
		}
		&-stock {
			font-size: .85rem;
			font-style: italic;
		}
		&-attributes {
			margin-bottom: 1rem;
			progress {
				display: block;
				width: 100%;
			}
		}
		&-colors {
			margin-bottom: 1rem;
			button {
				border: 1px solid;
				margin: .25rem;
				padding: .25rem;
				&.active {
					border: 1px $red dotted;
				}
			}
		}
		&-message {
			align-items: center;
			background-color: $off-white;
			border-radius: .5rem;
			display: none;
			flex-direction: row;
			justify-content: flex-start;
			padding: 1rem;
			.message {
				font-size: .85rem;
				max-width: 80%;
				padding: 1rem;
				text-align: left;
			}
			button {
				align-items: center;
				background-color: $black;
				border-radius: 50%;
				color: $white;
				display: flex;
				font-size: 1.15rem;
				height: 1.25rem;
				justify-content: center;
				width: 1.25rem;
				* {
					line-height: 0;
				}
			}
		}
	}
	&-loading {
		padding: 2rem;
	}
	&-noresults {
		padding: 2rem;
	}
}
