.bat-messagebar {
	
	//-----------------------------------------------------------------| Default
	&--default {
		display: none;
		
		&.active {
			display: block;
			margin-bottom: 20px;
		}
		
		&-message {
			background-color: $off-white;
			display: none;
			padding: 20px;
			text-align: center;
			
			&.active {
				display: block;
			}
			
			p {
				display: inline;
			}
		}
		
		&-message-error {
			background-color: $red;
			display: none;
			padding: 20px;
			text-align: center;

			&.active {
				display: block;
			}
			
			p {
				color: $white;
				display: inline;
				text-transform: uppercase;
			}
		}
	}

}
