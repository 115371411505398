// sass-lint:disable no-duplicate-properties
// sass-lint:disable indentation
@font-face {
	font-display: block;
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/icomoon/icomoon.eot?mgh1hd');
	src: url('../fonts/icomoon/icomoon.eot?mgh1hd#iefix') format('\embedded-opentype'),
		url('../fonts/icomoon/icomoon.ttf?mgh1hd') format('truetype'),
		url('../fonts/icomoon/icomoon.woff?mgh1hd') format('woff'),
		url('../fonts/icomoon/icomoon.svg?mgh1hd#icomoon') format('svg');
}
// sass-lint:disable indentation
.icon,
[class^='icon-'],
[class*=' icon-'] {

	&::before {
		/* use !important to prevent issues with browser extensions that change fonts */
		// sass-lint:disable no-important
		font-family: 'icomoon' !important;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1;
		speak: none;
		text-transform: none;
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

// sass-lint:disable force-pseudo-nesting
.icon-account:before {
	content: '\e926';
}

.icon-arrow-down:before {
	content: '\e93f';
}

.icon-arrow-up:before {
	content: '\e940';
}

.icon-arrow-left:before {
	content: '\e941';
}

.icon-arrow-right:before {
	content: '\e942';
}

.icon-bag-add:before {
	content: '\e929';
}

.icon-bag-empty:before {
	content: '\e929';
}

.icon-bag:before {
	content: '\e929';
}

.icon-calendar:before {
	content: '\e92c';
}

.icon-chat:before {
	content: '\e912';
}

.icon-checkbox-off:before {
	content: '\e952';
}

.icon-checkbox-on:before {
	content: '\e952';
}

.icon-chevron-down:before {
	content: '\e95a';
}

.icon-chevron-left:before {
	content: '\e93e';
}

.icon-chevron-right:before {
	content: '\e93d';
}

.icon-chevron-up:before {
	content: '\e95b';
}

.icon-close:before {
	content: '\e952';
}

.icon-credit-card:before {
	content: '\e952';
}

.icon-cross:before {
	content: '\e952';
}

.icon-delivery:before {
	content: '\e924';
}

.icon-eye-off:before {
	content: '\e968';
}

.icon-eye-on:before {
	content: '\e96a';
}

.icon-facebook:before {
	content: '\e905';
}

.icon-filter:before {
	content: '\e943';
}

.icon-flag:before {
	content: '\e952';
}

.icon-flexibility:before {
	content: '\e952';
}

.icon-heart:before {
	content: '\e90f';
}

.icon-info:before {
	content: '\e92e';
}

.icon-instagram:before {
	content: '\e903';
}

.icon-lock:before {
	content: '\e94f';
}

.icon-map-pin:before {
	content: '\e952';
}

.icon-menu:before {
	content: '\e90c';
}

.icon-minus:before {
	content: '\e958';
}

.icon-new:before {
	content: '\e920';
}

.icon-no-search:before {
	content: '\e92f';
}

.icon-offers:before {
	content: '\e952';
}

.icon-personalisation:before {
	content: '\e969';
}

.icon-phone:before {
	content: '\e95f';
}

.icon-plus:before {
	content: '\e959';
}

.icon-products:before {
	content: '\e952';
}

.icon-quality:before {
	content: '\e917';
}

.icon-radio-off:before {
	content: '\e952';
}

.icon-radio-on:before {
	content: '\e952';
}

.icon-recycling:before {
	content: '\e951';
}

.icon-search:before {
	content: '\e91d';
}

.icon-shield:before {
	content: '\e923';
}

.icon-sort:before {
	content: '\e96c';
}

.icon-star:before {
	content: '\e955';
}

.icon-subscriptions:before {
	content: '\e965';
}

.icon-tick:before {
	content: '\e952';
}

.icon-trophy:before {
	content: '\e916';
}

.icon-twitter:before {
	content: '\e904';
}

.icon-velo:before {
	content: '\e952';
}

.icon-wallet:before {
	content: '\e930';
}

.icon-youtube:before {
	content: '\e902';
}

// Below are all the new icon selectors added with Zonnic
.icon-email:before {
	content: '\e900';
}

.icon-whatsapp:before {
	content: '\e901';
}

.icon-sound-playing:before {
	content: '\e906';
}

.icon-play-backward:before {
	content: '\e907';
}

.icon-sound-muted:before {
	content: '\e908';
}

.icon-play-forward:before {
	content: '\e909';
}

.icon-pause:before {
	content: '\e90a';
}

.icon-play:before {
	content: '\e90b';
}

.icon-wide-product-range:before {
	content: '\e90d';
}

.icon-pouches:before {
	content: '\e90e';
}

.icon-map:before {
	content: '\e910';
}

.icon-mail:before {
	content: '\e911';
}

.icon-heart-filled:before {
	content: '\e913';
}

.icon-check:before {
	content: '\e914';
}

.icon-tested:before {
	content: '\e915';
}

.icon-shield-1:before {
	content: '\e918';
}

.icon-zoom-in:before {
	content: '\e919';
}

.icon-zoom-out:before {
	content: '\e91a';
}

.icon-check-circle-outlined:before {
	content: '\e91b';
}

.icon-check-circle-filled:before {
	content: '\e91c';
}

.icon-help:before {
	content: '\e91e';
}

.icon-sustainability:before {
	content: '\e91f';
}

.icon-flavours:before {
	content: '\e921';
}

.icon-stores:before {
	content: '\e922';
}

.icon-van:before {
	content: '\e925';
}

.icon-configure:before {
	content: '\e927';
}

.icon-verify:before {
	content: '\e928';
}

.icon-bookmark:before {
	content: '\e92a';
}

.icon-card:before {
	content: '\e92b';
}

.icon-error:before {
	content: '\e92d';
}

.icon-sale:before {
	content: '\e931';
}

.icon-tag:before {
	content: '\e932';
}

.icon-folder:before {
	content: '\e933';
}

.icon-file:before {
	content: '\e934';
}

.icon-gift:before {
	content: '\e935';
}

.icon-delete:before {
	content: '\e936';
}

.icon-edit:before {
	content: '\e937';
}

.icon-disabled:before {
	content: '\e938';
}

.icon-link:before {
	content: '\e939';
}

.icon-at-sign:before {
	content: '\e93a';
}

.icon-clock:before {
	content: '\e93b';
}

.icon-attach:before {
	content: '\e93c';
}

.icon-resize:before {
	content: '\e944';
}

.icon-loading:before {
	content: '\e945';
}

.icon-refresh:before {
	content: '\e946';
}

.icon-camera:before {
	content: '\e947';
}

.icon-chart-bar:before {
	content: '\e948';
}

.icon-thumbs-down:before {
	content: '\e949';
}

.icon-thumbs-up:before {
	content: '\e94a';
}

.icon-microphone:before {
	content: '\e94b';
}

.icon-chart-pie:before {
	content: '\e94c';
}

.icon-send:before {
	content: '\e94d';
}

.icon-unlocked:before {
	content: '\e94e';
}

.icon-language:before {
	content: '\e950';
}

.icon-alert-info:before {
	content: '\e953';
}

.icon-cookies:before {
	content: '\e954';
}

.icon-notification-filled:before {
	content: '\e956';
}

.icon-notification:before {
	content: '\e957';
}

.icon-cog:before {
	content: '\e95c';
}

.icon-note:before {
	content: '\e95d';
}

.icon-wifi:before {
	content: '\e960';
}

.icon-cast:before {
	content: '\e961';
}

.icon-cut:before {
	content: '\e962';
}

.icon-megaphone:before {
	content: '\e963';
}

.icon-image:before {
	content: '\e964';
}

.icon-copy:before {
	content: '\e966';
}

.icon-backspace:before {
	content: '\e967';
}

.icon-share:before {
	content: '\e96b';
}

.icon-export:before {
	content: '\e96d';
}
