.bat-card {

	//-----------------------------------------------------------------| Default
	&--default {

		&-tile {
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			&:after {
				bottom: 0;
				color: $system-red;
				content: '\e90f';
				font-family: 'icomoon';
				@include font-rem(25px, 25px);
				position: absolute;
				right: 0;
				transition: all .3s ease;
				z-index: 3;
			}

			&:hover {
				&:after {
					color: $system-red;
					font-weight: 700;
				}
			}

			a {
				border: 2px solid $system-red;
				transform: translate3d(0, 0, 0);
				transition: border .3s ease 0s;

				&:before {
					border: 2px solid transparent;
					bottom: 0;
					content: '';
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					transition: border-top .3s ease;
					z-index: 1;
				}

				&:after {
					border-top: 2px solid $system-red;
					content: '';
					display: block;
					height: 90px;
					position: absolute;
					right: -10px;
					top: calc(100% - 38px);
					transform: skew(10deg, -25deg);
					transition: border-top .3s ease;
					width: 180px;
					z-index: 2;

				}

				@include media-breakpoint-up(lg) {

					&:hover {
						border-color: $system-red;

						&:before {
							border-color: $system-red;
						}

						&:after {
							border-top: 4px solid $system-red;
						}
					}
				}
			}

			&-headline {
				min-height: 74px;
				padding: 15px;

				h3 {
					@include font-rem(22px, 20px);
					font-weight: 700;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Hero
	&--hero {

		&-content {

			&-headline {

				h2 {
					@include font-rem(44px, 41px);
					letter-spacing: -.6px;

					@include media-breakpoint-up(lg) {
						@include font-rem(48px, 45px);
						letter-spacing: -1.4px;
					}
				}
			}

			&-cta-list {
				margin-top: 9px;

				@include media-breakpoint-up(lg) {
					margin-top: 5px;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Category
	&--category {
		margin-bottom: 20px;

		&-content {

			&-headline {

				h4 {
					@include font-rem(24px, 24px);
					margin-bottom: 0;
				}
			}

			&-text {
				p {
					margin-bottom: 0;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Step
	&--step {
		margin: 0 auto;
		padding-top: 240px;

		@include media-breakpoint-up(sm) {
			min-height: 30vh;
			padding-top: 246px;
		}

		@include media-breakpoint-up(md) {
			min-height: 325px;
			padding-top: 145px;
		}

		@include media-breakpoint-up(lg) {
			min-height: 450px;
			padding-top: 125px;
		}
	}

	//-----------------------------------------------------------------| Hustle
	&--hustle {
		margin: 0 auto;

		&-tile {
			&-headline {
				h3 {
					span {
						color: $system-red;
						display: block;
					}
				}
			}
		}

		button {

			&[data-cta-type='video'] {
				&:after {
					color: $white;
					content: '\f04b';
					font-family: 'Font Awesome 5 Free';
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}

				&:before {
					background-color: $navy;
					border-radius: 50%;
					color: $white;
					content: '\f04b';
					font-family: 'Font Awesome 5 Free';
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
			}
		}
	}

	//-----------------------------------------------------------------| Subscription
	&--subscription {
		background-color: $concrete;
		margin: 0 auto 20px;
		max-width: 275px;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin: 0 auto;
			max-width: none;
		}

		&-text {
			padding: 20px;

			>p {
				color: $navy;
				margin: 0 auto;
				max-width: 186px;
				text-transform: uppercase;

				&:nth-child(1) {
					@include font-rem(20px, 22px);

					@include media-breakpoint-up(lg) {
						@include font-rem(23px, 27px);
					}
				}

				&:nth-child(2) {
					@include font-rem(14px, 16px);
					color: $dark-gray;
					margin-bottom: 12px;
					padding-bottom: 12px;

					@include media-breakpoint-up(lg) {
						@include font-rem(16px, 19px);
					}

					.gold & {
						border-bottom: solid 2px $system-red;
					}

					.silver & {
						border-bottom: solid 2px $system-red;
					}

					.bronze & {
						border-bottom: solid 2px $system-red;
					}
				}

				&:nth-child(3) {
					@include font-rem(30px, 32px);
					font-weight: 700;

					@include media-breakpoint-up(lg) {
						@include font-rem(39px, 47px);
					}
				}

				&:nth-child(4) {
					@include font-rem(17px, 19px);
					font-weight: 700;

					@include media-breakpoint-up(lg) {
						@include font-rem(20px, 24px);
					}
				}
			}
		}

		.gold & {

			.bat-label {
				@extend .bat-label--subscription-gold;
			}
		}

		.silver & {

			.bat-label {
				@extend .bat-label--subscription-silver;
			}
		}

		.bronze & {

			.bat-label {
				@extend .bat-label--subscription-bronze;
			}
		}

		//-----------------------------------------------------------------| Subscription small
		.small & {
			background-color: $white;
			max-width: 190px;

			.bat-label--subscription {
				border-radius: 10px 10px 0 0;
			}

			h3 {
				@include font-rem(30px, 36px);

				span {
					display: block;

					&:nth-child(1) {
						@include font-rem(14px, 14px);
						font-weight: 400;
					}

					&:nth-child(2) {
						color: $system-red;
						@include font-rem(12px, 12px);
						font-weight: 400;
					}
				}
			}

			&-text {

				padding: 7px 20px 14px;

				>p {

					&:nth-child(1) {
						@include font-rem(27px, 27px);
						font-weight: 700;
						margin-bottom: 2px;
					}

					&:nth-child(2) {
						border-bottom: 0;
						color: $navy;
						@include font-rem(16px, 16px);
						font-weight: 700;
						margin-bottom: 0;
						padding-bottom: 0;
					}
				}
			}
		}

		//sass-lint:disable force-element-nesting
		.small.gold &,
		.small.silver &,
		.small.bronze & {
			border-radius: 10px;

			.bat-label--subscription-lg {

				&:after {
					right: -100px;
				}
			}
		}

		.small.gold & {
			border: solid 1px $system-red;

			&-text {

				>p {

					&:nth-child(1) {
						color: $system-red;
					}
				}
			}
		}

		.small.silver & {
			border: solid 1px $system-red;

			&-text {

				>p {

					&:nth-child(1) {
						color: $system-red;
					}
				}
			}
		}

		.small.bronze & {
			border: solid 1px $system-red;

			&-text {

				>p {

					&:nth-child(1) {
						color: $system-red;
					}
				}
			}
		}
	}

	//-----------------------------------------------------------------| 404 Error
	&--404-error {
		margin: 5% 0 40px;
		text-align: center;

		&-heading {
			margin: 20px 0 10px;
		}

		&-cta {
			margin-top: 60px;

			@include media-breakpoint-down (md) {
				margin-left: auto;
				margin-right: auto;
				max-width: 400px;
			}

			a {
				margin: 0 10px;
			}

			@include media-breakpoint-down (lg) {
				& .bat-cta-style {
					&:last-of-type {
						margin-top: 15px;
					}
				}
			}
		}


	}

	//-----------------------------------------------------------------| 500 Error Zonnic
	&--500-error-zonnic {
		align-items: center;
		display: flex;
		justify-content: space-between;
		position: relative;
		
		@include media-breakpoint-up(xxs) {
			flex-direction: column;
		}

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			justify-content: space-around;
		}

		&-wrapper {
			background-color: $white;
			padding: 5%;
			position: relative;
			width: 50%;
			z-index: 10;

			@include media-breakpoint-up(xxs) {
				margin-top: 10%;
				padding: 10%;
				width: 85%;
			}

			@include media-breakpoint-up(md) {
				width: 75%;
			}

			@include media-breakpoint-up(xl) {
				margin-top: 0;
				padding: 5%;
				width: 50%;
			}

			@include media-breakpoint-up(xxl) {
				width: 40%;
			}

			a {
				@include media-breakpoint-up(xxs) {
					width: 30%;
				}
			}
		}

		&-prodimg {
			background-image: url('/content/dam/zonnic-content/ca/error-500/Zonnic_Sitedown_ProductImage.webp');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
			height: 500px;
			width: 500px;

			@include media-breakpoint-up(xxs) {
				width: 85%;
			}

			@include media-breakpoint-up(sm) {
				width: 65%;
			}

			@include media-breakpoint-up(lg) {
				height: 500px;
				width: 500px;
			} 
		}
		
		&-heading {

			@include media-breakpoint-up(xxs) {
				margin-top: 10%;
			}

			h1 {
				font-size: 42px;
				line-height: 46px;
				margin: 8px 0 20px;

				@include media-breakpoint-up(xxs) {
					font-size: 28px;
					line-height: 32px;
				}

				@include media-breakpoint-up(md) {
					font-size: 42px;
					line-height: 46px;
				}
			}
		}

		&-contact {
			background-image: url('/content/dam/zonnic/images/global/phone.webp');
			background-repeat: no-repeat;
			border: 1px solid $pale-gray;
			margin-top: 30px;
			padding: 4.5% 1.5% 4.5% 20%;
			

			@include media-breakpoint-up (xxs) {
				background-position: 6% 15%;
				background-size: 11%;
				width: 100%;
			}

			@include media-breakpoint-up (md) {
				background-position: 10% 20%;
				width: 70%;
			}

			p {
				font-size: $font-sm;
			}

			h3 {
				color: $black;
				display: block;
				font-family: $primary-font;
				font-size: 20px;
				font-weight: 600;
				margin-bottom: 10px;
			}

			ul {
				margin: 0;
				padding: 0;
			}

			li {
				color: $black;
				font-weight: 700;
				list-style-type: none;
				margin: 0;
			}
		}
	}

	//-----------------------------------------------------------------| Masthead
	&--masthead {

		overflow: hidden;
		position: relative;

		&-main {
			height: inherit;
			position: absolute;
			top: 0;
			width: 100%;

			@include media-breakpoint-up (md) {
				width: 45%;
			}

			span,
			p {

				&.dark-gray,
				.dark-gray {
					color: $system-red;
				}

				&.dark,
				.dark {
					color: $navy;
				}

				&.light,
				.light {
					color: $white;
				}
			}
		}

		&-img {
			height: inherit;

			picture {
				height: 100%;
				width: initial;
			}


		}
	}

	// Not sure what this is used for, Zonnic blurb is at bottom of page.
	//-----------------------------------------------------------------| FindYourVelo / Blurb
	&--blurb {
		height: auto;
		max-height: unset;
		overflow: hidden;
		position: relative;

		&-text {

			h2 {
				font-size: 34px;
				line-height: 30px;
			}

			.bat-headline-style {
				.headline2-color1 {
					font-size: 34px;
					line-height: 30px;
				}
			}

			@include media-breakpoint-down(sm) {
				h2 {
					font-size: 32px;
					line-height: 28px;
				}
			}

			p {
				font-size: 15px;
				line-height: 150%;
			}
		}

		&-main,
		&-img {
			position: absolute;
		}

		&-main {
			bottom: 0;
			left: 0;
			padding: 30px;
		}

		&-img {
			bottom: 0;
			left: 0;
			position: relative;
			right: 0;
			top: 0;

			picture,
			img {
				height: 100%;
				object-fit: cover;
				width: 100%;
			}

			@include media-breakpoint-down(sm) {

				picture,
				img {
					height: 100%;
					object-fit: cover;
					width: 100%;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Offer Card
	&--offer {
		&-wrapper {
			align-content: space-between;
			display: flex;
			flex-flow: row wrap;
			min-height: 320px;

			&-content {
				padding: 1rem 1.5rem;

				&-headline {
					margin-bottom: 1rem;

					.purple & {
						color: $system-red;
					}
				}

				&-specialcta {
					align-items: center;
					background-color: $system-red;
					color: $white;
					display: flex;
					padding: .5rem 1.25rem;

					a {
						align-items: center;
						display: flex;
					}

					.bat-headline-style {
						@include font-rem(20px, 20px);
						margin-left: 1rem;

						div {
							@extend p;
							@include font-rem(14px, 14px);
							font-weight: bold;
							margin-bottom: 0;
							margin-top: 3px;
						}
					}
				}

				&-text {
					margin-top: 1rem;

					p {
						@include font-rem(15px, 15px);
					}

				}
			}

			&-ctalist {
				margin-bottom: 1rem;
				margin-top: 1rem;
				padding: 1rem 1.5rem;
			}
		}
	}

	//-----------------------------------------------------------------| News Card
	// Updated for Zonnic, on mobile placed into carousel

	&--news {
		margin-top: 8px;

		@include media-breakpoint-down(md) {
			//border-bottom: 2px solid $system-red;
			margin-bottom: 0;
			margin-top: 0;
			padding: 30px 0;
		}

		&-img {
			height: 100%;
			margin-bottom: 28px;
			max-height: 240px;
			overflow: hidden;
			
			@include media-breakpoint-down(md) {
				margin-bottom: 20px;
			}

			a {
				display: block;
			}
		}

		&-text {
			margin: 15px 0 10px;
			word-break: break-word;

			@include media-breakpoint-down(md) {
				margin: 8px 0 5px;
			}

			h3 {
				padding-top: 0;

				@include media-breakpoint-down(md) {
					padding-top: 16px;
				}
			}

			p {
				&:first-of-type {
					color: $berry-frost-dark;
				}

				@include font-rem(14px, 20px);
				margin-top: 10px;

				@include media-breakpoint-down(md) {
					color: $dark-gray;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Zonnic - Blog Card
	&--blog {
		margin-top: 8px;

		@include media-breakpoint-down(md) {
			border-bottom: 2px solid $pale-gray;
			margin-bottom: 24px;
			margin-top: 24px;
			padding: 0 0 16px;
		}

		&-img {
			height: 100%;
			margin-bottom: 28px;
			max-height: 240px;
			overflow: hidden;

			@include media-breakpoint-down(md) {
				margin-bottom: 20px;
			}

			a {
				display: block;
			}
		}

		&-text {
			margin: 15px 0 10px;
			word-break: break-word;

			@include media-breakpoint-down(md) {
				margin: 8px 0 5px;
			}

			h3 {
				font-weight: 700;
				padding-top: 8px;
			}

			p {
				&:first-of-type {
					color: $tropic-breeze-dark;
				}

				@include font-rem(14px, 20px);
				margin-top: 10px;

				@include media-breakpoint-down(md) {
					color: $dark-gray;
				}
			}
		}

		&-ctalist {
			.bat-cta-style {
				margin-top: 8px;
			}
		}
	}
}
.border-bottom-none {
	.bat-card--blog {
		border-bottom: 0;
	}
}

//Background Classes 
bat-card-step {

	&.step1,
	&.step2,
	&.step3,
	&.step4 {
		position: relative;

		&:before {
			@extend h1;
			color: $off-white;
			font-size: 220px;
			line-height: 220px;
			position: absolute;
			top: 0;

			@include media-breakpoint-up(md) {
				font-size: 160px;
				line-height: 160px;
			}

			@include media-breakpoint-up(lg) {
				font-size: 220px;
				line-height: 220px;
			}
		}
	}
}

// This is not being used within Zonnic.
// Masthead Card
bat-card-masthead {

	&.masthead {

		&-full,
		&-full__reverse {
			.bat-card--masthead {
				height: 470px;

				&-main {
					box-sizing: border-box;
					padding: 5%;
				}

				&-img {
					bottom: 0;
					clip-path: none;
					overflow: hidden;
					position: absolute;
					top: 0;
					width: 100%;

					@include media-breakpoint-up (md) {
						width: 55%;
					}

					picture {
						height: inherit;
						width: initial;
					}

					img {
						height: inherit;
						width: auto;

						@include media-breakpoint-up (lg) {
							width: 100%;
						}
					}

				}

				&-ctalist {
					margin-top: 30px;
				}
			}
		}

		&-full {
			.bat-card--masthead {
				&-img {
					left: 0;

					@include media-breakpoint-up(md) {
						clip-path: polygon(0% 0%, 100% 0, 65% 100%, 0 100%);
					}
				}

				&-main {
					right: 0;
				}
			}
		}

		&-full__reverse {
			.bat-card--masthead {
				&-img {
					right: 0;
				}

				&-main {
					left: 0;
				}
			}
		}

		&-blurb {
			margin-bottom: 3%;

			@include media-breakpoint-up (md) {
				margin-bottom: 0;
			}

			.bat-card--masthead {
				height: 260px;

				&-main {
					box-sizing: border-box;
					padding: 2.5%;
					width: 60%;

					@include media-breakpoint-up (xxs) {
						width: 65%;
					}
				}

				img {

					@include media-breakpoint-up (xxs) {
						height: inherit;
						width: auto;
					}

					@include media-breakpoint-up (xs) {
						height: auto;
						width: 100%;
					}
				}
			}

			&__left,
			&__right,
			&__center {
				.bat-card--masthead {
					&-ctalist {
						bottom: 20px;
						position: absolute;
						width: 100%;
					}
				}
			}

			&__left {
				.bat-card--masthead {

					&-main,
					&-ctalist {
						left: 15px;
					}

					&-main {
						text-align: left;
					}
				}
			}

			// right content
			&__right {
				.bat-card--masthead {

					&-main,
					&-ctalist {
						right: 15px;
					}

					&-main {
						text-align: right;
					}
				}
			}

			&__center {
				.bat-card--masthead {

					&-main,
					&-ctalist {
						left: 0;
						right: 0;
						text-align: center;
						width: auto;
					}
				}
			}
		}
	}

	// Authorable class "cover" to make background image cover
	&.cover {
		.bat-card--masthead-img {
			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}
}


bat-card-simpleproduct {
	background-color: $navy;

	.bat-card {
		&--simpleproduct {
			display: flex;
			flex-wrap: wrap;

			@include media-breakpoint-up (lg) {
				flex-wrap: nowrap;
			}

			&-img {
				display: flex;
				justify-content: center;
				width: 100%;

				@include media-breakpoint-up (lg) {
					width: 33%;
				}

				picture {
					max-width: 500px;
				}
			}

			&-main {
				width: 100%;

				@include media-breakpoint-up (lg) {
					padding-left: 1rem;
					width: 67%;
				}

				&-title {
					color: $white;
					margin-bottom: 1rem;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}

					h3 {
						@include font-rem(26px, 26px);
					}
				}

				&-text {
					color: $white;
					margin-bottom: 1rem;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}
				}

				&-ctalist {
					color: $white;
					display: flex;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}

					&-button {
						border: 1px solid $white;
						padding: .5rem 1rem;

						@include media-breakpoint-up(xs) {
							padding: .5rem 1.75rem;
						}

						@include media-breakpoint-up(md) {
							padding: .5rem 2rem;
						}

						div {
							@include font-rem(12px, 8px);
							margin-top: 5px;
							text-align: center;
							width: 100%;
						}

						.bat-icon {
							display: inline-flex;
							width: 10px;

							&::before {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}

//-----------------------------------------------------------------| News Card HP mobile
@include media-breakpoint-down(xs) {

	.news-card-first {
		.bat-card--news {
			padding-top: 18px;
		}
	}

	.news-card-last {
		.bat-card--news {
			border: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

//-----------------------------------------------------------------| BlogHub, overides to news card
.bat-blog-hub {
	.bat-card--news {
		@include media-breakpoint-down(xs) {
			padding-bottom: 16px;
			padding-top: 30px;
		}
	}

	.bat-card--news-text {
		p {
			&:first-of-type {
				margin-top: 26px;

				@include media-breakpoint-down(xs) {
					margin-top: 16px;
				}
			}
		}

		h3 {
			padding-top: 0;
		}
	}

	.bat-cta-style {
		@include media-breakpoint-down(xs) {
			margin-top: 10px;
		}
	}
}

//-----------------------------------------------------------------| Blurb Zonnic

.bat-card--blurb-zonnic {
	box-sizing: content-box;
	display: grid;
	width: 100%;

	@include media-breakpoint-down(sm) {
		margin-top: 4px;
	}

	&-img {
		grid-area: 1/-1;

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	&-main {
		align-self: flex-end;
		grid-area: 1/-1;
		padding: 0 0 44px 40px;
		width: 60%;
		z-index: 99;

		@include media-breakpoint-down(md) {
			padding: 0 0 24px 16px;
			width: 70%;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 0 40px 20px;
			width: 80%;
		}
	}

	&-headline {

		h3 {
			span {
				display: block;
			}

			.light {
				color: $white;
			}
		}
	}

	&-text {
		color: $navy-dark;
		margin-top: 8px;

		.light {
			color: $white;
		}

	}

	&-ctalist {
		margin-top: 16px;
	}
}

//-----------------------------------------------------------------| Zonnic MastHead

.card-masthead-product {

	.bat-card--masthead-zonnic {
		align-items: center;

		&-main {
			margin-bottom: 16px;
			padding: 40px 8px 16px 40px;
		}

		&-img {
			justify-self: right;
			margin-right: 8px;
			width: 40%;
		}
	}
}

.bat-card--masthead-zonnic {
	display: grid;
	width: 100%;

	@include media-breakpoint-down(lg) {
		margin-top: 4px;
	}

	&-img {
		grid-area: 1/-1;

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	&-main {
		align-self: center;
		grid-area: 1/-1;
		padding-left: 40px;
		width: 60%;
		z-index: 99;

		@include media-breakpoint-down(lg) {
			padding-left: 20px;
			width: 56%;
		}
	}

	&-heading {

		h3 {
			span {
				display: block;
			}

			.light {
				color: $white;
			}
		}
	}

	&-text {
		margin-top: 16px;
	}

	&-ctalist {
		margin-top: 16px;
	}
}

//-----------------------------------------------------------------| Zonnic 404 Error
bat-card-errorzonnic {
	margin: 0;
	
	.bat-card {
		background-image: url('/content/dam/zonnic-content/ca/error-500/Zonnic_Sitedown_500_501_Background.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		margin: 8%;
		position: relative;
		width: 100%;

		@include media-breakpoint-up(xxs) {
			position: relative;
		}

		@include media-breakpoint-up(lg) {
			position: fixed;
		}

		@include media-breakpoint-up(xxs) {
			margin: 0;
		}

		@include media-breakpoint-up(md) {
			padding: 5%;
		}

		@include media-breakpoint-up(lg) {
			padding: 7%;
		}

	}
}


.bat-gtr-category-card {
	.bat-card--category {
		margin-bottom: 0;
		max-width: 100%;
		padding: 37px 33px 10px;
		display: flex;
		align-items: center;
		
		.bat-card--category-tile {
			width: 64px;
			flex-shrink: 0;
			margin-bottom: 0;

			@include media-breakpoint-up(md) {
				width: 103px;

				a {
					display: block;
				}
			}
		}
		.bat-card--category-content {
			padding-left: 20px;
			.bat-card--category-content-cta-list {
				margin-top: 0;
			}
		}
		.bat-card--category-content-headline {
			text-align: left;

			h4 {
				@include font-rem(24px, 1.3);
				font-weight: bold;


				@include media-breakpoint-up(lg) {
					@include font-rem(32px, 130%);

				}
			}
		}
	}
	&.rectangle-left {
		.bat-card--category {
			position: relative;
			padding-top: (98 / 375 * 100%);
			z-index: 0;
			@media (min-width: 576px) and (max-width: 767px) {
				padding-top: 20%;
			}
			&:before {
				z-index: -1;
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				padding-top: 61.6%;
				background-image: url('/content/dam/gtr/images/gtr/rectangle-left.svg');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: top center;
			}
		}
		&.rectangle-left--right {
			.bat-card--category:before {
				background-image: url('/content/dam/gtr/images/gtr/rectangle-right.svg');
			}
		}
	}

	//instore
	&.in-store {
		.bat-card--category {
			.bat-card--category-content-headline {
				h4 {
					@include media-breakpoint-up(lg) {
						@include font-rem(36px, 130%);

					}
				}
			}
		}
	}
}

.bat-card--gtr {
	&__content {
		display: flex;
		align-items: center;
		&__image {
			width: 119px;
			flex-shrink: 0;
			@media (max-width: 767px) {
				width: 142px;
			}
			a {
				display: block;
			}
			img {
				max-width: 100%;
				width: auto;
				margin: 0 auto;
			}
		}
		&__content {
			padding-left: 8px;
			flex-grow: 1;
		}
		&__headline {
			
			h4 {
				font-weight: 700;
				font-size: 18px;
				line-height: 150%;
				color: #000000;
			}
		}
		&__text {
			font-size: 15px;
			line-height: 150%;
			p {
				font-size: inherit;
				line-height: inherit;
			}
		}
		@include media-breakpoint-up(lg) {
			&__image {
				width: 268px;
			}
			&__headline {
				h4 {
					font-size: 24px;
				}
			}
		}
	}
}
.bat-card--gloproduct {
	.bat-card--gtr {
		
		@include media-breakpoint-up(md) {
			&__content {
				display: block;
				&__content {
					padding-left: 0;
				}
				&__image {
					width: auto;
					margin-bottom: 24px;
				}
				&__headline {
					margin-bottom: 5px;
					text-align: center;
				}
				&__text {
					text-align: center;
					margin-bottom: 5px;
					width: 259px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
		
	}
}

.feature-product-cards {
	position: relative;
	background: linear-gradient(180deg, rgba(249, 249, 249, 1) 0%, rgba(225, 225, 225, 1) 100%);

	> div {
		padding: 60px 90px;

		@include media-breakpoint-down(md) {
			padding: 60px 20px;
		}
	}

	.bat-headline {
		max-width: 585px;
		margin: 0 auto 40px;
	}

	.aem-GridColumn.card {
		padding: 0;
	}

	.card {
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}
	}

	.bat-card--category {
		max-width: none;

		&-content {
			padding: 0 30px;

			&-text {
				p {
					font-size: 15px;
				}
			}

			&-headline {
				margin-bottom: 10px;

				h4 {
					font-size: 20px;
				}
			}
		}
	}


	&.in-store {
		.bat-card--category {

			&-content {
				&-headline {
					h4 {
						font-size: 28px;
					}
				}
			}
		}
	}

	&.global-thankyou {
		&:before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			padding-top: (118 / 1920 * 100%);
			background-image: url('/content/dam/gtr/images/webp-global/rectangle-top-fp-ty.webp');
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

}

//HOME
.home-main-container {
	&.global-home {
		background:  url('/content/dam/gtr/images/webp-global/bg-home.webp');
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		padding-top: 70px;

		.feature-product-cards {
			position: relative;
			background: none;

			> div {
				max-width: 1200px;
				margin: 0 auto;
				width: calc(100% - 40px);
				padding: 30px 20px 100px 20px;
				background: linear-gradient(360deg, rgba(0, 0, 0, 0) 23.95%, rgba(0, 0, 0, 0.1) 98.12%), #FFFFFF !important;

				@include media-breakpoint-up(md) {
					padding: 60px 60px 150px 60px;
				}
			}

			.bat-card--category-content {
				&-headline {
					h4 {
						font-size: 20px;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {

	//EDUCATION
	.bat-gtr-category-card {
		.bat-card--category {
			justify-content: center;
		}

		&.rectangle-left {
			.bat-card--category {
				padding-top: 120px;
				margin-bottom: 50px;
			}

			.bat-card--category:before {
				background-image: url('/content/dam/gtr/images/webp-in-store/rectangle-left.webp');
                padding-top: (372 / 1920 * 100%);
			}

			&.rectangle-left--right {
				.bat-card--category:before {
					background-image: url('/content/dam/gtr/images/webp-in-store/rectangle-right.webp');
                    padding-top: (372 / 1920 * 100%);
				}
			}

			&.rectangle-color {
				:before {
					background-image: url('/content/dam/gtr/images/webp-in-store/rectangle-color.webp');
					height: auto;
					z-index: -1;
					padding-top: (394 / 1920 * 100%);
				}

				.bat-card--category {
					padding-top: 140px;
				}
			}
		}
	}

}

.in-store {
	.bat-card--gtr {
		&__content {
				
			&__image {
				max-width: 410px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
			}
			
			&__headline {
				margin-bottom: 19px;
				h4 {
					font-size: 32px;
				}
			}
			
			&__text {
				font-size: 24px;
			}
		}
	}
	.bat-card--gloproduct {
		.bat-card--gtr {
			&__content {
				&__image {
					margin-bottom: 30px;
					width: 440px;
					max-width: 100%;
				}
				&__headline {
					margin-bottom: 8px;
				}
			}
		}
		.bat-card--gtr__content__text {
			max-width: 403px;
			width: auto;
			margin-left: auto;
			margin-right: auto;
		}
	}
}


[dir="rtl"] {
	
    .bat-card--gtr__content__content {
        padding-left: 0;
        padding-right: 8px;
    }
	@media (min-width: 768px) {
		.bat-card--gloproduct .bat-card--gtr__content__content {
			padding-right: 0;
		}
	}
	.bat-gtr-category-card .bat-card--category .bat-card--category-content {
		padding-right: 20px;
		padding-left: 0;
	}
}