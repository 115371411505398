.bat-modal {
	align-items: flex-start;
	background-color: $black-60;
	height: 100vh;
	overflow-y: auto;
	z-index: $header-z-index - 1;
	
	&-content {
		animation: slide-down 250ms ease;
		max-height: none;
		overflow: hidden;
		position: relative;
		// modal window distance from top of page
		top: 120px;
		
		//AEM author mode 
		.authorEditMode & { // sass-lint:disable-line class-name-format
			left: 0;
			margin: auto;
			right: 0;
			top: 0;
		}
		
		&.left {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			text-align: left;
		}
		
		&.right {
			align-items: flex-end;
			display: flex;
			flex-direction: column;
			text-align: right;
		}
		
		&.center {
			align-items: center;
			display: flex;
			flex-direction: column;
			text-align: center;
		}
		
		&-video {
			max-height: calc(79vh - 100px);
		}
		// Used within newsletterzonnic-form.js
		.hide { // sass-lint:disable no-important
			display: none !important;
		}
		.show { // sass-lint:disable no-important
			display: block !important;
		}
		&.fade-out {
			animation: fade-out 200ms ease;
		}
	}

	@keyframes slide-down {
		0%{
			opacity: 0;
			transform: translateY(-100%);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@keyframes fade-out {
		0%{
			opacity: 1;
			transform: scale(1);
		}
		100% {
			opacity: 0;
			transform: scale(0.5);
		}
	}
	
	&-close {
		border: 0;
		right: 24px;
		top: 24px;
		width: 34px;
		z-index: 99999;

		// Magento remote navigation overrides
		&:hover {
			background-color: transparent;
			border: 0;
		}
		
		i {
			color: $white;
			font-size: 12px;

			&:before {
				color: $navy;
				content: '\e952';
				font-family: 'icomoon';
				font-size: 34px;
				font-weight: 400;
			}
		}
	}
}
