//TEXT
p {
	.dark,
	&.dark {
		color: $navy;
	}

	.dark-gray,
	&.dark-gray {
		color: $dark-gray;
	}

	.light,
	&.light {
		color: $white;
	}
}

ol,
ul {

	.dark,
	&.dark {
		color: $navy;
	}

	.dark-gray,
	&.dark-gray {
		color: $dark-gray;
	}

	.light,
	&.light {
		color: $white;
	}

	.no-style,
	&.no-style {
		list-style: none;
		margin: none;
		padding: none;
	}

	li {
		.xsm {
			padding-left: 12px;
		}
	}

	.arrow-bullets,
	&.arrow-bullets {

		list-style-type: none;
		margin: none;
		padding: none;

		li {
			padding-left: 16px;
			position: relative;

			&:before {
				color: $navy;
				content: '\e942';
				font-family: 'icomoon';
				font-size: 20px;
				font-weight: 300;
				left: 0;
				position: absolute;
			}
		}
	}
}

.list-bullets-arrow {

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		@include font-rem($font-sm, 20px);
		color: $black;
		padding-left: 34px;
		position: relative;

		&:before {
			color: $navy;
			content: '\e942';
			font-family: 'icomoon';
			font-size: 20px;
			font-weight: 300;
			left: 0;
			line-height: 88%;
			position: absolute;
		}
	}
}
.list-xsm-gray {

	li {
		@include font-rem($font-xsm, 18px);
		color: $black;
		margin-bottom: 8px;

		span {
			padding-left: 16px;
		}
	}
}

::selection {
	background: $light-blue;
	color: inherit;
}


// .in-store {
// 	.bat-text {
// 		max-width: 1006px;
// 		margin-left: auto;
// 		margin-right: auto;
// 	}
// }