// File:           _video.scss
// Description:   Video Styles
.bat-video {
	
	& ~ .bat-modal {
	
		.bat-modal-content {
			max-width: $max-width-xl;
			padding: 45px;	
		}
	}
	
	&-content {
		padding: 45px;
	
		&-video {
	
			picture {
				
				&:after {
					color: $white;
					content: '\f04b';
					font-family: 'Font Awesome 5 Free';
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
				&:before {
					background-color: $navy;
					border-radius: 50%;
					color: $white;
					content: '\f04b';
					font-family: 'Font Awesome 5 Free';
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
				
				&:hover {
					cursor: pointer;
					
					&:after {
						color: $black;
					}
					
					&:before {
						background-color: $white;
					}
				}
				
			}
        }
	}
}

// Video for zonnic article page. 
.bat-article-video {
	.bat-video-zonnic {
		&-content {
			padding: 0;
		}
		.bat-video-content-video-wrapper {
			padding-top: 0;	
		}
	}
}
