.bat-reviewsform {
	&-form {
		display: flex;
		flex-direction: column;
		label, 
		legend {
			display: block;
			font-size: .8rem;
			font-weight: bold;
			margin-bottom: 1rem;
			input, 
			textarea {
				margin: .5rem 0;
			}
		}
		.textarea-wrapper {
			margin-bottom: 1rem;
			label {
				margin-bottom: 0;
			}
		}
		.form-error {
			color: $red;
			display: none;
			font-size: .8rem;
			font-style: italic;
			font-weight: 300;
		}
	}
	&-ratings {
		margin: 1rem 0;
		legend {
			margin: .5rem 0;
		}
		label {
			display: inline;
			input {
				&[type = 'radio'] {
					display: inline;
					height: 1rem;
					line-height: 1rem;
					margin: 0 .5rem 0 0;
					position: relative;
					width: 1rem;
					&::before {
						color: $gray;
						content: '\f005';
						display: inline-block;
						font-family: 'Font Awesome 5 Free';
						font-size: 1rem;
						height: 1rem;
						position: relative;
						width: 1rem;
					}
					&:checked, 
					&:hover, 
					&.active {
						&::before {
							color: $yellow;
							content: '\f005';
						}
					}
				}
			}
		}
	}
	&-comments {
		position: relative;
		.counter {
			bottom: 1rem;
			font-size: .8rem;
			font-weight: normal;
			position: absolute;
			right: 1rem;
			z-index: 1;
		}
	}
}

.bat-reviewslist {
	width: 100%;
	h5 {
		line-height: 1;
		margin-bottom: .75rem;
	}
	&-slot {
		margin-bottom: .75rem;
		width: 100%;
	}
	&-summary {
		color: $gray;
		font-size: .9rem;
		margin-bottom: .75rem;
	}
	&-stars {
		color: $gray;
		margin-bottom: .75rem;
		.star {
			&.active {
				color: $yellow;
			}
		}
	}
	&-list {
		background-color: $off-white;
		padding: 2rem 3rem;
	}
	&-item-wrapper {
		hr {
			background-color: $gray;
			border: 0;
			height: 1px;
			margin: 2rem 0;
		}
	}
	&-title {
		font-size: 1.25rem;
		font-weight: 600;
		margin-bottom: .75rem;
	}
	&-user {
		color: $gray;
		font-size: .9rem;
		margin-bottom: .75rem;
	}
	&-loadmore {
		align-items: center;
		display: flex;
		justify-content: center;
		text-align: center;
		button {
			align-items: center;
			display: flex;
			flex-direction: column;
			font-size: 1rem;
			justify-content: center;
		}
	}
}
