bat-productcard-default {
	height: 100%;
}

.bat-productcard {
	margin-bottom: 20px;
	opacity: 0;
	transition: opacity .2s ease-in;
	
	&.loaded {
		opacity: 1;
	}
	
	.bat-quantity {
		justify-content: center;
	}

	&-content {
		
		&-headline {
			
			h6 {
				@include font-rem(16px, 16px);
				
				.slash {
					color: $dark-gray;
					@include font-rem(14px, 14px);
				}
			}
		}
		
		&-text {
			@include font-rem(16px, 16px);
			font-weight: 700;
			letter-spacing: -.6px;
			margin: 0 auto 10px;
		}
		
		&-cta-list {
			
			button {
				margin: 0 auto 10px;
			}
		}
	}
}
