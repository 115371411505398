.basket {
	display: grid;

	@include media-breakpoint-up(lg) {
		grid-template-columns: 60% 40%;
	}

	&__container {
		padding: 30px 20px;

		@include media-breakpoint-up(lg) {
			padding: 64px;
		}
	}

	&__title {
		@extend .headline4;
		text-transform: initial;
	}

	&__title+&__hr {
		margin: 10px 0;

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&__hr {
		background: $pale-gray;
		border: 0;
		height: 1px;
		margin: 30px 0;
	}

	&__total {
		display: flex;
		justify-content: space-between;
		@extend .headline3;

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&__list {
		margin-top: 30px;
	}

	&__item {
		align-items: center;
		display: flex;
		gap: 15px;

		@include media-breakpoint-up(lg) {
			gap: 55px;
		}

		&__image {
			height: 90px;
			object-fit: contain;
			width: 90px;

			@include media-breakpoint-up(lg) {
				height: 164px;
				width: 164px;
			}
		}

		&__details {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			gap: 20px;

			@include media-breakpoint-up(lg) {
				gap: 2rem;
			}

			&__top,
			&__bottom {
				display: flex;
				gap: 1rem;
				justify-content: space-between;
			}

			&__bottom {
				align-items: flex-start;
				flex-direction: column;

				@include media-breakpoint-up(lg) {
					align-items: flex-end;
					flex-direction: row;
				}
			}
		}

		&__name {
			@extend .headline4;
			font-weight: 600;
			text-transform: initial;
		}

		&__button {
			align-items: center;
			background: $pale-gray;
			border-radius: 50%;
			color: $navy;
			display: flex;
			flex-shrink: 0;
			height: 24px;
			justify-content: center;
			width: 24px;

			.bat-icon:before {
				font-size: 16px;
			}

			&:focus {
				.bat-icon:before {
					animation: spin 2s linear infinite;
					content: '\e946';
					font-size: 20px;
				}
			}

			@include media-breakpoint-up(lg) {
				&[data-remove] {
					height: 32px;
					width: 32px;

					.bat-icon:before {
						font-size: 24px;
					}
				}
			}
		}

		&__quantity {
			&__label {
				@extend .headline5;
				margin-bottom: 14px;
			}

			&__controls {
				align-items: center;
				display: flex;
				gap: 16px;
			}

			&__number {
				font-weight: 700;
			}
		}

		&__price {
			display: flex;
			gap: 10px;

			&--discount {
				@extend .headline3;
				color: $system-red;
			}

			&--regular {
				@extend .headline4;
				position: relative;
			}

			&--discount+&--regular {

				&:after {
					border-color: currentColor;
					border-top: 2px solid;
					content: '';
					left: 0;
					position: absolute;
					right: 0;
					top: 50%;
					transform: rotate(-5deg);
				}
			}
		}
	}

	&__summary {
		background: $whisper-gray;
		padding: 30px 20px;
		text-align: center;

		@include media-breakpoint-up(lg) {
			padding: 64px;
		}

		.bat-cta-list--horizontal {

			a,
			button {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
