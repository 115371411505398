bat-form-savedpayments {
	.bat-headline-style {
		margin-bottom: 26px;
	}
	.payment-method--no-payment-message {
		display: none;
		> p {
			margin-bottom: 38px;
		}
		&.show {
			display: block;
		}
	}
	#payment-methods--payment-types {
		display: flex;
		flex-flow: column wrap;
	}
	.payment-type {
		align-items: center;
		border: 2px solid $pale-gray;
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		padding: 18px 20px;
		@include media-breakpoint-down(sm) {
			align-items: unset;
			flex-flow: column wrap;
			justify-content: unset;
		}
		&--card-info {
			align-items: center;
			display: flex;
			width: 100%;
			@include media-breakpoint-down(sm) {
				margin-bottom: 25px;
			}
			span {
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 0;
				line-height: normal;
				&.payment-type--icon {
					align-items: center;
					display: flex;
					flex: 0 1 auto;
					justify-content: center;
				}
				&.payment-type--number {
					flex: 1 0 auto;
					margin-left: 15px;
				}
				&.payment-type--exprire-date {
					text-align: center;
					width: 100%;
					@include media-breakpoint-down(sm) {
						text-align: right;
					}
				}
			}
		}
		svg {
			&.visa {
				height: auto;
				width: 40px;
			}
			&.mastercard {
				height: auto;
				width: 29px;
			}
			&.amex {
				height: auto;
				width: 48px;
			}
		}
		&.expired {
			order: 1;
			.payment-type--exprire-date {
				color: $system-red;
			}
		}
	}
}
