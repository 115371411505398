.bat-header--landing {
    bat-header-gtrlanding:not(.author-mode) & {
        position: fixed;
        left: 0;
        right: 0;
    }
    
    transition: background-color 0.4s ease, color 0.4s ease;
    background-color: transparent;
    color: #fff;
    z-index: 30;
    padding: 24px 23px;
    .bat-header-wrapper {
        display: flex;
        align-items: center;
    }
    .btn-lang-toggle {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.02em;
        svg {
            display: block;
            
            flex-shrink: 0;
            &.icon-world {
                width: 20px;
                height: auto;
            }
        }
        span {
            display: block;
            padding: 0 6px;
        }
    }
    .btn-back {
        color: inherit;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        .icon {
            flex-shrink: 0;
            display: block;
            margin-right: 4px;
        }
    }
    .bat-header__lang {
        margin-left: auto;
        padding-left: 10px;
        position: relative;
        ul {
            border-radius: 3px;
            background-color: #fff;
            padding: 4px;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
            display: none;
            top: 100%;
            margin-top: 4px;
            margin-bottom: 0;
            position: absolute;
            right: 0;
            min-width: 70px;
            list-style: none;
            li {
                text-align: center;
                margin-bottom: 0;
                span {
                    display: block;
                    font-size: 12px;
                    line-height: 18px;
                    border-bottom: 1px solid transparent;
                    transition: border-color 0.4s ease;
                    &:hover {
                        border-color: #000;
                    }
                }
                &:first-child {
                    span {
                        border-color: #000;
                    }
                }
                & + li {
                    margin-top: 5px;
                }
                a {
                    color: #000;
                }
            }
        }
    }
    &.is-scroll {
        background-color: #fff;
        box-shadow: 0 2px 2px 0 rgba(#000, 0.15);
        color: #000;
        
    }
}
[dir="rtl"] {
    .bat-header--landing {
        .bat-header__lang {
            margin-left: 0;
            margin-right: auto;
            padding-left: 0;
            padding-right: 10px;
            ul {
                right: auto;
                left: 0;
            }
        }
        .btn-back {
            .icon {
                order: 1;
            }
        }
    }
}