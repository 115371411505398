bat-productcard-zonnic {
	margin: 0 -3.5px;
	.bat-productcard {
		
		&--zonnic {
			background-color: $white;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: space-between;
			margin-bottom: 7px;
			max-width: unset;
			opacity: 0;
			padding: 20px 0;
			position: relative;
			transition: opacity .2s ease-in;
			
			&.loaded {
				opacity: 1;
			}
			
			.bat-productcard-content {
				//border-bottom: 1px solid $pale-gray;
				display: flex;
				flex-direction: row;
				margin: 0 auto 20px;
				max-width: calc(100% - 40px);
				//padding-bottom: 20px;
				width: 100%;

				.bat-productcard-image {
					flex: 1 0 115px;
					height: auto;
					margin: 0;
					
					a,
					img {
						display: block;
						height: auto;
						margin-bottom: 0.5rem;
						width: 100%;
					}
				}			
					
				.bat-productcard-text {
					display: flex;
					flex-direction: column;
					max-width: 375px;
					padding-left: 26px;
					width: auto;
					
					&-description {
						display: block;
						text-align: left;
						* {
							@include font-rem(12px, 18px);
							letter-spacing: 0;
						}
						a {
							color: $navy;
							font-weight: 700;
							text-decoration-thickness: 1px; // sass-lint:disable-line no-misspelled-properties
							text-underline-offset: 1px; // sass-lint:disable-line no-misspelled-properties
						}
						p {
							color: $mid-gray;
							font-weight: 300;
							margin-bottom: 10px;
							margin-top: 0;
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
					
					&-name {
						margin-bottom: 8px;
						* {
							@extend .headline4-subtitle;
							color: $navy;
							text-align: left;
							text-transform: capitalize;
						}
					}

					&-out-of-stock {
						text-align: left;
					}
					
					&-price {
						@extend .headline4;
						color: $navy;
						margin: 0 0 11px;
						text-align: left;
						vertical-align: middle;

						.base-price {
							color: $navy; 
						}

						.sale-price {
							color: $system-red;
							display: none;
							margin-right: 10px;
						}
				
						&.on-sale { 
							.sale-price {
								display: inline;
							}
							
							.base-price {
								@include font-rem(18px, 28px);
								text-decoration: line-through;
							}
							/* Render a slash across the price
							position: relative;						
							&:before {
								-moz-transform: rotate(-5deg);
								-ms-transform: rotate(-5deg);
								-o-transform: rotate(-5deg);
								-webkit-transform: rotate(-5deg);
								border-color: $dark-gray;
								border-top: 2px solid;
								content: '';
								left: 0;
								position: absolute;
								right: 0;
								top: 50%;
								transform: rotate(-5deg);
							} */
						}
					}

					&-promo {
						background-color: rgba($navy, .1); // sass-lint:disable-line no-color-literals
						color: $navy;
						margin-bottom: 10px;
						padding: 10px 5px 8px;
						position: relative;
						text-align: center;
						* {
							display: block;
						}
						.icon-info {
							display: none;
							position: absolute;
							right: 15px;
							top: calc(50% - 14px); //---| 14px is the height of the icon
						}
						.text {
							@include font-rem(12px, 18px);
							font-weight: 300;
						}
						.title {
							@include font-rem(12px, 14px);
							font-weight: 700;
							letter-spacing: .5px;
							text-transform: uppercase;
						}
					}

					// default styles copied from core
					&-tip {
						@include font-rem($font-xsm, 18px);
						color: $black;
						display: inline-block;
						height: 16px;
						position: relative;
						right: unset;
						text-transform: none;
						top: -2px;
						width: 16px;
						z-index: 10;
						
						i {
							cursor: help;
							height: 16px;
							position: relative;
							width: 16px;
							z-index: 1;
							
							&:before {
								color: $navy;
								content: '\e92e';
								font-family: 'icomoon';
								font-size: 20px;
								font-weight: 300;
								margin-left: 12px;
							}
				
							span {
								@include font-rem(14px, 14px);
								background-color: $light-blue;
								border: unset;
								bottom: 40px;
								box-shadow: unset;
								display: none;
								left: -118px;
								padding: 20px;
								position: absolute;
								text-transform: unset;
								transform: translate(0%, 0%); 
								width: 100%;
								z-index: 1;

								&:before {
									border: solid transparent;
									border-bottom-color: $black;
									border-width: 6px;
									content: '';
									margin-right: -6px;
									position: absolute;
									right: 28px;
									top: -13px;
									z-index: 1;
								}
								
								&:after {
									border: solid transparent;
									border-bottom-color: $off-white;
									border-width: 5px;
									content: '';
									margin-right: -5px;
									position: absolute;
									right: 28px;
									top: -10px;
									z-index: 2;
								}
							}
						
							&:hover {
								span {
									display: block;
								}
							}
						}
					}
				}
			}

			.bat-productcard-controls {
				border-top: 1px solid $pale-gray;
				display: flex;
				flex-direction: column;
				margin: 0 auto;
				max-width: calc(100% - 40px);
				padding-top: 20px;
				width: 100%;		
				.bat-quantity {
					@extend .bat-button;
					@extend .bat-button--secondary--dark;
					display: flex;
					justify-content: space-between;
					margin: 0 0 10px;
					max-width: unset;
					padding: 7px 20px;
					width: 100%;		
					&-button {
						background-color: transparent;
						&:hover i::before {  // sass-lint:disable-line force-element-nesting force-pseudo-nesting
							color: $navy;
						}
					}
					&-field {
						pointer-events: unset;
					}
				}
				.bat-ctas {
					margin: 0;
					width: 100%;		
					button {
						max-width: unset;
						padding: 15px 20px;
						width: 100%;
						&[data-cta-type='button'] {
							display: none;
						}
					}
				}
			}
		}
	}
}

// SM viewport 576+
@include media-breakpoint-up(sm) {
	bat-productcard-zonnic {
		.bat-productcard {			
			&--zonnic {
				.bat-productcard-content {
					.bat-productcard-image {
						flex: 1 0 160px;
					}
					.bat-productcard-text {
						max-width: 520px;
						&-promo {
							padding: 10px 45px 8px 15px;
							text-align: left;
							.icon-info {
								display: block;
							}
						}
					}
				}
				.bat-productcard-controls {
					flex-direction: row;		
					.bat-quantity {
						margin: 0 5px 0 0;
						width: 50%;
						&:not([style*='display: none']) + .bat-ctas:not(:first-child) {
							margin: 0 0 0 5px;
							width: 50%;
						}
					}
				}
			}
		}
	}
}

// MD viewport 769+
@media (min-width: ($breakpoint-md + 1)) {
	bat-productcard-zonnic {
		height: calc(100% - 7px);
		margin: unset;
		.bat-productcard {			
			&--zonnic {
				.bat-productcard-content {
					flex-direction: column;
					.bat-productcard-image {
						width: 100%;
					}
					.bat-productcard-text {
						padding-left: 0;
						width: 100%;
						.icon-info {
							display: none;
						}
					}
				}
				.bat-productcard-controls {
					flex-direction: column;			
					.bat-quantity {
						display: flex;
						justify-content: space-between;
						margin: 0 0 10px;
						max-width: unset;
						padding: 7px 20px;
						width: 100%;		
						&-button {
							background-color: transparent;
							&:hover i::before {  // sass-lint:disable-line force-element-nesting force-pseudo-nesting
								color: $navy;
							}
						}	
					}
					.bat-ctas {
						margin: 0;
						width: 100%;		
						button {
							max-width: unset;
							padding: 15px 20px;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

// XL viewport 1500+
@include media-breakpoint-up(xl) {
	bat-productcard-zonnic {
		.bat-productcard {			
			&--zonnic {
				.bat-productcard-content {
					.bat-productcard-text {
						&-promo {
							.icon-info {
								display: block;
							}
						}
					}
				}
				.bat-productcard-controls {
					flex-direction: row;		
					.bat-quantity {
						margin: 0 5px 0 0;
						width: 50%;
						&:not([style*='display: none']) + .bat-ctas:not(:first-child) {
							margin: 0 0 0 5px;
							width: 50%;
							button {
								max-width: unset;
								padding: 15px 0;
							}
						}
					}
				}
			}
		}
	}
}

// Currently not supporting this so commented out in case needed later
// @media (prefers-color-scheme: dark) {
// 	bat-productcard-zonnic {
// 		.bat-productcard {
// 			&--zonnic {
// 				background-color: $black;
			
// 				.bat-productcard-content {
// 					.bat-productcard-text {
						
// 						&-description {
// 							a { color: $pale-gray; }
// 							p { color: $pale-gray; }
// 						}
						
// 						&-name { color: $pale-gray; }
						
// 						&-price {
// 							color: $pale-gray;
// 							.sale-price { color: $white; }
// 						}
	
// 						&-promo {
// 							background-color: transparent;	
// 							border: 2px solid $white;
// 							color: $white;
// 						}
// 					}
// 				}

// 				.bat-productcard-controls {
// 					.bat-ctas {		
// 						button {
// 							background-color: $white;
// 							border-color: $white;
// 							color: $black;
// 						}
// 					}
// 					.bat-quantity {
// 						border-color: $white;		
// 						&-button {
// 							i::before {  // sass-lint:disable-line force-pseudo-nesting
// 								color: $white;
// 							}
// 						}
// 						&-field {
// 							background-color: transparent;
// 							color: $white;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }
