// For AEM
.expander {
	
	bat-expander-default {
		
		.bat-expander {
			border-top: solid 1px $pale-gray;
		}
	}
	
	~ .expander {
		bat-expander-default {
			.bat-expander {
				border-top: 0;
			}
		}
		
	}
}

//-----------------------------------------------------------------| Default Exapander
bat-expander-default {
	.bat-expander {
		border-top: solid 1px $pale-gray;
	}
	
	~ bat-expander-default {
		
		.bat-expander {
			border-top: 0;
		}
	}
}

//-----------------------------------------------------------------| Bat Exapander
.bat-expander {
	border-bottom: 1px solid $pale-gray;
	margin-top: -2px;

	@keyframes expanderOut {
		0% {
			height: auto;
			opacity: 1;
			transform: scale(1);
		}
		100% {
			opacity: 0;
		}
	}
	
	@keyframes expanderIn {
		0% {
			opacity: 0;
			transform-origin: 50% 0;
		}
		100% {
			height: auto;
			opacity: 1;
		}
	}

	&-button {
		align-items: center;
		border: 0;
		outline: none;
		padding: 20px 0;
		text-align: left;

		@include media-breakpoint-down(md) {
			padding: 14px 0;
		}

		&-label {
			@include font-rem($font-sm, $font-sm + 6);
			color: $navy-dark;
			font-family: $primary-font;
			font-weight: 600;
			text-transform: unset;

			@include media-breakpoint-down(md) {
				@include font-rem($font-xsm, $font-xsm + 6);
			}
		}
		
		.chat & {
			padding: 14px 0;
			
			&:before {
				color: $navy;
				content: '\e909';
				font-family: 'icomoon';
				font-size: 24px;
			}
		}
		
		.phone & {
			padding: 14px 0;
			
			&:before {
				color: $navy;
				content: '\e925';
				font-family: 'icomoon';
				font-size: 24px;
			}
		}
		
		.email & {
	
			&:before {
				color: $navy;
				content: '\f0e0';
				font-family: 'Font Awesome 5 Free';
				font-size: 14px;
				padding: 0 4px;
			}
		}
		
		&-icon {
			
			&-close,
			&-open {
				
				i {
					height: 14px;
					padding: 0 8px;
					width: 40px;
				}
			}
			
			&-close {
				i {
					&::before {
						color: $navy;
						content: '';
					}
				}
			}
			
			&-open {
				i {
					&::before {
						color: $navy;
						content: '';
					}
				}
			}
		}
	}
	
	&-content {
		animation: expanderOut .5s ease-in-out 1 alternate both;
	
		.authorEditMode & { // sass-lint:disable-line class-name-format
			animation: none;
		}
		
		.active & {
			animation: expanderIn .5s ease-in-out 1 normal both;
			border-bottom: 0;
		}

		& > div {
			padding: 0 0 20px;
			
			.below & {
				padding: 20px 0 0;
			}
		}
	}
}

//-----------------------------------------------------------------| Zonnic Exapander
.bat-expander-zonnic {
	.bat-expander {
		&-button {
			align-items: center;
			display: flex;
			height: 60px;
			padding: 0;
		}
	}
	.bat-headline {
		h3 {
			@include font-rem(15px, 15px);
			color: $black;
			font-family: $primary-font;
			font-weight: 500;
			margin-top: 16px;
			text-transform: uppercase;
		}
	}
	.bat-text {
		p {
			color: $black;
			margin-top: 24px;
		}
	}
}

//-----------------------------------------------------------------| Zonnic Order Summary Exapander
.bat-order-summary {
	.bat-expander-zonnic {
		border: 0;
		padding: 0 16px;

		@include media-breakpoint-down(md) {
			background-color: $pale-gray;
		}
	
		.bat-expander-button-label {
			align-items: center;
			display: flex;
			font-weight: 700;
			justify-content: space-between;
			width: 100%;

			.your-items {
				@include font-rem(12px, 14px);
				color: $black;

				span {
					display: none;

					@include media-breakpoint-down(md) {
						@include font-rem(14px, 20px);
						display: block;
						font-weight: 300;
						text-transform: capitalize;
					}
				}
			}

			.your-total {
				@include font-rem(14px, 20px);
				color: $black;
				padding-right: 4px;
				padding-top: 2px;

				@include media-breakpoint-down(md) {
					font-size: 20px;
					font-weight: 800;
				}
			}
		}
	}
}

//-----------------------------------------------------------------| Zonnic PLP Exapander
.plp-content-expander {
	// Expander Button	
	.bat-expander {
		border: 0;
		padding-bottom: 36px;

		&-button {
			align-items: center;
			background-color: $white;
			border-radius: 0 0 4px 4px;
			box-shadow: 0 4px 12px $black-10;
			display: flex;
			height: 84px;
			justify-content: center;
			margin: 0 auto;
			width: 100%;
			z-index: 9;
	
			@include media-breakpoint-down(sm) {
				padding-left: 24px;
				padding-right: 16px;
				padding-top: 2px;
			}
	
			&-label {
				align-items: center;
				display: flex;
		
				.content-icon {
					background-repeat: no-repeat;
					height: 40px;
					margin-right: 20px;
					position: relative;
					width: 40px;
				}
				h4 {
					display: block;
					position: relative;
					
					@include media-breakpoint-up(sm) {
						display: inline;
					}

					span {
						display: block;
						font-size: unset;
						font-weight: 300;
						margin: 0;
						text-transform: none;
						
						@include media-breakpoint-up(sm) {
							display: inline;
							margin-left: 16px;
						}

						.xsm,
						&.xsm {
							@include font-rem($font-xsm, $font-xsm + 6);
						}
						.sm,
						&.sm {
							@include font-rem($font-sm, $font-sm + 8);
						}
						.reg,
						&.reg {
							@include font-rem($font-md, $font-md + 8);
						}
						.lg,
						&.lg {
							@include font-rem($font-md +2, $font-md + 10);
						}
						
						.xl,
						&.xl {
							@include font-rem($font-md + 4, $font-xlg + 10);
						}
					}
				}
			}
			
			&-icon {
				align-items: center;
				display: flex;
				margin-left: 0;
				margin-right: 8px;
				@include media-breakpoint-down(sm) {
					margin-left: auto;
				}
		
				i {
					padding: 0;
					padding-left: 8px;
				}
			}
		}
	}
	
	// Expander Content
	.bat-expander-content {
		bottom: 1px;
		position: relative;

		&-container {
			background-color: $white;
			border-radius: 0 0 10px 10px;
		}
	} 

	// Padding helper classes 
	&.padding-sides-small {
		.bat-expander-content-container {
			padding-left: 20px;
			padding-right: 20px;
		}
		
	}

	&.padding-sides-medium {
		.bat-expander-content-container {
			padding-left: 60px;
			padding-right: 60px;

			@include media-breakpoint-down(sm) {
				padding-left: 20px;
				padding-right: 20px;
			}

		}
		
	}

	&.padding-sides-large {
		.bat-expander-content-container {
			padding-left: 120px;
			padding-right: 120px;

			@include media-breakpoint-down(sm) {
				padding-left: 20px;
				padding-right: 20px;
			}

		}
		
	}

	&.padding-sides-xlarge {
		.bat-expander-content-container {
			padding-left: 180px;
			padding-right: 180px;

			@include media-breakpoint-down(md) {
				padding-left: 60px;
				padding-right: 60px;
			}

			@include media-breakpoint-down(sm) {
				padding-left: 20px;
				padding-right: 20px;
			}

		}
	}

	&.padding-top-bottom-small {
		.bat-expander-content-container {
			padding-bottom: 20px;
			padding-top: 20px;
		};
	}

	&.padding-top-bottom-medium {
		.bat-expander-content-container {
			padding-bottom: 60px;
			padding-top: 60px;

			@include media-breakpoint-down(sm) {
				padding-bottom: 20px;
				padding-top: 20px;
			}
		};
	}
	&.padding-top-bottom-large {
		.bat-expander-content-container {
			padding-bottom: 120px;
			padding-top: 120px;

			@include media-breakpoint-down(sm) {
				padding-bottom: 20px;
				padding-top: 20px;
			}
		};
	}
	&.padding-top-bottom-xlarge & {
		.bat-expander-content-container {
			padding-bottom: 180px;
			padding-top: 180px;

			@include media-breakpoint-down(md) {
				padding-bottom: 60px;
				padding-top: 60px;
			}

			@include media-breakpoint-down(sm) {
				padding-bottom: 20px;
				padding-top: 20px;
			}
		};
	}
}

//-----------------------------------------------------------------| Zonnic My Account 
.expander-account-zonnic {
	background-color: $whisper-gray;
	height: 100%;
 	
	.bat-expander-zonnic {
		border: 0;
		
		.bat-expander-button { // sass-lint:disable no-important
			display: flex !important;

			@include media-breakpoint-up(md) { // sass-lint:disable no-important
				display: none !important;
			}

			&-label {
				color: $black;
				font-weight: 700;
				padding-left: 20px;
				text-transform: uppercase;
			}

			&-icon {
				padding-right: 10px;
			}
		}
	}

	.bat-expander-content {
		padding-left: 20px;
		padding-right: 20px;
	}
}
