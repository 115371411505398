.bat-search {
	margin: 0 auto;
	max-width: $max-width-lg;
	min-height: 300px;
	padding-bottom: 15px;
	padding-top: 50px;
	
	&-input {
		position: relative;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: 50%;
		}
		
		form {
			align-items: center;
			display: flex;
			position: relative;
			
			input {
				height: 40px;
				padding: 5px 30px 5px 10px;
				width: 100%;
			}
		}
		
		&-button {
			background: none;
			border: 0;
			cursor: pointer;
			height: 24px;
			padding: 2px;
			position: absolute;
			right: 10px;
			top: 8px;
			width: 24px;
			
			i {
				font-size: 20px;
				&:before {
					font-size: 20px;
				}
			}
		}
	}
	
	&-results,
	&-no-results {
		margin-top: 40px;
	}
	
	&-results {

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			
			li {
				margin-bottom: 20px;
				width: 100%;
		
				@include media-breakpoint-up(md) {
					width: 50%;
				}
				
				a {
					-moz-hyphens: auto;
					-ms-hyphens: auto;
					
					/* Careful, this breaks the word wherever it is without a hyphen */
					-webkit-hyphens: auto;
					display: block;

					/* Adds a hyphen where the word breaks */
					hyphens: auto;
					overflow-wrap: break-word;
					text-decoration: underline;
					word-wrap: break-word;
				}
				
				h6 {
					margin-bottom: 10px;
				}
			}
		}
	}
	
	&-no-results {
		display: none;
		
		&.active {
			display: block;
		}
	}
}
