.bat-card-gtr-feature {
    &__wrapper {
        display: flex;
    }
    &__image {
        flex-shrink: 0;
        width: 64px;
    }
    &__content {
        flex-grow: 1;
        padding-left: 14px;
    }
    &__headline {
        h4 {
            
            font-weight: 700;
            font-size: 18px;
            line-height: 130%;
            color: #000000;
            word-break: break-word;
        }
    }
    &__text {
        margin-top: 1px;
        *:last-child {
            margin-bottom: 0;
        }
    }
    &__cta-list {
        margin-top: 9px;
    }
    @include media-breakpoint-up(md) {
        &__image {
            width: 81px;
            margin-top: 5px;
        }
        &__content {
            padding-left: 27px;
        }
        &__headline {
            h4 {
                font-weight: 700;
                font-size: 24px;
                line-height: 130%;
            }
        }
        &__text {
            margin-top: 8px;
        }
    }
}
// .align-item-center {
//     .bat-card-gtr-feature {
//         &__wrapper {
//             align-items: center;
//         }
//     }
// }
.in-store {
    .bat-card-gtr-feature {
        &__image {
            width: 110px;
        }
        &__content {
            padding-left: 29px;
        }
        &__headline {
            h4 {
                font-weight: 500;
                font-size: 32px;
                line-height: 150%;
            }
        }
        &__text {
            margin-top: 12px;
            max-width: 507px;
        }
    }
}

[dir='rtl'] {
    .bat-card-gtr-feature__content {
        padding-left: 0;
        padding-right: 14px;
        @include media-breakpoint-up(md) {
            padding-left: 0;
            padding-right: 27px;
        }
    }
}