.bat-gtr-image-vuse-logo, 
.bat-gtr-image-velo-logo {
    .bat-image {
        width: 151.21px;
        margin: 12px auto 30px;
    }

}
.bat-gtr-image-glo-logo {
    .bat-image {
        width: 90.61px;
        margin: 12px auto 30px;
    }
}