.bat-minicart {
	background-color: $whisper-gray;
	border: 1px solid $whisper-gray;
	bottom: 0;
	height: auto;
	margin-bottom: 0;
	max-height: none;
	padding: 0 12px;
	position: fixed;
	right: 0;
	top: 0;
	z-index: $modal-zindex;

	@include media-breakpoint-up(md) {
		max-width: 540px;
		padding-inline: 48px;
	}

	&-count {
		@include font-rem(20px, 24px);
		font-weight: 900;
		letter-spacing: .5px;
		padding: 30px 0 60px;
		text-align: center;
		text-transform: uppercase;
		@include media-breakpoint-up(md) {
			text-align: left;
			@include font-rem(22px, 26px);
		}
	}
	
	&-close {
		left: 12px;
		position: absolute;
		top: 28px;
		
		i {
			&:before {
				color: $navy;
				transition: color .5s ease;
			}
		}
		
		&:hover {
			i {
				&:before {
					color: $navy-dark;
				}
			}
		}
	}
	
	&-wrapper {
		background-color: $whisper-gray;
		padding: 0 0 60px;

		hr {
			border: 0;
			border-top: 1px solid $concrete ;
			margin-bottom: 20px;
		}
	}
	
	&-buttons {
		display: block;
		> div {
			.bat-cta-style {
				max-width: unset;
				width: 100%;
			}
		}
		
		@include media-breakpoint-up(sm) {
			display: flex;
		}
	}
	
	&-button-edit {
		margin-block: 25px 0;
		width: 100%;
		.bat-button {
			&.bat-cta-style {
				&.button-dark {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}

	&-button-checkout {
		width: 100%;
	}
	
	&-subtotal-value,
	&-subtotal-label {
		@include font-rem(18px, 28px);
		font-weight: 800;
		letter-spacing: 0;
		width: 49%;
		@include media-breakpoint-up(md) {
			letter-spacing: .5px;
			@include font-rem(22px, 26px);
			text-align: right;
		}
	}
	.bat-minicart-subtotal-prefix {
		@include font-rem(12px, 18px);
		font-weight: 300;
		letter-spacing: 0;
		text-transform: lowercase;
	}

	&-basket-total {
		align-items: baseline;
		display: flex;
		.bat-minicart-total-items {
			flex: 1 0 auto;
			margin-left: 8px;
		}
	}

	&-subtotal-label {
		@include font-rem(12px, 14px);
		letter-spacing: 1.2px;
		position: relative;
		text-transform: uppercase;
		top: -2px;
		@include media-breakpoint-up(md) {
			text-align: left;
		}
		> span {
			display: block;
		}
	}

	&-subtotal-value-quantity {
		@include font-rem(14px, 20px);
		font-weight: normal;
		margin-left: .5em;
	}
	
	&-cart-item-container {
		margin-top: 0;
		position: relative;
		
		&.active {
			
			&:after {
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $navy;
				border-radius: 50%;
				border-top: 10px solid  $navy;
				bottom: 0;     
				content: '';
				height: 80px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 80px;
				z-index: 1;
			}
			
			.bat-minicart-cart-item {
				opacity: .3;
				pointer-events: none;
			}
		}
	}
	
	&-cart-item {
		align-items: center;
		background-color: $white;
		margin-bottom: 6px;
		opacity: 1;
		transition: opacity .5s ease;
	
		&-product-container {
			&-content {
				align-items: center;
				display: flex;
				.update-item {
					margin-left: .5rem;
					@include media-breakpoint-up(md) {
						margin-left: 1rem;
					}
				}
			}
			.bat-quantity {
				padding-bottom: 0;
				position: relative;
				top: 0;
				.bat-quantity-field {
					pointer-events: unset;
				}
			}
			&-title {
				@include font-rem(16px, 24px);
				color: $navy;
				display: flex;
				font-weight: bold;
				margin-bottom: .5em;
				span {
					flex: 1;
				}
				.remove-item {
					background-color: $pale-gray;
					border-radius: 50%;
					height: 24px;
					margin-left: 15px;
					margin-top: -5px;
					width: 24px;
					@include media-breakpoint-up(md) {
						height: 32px;
						width: 32px;
					}
					i {
						@extend .icon;
						@extend .icon-close;
						color: $navy-dark;
						font-size: 16px;
						position: relative;
						top: 1px;
						transition: color .5s ease;
						@include media-breakpoint-up(md) {
							font-size: 22px;
						}
					}
					
					&:hover {
						
						i {
							color: $navy;
						}
					}
				}
			}

			&-strength {
				@include font-rem(16px, 24px);
				color: $navy;
				display: flex;
				font-weight: bold;
				margin-bottom: .5em;
			}
			
			&-nicotine-level,
			&-flavor,
			&-delivery {
				@extend p;
			}
			
			&-unit-price {
				@include font-rem(16px, 24px);
				bottom: 0;
				color: $navy;
				font-weight: bold;
				margin: 0;
				position: absolute;
				right: 1rem;
				@include media-breakpoint-up(md) {
					@include font-rem(20px, 28px);
				}
			}	
			
			&-quantity-label {
				@include font-rem(16px, 24px);
				color: $navy;
				display: block;
				font-weight: bold;
				margin-bottom: 2px;
				text-transform: uppercase;
				width: 100%;
			}
			
			&-quantity {
				margin-top: 10px;
				
				.update-item {
					margin-left: 20px;
					margin-top: 0;
					width: auto;
					
					span {
						@include font-rem(12px, 12px);
						color: $navy;
						transition: color .5s ease;
					}
					
					&:hover {
						
						span {
							color: $navy;
						}
					}
				}
			}
			
			&-delivery {
				@include font-rem(12px, 12px);
				margin-bottom: 20px;
				
				span {
					font-weight: 700;
				}
			}
			
			&-option {
				@include font-rem(14px, 14px);
				color: $system-red;
				margin-bottom: 10px;
				
				h6 {
					@include font-rem(12px, 12px);
				}
				
				p {
					@include font-rem(12px, 12px);
				}
			}
		}
	}
	&-empty-content {
		align-items: center;
		display: flex;
		flex-flow: column wrap;
		.bat-minicart-empty-icon {
			height: 42px;
			margin: 20px 0;
			width: 42px;
		}
	}
}


/* Safari */
@-webkit-keyframes spin {
	0% { 
		-webkit-transform: rotate(0deg); 
	}
	100% { 
		-webkit-transform: rotate(360deg);
	 }
}
  
@keyframes spin {
	0% { 
		transform: rotate(0deg); 
	}
	100% { 
		transform: rotate(360deg); 
	}
}
