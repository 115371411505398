/* Generates all the rules for the grid columns up to the given amount of column */
@mixin generate-columns($columnTotal, $breakPoint, $column: 1) {
	@if $column <= $columnTotal {
		> .aem-GridColumn.aem-GridColumn--#{$breakPoint}--#{$column} {
		width: ($column * 100% / $columnTotal);
		}

		@include generate-columns($columnTotal, $breakPoint, ($column + 1));
	}
}

/* Generates all the rules for the grid column offset up to the given amount of column */
@mixin generate-offsets($columnTotal, $breakPoint, $column: 0) {
	@if $column <= $columnTotal {
		> .aem-GridColumn.aem-GridColumn--offset--#{$breakPoint}--#{$column} {
		margin-left: ($column * 100% / $columnTotal);
		}

		@include generate-offsets($columnTotal, $breakPoint, ($column + 1));
	}
}

/* Generates all the rules for the grid and columns for the given break point and total of columns */
@mixin generate-grid-columns($breakPoint, $columnTotal) {
	@if $columnTotal > 0 {
		&.aem-Grid--#{$columnTotal} {
		@include generate-columns($columnTotal, $breakPoint);
		@include generate-offsets($columnTotal, $breakPoint);
		}

		&.aem-Grid--#{$breakPoint}--#{$columnTotal} {
		@include generate-columns($columnTotal, $breakPoint);
		@include generate-offsets($columnTotal, $breakPoint);
		}
	}
}

/* Generates all the rules for the grids and columns */
@mixin grid-loop($breakPoint, $columnTotal, $column: 1) {
	@if $column <= $columnTotal {
		@include generate-grid-columns($breakPoint, $column);
		@include grid-loop($breakPoint, $columnTotal, ($column + 1));
	}
}

/* API function to be called to generate a grid config */
@mixin generate-grid($breakPoint, $columnTotal){
	@include grid-loop($breakPoint, $columnTotal);
}

@mixin generate-grid-hide($breakPoint) {
	> .aem-GridColumn.aem-GridColumn--#{$breakPoint}--hide {
		/* hide behavior */
		display: none;
	}
}
  