.bat-message {
	
	&-text {
	
		p {
			padding-left: 30px;
			position: relative;
			
			&:before {
				content: '';
				display: inline-block;
				font-family: 'Font Awesome 5 Free';
				font-size: 18px;
				font-weight: 900;
				height: 20px;
				left: 1px;
				margin-right: 5px;
				margin-top: -2px;
				position: absolute;
				text-align: center;
				top: 3px;
				vertical-align: middle;
				width: 20px;
			}
		}
	}
	
	&.error {
	
		p {
			&:before {
				color: $red;
				content: '\f12a';
			}
		}
		
		p, 
		a {
			color: $red;
		}
	}
	
	&.warning {
		
		p {
			&:before {
				color: $yellow;
				content: '\f071';
			}
		}
		
		p, 
		a {
			color: $yellow;
		}
	}
	
	&.confirmation {
		
		p {
			&:before {
				color: $green;
				content: '\f00c';
			}
		}
	
		p, 
		a {
			color: $green;
		}
	}
	
	&.info {

		p {
			&:before {
				color: $black;
				content: '\f129';
			}
		}

		p, 
		a {
			color: $black;
		}
	}


	&-box {
		cursor: pointer;
		margin-bottom: 20px;
		padding: 20px 80px 20px 20px;
		position: relative;
		
		p {
			margin: 0;
		}
	
		&.error {
			background: $red-10;
		}
		
		&.warning {
			background: $yellow-10;
		}
		
		&.confirmation {
			background: $green-10;
		}
		
		&.info {
			background: $dark-10;
		}
		
		&:after {         
			color: $gray;
			content: '\f00d';
			display: inline-block;
			font-family: 'Font Awesome 5 Free';
			font-size: 24px;
			position: absolute;
			right: 10px;
			text-align: center;
			top: 10px;
			vertical-align: middle;
		}
		
		.velo-message-text {
			p {
				margin-bottom: 0;
			}
		}
	}
}
