// Live chat styling for Poland
.bat-chat-pl {
	:not(.authorMode) & { 
		cursor: pointer;
		display: flex;
		justify-content: flex-end;
		max-width: 1440px;
		position: fixed;
		z-index: 999;

		[id^=liveagent_button_online] {
			background: transparent;
			bottom: 0;
			cursor: pointer;
			height: 56px;
			margin-bottom: 106px;
			position: fixed;
			right: 20px;
			width: 63px;
			@include media-breakpoint-down(sm) {
				margin-bottom: 68px;
			}
		
			[id^=liveagent_button_offline] {
				display: none;
			}
		}
	}
}
