.bat-cta {
	
	&-list {
		
		&--horizontal {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			
			a, 
			button {
				flex-grow: 0;
				margin-bottom: $gutter / 2;
				margin-right: $gutter / 2;
			}
		}
		
		&--vertical {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: flex-start;
			
			a, 
			button {
				flex-grow: 0;
				margin-bottom: $gutter / 2;
			}
		}
	}
	
	&-style {
		//-----------------------------------------------------------------| Buttons
	
		&.button-dark {
			@extend .bat-button;
			@extend .bat-button--dark;
		}
		&.button-light {
			@extend .bat-button;
			@extend .bat-button--light;
		}
		&.button-secondary-dark {
			@extend .bat-button;
			@extend .bat-button--dark;
			@extend .bat-button--secondary;
		}
		&.button-secondary-light {
			@extend .bat-button;
			@extend .bat-button--light;
			@extend .bat-button--secondary;
		}
	
		//-----------------------------------------------------------------| Links
		&.link-dark {
			@extend .bat-link;
			@extend .bat-link--dark;
		}
		&.link-light {
			@extend .bat-link;
			@extend .bat-link--light;
		}
	}
}
