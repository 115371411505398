.bat {
		
	&-container {
		
		&--xxl {
			margin: 0 auto;
			max-width: $max-width-xxl;
		}
		
		&--xl {
			margin: 0 auto;
			max-width: $max-width-xl;
		}
		
		&--lg {
			margin: 0 auto;
			max-width: $max-width-lg;
		}
		
		&--md {
			margin: 0 auto;
			max-width: $max-width-md;
		}
		
		&--sm {
			margin: 0 auto;
			max-width: $max-width-sm;
		}
	}
}
