.bat-text {
	
	//-----------------------------------------------------------------| Box
	&--box {
		background-color: $pale-gray;
		border-radius: 20px;
		padding: 23px;
		width: 100%;
	}
}

bat-text-box {
	width: 100%;
}

.bat-section{
	.bat-section-content{
		.cmp-title__text{
			@media (max-width: 767px) {
				font-size: 28px;
				padding-top: 5px;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}
