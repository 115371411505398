.bat-agegate {
	
	//-----------------------------------------------------------------| Default
	&--default {
		
		&-content {
			padding: $gutter;
			
			@include media-breakpoint-up(sm) {
				max-width: 450px;
				padding: $gutter * 2;
			}
			
			&-image {
				margin-bottom: $gutter;
				width: 100px;
			}
			
			&-greeting {
				
				&-headline {
					margin-bottom: $gutter;
				}
				
				&-text {
					margin-bottom: $gutter;
				}
				
				&-cta-list {
					button {
						margin: $gutter / 2;
						min-width: 158px;
					}
				}
				
				&.in-active {
					display: none;
				}
			}
			
			&-error-message {
				display: none;
				
				&.active {
					display: block;
				}
			}
		}
	}
}
