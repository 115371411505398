.bat-form {
	margin: 0 auto;

	&-step {
		display: none;
		
		&.active {
			display: block;
		}
	}
	
	&-step-head {
		display: flex;
		flex-direction: row;
		margin: 20px 0 50px;
		
		span {
			
			&.number {
				align-self: flex-end;
				background-color: $off-white;
				border: inherit;
				border-radius: 50%;
				color: $black;
				font-size: 1.25rem;
				font-weight: 900;
				height: 36px;
				letter-spacing: -.023rem;
				line-height: 24px;
				margin: 0;
				padding: 6px 0;
				text-align: center;
				width: 36px;
				
				&.active {
					background-color: $gray;
				}
			}
			
			&.divider {
				align-self: center;
				background-color: $off-white;
				border: 0;
				border-radius: 0;
				display: inline-block;
				height: 5.5px;
				margin: 0;
				width: 50px;
				
				&.active {
					background-color: $gray;
				}
			}
		}
	}
	
	&-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 0 20px;
		
		&-text {
			margin-bottom: 20px;
			width: 100%;
			
			h1, 
			h2, 
			h3, 
			h4, 
			h5, 
			h6 {
				margin-bottom: 10px;
			}
		}
		
		&--hidden {
			display: none;
			
			&.active {
				display: block;
			}
		}
	}
	
	&-divider {
		align-items: center;
		display: flex;
		flex-basis: 100%;
		margin: 50px 0;
		
		&:after, 
		&:before {
			background: $gray;
			content: '';
			flex-grow: 1;
			font-size: 0;
			height: 1px;
			line-height: 0;
			margin: 0 8px;
		}
	}
	
	&-field {
		margin-bottom: 20px;
		position: relative;
		width: 100%;
	
		label {
			background: $white;
			color: $gray;
			display: block;
			left: 11px; 
			padding: 0 4px;
			position: absolute;
			top: 22px;
			transition: all .1s ease-in;
			z-index: 1;
			
			&.blurred  {
				color: $black;
				top: -7px;
			}
		}
		
		&-set {
			
			legend {
				color: $black;
				font-weight: 900;
				left: 0;
				margin-bottom: 10px;
				position: relative;
				top: 0;
			}
			
			label {
				background: transparent;
				display: flex;
				left: 0; 
				padding: 0;
				position: relative;
				top: 0;
			}
		}
		
		&--read-only {
			
			label {
				color: $black;
				top: -7px;
			}
			
			input {
				border: solid 2px $black;
				cursor: not-allowed;
				padding-left: 30px;
			}
		
			&:before {
				color: $black;
				content: '\f023';
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				height: 34px;
				left: 10px;
				pointer-events: none;
				position: absolute;
				top: 19px;
			}
		}
		
		&--hidden {
			display: none;
			
			&.active {
				display: block;
			}
		}
		
		&-tip {
			height: 16px;
			position: absolute;
			right: 10px;
			top: 19px;
			width: 16px;
			z-index: 10;
			
			i {
				cursor: help;
				height: 16px;
				position: relative;
				width: 16px;
				z-index: 1;
				
				&:before {
					color: $black;
					content: '\f059';
					font-family: 'Font Awesome 5 Free';
					font-size: 15px;
					font-weight: 900;
				}
				
				span {
					@include font-rem(14px, 14px);
					background-color: $off-white;
					border: solid 1px $black;
					bottom: 0;
					box-shadow: 3px 6px 10px 0 $dark-30;
					display: none;
					left: 0;
					padding: 10px;
					position: absolute;
					transform: translate(-88%, 103%); 
					width: 300px;
					z-index: 1;
					
					&:before {
						border: solid transparent;
						border-bottom-color: $black;
						border-width: 6px;
						content: '';
						margin-right: -6px;
						position: absolute;
						right: 28px;
						top: -13px;
						z-index: 1;
					}
					
					&:after {
						border: solid transparent;
						border-bottom-color: $off-white;
						border-width: 5px;
						content: '';
						margin-right: -5px;
						position: absolute;
						right: 28px;
						top: -10px;
						z-index: 2;
					}
				}
			
				&:hover {
					
					span {
						display: block;
					}
				}
			}
		}
	}
	
	.bat-message {
		display: none;
		
		p {
			line-height: 1rem;
			padding-left: 20px;
			
			&:before {
				font-size: 12px;
				height: 15px;
				width: 15px;
			}
		}
		
		&.active {
			display: block;
		}
	}
	
	//-----------------------------------------------------------------| Default
	&--default {
		
		@include media-breakpoint-up(sm) {
			
			&-submit {
				text-align: center;
				
				@include media-breakpoint-up(sm) {
					text-align: left;
				}
			}
			
			&-address {
			
				&-street {
					width: 75%;
				}
				
				&-apt {
					width: 23%;
				}
				
				&-zip-code {
					width: 30%;
				}
				
				&-city {
					width: 43%;
				}
				
				&-state {
					width: 23%;
				}
			}
		}
	}
	//-----------------------------------------------------------------| Log In
	&--login {
		
		@include media-breakpoint-up(sm) {
			
			&-email {
				width: 75%;
			}
			
			&-password {
				width: 75%;
			}
			
			&-submit {
				align-items: center;
				display: flex;
				
				a {
					padding: 0 0 0 30px;
					text-align: center;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	//-----------------------------------------------------------------| Username
	&--username {
		
		@include media-breakpoint-up(sm) {
			
			&-submit {
				text-align: center;
				
				@include media-breakpoint-up(sm) {
					text-align: left;
				}
			}
		
			&-address {
				
				&-street {
					width: 75%;
				}
				
				&-apt {
					width: 23%;
				}
				
				&-zip-code {
					width: 30%;
				}
				
				&-city {
					width: 43%;
				}
				
				&-state {
					width: 23%;
				}
			}
			
			&-dob {
				
				&-month {
					width: 29%;
				}
				
				&-day {
					width: 29%;
				}
				
				&-year {
					width: 38%;
				}
			}
		}
	}
	//-----------------------------------------------------------------| Password
	&--password {
		
		&-submit {
			text-align: center;
			
			@include media-breakpoint-up(sm) {
				text-align: left;
			}
		}
		
		@include media-breakpoint-up(sm) {
		
			&-address {
				
				&-street {
					width: 75%;
				}
				
				&-apt {
					width: 23%;
				}
				
				&-zip-code {
					width: 30%;
				}
				
				&-city {
					width: 43%;
				}
				
				&-state {
					width: 23%;
				}
			}
			
			&-dob {
				
				&-month {
					width: 29%;
				}
				
				&-day {
					width: 29%;
				}
				
				&-year {
					width: 38%;
				}
			}
		}
	}
	//-----------------------------------------------------------------| Registration
	&--registration {
		
		&-submit {
			text-align: center;
			
			@include media-breakpoint-up(sm) {
				text-align: left;
			}
		}
		
		.bat-form-block--hidden  {
				
			label {
				color: $black;
				font-weight: 900;
				left: 0;
				margin-bottom: 10px;
				position: relative;
				top: 0;
			}
		}
		
		@include media-breakpoint-up(sm) {
		
			&-address {
				
				&-street {
					width: 75%;
				}
				
				&-apt {
					width: 23%;
				}
				
				&-zip-code {
					width: 30%;
				}
				
				&-city {
					width: 43%;
				}
				
				&-state {
					width: 23%;
				}
			}
			
			&-dob {
				
				&-month {
					width: 29%;
				}
				
				&-day {
					width: 29%;
				}
				
				&-year {
					width: 38%;
				}
			}
			
			&-ssn {
				width: 29%;
			}
		}
	}
}
