.bat-card {
	background-color: inherit;
	
	//-----------------------------------------------------------------| Default
	&--default {
		background-color: inherit;
		
		@include media-breakpoint-up(lg) {
			max-width: 320px;
		}
		
		&-tile {
			background-color: inherit;
			margin-bottom: 20px;
			
			&:before {
				background-color: inherit;
			}
			
			&:after {
				background-color: inherit;
			}
			
			&-headline {
				background-color: inherit;
				padding: 20px 0;
				
				h3 {
					@include font-rem(20px, 20px);
				}
			}
		
			a {
				background-color: inherit;
				display: block;
				
				&:after {
					background-color: inherit;
				}
			}
		}
	}
	
	//-----------------------------------------------------------------| Hero
	&--hero {
		display: block;
		
		@include media-breakpoint-up(lg) {
			align-content: center;
			display: flex;
			justify-content: flex-start;
			margin: 0 auto;
			max-width: $max-width-xxl;
		}
		
		&-tile {
			padding: 0 $gutter;
			width: 100%;
			
			@include media-breakpoint-up(lg) {
				flex: 0 1 50%;
			}
		}
		
		&-content {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
			padding: 0 $gutter;
			width: 100%;
			
			@include media-breakpoint-up(lg) {
				flex: 0 1 50%;
			}
			
			&-headline {
				
				h2 {
					@include font-rem(40px, 40px);
				}
			}
			
			
			&-cta-list {
		
				a {
					margin: $gutter $gutter 0 0;
				}
			}
		}
	}
	
	//-----------------------------------------------------------------| Category
	&--category {
		margin: 0 auto;
		max-width: 210px;
		
		&-tile {
			margin-bottom: 20px;
			width: 100%;
		}
		
		&-content {
			
			&-headline {
				text-align: center;
				
				h4 {
					@include font-rem(25px, 25px);
				}
			}
			
			&-text {
				text-align: center;
			}
			
			&-cta-list {
				margin-top: 20px;
				text-align: center;
				
				a,
				button {
					display: block;
					margin: 0 auto $gutter;
				}
			}
		}
	}
	//-----------------------------------------------------------------| Step
	&--step {
		display: flex;
		flex-direction: row-reverse;
		margin: 0 auto;
		max-width: 450px;
	
		//AEM author mode 
		.authorEditMode & { // sass-lint:disable-line class-name-format
			height: 450px !important; // sass-lint:disable-line no-important
			min-height: 0 !important; // sass-lint:disable-line no-important
		}
		
		&-content {
			display: flex;
			flex-basis: 50%;
			flex-direction: column;
			height: inherit;
			justify-content: flex-start;
			margin-right: auto;
			margin-top: 100px;
			
			&-headline {
				
				h5 {
					@include font-rem(20px, 20px);
					margin-bottom: 0;
				}
			}

			&-text {
				p {
					@include font-rem(16px, 20px);
				}
			}
		}
		&-tile {
			flex-basis: 50%;
			height: inherit;
		}
	}
	//-----------------------------------------------------------------| Hustle
	&--hustle {
		margin: 0 auto;
		max-width: 512px;
		
		&-tile {
			&-headline {
				padding-bottom: 28px;
				padding-right: 10px;
				text-align: end;
				
				h3 {
					span {
						color: $gray;
					}
				}
			}
		}

		&-text {
			margin: 24px 0;
			text-align: center;

			p {
				padding: 0 5px;
			}
		}

		&-cta-list {
			text-align: center;
		}
		
		button {
			
			&[data-cta-type='video'] {
				position: relative;
				
				&:after {
					color: $white;
					content: '\f04b';
					font-family: 'Font Awesome 5 Free';
					@include font-rem(40px, 40px);
					position: absolute;
					transition: transform .2s linear;
				}
			}
			&:hover {
				&:after {
					transform: scale(1.1);
				}
			}
		}
	}
}

//Background Classes 
bat-card-step {

	&.step1,
	&.step2,
	&.step3,
	&.step4  {
		position: relative;
		
		&:before {
			color: $gray;
			content: '';
			font-size: 220px;
			font-weight: 900;
			line-height: 220px;
			text-align: center;
			width: 100%;
			z-index: -1;
			
			@include media-breakpoint-up(md) {
				font-size: 160px;
				line-height: 160px;
			}

			@include media-breakpoint-up(lg) {
				font-size: 220px;
				line-height: 220px;
			}
		}
		
	}

	&.step1 {
		
		&:before {
			content: '01';
		}
	}
	
	&.step2 {

		&:before {
			content: '02';
		}
	}
	
	&.step3 {

		&:before {
			content: '03';
		}
	}

	&.step4 {

		&:before {
			content: '04';
		}
	}
}
