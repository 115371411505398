.bat-minicart {
	background-color: $white;
	box-shadow: 0 6px 12px 0 $dark-20;
	height: auto;
	margin-bottom: 1rem;
	max-height: calc(100vh - 19rem);
	max-width: 400px;
	overflow: auto;
	padding: 1rem;
	position: absolute;
	right: 0;
	top: 10rem;
	width: 95%;
	z-index: $mini-cart;
	
	&-count {
		font-weight: bold;
		letter-spacing: -.7px;
		text-align: center;
		text-transform: uppercase;
	}
	&-subtotal-label {
		display: inline-block;
		font-weight: 500;
		letter-spacing: -.7px;
		width: calc(50% - .5rem);
	}
	&-subtotal-value {
		display: inline-block;
		font-weight: bold;
		letter-spacing: -.7px;
		text-align: right;
		width: calc(50% - .5rem);
	}
	&-button-checkout,
	&-button-edit {
		margin-bottom: 1rem;
		margin-top: 1rem;
		text-align: center;
		text-transform: uppercase;
	}
	&-cart-item {
		align-items: flex-start;
		display: flex;
		padding-bottom: 1rem;
		padding-top: 1rem;
		
		&-img {
			flex: 0 0 30%;
			max-width: 30%;
			padding: 0 20px 0 10px;
			position: relative;
			img {
				max-width: 100%;
			}
		}
		
		&-product-container {
			flex: 0 0 70%;
			max-width: 70%;
			padding: 0 10px;
			position: relative;

			&-title {
				display: flex;
				
				.remove-item {
					height: 20px;
					margin-left: 20px;
					width: 20px;
					
					i {
						@extend .fa;
						@extend .fa-times-circle;
						font-size: 20px;
					}
				}
			}
			&-nicotine-level,
			&-flavor,
			&-delivery {
				margin-bottom: 0;
			}
			
			&-unit-price {
				font-weight: bold;
				margin-bottom: 1rem;
				margin-top: 1rem;
			}	
			&-quantity-label {
				margin-bottom: 0;
				margin-right: 10px;
			}
			&-quantity {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				
				.bat-quantity {
					margin: 0;
					padding: 0;
					
					&.changed + .update-item {
						display: block;
					}
				}
				
				.update-item {
					display: none;
					margin-top: 10px;
				}
			}
			&-delivery {
				margin-bottom: 1rem;
			}
			&-option {
				margin-bottom: 1rem;
			}
		}
	}
}
