.bat-hero-gtr {
	&--edu {
		&__image {
			padding-top: 101%;
			position: relative;
			z-index: 0;
			img {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				object-fit: cover;
			}
		}
		&__content {
			text-align: center;
			padding: 77px 24px 0;
			position: relative;
			z-index: 1;
			margin-top: -194px;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 6.1%,
				#000000 16%
			);

			&__wrapper {
				padding: 45px 20px 0;
				background-color: #fff;
				letter-spacing: $letter-spacing_base;
				padding-bottom: calc((115 / 767 * 100%) + 30px);

				.in-store & {
					padding-top: 75px;
				}
			}
			&__topsubtitle {
				color: #fff;
				margin-bottom: 10px;
				p {
					line-height: 1.2;
				}
			}
			&__headline {
				margin-bottom: 14px;
				.bat-headline-style {
					color: #000;
				}
				.bat-headline h3 {
					@media (max-width: 767px) {
						font-size: 28px;
					}
				}
			}
			&__text p {
				color: #000;
				@media (max-width: 767px) {
					font-size: 15px;
				}
				@media (max-width: 480px) {
					padding-bottom: 20px;
				}
			}
			//&:before {
			//	content: '';
			//	position: absolute;
			//
			//	z-index: -2;
			//	bottom: -61px;
			//	height: 62px;
			//	left: 0;
			//	right: 0;
			//	background-color: #000;
			//	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 3%);
			//}
			//&:after {
			//	background: linear-gradient(
			//		180deg,
			//		#0047b8 19.86%,
			//		#009681 100%
			//	);
			//	content: '';
			//	position: absolute;
			//	bottom: -72px;
			//	height: 73px;
			//	left: 0;
			//	right: 0;
			//	z-index: -5;
			//	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 23%);
			//}
		}
	}
	&--cta {
		padding: 70px 12px 40px;
		background-image: url('/content/dam/gtr/images/webp-gtr/bg-cta.webp');
		background-size: cover;
		background-repeat: no-repeat;
		color: #fff;

		&__wrapper {
			display: flex;
			align-items: center;

			@include media-breakpoint-down(xs) {
				padding: 10px 0;
			}
		}
		&__image {
			max-width: 45%;
			flex: 0 0 45%;

			@include media-breakpoint-down(xs) {
				transform: scale(1.5);
			}
		}
		&__content {
			padding: 0 10px;
			flex: 0 0 55%;
			max-width: 55%;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
			text-align: center;

			h1,
			h2,
			h3,
			h4 {
				color: #fff;
			}
			&__text {
				p {
					font-size: 14px;
					line-height: 150%;
					letter-spacing: 0.02em;

					@include media-breakpoint-down(sm) {
						b {
							display: block;
							margin: 5px 0;
						}
					}
				}
				h2 {
					font-size: 25px;
					line-height: 150%;
				}
				h3 {
					font-size: 20px;
					line-height: 150%;
				}
			}
			&__text,
			&__text p {
				font-weight: 700;
				font-size: 14px;
				line-height: 150%;

				b {
					font-size: 1.5em;
				}
			}

			&__topsubtitle {
				> * {
					font-weight: bold;
					@include font-rem(25px, 1.5);
					letter-spacing: 0.02em;
				}
			}

			&__topsubtitle,
			&__text {
				letter-spacing: $letter-spacing_s;
			}
		}
		&__cta-list {
			margin-top: 24px;
			text-align: center;

			.button-dark {
				border-radius: 50px;

				@include media-breakpoint-down(sm) {
					min-height: 40px;
					padding: 10px 20px;
					min-width: 169px;
				}
			}
		}
		body:not(.airport-view) & {
			&__content {
				&__text {
					@include media-breakpoint-up(md) {
						h2 {
							font-size: 55px;
						}
						h3 {
							font-size: 45px;
						}
					}
				}
				&__text,
				&__text p {
					@include media-breakpoint-up(md) {
						font-size: 20px;
					}

					@include media-breakpoint-up(lg) {
						font-size: 32px;
					}
				}

				&__topsubtitle {
					> * {
						@include media-breakpoint-up(md) {
							@include font-rem(32px, 1.5);
						}

						@include media-breakpoint-up(lg) {
							@include font-rem(55px, 1.5);
						}
					}
				}
			}
			&__cta-list {
				.button-dark {
					@include media-breakpoint-up(md) {
						max-width: 225px;
						width: 100%;
					}
				}
			}
		}
	}

	&--product {
		&__image {
			//padding-top: (503 / 375 * 100%);
			//position: relative;
			//z-index: 5;
			//img {
			//	position: absolute;
			//	top: 0;
			//	left: 0;
			//	object-fit: cover;
			//	height: 100%;
			//}
		}
		&__content {
			overflow: hidden;
			position: relative;
			z-index: 10;
			text-align: center;
			padding: 102px 30px 0;
			padding-bottom: (55 / 375 * 100%);
			margin-top: -22%;
			background: linear-gradient(
				180deg,
				rgba(48, 48, 45, 0) 3.18%,
				#30302d 11.77%
			);
			color: #fff;
			&:after {
				content: '';
				position: absolute;
				bottom: -1px;
				left: -1px;
				right: -1px;
				padding-top: (55 / 375 * 100%);
				background-image: url('/content/dam/gtr/images/gtr/rectangle-bottom.svg');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&__headline {
				margin-bottom: 20px;
				.bat-headline-style,
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					color: #fff;
				}
			}
			&__topsubtitle {
				margin-bottom: 19px;
			}
			&__text {
				margin-bottom: 21px;
				p {
					line-height: 1.2;
				}
			}
			&__cta-list {
				padding-bottom: 45px;
				.bat-cta-style {
					text-align: center;
					width: 275px;
					max-width: 100%;
					margin: 0 auto;
				}
			}
		}
		@include media-breakpoint-up(md) {
			position: relative;
			overflow: hidden;
			padding: 0 40px;
			padding-bottom: (121 / 1920 * 100%);
			background-color: #30302d;
			&:after {
				z-index: 10;
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background-image: url('/content/dam/gtr/images/webp-gtr-instore/rectangle-bottom.webp');
				background-repeat: no-repeat;
				background-size: cover;
				padding-bottom: (121 / 1920 * 100%);
			}
			&__image {
				padding-top: 0;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				img {
					position: absolute;
					right: 0;
					top: 0;
					object-fit: initial;
					height: auto;
				}
			}
			&__content {
				margin-top: 0;
				background: transparent;
				max-width: 621px;
				width: 50%;
				padding: 78px 0 12px;
				&:after {
					content: none;
				}
			}
		}
		@include media-breakpoint-up(xl) {
			padding-left: 153px;
			padding-right: 153px;
			&__content {
				width: 40%;
			}
		}
		@media (min-width: 1600px) {
			&__content {
				&__headline {
					margin-bottom: 34px;
				}
				&__subtitle {
					margin-bottom: 43px;
				}
				&__text {
					margin-bottom: 50px;
				}
				&__cta-list {
					padding-bottom: 0;
					.bat-cta-style {
						width: 425px;
						max-width: 100%;
						margin: 0 auto;
					}
				}
			}
		}
	}
}
.rectangle-top {
	.bat-hero-gtr--cta {
		position: relative;
		overflow: hidden;
		padding-top: 10.6666666667%;
		&:before {
			content: '';
			position: absolute;
			top: -0.75px;
			left: 0;
			right: 0;
			pointer-events: none;
			padding-top: 10.6666666667%;
			background-image: url('/content/dam/gtr/images/gtr/rectangle-top.svg');
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
}
.gtr-product-hero-velo {
	.bat-hero-gtr {
		&--product {
			@media (max-width: 767px) {
				&__content {
					background: linear-gradient(
						180deg,
						rgba(49, 67, 181, 0) 3.18%,
						#3143b5 12.64%,
						#011e46 73.73%
					);
				}
			}
			@media (min-width: 768px) {
				&__image img {
					object-fit: cover;
					object-position: top left;
					width: 100%;
					height: 100%;
				}
				&__content {
					padding: 161px 0 140px;
					margin-left: auto;
					background: transparent;
				}
			}
		}
	}
}
.gtr-product-hero-glo {
	.bat-hero-gtr {
		&--product {
			&__content {
				@media (max-width: 767px) {
					background: linear-gradient(
						180deg,
						rgba(45, 33, 69, 0) 0%,
						#2d2145 12%
					);
				}
			}

			@media (min-width: 768px) {
				&__image img {
					object-fit: cover;
					object-position: top left;
					width: 100%;
					height: 100%;
				}
				&__content {
					padding: 161px 0 140px;
					margin-left: auto;
					background: transparent;
				}
			}
		}
	}
}

//Airport
//hide button in content div
@include media-breakpoint-down(sm) {
	.bat-hero-gtr--cta {
		&__cta-list.inside-content {
			display: none;
		}
	}
}
@include media-breakpoint-up(md) {
	body .bat-wrapper bat-hero-gtrproduct.bat {
		max-width: 100%;
	}
	body:not(.airport-view) {

		//ctr hero top/middle/bottom including top hero banner homepage
		.bat-hero-gtr--cta {
			&__cta-list:not(.inside-content) {
				display: none;
			}
		}
	
		.bat-hero-gtr--cta__section {
			.bat-hero-gtr--cta {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				padding-bottom: 20px;
				padding-top: 60px;
	
				&__wrapper {
					align-items: center;
					max-width: 1500px;
					width: 100%;
				}
	
				&__image {
					max-width: 50%;
					flex: 0 0 50%;
	
					img {
						margin: 0 auto;
					}
				}
	
				&__content {
					max-width: 480px;
					flex: 0 0 50%;
				}
			}
	
			&.top-pos {
				.bat-hero-gtr--cta {
					position: relative;
					padding: 2% 0 6%;
					display: flex;
					background-position: top left;
	
					&:after {
						content: '';
						position: absolute;
						bottom: -1px;
						left: 0;
						right: 0;
						padding-top: (177 / 1920 * 100%);
						background-image: url(/content/dam/gtr/images/webp-in-store/home-hero-divider.webp);
						background-repeat: no-repeat;
						background-size: cover;
						width: 100%;
					}
	
					&__wrapper {
						max-width: 1650px;
					}
	
					&__content {
						&__topsubtitle {
							> * {
								@include font-rem(32px, 1.3);
	
								@include media-breakpoint-up(lg) {
									@include font-rem(66px, 120%);
								}
							}
						}
					}
				}
			}
	
			&.mid-pos {
				position: relative;
				z-index: 1;
				margin-top: -100px;
	
				.bat-hero-gtr--cta {
					background-image: url('/content/dam/gtr/images/webp-in-store/bg-register-mid.webp');
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
	
					&__wrapper {
						width: 75%;
						max-width: 1200px;
	
						@media (min-width: 1366px) {
							width: 70%;
						}
					}
	
					&__cta-list:not(.inside-content) {
						display: flex;
						width: 25%;
						align-self: center;
	
						@media (min-width: 1366px) {
							width: 30%;
						}
					}
	
					&__cta-list.inside-content {
						display: none;
					}
	
					&__content {
						&__topsubtitle,
						&__text {
							p {
								font-size: 20px;
	
								@include media-breakpoint-up(lg) {
									font-size: 30px;
								}
							}
						}
					}
				}
			}
	
			&.bottom-pos {
				.bat-hero-gtr--cta {
					background-image: url('/content/dam/gtr/images/webp-in-store/bg-register.webp');
					min-height: 550px;
	
					&__wrapper {
						max-width: 1500px;
					}
				}
	
				&.global-home {
					.bat-hero-gtr--cta {
						margin-top: -100px;
						position: relative;
					}
				}
			}
		}
	
		// global
		.bat-hero-gtr--cta__section.global {
			.bat-hero-gtr--cta {
				&__image {
					img {
						max-width: 529px;
					}
				}
			}
		}
	
		//in store
		.bat-hero-gtr--cta__section.in-store {
			.bat-hero-gtr--cta__cta-list {
				.bat-cta-style {
					width: 256px;
					max-width: 100%;
				}
			}
	
			&,
			&.mid-pos,
			&.bottom-pos {
				.bat-hero-gtr--cta {
					&__content {
						&__topsubtitle {
							> * {
								@include font-rem(72px, 120%);
							}
						}
	
						&__text {
							p {
								font-size: 30px;
	
								b {
									font-size: 45px;
								}
							}
						}
					}
				}
			}
	
			&.top-pos {
				.bat-hero-gtr--cta {
					&__content {
						max-width: 600px;
	
						&__topsubtitle {
							> * {
								@include font-rem(80px, 120%);
							}
						}
	
						&__text {
							p {
								font-size: 36px;
	
								b {
									font-size: 48px;
								}
							}
						}
					}
	
					&__cta-list {
						.bat-cta-style {
							min-height: 60px;
							width: 246px;
							max-width: 100%;
							padding: 10px 35px;
						}
					}
				}
			}
	
			&.mid-pos {
				.bat-hero-gtr--cta {
					&__wrapper {
						max-width: 1280px;
					}
				}
			}
	
			&.bottom-pos {
				.bat-hero-gtr--cta {
					&__content {
						max-width: none;
						width: 50%;
					}
				}
			}
		}
	}
}
.in-store {
	.bat-hero-gtr {
		&--edu {
			&__content {
				margin-top: 129px;
				&__headline {
					margin-bottom: 24px;
				}
			}
		}
	}
	&.bat-hero-gtr--edu__section {
		.title {
			margin-bottom: 11px;
		}
	}
	&.bat-hero-gtr--cta__section {
		&.bottom-pos {
			.bat-hero-gtr--cta {
				padding: 109px 0 50px;
				&__wrapper {
					max-width: 1261px;
				}
				&__image {
					width: (677 / 1261 * 100%);
					height: 533px;
					flex: unset;
					max-width: none;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						max-height: 100%;
						display: block;
					}
				}
				&__content {
					padding-left: 57px;
					padding-right: 0;
					width: ((527 + 57) / 1261 * 100%);
				}
			}

			.bat-hero-gtr--cta__content__topsubtitle {
				& > * {
					line-height: 1.5;
				}
				p {
					margin-bottom: 0;
				}
				margin-bottom: 12px;
			}
			.bat-hero-gtr--cta__cta-list {
				margin-top: 38px;
			}
			.bat-hero-gtr--cta__content__text {
				p {
					margin-bottom: 0;
					font-size: 30px;
					line-height: 1.5;
				}
			}
		}
	}
}
//responsive cta hero banner global
@include media-breakpoint-down(sm) {
	.bat-hero-gtr--cta__section {
		&.mid-pos {
			.bat-hero-gtr--cta {
				background-image: url('/content/dam/gtr/images/webp-in-store/bg-register-mid-mobile.webp');
				position: relative;
				margin-top: calc(-115 / 767 * 100% - 30px);
				z-index: 1;
			}
		}

		&.bottom-pos {
			.bat-hero-gtr--cta {
				background-image: url('/content/dam/gtr/images/webp-in-store/bg-register-mobile.webp');
				position: relative;
				margin-top: -1px;
			}

			&.global-home {
				.bat-hero-gtr--cta {
					margin-top: -135px;
				}
			}
		}
	}

	.bat-hero-gtr--edu__section {
		.bat-section-content {
			padding-top: 30px;

			> .hero {
				margin-top: 30px;
			}
		}
	}
}

//edu hero banner global + in store
.bat-hero-gtr--edu__section {
	.title {
		margin-bottom: 15px;

		&,
		& + .text {
			text-align: center;
			padding: 0 15px;
		}
	}
}

//responsive edu hero banner global
@include media-breakpoint-down(sm) {
	.bat-hero-gtr--edu__section {
		.bat-section-content {
			padding-top: 30px;

			> .hero {
				margin-top: 30px;
			}
		}

		.title {
			max-width: 360px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

//top hero banner education page global & instore

body:not(.airport-view) {
	@include media-breakpoint-up(md) {
		.bat-hero-gtr--edu__section {
			background: url('/content/dam/gtr/images/webp-in-store/bg-hero-edu.webp')
				70% 50% no-repeat;
			background-size: cover;
			color: #fff;
			padding-top: 160px;

			.title {
				h1 {
					color: #fff;
				}

				&,
				& + .text {
					width: 60%;
					max-width: 400px;

					@include media-breakpoint-up(lg) {
						max-width: 670px;
					}

					@include media-breakpoint-up(xl) {
						margin-left: 9vw;
					}
				}

				& + .text {
					p {
						font-size: 13px;
					}
				}
			}

			.bat-hero-gtr--edu {
				&__image {
					padding: 0;
					display: none;
				}

				&__content {
					margin-top: 100px;
					background: none;
					padding: 0;

					&:before,
					&:after {
						display: none;
					}

					&__wrapper {
						max-width: 1350px;
						width: 81%;
						margin: 0 auto;
						padding-bottom: 150px;
					}

					&__text,
					&__headline {
						width: 81%;
						max-width: 1087px;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}

			//instore
			&.in-store {
				.title {
					&,
					& + .text {
						@include media-breakpoint-up(lg) {
							max-width: 780px;
						}
					}

					& + .text {
						p {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}

.gtr-vuse-go-cta.bat-hero-gtr--cta__section.bottom-pos {
	margin-top: -11%;
	body:not(.airport-view) & { 
		@media (min-width: 768px) {
			margin-top: -5%;
		}
	}
}
body:not(.airport-view) {
	.bat-hero-gtr--cta__section.top-pos.in-store {
		.bat-hero-gtr--cta {
			padding-top: 181px;
			padding-left: 98px;
			padding-right: 182px;
			overflow: hidden;
			&:after {
				bottom: 0;
				padding-top: (98 / 1675 * 100%);
				background-image: url('/content/dam/gtr/images/webp-gtr/home-hero-top-cta.webp');
			}
			&:before {
				height: 241px;
				content: '';
				position: absolute;
				bottom: 1px;
				left: 0;
				right: 0;
				background: linear-gradient(
					180deg,
					rgba(0, 0, 0, 0) 0%,
					#1a1739 156.43%
				);
				pointer-events: none;
			}
		}
		.bat-hero-gtr--cta__wrapper {
			max-width: 100%;
			width: 100%;
			padding-bottom: 82px;
			align-items: flex-start;
		}
		.bat-hero-gtr--cta__image {
			width: 873px;
			max-width: 63.3067440174%;
			height: 718px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: unset;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.bat-hero-gtr--cta__content {
			width: 493px + 20px;
			max-width: (100% - 63.3067440174%);
			flex: unset;
			padding: 0;
			margin-left: auto;
			padding: 150px 5px 0;
		}
		.bat-hero-gtr--cta__cta-list {
			margin-top: 46px;
		}
	}
}

body:not(.airport-view) {
	@media (min-width: 768px) {
		.bat-hero-gtr--cta__section.mid-pos .bat-hero-gtr--cta__wrapper {
			width: 100%;
		}
		.bat-hero-gtr--cta__section.mid-pos
			.bat-hero-gtr--cta__cta-list:not(.inside-content) {
			width: 100%;
			justify-content: center;
		}
		.bat-hero-gtr--cta__section .bat-hero-gtr--cta {
			padding-bottom: 30px;
		}
		.bat-hero-gtr--cta__section.bottom-pos {
			.bat-hero-gtr--cta__content {
				&__topsubtitle {
					* {
						font-size: 30px;
					}
				}
				&__text {
					* {
						font-size: 30px;
					}
					b {
						font-size: 1.5em;
					}
				}
			}
		}
	}
}
body.airport-view {
	.bat-hero-gtr--cta {
		&__cta-list.inside-content {
			display: none;
		}
	}
	.bat-hero-gtr--cta__section {
		&.mid-pos {
			.bat-hero-gtr--cta {
				background-image: url('/content/dam/gtr/images/webp-in-store/bg-register-mid-mobile.webp');
				position: relative;
				margin-top: calc(-115 / 767 * 100% - 30px);
				z-index: 1;
			}
		}

		&.bottom-pos {
			.bat-hero-gtr--cta {
				background-image: url('/content/dam/gtr/images/webp-in-store/bg-register-mobile.webp');
				position: relative;
				margin-top: -1px;
			}

			&.global-home {
				.bat-hero-gtr--cta {
					margin-top: -135px;
				}
			}
		}
	}

	.bat-hero-gtr--edu__section {
		.bat-section-content {
			padding-top: 30px;

			> .hero {
				margin-top: 30px;
			}
		}
	}
	
}