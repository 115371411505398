bat-productlisting-avalanche {
	.bat-productlistings {
		max-width: unset;

		&--avalanche  {
			background-color: $concrete;
			display: flex;
			flex-direction: row;
			margin: 0 auto;
			padding: 0 0 20px;
			position: relative;
			width: 100%;
			
			.bat-productlistings-card {
				background-color: $white;
				display: flex;
				flex-direction: column;
				height: 100%;
				margin: 5px 0;
				max-width: 100%;
				padding: 20px;
				position: relative;
				width: 100%;
				
				&-buttons {
					margin: auto 0 15px;
					
					a {
						&.arrow-link-dark {
							display: none;
						}
					}
					button {
						display: none;
						margin-bottom: 0;
						max-width: unset;
						width: 100%;
						&.active {
							display: inline-block;
						}
					}
				}
				
				&-description {
					p {
						@include font-rem($font-sm, $font-sm + 3);
						color: $dark-gray;
						font-style: normal;
						font-weight: 400;
						letter-spacing: -.25px;
						margin-bottom: 15px;
						margin-top: 0;
						text-align: left;
					}
				}
				
				&-details {
					align-items: center;
					border-bottom: 1px solid $concrete;
					display: flex;
					flex-direction: row;
					height: auto;
					margin: 0 0 18px;
					max-height: unset;
					padding: 0;
					position: static;
					width: 100%;
					z-index: initial;

					& > div {
						display: flex;
						flex: 1 1 100%;
						flex-direction: column;
					}

					&-image {
						padding-top: 15px;
						a {
							display: block;
							flex: 0 0 105px;					
							img {
								height: auto;
								padding-right: 0;
								width: 100%;
							}
						}
					}

					&-text {
						padding-left: 10px;
						padding-top: 0;
					}
				}
				
				&-message {
					background-color: transparent;
					color: $system-red;
					display: none;
					justify-content: center;
					padding: 5px;
					
					button {
						background-color: transparent;
						color: $error;
					}
					
					.message {
						padding: 0 1rem;
					}
				}

				&-name {
					@include font-rem(18px, 22px);
					border-bottom: 0;
					font-weight: 400;
					margin: 0 0 15px;
					padding: 0;
					text-align: left;
					text-transform: capitalize;						
					a {
						text-decoration: none;
					}
				}

				&-options {

					display: flex;
					flex: 1 1 auto;
					flex-direction: column;
					text-align: left;
					width: auto;
				
					&-title {
						@include font-rem(12px, 16px);
						font-weight: 700;
						letter-spacing: 0;
						margin-bottom: 5px;
						text-transform: uppercase;
					}
				}

				&-prices {
					@include font-rem(18px, 18px);
					color: $navy;
					font-weight: 700;
					margin: 0 0 11px;
					text-align: left;
					vertical-align: middle;
					
					.base-price {
						margin-right: .5rem;
						
						&.has-sale-price {
							@include font-rem(18px, 18px);
							color: $gray;
							position: relative;
							text-decoration: none;
							
							&:before {
								-moz-transform: rotate(-5deg);
								-ms-transform: rotate(-5deg);
								-o-transform: rotate(-5deg);
								-webkit-transform: rotate(-5deg);
								border-color: $gray;
								border-top: 2px solid;
								content: '';
								left: 0;
								position: absolute;
								right: 0;
								top: 50%;
								transform: rotate(-5deg);
							}
						}
					}
				}
				
				&-quantity {
					margin: 0 0 0 auto;
					width: 90px;
				}
				
				&-stock {
					@include font-rem(20px, 20px);
					color: $system-red;
					display: none;
					font-style: normal;
					padding: 0;
					text-transform: uppercase;
				}

				&-strengths {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 15px;
					button {
						&.bat-indicator {
							align-items: center;
							border-top-width: 0;
							flex-direction: row;
							font-family: $primary-font;
							justify-content: center;
							margin: 0;
							min-width: 95px;	
							padding: 15px;
							width: 50%;
							&.full-width {
								width: 100%;
							}
							&:nth-child(even) {
								border-left: 0;
							}
							&:nth-child(1),
							&:nth-child(2) {
								border-top-width: 1px;
							}
							span {
								@include font-rem(10px, 10px);
								font-weight: 700;
								margin-bottom: 3px;
							}
						}
					}
				}
				
				&-variants {
					margin: 10px 0 20px auto;
					max-width: 45%;
					
					form {
						.select-wrapper {
							
							select {
								padding: 11px 30px 13px 13px;
								text-overflow: ellipsis;
							}
						}
					}
				}
			}

			.bat-productlistings-cards {
				max-width: $site-max-width;
				&__error-text {
					margin: 20px;
					text-align: center;
				}
			}

			.loading-wrapper {
				align-items: center;
				display: flex;
				height: 110px;
				position: relative;
				width: 100px;
				
				p {
					font-size: 12px;
					text-align: center;
					width: 100%;
				}
				
				&:after {
					-webkit-animation: spin 2s linear infinite; /* Safari */
					animation: spin 2s linear infinite;
					border: 10px solid $system-red;
					border-radius: 50%;
					border-top: 10px solid $system-red;
					bottom: 0;
					content: '';
					height: 100px;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					top: 0;
					width: 100px;
					z-index: 1;
				}
			}
			
			.errors-wrapper {
				ul {
					display: none;
				}
			}
		}

	}
}

@include media-breakpoint-up(sm) { // 576+
	bat-productlisting-avalanche {
		.bat-productlistings {
			&--avalanche {

				.bat-productlistings-card {
					margin: 8px 3.5px;
					min-width: 268px;
					width: calc(50% - 7px);
					&-description {
						p {
							display: none;
						}
					}
					&:hover {
						.bat-productlistings-card-buttons {
							border-bottom: 1px solid $system-red;
							margin-bottom: 15px;
							padding-bottom: 15px;
							a {
								display: none;
							}
							button {
								&.active {
									display: inline-block;
								}
							}
						}
						.bat-productlistings-card-description {
							p {
								display: block;
							}
						}
						.bat-productlistings-card-strengths {
							border-bottom: 0;
							margin-bottom: 0;
						}
					}
				}

				.bat-productlistings-filter-count {
					display: block;
				}

				.bat-productlistings-card-buttons {
					a {
						&.arrow-link-dark {
							display: inline-flex;
						}
					}
					button {
						&.active {
							display: none;
						}
					}
				}

				.bat-productlistings-card-details {
					border: 0;
					flex-direction: column;
					margin-bottom: 14px;
					padding: 0;
					& > div {
						flex: 1 0 100%;
						padding: 0;
						width: 100%;
					}
					&-image {
						a {
							flex: 1 0 100%;
							text-align: center;

							img {
								max-width: 75%;
							}
						}
					}
				}

				.bat-productlistings-card-name {
					margin-bottom: 14px;
				}

				.bat-productlistings-card-prices {
					margin-bottom: 0;
				}

				.bat-productlistings-card-strengths {
					border-bottom: 1px solid $concrete;
					padding-bottom: 15px;
				}

			}

		}
	}
}

@include media-breakpoint-up(lg) { // 992+
	bat-productlisting-avalanche {
		&.bat {
			// sass-lint:disable no-important
			max-width: unset !important;
		}
		.bat-productlistings {		
			&--avalanche {

				.bat-productlistings-card {
					min-width: 310px;
					width: calc(33.3% - 7px);
				}

			}
		}
	}
}

@include media-breakpoint-up(xl) { //1200+
	bat-productlisting-avalanche {
		.bat-productlistings {		
			&--avalanche {

				.bat-productlistings-card {
					min-width: 280px;
					width: calc(25% - 7px);
				}

			}
		}
	}
}
/* Safari */
@-webkit-keyframes spin {
	0% { 
		-webkit-transform: rotate(0deg); 
	}
	100% { 
		-webkit-transform: rotate(360deg);
	 }
}
  
@keyframes spin {
	0% { 
		transform: rotate(0deg); 
	}
	100% { 
		transform: rotate(360deg); 
	}
}
