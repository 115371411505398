bat-footer-zonnic {
	.bat-footer-zonnic {
		position: relative;
		
		@include root-prop(--bg-color, #{$navy});
		@include root-prop(--border-color, #{$concrete});
		@include root-prop(--text-color, #{$white});
		@include root-prop(--footer-header-bg-color, #{$navy-dark});
		@include root-prop(--footer-link-color, #{$light-blue});
		@media (prefers-color-scheme: dark) {
			@include root-prop(--bg-color, #{$dark-gray});
			@include root-prop(--text-color, #{$white});
			@include root-prop(--footer-header-bg-color, #{$black});
		}
		
		// Display dark mode via a class
		&.dark-mode {
			@include root-prop(--bg-color, #{$dark-gray});
			@include root-prop(--text-color, #{$white});
			@include root-prop(--footer-header-bg-color, #{$black});
			background-color: var(--bg-color);
		}
		// main content
		&-main {
			background-color: var(--bg-color);
			color: $white;
			padding: 0 20px;
			position: relative;
			> .bat-footer-social-nav {
				display: none;
				@include media-breakpoint-down(md) {
					display: block;
					margin: 20px 0;
				}
			}
			@include media-breakpoint-down(sm) {
				overflow: hidden;
			}

			@include media-breakpoint-up(lg) {
				padding: 42px 20px;
				> .bat-footer-social-nav {
					display: none;
				}
			}
		}

		&-top {
			background-color: var(--footer-header-bg-color);
			height: 55px;
			padding: 0;

			@include media-breakpoint-down(md) {
				padding: 0 20px;
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}
}

.bat-logo {
	&--top {
		@include media-breakpoint-down(md) {
			display: none;
		}

		@include media-breakpoint-up(lg) {
			display: inline-flex;
		}
	}

	&--bottom {
		@include media-breakpoint-down(md) {
			display: block;
		}

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
}

.bat-footer-zonnic {
	background-color: var(--bg-color);

	&--row {
		display: flex;
		margin-left: auto;
		margin-right: auto;
		max-width: $max-width-xxl;
		position: relative;

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}

		&:first-child {
			@include media-breakpoint-up(lg) {
				justify-content: flex-end;
			}
		}

		&:last-child {
			margin-top: 95px;
			z-index: 10;
			@include media-breakpoint-down(md) {
				margin-top: 0;
			}
		}
	}

	&-copyright {
		align-items: center;
		display: flex;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: $max-width-xxl;

		&--bottom {
			margin: 1em 0 1em;
			@include media-breakpoint-up(lg) {
				display: none;
			}
			p {
				font-size: 14px;
				font-weight: 700;
				letter-spacing: 0;
				line-height: 20px;
				text-align: center;				
			}
		}

		p {
			color: var(--text-color);
			font-size: 14px;
			font-weight: 300;
			line-height: 20px;
			margin: 0;
		}

		@include media-breakpoint-up(md) {
			padding-left: 20px;
		}
		@include media-breakpoint-down(xxl) {
			padding-left: 0;
		}
	}

	&-logo {
		left: 0;
		margin: 5% auto 0;
		padding: 0;
		position: relative;
		right: 0;
		width: 166px;
		z-index: 1;

		@include media-breakpoint-up(md) {
			flex: 1 0 auto;
			left: unset;
			margin-top: 20px;
			padding: 0;
			position: relative;
			right: 0;
			top: unset;
			z-index: 1;
		}

		&.footer-logo {
			&--bottom {
				margin-top: 1em;

				@include media-breakpoint-down(sm) {
					display: block;
				}

				@include media-breakpoint-up(md) {
					display: none;
				}
			}

			&--top {
				@include media-breakpoint-down(sm) {
					display: none;
				}

				@include media-breakpoint-up(md) {
					display: block;
				}
			}
		}
	}

	&-nav {
		display: flex;
		flex: none;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-end;
		padding: 0;
		position: relative;
		width: 100%;
		z-index: 1;

		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}

		@include media-breakpoint-up(lg) {
			flex: 1 1 auto;
			flex-direction: row;
		}

		// .bat-footer-zonnic-nav-menu
		&-menu {
			flex-wrap: wrap;
			width: 100%;
			&:last-of-type {
				@include media-breakpoint-down(md) {
					border-bottom: 0;
				}
			}

			@include media-breakpoint-down(md) {
				min-width: unset;
				width: 100%;
			}

			@include media-breakpoint-up(lg) {
				padding-right: 40px;
				width: 28.3%;
			}

			@include media-breakpoint-up(lg) {
				padding-right: 40px;
			}

			@include media-breakpoint-down(md) {
				border-bottom: 1px solid var(--border-color);
				&:first-of-type {
					@include media-breakpoint-down(md) {
						border-top: 1px solid var(--border-color);
					}
				}
			}

			> .bat-footer-social-nav {
				display: block;
				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			// .bat-footer-zonnic-nav-menu-title
			&-title {
				border-bottom: 1px solid var(--border-color);
				color: $white;
				cursor: pointer;
				display: flex;
				font-family: $primary-font;
				font-size: 14px;
				font-weight: 700;
				line-height: 20px;
				padding: 10px 0 12px;
				position: relative;
				text-align: left;
				text-transform: uppercase;

				@include media-breakpoint-down(md) {
					align-items: center;
					border-bottom: 0;
					font-size: 12px;
					font-weight: 700;
					height: 50px;
					justify-content: space-between;
					letter-spacing: 1.5px;
					line-height: 18px;
					margin-bottom: 0;
					padding: 0;
				}

				i {
					@include media-breakpoint-down(md) {
						font-size: 20px;
					}

					&:before {
						content: '\e958';
						font-family: 'icomoon';
						transition: content .5s ease-in-out;
						@include media-breakpoint-down(md) {
							font-size: 20px;
						}
					}

					&.icon-plus {
						&:before {
							content: '\e959';
							@include media-breakpoint-down(sm) {
								font-size: 20px;
							}
						}
					}

					@include media-breakpoint-up(lg) {
						display: none;
					}
				}
			}
		}

		ul {
			margin-bottom: 30px;
			padding-left: 0;

			@include media-breakpoint-up(lg) {
				margin-bottom: 8px;
			}

			&:first-child {
				font-weight: 700;
			}

			li {
				display: flex;
				list-style-type: none;
				margin-bottom: 5px;
				text-align: left;

				a,
				.no-link {
					color: var(--font-color);
					font-family: $primary-font;
					font-size: 14px;
					font-weight: 300;
					line-height: 20px;
					padding: 0;
					transition: color .1s ease-in;
				}
				.opening-hours {
					align-items: center;
					display: flex;
					font-size: 12px;
					font-weight: 700;
					letter-spacing: 0;
					line-height: 18px;
					> span {
						color: var(--font-color);
						font-family: $primary-font;
						font-size: 12px;
						letter-spacing: 0;
						line-height: 18px;
						padding: 0;
						text-transform: uppercase;
						transition: color .1s ease-in;
					}
					> .bat-icon {
						bottom: 2px;
						height: 13px;
						margin-left: 4px;
						position: relative;
						width: 20px;
						&:before {
							font-size: 20px;
							top: 4px;
						}
					}

				}
				// sass-lint:disable force-element-nesting
				&.opening-hours-group-start {
					.bat-footer-limited-submenu.submenu {
						display: none;
					}
					~ li {
						.submenu-content {
							a {
								font-size: 12px;
								font-weight: 600;
								letter-spacing: .64px;
								line-height: 22px;
								pointer-events: none;
								
							}
						}
					}
				}
				// sass-lint:disable force-element-nesting
				&.opening-hours-group-start--open {
					.bat-footer-limited-submenu.submenu {
						display: block;
					}
					.opening-hours {
						.bat-icon.icon-chevron-up {
							&:before {
								content: '\e95b';
							}
						}
					}
				}
				// sass-lint:disable no-misspelled-properties
				a {
					&:hover {
						color: var(--footer-link-color);
						text-decoration-color: inherit;
						text-decoration-line: underline;
						text-decoration-style: solid;
						text-decoration-thickness: 1px;
						text-underline-offset: 5px;
					}
				}
				i {
					&:before {
						font-size: 15px;
						line-height: 15px;
					}
				}
			}
		}
	}

	// bat-footer-zonnic-submenu
	&-submenu {
		display: none;
		padding-top: 1em;

		@include media-breakpoint-down(md) {
			display: none;
			padding-top: 0;
		}

		@include media-breakpoint-up(lg) {
			display: block;
		}
		// sass-lint:disable force-pseudo-nesting
		.submenu-content:not(.opening-hours) {
			align-items: flex-start;
			flex-direction: row;

			.bat-icon {
				display: inline-flex;

				&:before {
					content: '\e90c';
					font-size: 24px;
					position: relative;
					top: 6px;
				}

				&.icon-plus {
					&:before {
						content: '\e90f';
					}
				}
			}
		}

		li {
			flex-direction: column;
			position: relative;

			&.opening-hours-group-start {
				cursor: pointer;
				@include media-breakpoint-down(md) {
					padding-top: 10px;
				}

				@keyframes showHours {
					from {
						opacity: 0;
						transform: translateY(-100%);
					}
					to {
						opacity: 1;
						transform: translateY(0);
					}
				}

				~ li {
					animation: showHours .33s;
					display: none;
					will-change: transform;
				}

				// sass-lint:disable force-element-nesting
				.opening-hours + .bat-icon.icon-chevron-down {
					&:before {
						transition: .33s;
					}
				}

				&--open {
					~ li {
						display: block;
					}

					.opening-hours + .bat-icon.icon-chevron-down {
						&:before {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		// bat-footer-zonnic-submenu-child
		&-child {
			display: none;
			margin: 15px 0;
			position: absolute;
			top: 30px;

			@include media-breakpoint-down(md) {
				top: 25px;
			}

			li {
				position: relative;
			}
		}
	}

	&-fixed-spacer {
		height: $warning-height-mobile;
		@include media-breakpoint-up(md) {
			// height: $warning-height;
			height: #{$warning-height * 2}; // Fix for covering up legal info
		}
		// modify for belgium (warning hidden)
		// [data-country=be] & {
		// 	height: 20px;
		// 	@include media-breakpoint-up(md) {
		// 		height: 20px;
		// 	}
		// }
	}

	&-disclaimer {
		@include media-breakpoint-down(sm) {
			margin-top: 5%;
		}
		@include media-breakpoint-up(md) {
			padding-left: 20px;
			padding-right: 20px;
		}
		@include media-breakpoint-down(xxl) {
			padding-left: 0;
			padding-right: 0;
		}

		p {
			color: var(--text-color);
			font-size: 12px;
			line-height: 16px;
			margin: 0 auto 15px;
			max-width: $max-width-xxl;
		}
	}

	.bat-footer-social-nav {
		margin-top: 20px;

		// @include media-breakpoint-down(sm) {
		// 	display: none;
		// }

		ul {
			justify-content: center;
			@include media-breakpoint-up(lg) {
				justify-content: unset;
			}
			li {
				a {
					&:hover {
						text-decoration: none;
					}
					i {
						height: 24px;
						margin-right: 16px;
						width: 24px;
						&:before {
							font-size: 24px;
						}
					}
				}
				&:first-child {
					width: auto;
					i {
						display: block;
					}
					&:after {
						background-color: unset;
						display: none;
					}
				}
			}
		}
	}

	.bat-footer-payment-icons {
		align-items: center;
		background: var(--bg-color);
		color: var(--text-color);
		display: none;
		height: 54px;
		justify-content: center;
		margin-top: 40px;
		padding-bottom: 20px;

		.payment-icons & {
			display: flex;
		}

		svg,
		img {
			display: none;
			height: auto;
			margin: 0 18px;
			&.bat-footer-payment-icons--amex {
				display: block;
				width: 48px;
			}
		}

		.bancontact &--bancontact {
			display: block;
			width: 50px;
		}

		.payu &--payu {
			display: block;
			width: 48px;
		}

		.visa &--visa {
			display: block;
			width: 40px;
		}

		.mastercard &--mastercard {
			display: block;
			width: 29px;
		}

		.paypal &--paypal {
			display: block;
			width: 54px;
		}
	}
}

// sass-lint:disable force-pseudo-nesting
.ava-nav-menu {
	&:last-child {
		.menu-title {
			&::after {
				@include media-breakpoint-down(sm) {
					background-color: transparent;
				}
			}
		}
	}
}
