.bat-agegate {
	//-----------------------------------------------------------------| Default
	&--default {
		.bat-modal {
			background-color: $navy;
		}

		&-content {
			padding: 10px;
			position: relative;
			text-align: center;

			@include media-breakpoint-up(sm) {
				width: 668px;
			}

			@include media-breakpoint-up(sm) {
				padding: 20px;
			}

			&-image {
				left: 0;
				margin: 1% auto 8%;
				position: relative;
				top: 0;
				width: 10%;
				z-index: 1;
			}
			&-greeting {
				position: relative;
				z-index: 2;

				&-headline {
					margin-bottom: 10px;

					h2 {
						@include font-rem(24px, 24px);

						@include media-breakpoint-up(sm) {
							@include font-rem(30px, 30px);
						}
					}
				}

				&-text {
					margin-bottom: $gutter 10px;

					p {
						@include font-rem(14px, 14px);

						@include media-breakpoint-up(sm) {
							@include font-rem(16px, 16px);
						}
					}
				}

				&-cta-list {
					align-items: center;

					justify-content: center;
					margin-bottom: 20px;

					@include media-breakpoint-up(sm) {
						display: flex;
						margin-bottom: 40px;
					}

					.bat-button {
						margin: 5px;
						min-width: 120px;
						padding: 13px 26px;

						@include media-breakpoint-up(sm) {
							margin: 5px 10px;
							min-width: 158px;
							padding: 13px 38px;
						}

						@include media-breakpoint-down(xs) {
							width: 100%;
						}
					}
				}

				&-footnote {
					margin: 0 auto;
					width: 70%;

					p {
						@include font-rem(14px, 14px);

						@include media-breakpoint-up(sm) {
							@include font-rem(16px, 16px);
						}
					}
				}
			}

			&-error-message {
				margin-top: 45px;
				position: relative;
				z-index: 2;
			}
		}
	}
	//-----------------------------------------------------------------| Avalanche
	&--avalanche {
		&-content {
			&-dob-input {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				> * {
					flex-grow: 1;
				}
				> div {
					display: flex;
					flex-direction: column;
					max-width: 30%;
					label {
						margin-bottom: 6px;
						text-align: left;
						width: 100%;
					}
					&:last-child {
						margin-right: 0;
					}
				}
				margin-bottom: 30px;
				@include media-breakpoint-up(sm) {
					margin-bottom: 20px;
				}
			}
		}
	}

	//-----------------------------------------------------------------| gtr
	&--gtr {
		.bat-modal {
			align-items: center;
			background-color: $navy;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			.bat-modal-content {
				max-height: 100%;
				position: unset;
				top: 0;
				width: 100%;
			}
			justify-content: center;
		}

		&-content {
			padding: 16px 30px;
			position: relative;
			text-align: center;

			@include media-breakpoint-up(sm) {
				max-width: 678px;
				padding: 2.3vw 7.1vw;
			}
			@include media-breakpoint-up(lg) {
				padding: 40px 123px 49px;
			}
			&-language-list {
				.button {
					border-bottom: 1px solid transparent;
					color: $navy;
					@include font-rem(12px, 18px);
					margin: 0 10px 26px;

					&:hover,
					&.active {
						border-bottom: 1px solid $black;
					}
				}
			}

			&-image {
				height: auto;
				left: 0;
				margin: 20px auto 25px;
				position: relative;
				top: 0;
				width: 108px;
				@include media-breakpoint-up(sm) {
					margin: 10px auto 20px;
					width: 148px;
				}
				z-index: 1;
			}
			form {
				text-align: left;
			}
			&-dob-iput-container {
				border: 0;
				height: 40px;
				padding: 0;
			}
			&-dob-input {
				&::-webkit-calendar-picker-indicator {
					background: $whisper-gray
						url('global/calendar-icon.svg')
						100% 100% no-repeat;
				}

				// on mobile make input span full width and look like a textfield
				@include media-breakpoint-down(md) {
					-webkit-appearance: textfield;
					-moz-appearance: textfield;
					display: flex;
					flex: 1 0 0;
					min-height: 1em;
				}
			}

			&-input {
				margin-bottom: 24px;
				@include media-breakpoint-up(sm) {
					margin-bottom: 20px;
				}
			}
			&-greeting {
				position: relative;
				z-index: 2;

				&.in-active {
					display: none;
				}

				&-headline {
					color: $navy;
					margin-bottom: 6px;

					h2 {
						@include font-rem(22px, 26px);

						@include media-breakpoint-up(sm) {
							@include font-rem(32px, 40px);
						}
					}
				}

				&-text {
					color: $black;
					margin-bottom: 23px;
					@include media-breakpoint-up(sm) {
						margin-bottom: 19px;
					}

					p {
						@include font-rem(14px, 20px);
					}
				}

				&-middle-text {
					color: $black;
					margin-bottom: 15px;

					p {
						@include font-rem(12px, 18px);
					}
					@include media-breakpoint-up(sm) {
						margin-bottom: 5px;
					}
				}

				&-cta-list {
					align-items: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin: 5px 10px 26px;
					@include media-breakpoint-up(sm) {
						flex-direction: row;
						margin-bottom: 7px;
						min-width: 158px;
						padding: 13px 0;
					}

					.bat-button {
						letter-spacing: 0.04em;
						min-width: 120px;
						padding: 13px 26px;

						@include media-breakpoint-up(sm) {
							margin: 5px 10px;
							min-width: 158px;
							padding: 13px 38px;
						}

						@include media-breakpoint-down(xs) {
							width: 100%;
						}
					}
					.age {
						white-space: nowrap;
					}
				}

				&-footnote {
					margin-bottom: 35px;
					width: 100%;
					@include media-breakpoint-up(sm) {
						margin: 0 auto 28px;
					}

					p {
						color: $black;
						@include font-rem(12px, 18px);

						@include media-breakpoint-up(sm) {
							@include font-rem(12px, 18px);
						}
					}
				}
			}

			&-error-message {
				display: none;
				margin-bottom: 35px;
				margin-top: 15px;
				position: relative;
				z-index: 2;
				&.active {
					display: block;
				}
			}
		}
		form {
			input {
				margin-bottom: 16px;
				@include media-breakpoint-up(sm) {
					margin-bottom: 24px;
				}
			}
		}
	}
	//-----------------------------------------------------------------| GTR
	&--gtr {
		.bat-messagebar--default-message-error {
			min-height: auto;
		}
		.bat-modal {
			background-image: url('/content/dam/gtr/images/webp-agegate/bg-age-gate-airport.webp');
			align-items: flex-start;
			background-size: cover;
			background-position: top center;
			@media (min-width: 768px) {
				background-image: url('/content/dam/gtr/images/webp-agegate/bg-age-gate-instore.webp');
			}
			.bat-modal-content {
				max-height: none;
				background-color: transparent;
				overflow: visible;
			}
		}
		.bat-agegate--gtr-content-language-list {
			z-index: 10;
			position: absolute;
			top: 35px;
			right: 39px;
			@media (max-width: 767px) {
				top: 27px;
				right: 23px;
			}
			.btn-toggle {
				padding: 0;
				border: none;
				background-color: transparent;
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 12px;
				line-height: 150%;
				letter-spacing: 0.02em;
				color: #ffffff;
				outline: none;
				&:focus {
					outline: none;
				}
				span {
					display: inline-block;
					padding: 0 6px;
				}
				@media (max-width: 767px) {
					font-size: 12px;
					letter-spacing: 0.02em;
					svg {
						max-width: 20px;
					}
				}
			}
			ul {
				background-color: #fff;
				box-shadow: 1px 1px 5px rgba(#000,.2);
				border-radius: 4px;
				position: absolute;
				top: 120%;
				right: 0;
				min-width: 70px;
				opacity: 0;
				visibility: hidden;
				list-style: none;
				margin-bottom: 0;
				transition: all 0.4s ease;
				padding: 4px;
				margin-top: 4px;
				font-size: 15px;
				line-height: 1.5;
				li {
					text-align: center;
					margin-bottom: 0;
				}
				li + li {
					margin-top: 5px;
				}
				a {
					color: #000;
					margin: 0;
					white-space: nowrap;
					&:hover,
					&.active {
						border-bottom: 1px solid #000;
					}
				}
				&.show {
					top: 100%;
					opacity: 1;
					visibility: visible;
				}
			}
		}
		.bat-agegate--gtr-content {
			max-width: 803px;
			padding: 80px 0 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 0 auto;
			@media (min-width: 768px) {
				padding: 115px 0;
			}
			@media (max-width: 480px) {
				padding: 65px 0 28px;

				.publishMode & {
					min-height: 58vh;
				}
			}
			&__container {
				&.in-active {
					display: none;
				}
			}
		}
		.bat-agegate--gtr-content-greeting-headline {
			color: #fff;
			margin-bottom: 9px;
			@media (min-width: 768px) {
				margin-bottom: 15px;
			}
			h2 {
				color: #fff;
				text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
				text-transform: capitalize;
				font-weight: 700;
				font-size: 28px;
				line-height: 130%;
				letter-spacing: -0.02em;

				text-align: center;
				@media (min-width: 768px) {
					font-size: 55px;
					line-height: 120%;
				}
			}
		}
		.bat-agegate--gtr-content-greeting-text {
			margin-bottom: 40px;
			h2 {
				color: #fff;
				text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
				text-transform: capitalize;
				font-size: 20px;
				line-height: 130%;
				text-align: center;
				font-weight: 700;
				@media (min-width: 768px) {
					font-size: 32px;
					line-height: 120%;
				}
			}
		}
		.bat-agegate-form-title {
			font-weight: 700;
			font-size: 15px;
			line-height: 18px;
			letter-spacing: 0.04em;
			margin-bottom: 17px;

			text-align: center;
			color: #fff;
			@media (min-width: 768px) {
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.04em;
				margin-bottom: 19px;
			}
		}
		.bat-agegate--gtr-content-error-message {
			margin-bottom: 80px;
			display: none;
			padding: 30px 20px;
			background-color: #fff;
			position: relative;
			&.active {
				display: block;
			}
			.bat-agegate--gtr-button-close {
				width: 30px;
				height: 30px;
				position: absolute;
				right: 4px;
				top: 10px;
				&:hover {
					cursor: pointer;
				}
			}
			h3 {
				width: 100%;
			}
			p {
				width: 100%;
			}
		}
		.bat-agegate--gtr-content-image {
			width: auto;
			max-width: 276px;
			margin: 0 auto;
			@media (min-width: 768px) {
				margin-bottom: 30px;
				max-width: 548px;
			}
			@media (max-width: 480px) {
				margin-bottom: 12px;
			}
		}

		form .select-wrapper select {
			margin-bottom: 0px;
			background-color: transparent;
			border: 1px solid #fff;
			font-size: 15px;
			color: #fff;
			height: 60px;
			padding: 18px 14px;
			background-image: none;
			@media (min-width: 768px) {
				height: 70px;
				max-height: 200px;
				padding: 10px 22px;
			}

			&:focus {
				border-color: #fff;
			}
			option {
				color: #000;
			}
		}
		.bat-agegate--avalanche-content-dob-input {
			display: flex;
			margin-bottom: 40px;
			& > div {
				max-width: (100% / 3);
				padding: 0 5px;
			}
			@media (max-width: 767px) {
				margin-left: 9px;
				margin-right: 9px;
				margin-bottom: 53px;
			}
			@media (min-width: 768px) {
				margin-left: -11px;
				margin-right: -11px;
				margin-bottom: 53px;
				& > div {
					padding: 0 11px;
				}
			}
		}
		.bat-agegate--gtr-content-greeting-cta-list {
			margin: 0;
			padding: 0 0 36px;
			@media (min-width: 768px) {
				padding-bottom: 44px;
			}

			.bat-cta-style.button-secondary-dark,
			.bat-cta-style.button-dark {
				margin: 0 5px;
				width: 309px;
				max-width: 100%;
				@media (min-width: 768px) {
					width: 275px;
				}
			}
		}
		.bat-agegate--gtr-content-greeting-footnote {
			margin-bottom: 0;

			p {
				color: #ffffff;
				font-weight: 400;
				font-size: 13px;
				line-height: 130%;
				text-align: center;
				letter-spacing: 0.04em;
				text-transform: capitalize;
				margin-bottom: 0;
			}
		}
	}
}

@supports (
	(-webkit-backdrop-filter: blur(0.5em)) or (backdrop-filter: blur(0.5em))
) {
	.agegate-blurred {
		.bat-modal {
			-webkit-backdrop-filter: blur(0.5em);
			backdrop-filter: blur(0.5em);
			background-color: $whisper-gray;
		}
	}
}

//IN-STORE
@include media-breakpoint-up(md) {
	.bat-agegate--gtr.bat-agegate--gtr {
		.bat-modal {
			background-position: top left;
		}

		.bat-agegate--gtr-content-image {
			margin-bottom: 30px;
		}
	}

	.bat-agegate--gtr-content-greeting-footnote {
		max-width: 605px;
	}
}

.dropdown-menu {
	max-height: 250px;
	overflow: auto;
}
//AIRPORT
.bat-agegate--gtr.bat-agegate--gtr {
	.bat-modal.active {
		display: flex;
		flex-wrap: wrap;
	}
}

.has-age-gate .bat-agegate--gtr .footer {
	display: block;
}
.in-store {
	.bat-agegate--gtr {
		.bat-agegate--gtr-content {
			padding: 159px 0 59px;
			max-width: 991px;
		}
	}
	.bat-agegate--gtr-content-greeting-headline {
		margin-bottom: 24px;
		h2 {
			font-size: 72px;
		}
	}
	.bat-agegate--gtr-content-greeting-text {
		margin-bottom: 52px;
		h2 {
			font-size: 40px;
		}
	}
	.bat-agegate--gtr-content-image {
		margin-bottom: 66px;
		img {
			max-width: 548px;
			width: 100%;
			max-height: 122px;
		}
	}
	form .select-wrapper select {
		height: 90px;
		padding: 10px 30px;
		font-size: 20px;
	}

	.bat-agegate--avalanche-content-dob-input {
		margin-bottom: 66px;
		max-width: 851px + 30px;
		margin-left: auto;
		margin-right: auto;
		& > div {
			padding: 0 15px;
		}
	}
	.bat-agegate--gtr-content-greeting-cta-list {
		padding-bottom: 55px;
		.bat-cta-style.button-secondary-dark,
		.bat-cta-style.button-dark {
			width: 347px;
		}
	}
	.bat-agegate-form-title {
		font-size: 28px;
		line-height: 34px;
		letter-spacing: 0.04em;
		margin-bottom: 34px;
	}
	.bat-agegate--gtr-content-greeting-footnote {
		max-width: 851px;
		margin: 0 auto;

		p {
			font-size: 20px;
			line-height: 130%;
		}
	}
	.bat-messagebar--default-message-error {
		width: 851px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}
.airport-view {
	.bat-agegate--gtr.bat-agegate--gtr .bat-modal {
		right: 0;
		max-width: 767px;
		margin-left: auto;
		margin-right: auto;
	}
}


// mclaren version
.bat-agegate--gtr {
	.is-used {
		display: none;
	}
}
.gtr-age-gate-mclaren .bat-agegate--gtr {
	.bat-agegate--gtr {
		&-content {
			padding: 94px 0 74px;
			&-image {
				margin-bottom: 42px;
				max-width: 303px;
				img {
					max-width: 100%;
					width: auto;
					margin-left: auto;
					margin-right: auto;
					max-height: 41px;
				}
			}
			&-greeting-headline {
				margin-bottom: 3px;
				max-width: 308px;
				margin-left: auto;
				margin-right: auto;
				h1,h2,h3,h4 {
					font-size: 55px;
					font-weight: 900;
					line-height: 0.9;
					font-family: 'Gotham';
					text-transform: uppercase;
					letter-spacing: 0.04em;

				}
			}
			&-greeting-text {
				margin-bottom: 33px;
				max-width: 258px;
				margin-left: auto;
				margin-right: auto;
				* {
					font-family: 'Gotham';
					font-weight: 900;
					font-size: 26px;
					line-height: 31px;
					text-align: center;
					letter-spacing: 0.04em;
					text-transform: uppercase;
					color: #FFFFFF;
				}
			}
			&-greeting-cta-list {
				padding-bottom: 0;
				.bat-button {
					width: 195px;
					max-width: 100%;
					margin-left: auto;
					margin-right: auto;
					@include media-breakpoint-up(sm) {
						padding: 10px 12px;
					}
				}
			}
		}
	}
	.bat-modal {
		background-image: url('/content/dam/gtr/images/webp-mclaren-airport/bg-age-gate-airport.webp');
	}
	.bat-agegate-form-title {
		font-family: 'Gotham';
	}
	.bat-agegate--avalanche-content-dob-input {
		margin-bottom: 34px;
	}
	.is-used {
		padding-bottom: 68px;
		display: block;
		color: #fff;
		text-align: center;
		&.confirmed {
			display: none;
			&+.dob-form {
				display: block;
			}
		}
		&.show-tooltip {
			.is-used__buttons__tooltip {
				opacity: 1;
				visibility: visible;
				top: 100%;
			}
		}
		&__title {
			font-family: 'Gotham';
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			text-align: center;
			letter-spacing: 0.04em;
			color: #FFFFFF;
			margin-bottom: 25px;
			max-width: 300px;
			margin-left: auto;
			margin-right: auto;
		}
		&__buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			width: 280px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			&__item {
				max-width: 50%;
				
			}
			&__tooltip {
				position: absolute;
				top: 100%;
				margin-top: 24px;
				padding: 11px 18px;
				background-color: #fff;
				box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
				border-radius: 10px;

				font-family: 'Gotham';
				font-weight: 500;
				font-size: 15px;
				line-height: 18px;
				text-align: center;

				color: #D7480D;
				top: 90%;
				right: 10px;
				width: 257px;
				max-width: 100%;
				z-index: 10;
				opacity: 0;
				visibility: hidden;
				transition: all 0.4s ease;
				&:before {
					content: "";
					position: absolute;
					bottom: 100%;
					right: 46px;
					border-bottom: 15px solid #fff;
					border-left: 9px solid transparent;
					border-right: 9px solid transparent;
				}
			}
			.bat-button {
				margin: 0 10px;
				width: 120px;
				

				&:disabled {
					opacity: 0.5;
					cursor: not-allowed;
				}
				&:before {
					content: "";
					position: absolute;
				}
			}
		}
	}
	.dob-form {
		display: none;
	}
	@media (min-width: 576px) {
		.bat-agegate--gtr-content-greeting-headline,
		.bat-agegate--gtr-content-greeting-text {
			max-width: 80%;
		}
	}
}
[dir="rtl"] {
	.gtr-age-gate-mclaren .bat-agegate--gtr .is-used__buttons__tooltip:before {
		right: auto;
		left: 46px;
	}
}