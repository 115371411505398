@include modifer-classes-from-map('margin-top', $sizes);
@include modifer-classes-from-map('margin-bottom', $sizes);
@include modifer-classes-from-map('margin-right', $sizes);
@include modifer-classes-from-map('margin-left', $sizes);

// For same margin on all viewports
.margin-none {
	margin-bottom: map-get($sizes, zero);
}
.margin-xsmall {
	margin-bottom: map-get($sizes, xsmall);
}
.margin-small {
	margin-bottom: map-get($sizes, small);
}
.margin-medium {
	margin-bottom: map-get($sizes, medium);
}
.margin-large {
	margin-bottom: map-get($sizes, large);
}
.margin-xl {
	margin-bottom: map-get($sizes, xlarge);
}

// XL view
@media (min-width:  $breakpoint-xl) {
	.xl-margin-none {
		margin-bottom: map-get($sizes, zero);
	}
	.xl-margin-xsmall {
		margin-bottom: map-get($sizes, xsmall);
	}
	.xl-margin-small {
		margin-bottom: map-get($sizes, small);
	}
	.xl-margin-medium {
		margin-bottom: map-get($sizes, medium);
	}
	.xl-margin-large {
		margin-bottom: map-get($sizes, large);
	}
	.xl-margin-xl {
		margin-bottom: map-get($sizes, xlarge);
	}
}

// Large view
@media (min-width:  $breakpoint-lg) and (max-width: ($breakpoint-xl)-1) {
	.lg-margin-none {
		margin-bottom: map-get($sizes, zero);
	}
	.lg-margin-xsmall {
		margin-bottom: map-get($sizes, xsmall);
	}
	.lg-margin-small {
		margin-bottom: map-get($sizes, small);
	}
	.lg-margin-medium {
		margin-bottom: map-get($sizes, medium);
	}
	.lg-margin-large {
		margin-bottom: map-get($sizes, large);
	}
	.lg-margin-xl {
		margin-bottom: map-get($sizes,  xlarge);
	}
}

// Medium view
@media (min-width:  $breakpoint-md) and (max-width: ($breakpoint-lg)-1) {
	.md-margin-none {
		margin-bottom: map-get($sizes, zero);
	}
	.md-margin-xsmall {
		margin-bottom: map-get($sizes, xsmall);
	}
	.md-margin-small {
		margin-bottom: map-get($sizes, small);
	}
	.md-margin-medium {
		margin-bottom: map-get($sizes, medium);
	}
	.md-margin-large {
		margin-bottom: map-get($sizes, large);
	}
	.md-margin-xl {
		margin-bottom: map-get($sizes, xlarge);
	}
}

// Small view
@media (min-width:  $breakpoint-sm) and (max-width: ($breakpoint-md)-1) {
	.sm-margin-none {
		margin-bottom: map-get($sizes, zero);
	}
	.sm-margin-xsmall {
		margin-bottom: map-get($sizes, xsmall);
	}
	.sm-margin-small {
		margin-bottom: map-get($sizes, small);
	}
	.sm-margin-medium {
		margin-bottom: map-get($sizes, medium);
	}
	.sm-margin-large {
		margin-bottom: map-get($sizes, large);
	}
	.sm-margin-xl {
		margin-bottom: map-get($sizes, xlarge);
	}
}

// XS view
@media (max-width: ($breakpoint-sm)-1) {
	.xs-margin-none {
		margin-bottom: map-get($sizes, zero);
	}
	.xs-margin-xsmall {
		margin-bottom: map-get($sizes, xsmall);
	}
	.xs-margin-small {
		margin-bottom: map-get($sizes, small);
	}
	.xs-margin-medium {
		margin-bottom: map-get($sizes, medium);
	}
	.xs-margin-large {
		margin-bottom: map-get($sizes, large);
	}
	.xs-margin-xl {
		margin-bottom: map-get($sizes, xlarge);
	}
}
