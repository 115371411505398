.bat-messagebar {
	
	//-----------------------------------------------------------------| Zonnic
	&--zonnic {
		display: none;
		
		&-message {
			display: none;
			text-align: center;
		}
		
		&-message-error {
			display: none;
			text-align: center;
			
			p {
				text-transform: uppercase;
			}
		}
	}
	
	&--zonnic,
	&--default {
		margin: 0;
		transition: max-height .3s ease-in;
		
		&.active {
			display: block;
			margin: 0;
			max-height: initial;
			padding: 0;
		}
		
		&-message,
		&-message-error {
			align-items: center;
			background-color: $cart-banner;
			flex-flow: row wrap;
			justify-content: center;
			min-height: 50px;
			padding: 10px 20px;
			text-transform: uppercase;
			margin-bottom: 15px;
			
			&.active {
				display: flex;
			}
			
			p {
				@include font-rem(14px, 20px);
				color: $black;
				display: inline;
				font-weight: 700;
				margin: 0;
				white-space: break-spaces;
			}
		}
		&-message-error {
			background: $system-light-red;
			color: $system-red;
			
			&.active {
				flex-direction: column;
			}
			
			p {
				color: inherit;
			}
		}
	}
}
