.bat-carousel {
	margin-bottom: 0 auto 40px auto;
	max-width: $max-width-xxl;
	position: relative;
	width: 100%;
	
	
	.bat-card {
		.bat-card--default {
			.authorEditMode & { // sass-lint:disable-line class-name-format
				margin: 0 auto;
				max-width: 400px;
			}
		}
	}
	
	&-slides {
		visibility: hidden;
		
		.authorEditMode & { // sass-lint:disable-line class-name-format
			visibility: visible;
		}
		
		&.loaded {
			visibility: visible;
		}
	}
	
	.slick-arrow {
		height: 40px;
		margin: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 40px;
		z-index: 1;
		
		&.slick-prev {
			left: 20px;
		}
		
		&.slick-next {
			right: 20px;
		}
	}
	
	.slick-dots {
		align-items: center;
		bottom: -40px;
		display: flex;
		flex-wrap: wrap;
		height: 40px;
		justify-content: center;
		left: 0;
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		
		li {
			display: flex;
			flex: 0 0 auto;
			padding: 5px;
			
			button {
				border: solid 1px $black;
				border-radius: 14px;
				height: 10px;
				text-indent: -99999999px;
				width: 10px;
			
				&:hover {
					background-color: $black;
				}
			}
			
			&.slick-active {
				
				button {
					background-color: $black;
				}
			}
		}
	}

	&-side {
		&-content {
			position: relative;
			
			&-header {
				left: 20px;
				position: absolute;
				top: 20px;
			}
		}
	}
	
	@include media-breakpoint-up(lg) {
		margin: 0 auto;
		max-width: $max-width-xxl;
	}
}
