.bat-account-table {
	display: none;
	width: 100%;
	
	thead {
		background-color: $whisper-gray;
		border-bottom: 2px solid $black;

		@include media-breakpoint-down(md) {
			display: none;
		}

		tr {
			@include font-rem($font-sm, $font-sm + 6);
			color: $navy;
			font-weight: 700;

			th {
				padding: 12px 0 12px 16px;
				text-align: left;
			}
		}
	}

	tbody {
		background-color: $white;

		tr {
			border-bottom: 2px solid $pale-gray;
			
			@include media-breakpoint-up(lg) {
				&:last-child {
					border: 0;
				}
			}

			@include media-breakpoint-down(md) {
				border: 2px solid $pale-gray;
				display: block;
				margin: 20px 0;
				padding: 26px 20px 0;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			

			td {
				@include font-rem($font-sm, $font-sm + 6);
				padding-left: 16px;

				@include media-breakpoint-down(md) {
					display: inline-table;
					//padding: 20px;
					position: relative;
					width: 100%;
				
					&:before {
						color: $navy;
						content: attr(data-th);
						display: inline-block;
						font-weight: 700;
						width: 112px;
					}
				}

				&:last-child {
					padding-right: 16px;
					white-space: nowrap;
					width: 1%;

					@include media-breakpoint-down(md) {
						width: 100%;

						&:before {
							width: 0;
						}
					}

				}
			}
			
			.bat-cta-style {
				margin: 8px auto;
				max-width: unset;
				width: 100%;

				@include media-breakpoint-down(md) {
					margin-bottom: 30px;
					margin-top: 16px;
				}
			}


			.shipping {
				&-success {
					color: $system-green;
				}
				&-pending {
					color: $mid-gray;
				}
				&-error {
					color: $system-red;
				}
			}

		}
	}
}

.no-orders-message {

	@include media-breakpoint-down(md) {
		text-align: center
	}

	p { 
		@include font-rem(20px, 28px);
		color: $black;
		font-weight: 600;
		margin-top: 10px;
	}

	.bat-cta-style {
		margin-top: 30px;
		@include media-breakpoint-down(md) {
			min-width: 100%;
			width: 100%;
		}
	}
}
