@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Bold.eot');
    src: url('../fonts/gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Bold.woff') format('woff'),
        url('../fonts/gotham/Gotham-Bold.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Book.eot');
    src: url('../fonts/gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Book.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Book.woff') format('woff'),
        url('../fonts/gotham/Gotham-Book.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Black.eot');
    src: url('../fonts/gotham/Gotham-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Black.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Black.woff') format('woff'),
        url('../fonts/gotham/Gotham-Black.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Black.svg#Gotham-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Light.eot');
    src: url('../fonts/gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Light.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Light.woff') format('woff'),
        url('../fonts/gotham/Gotham-Light.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-MediumItalic.eot');
    src: url('../fonts/gotham/Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-MediumItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-MediumItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-MediumItalic.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-MediumItalic.svg#Gotham-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-BlackItalic.eot');
    src: url('../fonts/gotham/Gotham-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-BlackItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-BlackItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-BlackItalic.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-BlackItalic.svg#Gotham-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-BoldItalic.eot');
    src: url('../fonts/gotham/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-BoldItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-BoldItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-BoldItalic.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-BookItalic.eot');
    src: url('../fonts/gotham/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-BookItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-BookItalic.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-BookItalic.svg#Gotham-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-LightItalic.eot');
    src: url('../fonts/gotham/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-LightItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-LightItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-LightItalic.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Medium.eot');
    src: url('../fonts/gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Medium.woff') format('woff'),
        url('../fonts/gotham/Gotham-Medium.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

