.bat-productsearch {
	border: 0;
	height: 40px;
	position: relative;
	

	&-desktop {
		overflow: hidden;
		position: relative;

		@include media-breakpoint-down(md) {
			position: initial;
		}
	}


	&-button {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: max-content;
		left: .5rem;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: max-content;
		z-index: 10;

		.search-button-icon,
		.search-button-label {
			margin: 0;
			padding: 0;
		}

		.search-button-icon {
			color: $white;
			position: relative;
		}

		.search-button-label {
			color: $white;
			font-size: $font-sm;

			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}

	&-input {
		background-color: transparent;
		border: 0;
		border-bottom: 1px solid;
		color: $white;
		display: none;
		outline: 0;
		padding: .5rem .25rem .5rem 1.5rem;

		&::-webkit-input-placeholder, 
		&::-moz-placeholder, 
		&::-moz-input-placeholder {
			color: $off-white;
			font-weight: 300;
		}
	}

}
.bat-productsearch-mobile {
	align-items: center;
	background-color: $white;
	display: none;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0;
	width: 100%;
	z-index: 9999;

	.input-close-button {
		border-left: 1px solid;
		height: 100%;
		margin-left: auto;
		padding: .5rem;
	}

	.bat-productsearch-input {
		border: 0;
		color: $black;
		display: block;
		padding: 1rem .5rem;
		width: 100%;
	}

	.input-wrapper {
		margin: 0;
		padding: 0;
		position: relative;
		width: 100%;
		
	}

	.input-reset-button {
		align-items: center;
		border: 2px solid;
		border-radius: 50%;
		display: flex;
		height: 2rem;
		justify-content: center;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
		width: 2rem;

	}

	.input-reset-icon {
		font-size: 1rem;
		line-height: 1rem;
		margin: 0;
		padding: 0;
	}
}
