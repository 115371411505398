// sass-lint:disable no-duplicate-properties
//-----------------------------------------------------------------| Santral

@font-face {
	font-family: 'VELO Sans';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/velo-sans/VELOSans-Regular.eot');
	src: local('VELOSans Regular'), local('VELOSans-Regular'),
	url('../fonts/velo-sans/VELOSans-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/velo-sans/VELOSans-Regular.woff2') format('woff2'),
	url('../fonts/velo-sans/VELOSans-Regular.woff') format('woff'),
	url('../fonts/velo-sans/VELOSans-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'VELO Sans';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/velo-sans/VELOSans-Medium.eot');
	src: local('VELOSans Medium'), local('VELOSans-Medium'),
	url('../fonts/velo-sans/VELOSans-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/velo-sans/VELOSans-Medium.woff2') format('woff2'),
	url('../fonts/velo-sans/VELOSans-Medium.woff') format('woff'),
	url('../fonts/velo-sans/VELOSans-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'VELO Sans';
	font-weight: bold;
	src: url('../fonts/velo-sans/VELOSans-Bold.eot');
	src: local('VELOSans Bold'), local('VELOSans-Bold'),
	url('../fonts/velo-sans/VELOSans-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/velo-sans/VELOSans-Bold.woff2') format('woff2'),
	url('../fonts/velo-sans/VELOSans-Bold.woff') format('woff'),
	url('../fonts/velo-sans/VELOSans-Bold.ttf') format('truetype');
}



