.gtr-not-found {
    padding: 34px 18px 0;
    background-position: top center;
    .bat-section-content {
        background-color: #fff;
        padding: 123px 34px 147px;
        background: linear-gradient(359.91deg, rgba(0, 0, 0, 0.1) -0.47%, rgba(0, 0, 0, 0) 10.06%), linear-gradient(180deg, rgba(255, 255, 255, 0) 1.06%, rgba(215, 219, 230, 0.72) 100%), #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .image {
            max-width: 271px;
            margin: 0 auto 34px
        }
        .headline {
            margin-bottom: 16px;
        }
        .text {
            margin-bottom: 22px;
            p {
                margin-bottom: 0;
            }
        }
        .cta {
            .bat-cta-style {
                margin: 0;
                width: 187px;
                max-width: 100%;
            }
        }
    }
    @media (min-width: 768px) {
        padding: 75px 15px 0;
        .bat-section-content {
            max-width: 966px;
            margin: 0 auto;
            padding: 171px 20px 236px;
            background: #fff;
            box-shadow: none;
            .image {
                max-width: 303px;
                margin-bottom: 48px;
            }
            .text {
                margin-bottom: 33px;
            }
        }
        
        .cta {
            .bat-cta-style {
                margin: 0;
                width: 175px;
            }
        }
        
    }
    &.in-store {
        padding: 70px 15px 0;
        display: flex !important;
        min-height: 100%;
        flex-direction: column;
        .bat-section {
            margin-top: auto;
        }
        .bat-section-content {
            max-width: 1350px;
            margin: 0 auto;
            padding: 236px 100px;
            background: linear-gradient(359.91deg, rgba(0, 0, 0, 0.1) -0.47%, rgba(0, 0, 0, 0) 10.06%), linear-gradient(180deg, rgba(255, 255, 255, 0) 1.06%, rgba(215, 219, 230, 0.72) 100%), #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            .image {
                max-width: 440px;
                margin-bottom: 44px;
            }
            .headline {
                margin-bottom: 12px;
            }
        }
        
        .cta {
            .bat-cta-style {
                margin: 0;
                width: 231px;
            }
        }
    }
}