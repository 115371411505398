.bat-producthero {
	background-color: $concrete;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	opacity: 0;
	padding: 20px;
	transition: opacity .2s ease-in;
	
	&.loaded {
		opacity: 1;
	}
	
	@include media-breakpoint-up(md) {
		background-color: $white;
		flex-direction: row;
		padding: 0;
	}
	
	&-content {
		background-color: $concrete;
		width: 100%;
		
		@include media-breakpoint-up(md) {
			width: 50%;
		}
	}
	
	&-gallery {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(md) {
			padding: 20px;
		}
		
		.slick-dots {
			display: none !important; // sass-lint:disable-line no-important
		}
		
		.slick-arrow {
			background-color: $system-red;
			border-radius: 30px;
			color: transparent;
			font-size: 0;
			height: 30px;
			position: absolute;
			width: 30px;
			

			&:before {
				color: $white;
				content: '';
				display: inline-block;
				font-size: 20px;
				vertical-align: middle;
			}
			
			&.slick-prev {
				left: 20px;
				
				&:before {
					content: '\e90d';
					font-family: 'icomoon';
				}
			}
			
			&.slick-next {
				right: 20px;
				
				&:before {
					content: '\e90e';
					font-family: 'icomoon';
				}
			}
		}
		
		
		&-images {
			
			.large {
		
				img {
					display: block;
					height: auto;
					margin: 0 auto;
					max-height: 400px;
					width: auto;
				}
			}
		}
		
		&-thumbs {
			display: none;
			flex-wrap: wrap;
			margin-top: 20px;
			padding: 0;
			
			@include media-breakpoint-up(md) {
				display: flex;
			}
			
			.thumb {
				background-color: $white;
				border-top: 4px solid $white;
				height: 65px;
				margin: 5px;
				width: 65px;
				
				@include media-breakpoint-up(lg) {
					height: 90px;
					width: 90px;
				}
				
				@include media-breakpoint-up(xl) {
					height: 124px;
					width: 124px;
				}
				
				&.active {
					border-top: 4px solid $system-red;
					outline: none;
				}
				
				img {
					display: block;
					margin: 0 auto;
					max-height: 90px;
					max-width: 80%;
					width: auto;
				}
			}
		}
	}
	
	&-configurator {
		display: flex;
		flex-direction: column;
		width: 100%;
		
		@include media-breakpoint-up(md) {
			padding: 20px;
			width: 50%;
		}
		
		@include media-breakpoint-up(lg) {
			padding: 35px 48px 20px;
		}
		
		&-title {
			margin-bottom: 20px;
			max-width: 90%;
			
			h3 {
				@include font-rem(32px, 32px);
				
				@include media-breakpoint-up(md) {
					@include font-rem(34px, 34px);
				}
			}
		}
		
		&-price {
			
			text-align: center;
			
			@include media-breakpoint-up(md) {
				text-align: left;
			}
			
			.formatted-price {
				@include font-rem(32px, 32px);
				
				&.slash-price {
					@include font-rem(16px, 16px);
					color: $navy;
					font-weight: normal;
					
					&::after {
						background: $navy;
						height: 1px; 
						transform: rotate(-2deg);
					}
				}
			}
			
			.suffix {
				display: none;
			}
			
			&.slash-price {
				color: $system-red;
			}
		}
		
		&-short-description {
			
			h6 {
				@include font-rem(14px, 14px);
				font-weight: 700;
				margin-bottom: 15px;
				
				@include media-breakpoint-up(md) {
					@include font-rem(20px, 20px);
				}
			}
		}
		
		&-description {
			@include font-rem(12px, 14px);
			color: $system-red;
			
			@include media-breakpoint-up(md) {
				@include font-rem(14px, 16px);
			}
			
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: inherit;
				font-size: inherit;
				line-height: inherit;
				margin-bottom: 7px;
			}
			
			p {
				color: inherit;
				font-size: inherit;
				line-height: inherit;
				margin-bottom: 15px;
			}
			
			ul {
				margin-bottom: 15px;
				
				li {
					color: inherit;
					font-size: inherit;
					line-height: inherit;
				}
			}
		}
		
		&-stock {
			display: none;
			font-weight: 700;
			margin-bottom: 20px;
		}
		
		&-subscription {
			background-color: $concrete;
			margin: 0 auto;
			max-width: 325px;
			
			@include media-breakpoint-up(md) {
				margin: 0;
				max-width: none;
				padding: 20px;
			}
			
			&-text {
				@include font-rem(12px, 12px);
				margin-top: 2px;
				
				.formatted-price {
					font-weight: normal;
				}
				
				&-note {
					@include font-rem(12px, 12px);
					display: inline-block;
					font-weight: normal;
					margin: 7px 0 0;
					text-transform: none;
				}
				
				.discount-savings {
					@include font-rem(12px, 12px);
					background-color: $navy;
					color: $white;
					display: inline-block;
					padding: 2px 5px;
				}
			}
			
			&-max-message {
				margin-top: 0;
			}
			
			&-details {
				
				button {
					color: $navy;
					font-weight: 700;
				}
			}
			
			fieldset {
				padding: 0;
				
				label {
					align-content: flex-start;
					align-items: flex-start;
					height: auto;
					min-height: 29px;

					input {
						margin-top: 2px;
					}
				}
			}
		}
		
		&-options {
			z-index: 1;
			
			form {
				
				@include media-breakpoint-up(lg) {
					flex-direction: row;
				}
			}
			
			&-option {
				flex: 0 1 auto;
				margin-right: 32px;
				padding: 0 0 10px;
				text-align: center;
			
				@include media-breakpoint-up(md) {
					padding: 0 0 20px;
					text-align: left;
				}
				
				p {
					@include font-rem(14px, 14px);
					font-weight: 700;
					margin-bottom: 5px;
				}
				
				&-input {
					
					.select-wrapper {
						display: inline-block;
						
						select {
							@include font-rem(13px, 13px);
							font-weight: 700;
						}
					}
					
					fieldset {
						
						label {
							cursor: pointer;
							display: inline-flex;
							margin-bottom: 0;
							margin-right: 5px;
							
							input {
								
								&[type=radio] {
									display: none;
									
									+ span {
										@include font-rem(13px, 13px);
										background-color: $concrete;
										color: $navy;
										display: block;
										padding: 14px;
										text-transform: uppercase;
									}
									
									&:checked {
									
										+ span {
											@include font-rem(13px, 13px);
											background: $navy;
											color: $white;
											display: block;
											font-weight: 700;
											padding: 14px;
											text-transform: uppercase;
										}
									}
									
									&:disabled {
										
										+ span {
											position: relative;
											
											&::after {
												background: $navy;
												content: '';
												height: 2px;
												left: -5px;
												position: absolute;
												top: 46%;
												transform: rotate(-34deg);
												width: 114%;
											}
										}
									}
								}
							}
						}
					}	
				}
			}
		}
		
		&-bottom {
			flex-direction: column;
			padding: 20px 0;
			text-align: center;
			
			@include media-breakpoint-up(lg) {
				flex-direction: row;
				text-align: left;
			}

			.bat-quantity {
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 0 10px;
				padding: 0;
				
				@include media-breakpoint-up(lg) {
					justify-content: flex-start;
					margin: 0 20px 0 0;
				}
				
				label {
					@include font-rem(12px, 12px);
					display: block;
					margin: 0 0 5px;
					width: 100%;
				}
			}
		}
	}
}
