.bat-quantity {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0 auto;
	padding: 0 10px 10px;
	
	&-label {
		margin-right: 10px;
	}
	
	&-field {
		padding: 5px;
		pointer-events: none;
		text-align: center;
		-webkit-appearance: none;
		-moz-appearance: textfield !important; // sass-lint:disable-line no-important
  
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
	}
	
	&-button {
		line-height: 0;
		padding: 10px;
		
		i {
			font-size: 10px;
			height: auto;
			width: auto;
		}
		
		&--minus {
			margin-right: 5px;
		}
		
		&--plus {
			margin-left: 5px;
		}
	}
}
