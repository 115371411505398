
.d-none {
  display: none !important; 
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important; 
}

.d-block {
  display: block !important; 
}

.d-table {
  display: table !important; 
}

.d-table-row {
  display: table-row !important; 
}

.d-table-cell {
  display: table-cell !important; 
}

.d-flex {
  display: flex !important; 
}

.d-inline-flex {
  display: inline-flex !important; 
}

@media (min-width: $breakpoint-xxs) {
	.d-xs-none {
		display: none !important; 
	}
	.d-xs-inline {
		display: inline !important; 
	}
	.d-xs-inline-block {
		display: inline-block !important; 
	}
	.d-xs-block {
		display: block !important;
	}
	.d-xs-table {
		display: table !important; 
	}
	.d-xs-table-row {
		display: table-row !important; 
	}
	.d-xs-table-cell {
		display: table-cell !important; 
	}
	.d-xs-flex {
		display: flex !important; 
	}
	.d-xs-inline-flex {
		display: inline-flex !important; 
	} 
}

@media (min-width: $breakpoint-xs) {
	.d-sm-none {
		display: none !important; 
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important; 
	}
	.d-sm-block {
		display: block !important; 
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important; 
	}
	.d-sm-table-cell {
		display: table-cell !important; 
	}
	.d-sm-flex {
		display: flex !important; 
	}
	.d-sm-inline-flex {
		display: inline-flex !important; 
	} 
}

@media (min-width: $breakpoint-sm) {
	.d-md-none {
		display: none !important; 
	}
	.d-md-inline {
		display: inline !important; 
	}
	.d-md-inline-block {
		display: inline-block !important; 
	}
	.d-md-block {
		display: block !important; 
	}
	.d-md-table {
		display: table !important; 
	}
	.d-md-table-row {
		display: table-row !important; 
	}
	.d-md-table-cell {
		display: table-cell !important; 
	}
	.d-md-flex {
		display: flex !important; 
	}
	.d-md-inline-flex {
		display: inline-flex !important; 
	} 
}

@media (min-width: $breakpoint-md) {
	.d-lg-none {
		display: none !important; 
	}
	.d-lg-inline {
		display: inline !important; 
	}
	.d-lg-inline-block {
		display: inline-block !important; 
	}
	.d-lg-block {
		display: block !important; 
	}
	.d-lg-table {
		display: table !important; 
	}
	.d-lg-table-row {
		display: table-row !important; 
	}
	.d-lg-table-cell {
		display: table-cell !important; 
	}
	.d-lg-flex {
		display: flex !important; 
	}
	.d-lg-inline-flex {
		display: inline-flex !important; 
	} 
}

@media (min-width: $breakpoint-lg) {
	.d-xl-none {
		display: none !important; 
	}
	.d-xl-inline {
		display: inline !important; 
	}
	.d-xl-inline-block {
		display: inline-block !important; 
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important; 
	}
	.d-xl-table-row {
		display: table-row !important; 
	}
	.d-xl-table-cell {
		display: table-cell !important; 
	}
	.d-xl-flex {
		display: flex !important; 
	}
	.d-xl-inline-flex {
		display: inline-flex !important; 
	} 
}

@media print {
	.d-print-none {
		display: none !important; 
	}
	.d-print-inline {
		display: inline !important; 
	}
	.d-print-inline-block {
		display: inline-block !important; 
	}
	.d-print-block {
		display: block !important; 
	}
	.d-print-table {
		display: table !important; 
	}
	.d-print-table-row {
		display: table-row !important; 
	}
	.d-print-table-cell {
		display: table-cell !important; 
	}
	.d-print-flex {
		display: flex !important; 
	}
	.d-print-inline-flex {
		display: inline-flex !important; 
	} 
}
