// File:          _sortfilter.scss
// Description:   Sort Filter

bat-sortfilter-zonnic {

	&.bat {
		display: block;
		// sass-lint:disable no-important
		margin-left: -3.5px !important;
		margin-right: -3.5px !important;
		max-width: unset !important;
	}
	.bat-plp-filter-container {
		margin: 0 auto;
		.bat-plp-slot {
			background: $whisper-gray;
		}
	}

	.bat-filter {
		-moz-box-shadow: 7px 6px 11px -1px $dark-30;
		-webkit-box-shadow: 7px 6px 11px -1px $dark-30;
		background-color: $white;
		box-shadow: 7px 6px 11px -1px $dark-30;

		z-index: $modal-zindex;

		&-overlay {
			background: $black;
			bottom: 0;
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			right: 0;
			top: 0;
			transition: .25s;
			z-index: $modal-zindex;
			&--open {
				opacity: 0.6;
				pointer-events: all;
			}
		}

		&-wrapper {
			padding: 20px;
		}

		&-closebutton {
			right: 1rem;
		}

		&-applybutton {
			padding: 0;
			button {
				&[data-parameters='applyButton'] {
					background-color: $navy;
					border: solid 2px $navy;
					color: $white;
					transition: background-color .25s ease;
					
					i {
						svg {
							fill: $white;
						}
					}
					
					&:hover {
						background-color: $navy-dark;
						border: solid 2px $navy-dark;
						
						span {
							color: $white;
						}
						
						i {
							svg {
								fill: $white;
							}
						}
					}
			
					&:focus,
					&:focus-visible {
						outline: $navy solid 1px;
						outline-offset: 5px;
					}
					
					&.disabled,
					&:disabled {
						background-color: $pale-gray;
						border: solid 2px $pale-gray;
						color: $mid-gray;
					}
				}
			}
		}

		&-collapse {
			border-top: 1px solid $pale-gray;
			padding: 1rem 0;

			&:hover {
				cursor: pointer;
			}

			i {
				height: 24px;
				width: 24px;

				&:before {
					font-size: 24px;
				}
			}

			.title {
				color: $navy;
				@include font-rem(18px, 25px);
				font-weight: 600;
				@include media-breakpoint-up(lg) {
					@include font-rem(20px, 28px);
				}
			}

			&-container {
				.group-title {
					@include font-rem(20px, 24px);
					border-bottom: 0;
					color: $navy;
					font-weight: 900;
					letter-spacing: .5px;
					padding: 1rem 0;
					@include media-breakpoint-up(lg) {
						@include font-rem(22px, 26px);
					}
				}
			}

			&-items {
				border: 0;
				padding: 0 0 2rem;

				ul {
					li {
						label {
							@include font-rem(16px, 16px);
							align-content: center;
							align-items: center;
							display: flex;
							margin-bottom: 15px;

							.count {
								@include font-rem(14px, 20px);
								color: $mid-gray;
								letter-spacing: 0;
								margin-left: 5px;
							}

							input {
								display: inline;
								margin-right: 12px;
								position: relative;
								width: auto;

								&:focus {
									border-radius: 1px;
									box-shadow: 0 0 0 5px $white, 0 0 0 6px $navy;
									outline: none;
									outline-width: none;
								}
								
								
								&:before {
									background-color: $white;
									background-image: url('/content/dam/gtr/images/global/icons-sprite.svg#checkbox-off');
									background-repeat: no-repeat;
									bottom: 3px;
									content: '';
									display: inline-block;
									height: 19px;
									margin: 0;
									position: relative;
									right: 3px;
									width: 19px;
								}

								&:checked {

									&:before {
										background-image: url('/content/dam/gtr/images/global/icons-sprite.svg#checkbox-on');
										background-repeat: no-repeat;
										content: '';
										display: inline-block;
									}

									+ span {
										@include font-rem(14px, 14px);
										color: $navy;
									}
								}

								+ span {
									color: $navy;
									font-weight: bold;
									@include font-rem(14px, 20px);
								}
							}







						}
					}
				}
			}
		}
	}

	.reset-button,
	.clear-button {
		align-items: center;
		border: 1px solid transparent;
		color: $mid-gray;
		display: flex;
		font-weight: bold;
		text-transform: uppercase;

		&:focus {
			border: 1px solid $navy;
			border-radius: 1px;
			outline: none;
			outline-width: none;
		}
	}

	.reset-button {
		i {
			&:before {
				font-size: 24px;
			}
		}
	}

	.bat-plp-button-wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		max-width: $site-max-width;
		padding: 0 10px;
		position: relative;
	}

	.bat-filter-button {
		display: flex;
		margin: 8px;

		[data-parameters='filterButton'] {
			@include font-rem(15px, 15px);
			align-items: center;
			border: 1px solid transparent;
			color: $navy;
			display: flex;
			font-family: $primary-font;
			font-weight: 700;
			padding: 4px 0 4px 4px;
			//transition: color .1s ease-in;
			
			&:hover {
				color: $mid-gray;

				i {
					&:before {
						color: $mid-gray;
					}
				}
			}

			&:focus {
				border: 1px solid $navy;
				border-radius: 4px;
				outline: none;
				outline-width: none;
			}

			i {
				margin-right: 5px;

				&:before {
					color: $navy;
					font-size: 24px;
				}
			}
		}
	}

	.bat-filter-count {
		display: none;
		position: absolute;
		right: 10px;
	}

	.bat-filter-closebutton {
		button {
			i {
				&:before {
					color: $navy;
					font-size: 30px;
				}
			}
		}
	}

	.bat-sort-button {
		display: flex;
		margin: 8px;

		[data-parameters='sortButton'] {
			@include font-rem(15px, 15px);
			align-items: center;
			border: 1px solid transparent;
			color: $navy;
			display: flex;
			font-family: $primary-font;
			font-weight: 700;
			padding: 4px 0 4px 4px;
			//transition: color .1s ease-in;

			&:focus {
				border: 1px solid $navy;
				border-radius: 4px;
				outline: none;
			}

			&:hover {
				color: $black;

				i {
					&:before {
						color: $black;
					}
				}
			}

			i {
				&:before {
					color: $navy;
				}
			}
		}

		i {
			&:before {
				color: $navy;
				font-size: 24px;
			}

			&[id='sortButtonIconOpen'] {
				display: none;
			}

			&[id='sortButtonIconClosed'] {
				display: flex;
			}
		}

		&.open {
			i {
				&[id='sortButtonIconOpen'] {
					display: flex;
				}

				&[id='sortButtonIconClosed'] {
					display: none;
				}
			}
		}
		
		#sortButtonIcon { // sass-lint:disable-line no-ids, id-name-format
			margin-left: 10px;
		}
	}

	.bat-sort-select {
		border: 0;
		min-width: 170px;
		z-index: 10;

		button {
			border: 1px solid transparent;
			color: $navy;
			padding: 4px;
			transition: color .25s ease-in;

			&:hover {
				color: $black;
			}

			&:focus {
				border: 1px solid $navy;
				border-radius: 2px;
				outline: none;
			}
		}
	}

	.bat-price-slider {
		&-output {
			label {
				span {
					text-transform: uppercase;
				}

				input {
					@include font-rem(14px, 14px);
					background-color: $concrete;
					border: 0;
					color: $navy;
					padding: 10px;
				}
			}
		}

		&-range {
			input {
				&[type='range'] {
					&:focus {
						&::-webkit-slider-runnable-track {
							background: $system-red;
						}
					}

					&::-webkit-slider-runnable-track {
						background: $system-red;
					}

					&::-webkit-slider-thumb {
						background: $system-red;
					}

					&::-moz-range-track {
						background: $system-red;
					}

					&::-moz-range-thumb {
						background-color: $system-red;
					}

					&::-ms-track {
						background-color: $system-red;
					}

					&::-ms-thumb {
						background-color: $system-red;
					}
				}
			}
		}
	}

	.sortfilter {
		padding-left: 0;
		padding-right: 0;
	}
}

// viewport 576+
@include media-breakpoint-up(sm) {
	bat-sortfilter-zonnic {
		.bat-filter-button {
			justify-content: flex-end;
		}
		.bat-sort-button {
			justify-content: flex-start;
		}
	}
}

// viewport 992+
@include media-breakpoint-up(lg) {
	bat-sortfilter-zonnic {
		.bat-filter {
			&-wrapper {
				padding: 20px 40px 20px 60px;
			}
		}
		.bat-filter-count {
			display: block;
		}
		.bat-plp-button-wrapper {
			justify-content: left;
		}
	}
}

.sortfilter-category-error {
	@include font-rem(16px, 28px);
	margin-bottom: 20px;
}
