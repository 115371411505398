.bat-footer-large {
	background-color: $system-red;
	overflow: hidden;
	position: relative;
	
	@include media-breakpoint-up(lg) {
		min-height: 0;
	}
	
	footer {
		color: $white;
		padding: 50px;
		
		@include media-breakpoint-up(sm) {
			min-height: 400px;
		}
	}
	
	&-logo {
		height: 186px;
		
		@include media-breakpoint-up(lg) {
			height: auto;
			justify-content: flex-start;
			width: 30%;
		}

		a {
			max-width: 145px;
			position: relative;
			width: 150px;
			z-index: 1;
		
			&:before {
				border-color: transparent transparent transparent $system-red;
				border-style: solid;
				border-width: 750px 0 750px 1500px;
				content: '';
				height: 0;
				margin: auto;
				position: absolute;
				right: -100vw;
				top: 50%;
				transform: translateY(-50%);
				width: 0;
				z-index: 0;
				
				@include media-breakpoint-up(sm) {
					right: -80vw;
				}
				
				@include media-breakpoint-up(lg) {
					right: -40vw;
				}
				
				@include media-breakpoint-up(xl) {
					right: -35vw;
				}
			}
			
			&:after {
				border-color: transparent transparent transparent $system-red;
				border-style: solid;
				border-width: 750px 0 750px 1500px;
				content: '';
				height: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 0;
				z-index: 1;
			}
			
			picture {
				position: relative;
				z-index: 2;
			}
		}
	}
	
	&-nav {
		position: relative;
		z-index: 2;
		
		@include media-breakpoint-up(lg) {
			width: 70%;
		}
		
		&-content {
			height: 95px;
			
			p {
				color: $white;
			}
		}
		
		&-menus {
			
			&-menu {
				
				@include media-breakpoint-up(lg) {
					padding-right: 40px;
				}
				
				button {
					@include font-rem($font-lg, 20px);
					border-radius: 0;
					border-top: solid 1px $white;
					color: $white;
					font-weight: 700;
					text-transform: uppercase;
					
					i {
						
						&:before {
							font-size: 12px;
						}
						
						svg {
							fill: $white;
						}
					}
					
					@include media-breakpoint-up(lg) {
						border-bottom: solid 1px $system-red;
						border-top: 0;
						margin-bottom: 10px;
						padding-bottom: 5px;
						width: 100%;
					}
				}
				
				div {
				
					a {
						@include font-rem($font-sm, 20px);
						color: $white;
						text-decoration: none;
						text-transform: uppercase;
						
						@include media-breakpoint-up(lg) {
							
							&:hover {
								color: $system-red;
							}
						}
					}
				}
			}
		}
	}
	
	&-social-nav {
		position: relative;
		z-index: 2;
		
		@include media-breakpoint-up(lg) {
			width: 71%;
		}
		
		ul {
			
			@include media-breakpoint-up(lg) {
				justify-content: flex-end;
			}
	
			li {
				
				a {
					i {
						height: 35px;
						width: 35px;
						
						svg {
							fill: $white;
						}
						
						&:before {
							color: $white;
						}
					}
					
					&:hover {
						
						i {
						
							svg {
								fill: $system-red;
							}
							
							&:before {
								color: $system-red;
							}
						}
					}
				}
				
			}
		}
	}
	
	&-copyright {
		color: $white;
		font-weight: 700;
		position: relative;
		z-index: 2;
		
		@include media-breakpoint-up(lg) {
			width: 29%;
		}
	}
}
