body {
	font-family: $primary-font;
	font-size: $base-font;
}

h1,
.headline1 {
	@include font-rem(60px, 60px);

	@include media-breakpoint-up(lg) {
		@include font-rem(80px, 80px);
	}
}

.headline1-large {
	@include font-rem(100px, 100px);

	@include media-breakpoint-up(lg) {
		@include font-rem(125px, 125px);
	}
}

h2,
.headline2  {
	@include font-rem(40px, 40px);

	@include media-breakpoint-up(lg) {
		@include font-rem(72px, 72px);
	}
}

h3,
.headline3  {
	@include font-rem(32px, 32px);

	@include media-breakpoint-up(lg) {
		@include font-rem(46px, 46px);
	}
}

h4,
.headline4 {
	@include font-rem(30px, 30px);
	font-style: normal;
	font-weight: 500;
}

h5,
.headline5  {
	@include font-rem(22px, 22px);
 
	@include media-breakpoint-up(lg) {
		@include font-rem(28px, 38px);
	}
}

h6,
.headline6 {
	@include font-rem(18px, 18px);
  
	@include media-breakpoint-up(lg) {
		@include font-rem(24px, 24px);
	}
}

h1,
.headline1,
.headline1-large,
h2,
.headline2,
h3,
.headline3,
h5,
.headline5,
h6,
.headline6 {
	font-weight: 900;
	
	&.center {
		text-align: center;
	}
}

p {
	@include font-rem($font-lg, $font-lg + 2px);
	font-weight: 400;
	margin-top: 0;

	.sm,
	&.sm {
		@include font-rem($font-sm, $font-sm + 2px);
		font-weight: 400;
	}
	
	.lg,
	&.lg {
		@include font-rem($font-xlg, $font-xlg + 2px);
		font-weight: 500;
	}
	
	.eyebrow,
	&.eyebrow {
		font-size: 20px;
		font-style: italic;
		font-weight: 900;
		text-transform: uppercase;
	}
	
	strong {
		font-weight: 600;
	}
}

b {
	font-weight: 700;
}

hr {
	border-top: 1px solid $off-white;
	margin: 10px 0;
}

ol,
ul {
	@include font-rem($font-lg, $font-lg + 10);
	margin-bottom: 20px;
	margin-top: 0;
	padding-left: 20px;
	
	&.sm {
		padding-left: 18px;
	}
	
	&.lg {
		padding-left: 28px;
	}

	.sm,
	&.sm {
		@include font-rem($font-sm, $font-sm + 10);
		font-weight: 400;
	}
	
	.lg,
	&.lg {
		@include font-rem($font-xlg, $font-xlg + 10);
		font-weight: 500;
	}
	
	li {
		margin-bottom: 8px;
	}
}
