body {
	font-family: $primary-font;
	color: #000;
	letter-spacing: 0;
	@include font-rem(15px, 1.3);
	@include media-breakpoint-up(xl) {
		@include font-rem(15px, 1.5);
	}
	&.in-store {
		@include font-rem(24px, 1.5);
	}
}

h1,
.headline1 {
	font-weight: bold;
	@include font-rem(32px, 1.3);


	 @include media-breakpoint-up(lg) {
	 	@include font-rem(55px, 120%);
	
	 }
}

.headline1-large {
	font-weight: bold;
	@include font-rem(38px, 1.3);
	
 
	@include media-breakpoint-up(lg) {
		@include font-rem(65px, 120%);
		
	}
}

h2,
.headline2  {
	@include font-rem(28px, 1.3);
	font-weight: bold;
	

	@include media-breakpoint-up(lg) {
		@include font-rem(32px, 130%);
		
	}
}

h3,
.headline3  {
	font-weight: bold;
	@include font-rem(24px, 1.5);
	

	@include media-breakpoint-up(lg) {
		@include font-rem(24px, 1.5);
	}
}

h4,
.headline4 {
	font-weight: bold;
	@include font-rem(20px, 1.5);
	

	@include media-breakpoint-up(lg) {
		@include font-rem(15px, 1.5);
		
	}
}

.headline4-subtitle {
	@extend .headline4;
	font-weight: 600;
}

h5,
.headline5  {
	font-weight: bold;
	@include font-rem(18px, 1.5);
	// @include media-breakpoint-up(lg) {
	// 	@include font-rem(18px, 1.3);
	// 	letter-spacing: 1.2px;
	// }
}

.headline5-small {
	@include font-rem(12px, 12px);
}

h6,
.headline6 {
	@include font-rem(15px, 1.5);
	// @include media-breakpoint-up(lg) {
	// 	@include font-rem(12px, 14px);
	// }
}

h1,
.headline1,
.headline1-large,
h2,
.headline2,
h3,
.headline3,
h4,
.headline4,
h5,
.headline5,
h6,
.headline6 {
	color: $black;
	margin-bottom: 0;
	// text-transform: uppercase;
	
	&.center {
		text-align: center;
	}
}

p {
	@include font-rem($font-sm, 1.5);
	font-weight: normal;
	margin-bottom: 5px;
	margin-top: 0;
	.xsm,
	&.xsm {
		@include font-rem($font-xsm, $font-xsm + 6);
		font-weight: normal;
		letter-spacing: 0;
		margin-bottom: 5px;
	}
	.sm,
	&.sm {
		@include font-rem($font-xsm, 1.3);
		font-weight: normal;
		letter-spacing: 0;
		margin-bottom: 5px;
		
		@media (min-width: 768px) {
			@include font-rem(13px, 1.5);
		}
	}
	
	.lg,
	&.lg {
		@include font-rem($font-lg, 1.5);
		font-weight: normal;
		letter-spacing: -.7px;
		margin-bottom: 8px;
	}
	
	.xl,
	&.xl {
		@include font-rem($font-xlg, 1.5);
		font-weight: normal;
		letter-spacing: 1px;
		margin-bottom: 10px;
	}
	
	.subtitle,
	&.subtitle {
		@include font-rem($font-lg, $font-xlg);
		font-weight: 700;
		letter-spacing: 1px;
	}
	
}
strong,
b {
	font-weight: bold;
}

hr {
	border-top: 1px solid $off-white;
	margin: 10px 0;

	&.account {
		background: $pale-gray;
		border: 0;
		height: 2px;
		margin: 30px 0;

		@include media-breakpoint-up(lg) {
			margin: 40px 0;
		}
	}
}

ol,
ul {
	@include font-rem($font-sm, 1.5);
	margin-bottom: 5px;
	margin-top: 0;
	padding-left: 22px;
	
	.xsm {
		@include font-rem($font-xsm, 140%);
		font-weight: 300;
		letter-spacing: 0;
		margin-bottom: 5px;
	}

	&.sm {
		@include font-rem($font-sm, $font-sm + 8);
		margin-bottom: 5px;
		padding-left: 22px;
	}
	
	&.lg {
		@include font-rem($font-lg, $font-lg + 2);
		margin-bottom: 8px;
		padding-left: 22px;
	}
	
	&.xl {
		margin-bottom: 10px;
		padding-left: 24px;
	}

	.xsm,
	&.xsm {
		@include font-rem($font-xsm, 140%);
		font-weight: 300;
		letter-spacing: 0;
		margin-bottom: 5px;
	}

	.sm,
	&.sm {
		@include font-rem($font-sm, $font-sm + 8);
		margin-bottom: 5px;
		padding-left: 16px;
	}
	
	.lg,
	&.lg {
		@include font-rem($font-lg, $font-lg + 2);
		margin-bottom: 8px;
		padding-left: 20px;
	}
	
	.xl,
	&.xl {
		@include font-rem($font-xlg, $font-xlg + 2);
		font-weight: 500;
		letter-spacing: 1px;
	}
	
	li {
		margin-bottom: 5px;
	}
	
}

// Can be applied directly on <a> element
.cta-link {
	@include font-rem(12px, 12px);
	color: $navy;
	font-family: $primary-font;
	font-style: normal;
	font-weight: bold;
	letter-spacing: 1.2px;

	&:hover {
		color: $navy;
		text-decoration: none;
		
		span {
			color: $navy;
			text-decoration: none;
		}
	}
}

// Contact card styling for text component, i.e. below forms
.contact-card {
	border: 1px solid currentColor;
	// sass-lint:disable no-important
	margin-left: 0 !important;
	padding: 1em 1em 1em 5em;
	position: relative;
	width: 30ch;

	span {
		.lg {
			display: block;
			// sass-lint:disable no-important
			margin-bottom: .5em !important;
		}
	}

	&:before {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		content: '\e925';
		font-family: 'icomoon' !important;
		font-size: 3em;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		left: .33em;
		line-height: 1;
		position: absolute;
		speak: none;
		text-transform: none;
		top: .25em;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}
.sm,
small,
.small {
	@include font-rem(12px, 1.5);
	@include media-breakpoint-up(lg) {
		@include font-rem(13px, 1.3);
	}
}
.text-light {
	color: #fff;
}
.in-store {
	font-size: 24px;
	line-height: 1.5;
	h1,
	.headline1 {
		@include font-rem(64px, 1.3);
	}
	h2,
	.headline2 {
		@include font-rem(40px, 1.3);
	}
	h3,
	.headline3 {
		@include font-rem(32px, 1.3);
	}
	.headline1-large {
		@include font-rem(72px, 1.5);
	}
	p,
	ul,
	ol {
		font-size: 24px;
		line-height: 1.5;
	}
	ul,
	ol {
		padding-left: 34px;
	}
	p {
		.sm {
			font-size: 16px;
			line-height: 130%;
			letter-spacing: 0.04em;
		}
		.lg {
			font-size: 20px;
		}
	}
}
[dir="rtl"] {
	ul, 
	ol {
		padding-right: 22px;
		padding-left: 0;
	}
}