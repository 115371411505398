bat-productcard-avalanche {
	.bat-productcard {
		&--avalanche {
			display: flex;
			flex-direction: row;
			height: 100%;
			margin-bottom: 20px;
			max-width: 375px;
			opacity: 0;
			padding: 20px 16px;
			position: relative;
			transition: opacity .2s ease-in;
			width: 100%;
			
			&.loaded {
				opacity: 1;
			}
			
			.bat-quantity {
				justify-content: center;
				margin: 0;
				width: 90px;
			}
			
			.bat-productcard-tile {
				flex: 1 0 105px;
				height: auto;
				margin: 0;
				
				&-image {
					padding-left: 8px;
				}
				
				img {
					height: auto;
					padding-right: 0;
					width: 100%;
				}
			}
			
			.bat-productcard-content {
				display: flex;
				flex-direction: column;
				padding-left: 26px;
				width: auto;
				
				&-cta-list {
					display: flex;
					flex-direction: column;
					margin: auto 0 0 auto;
					width: 100%;
					
					a, 
					button {
						margin: 0;
					}
					
					.bat-cta-style {
						&.arrow-link-dark {
							display: none;
						}
						&.button-dark,
						&.button-light,
						&.button-secondary-dark,
						&.button-secondary-light {
							padding: 11px 0;
						}
					}
					
					button {
						display: none;
						
						&.active {
							display: flex;
						}
					}

					.bat-button {
						padding: 11px 0;
					}
				}
				
				&-description {
					display: block;
					a {
						display: none;
					}
					p {
						@include font-rem($font-sm, $font-sm + 3);
						color: $dark-gray;
						font-weight: 400;
						letter-spacing: -.25px;
						margin-bottom: 15px;
						margin-top: 0;
						text-align: left;
					}
				}
				
				&-label {
					font-size: .75rem;
					font-weight: 700;
					letter-spacing: 0;
					line-height: 1rem;
					margin-bottom: 5px;
					text-transform: uppercase;
				}
				
				&-name {
					font-size: 1.125rem;
					font-weight: 400;
					line-height: 1.375rem;
					margin-bottom: 8px;
					text-align: left;
					text-transform: capitalize;
				}

				&-out-of-stock {
					text-align: left;
				}
				
				&-price {
					@include font-rem(18px, 18px);
					color: $navy;
					font-weight: 700;
					margin: 0 0 11px;
					text-align: left;
					vertical-align: middle;
			
					.slash { /* ----------------------| Render a slash across the price */
						@include font-rem(16px, 16px);
						color: $dark-gray;
						position: relative;
						
						&:before {
							-moz-transform: rotate(-5deg);
							-ms-transform: rotate(-5deg);
							-o-transform: rotate(-5deg);
							-webkit-transform: rotate(-5deg);
							border-color: $dark-gray;
							border-top: 2px solid;
							content: '';
							left: 0;
							position: absolute;
							right: 0;
							top: 50%;
							transform: rotate(-5deg);
						}
					}
				}

				&-quantity {
					display: none;
				}

				&-strengths {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 15px;
					.bat-indicator {
						align-items: center;
						border-top-width: 0;
						flex-direction: column;
						font-family: $primary-font;
						justify-content: center;
						margin: 0;	
						padding: 7px 6px;
						width: 50%;
						&.full-width {
							width: 100%;
						}
						&:nth-child(even) {
							border-left: 0;
						}
						&:nth-child(1),
						&:nth-child(2) {
							border-top-width: 1px;
						}
						span {
							@include font-rem(10px, 10px);
							font-weight: 700;
							margin-bottom: 3px;
						}
					}
				}
			}
		}
	}
}

bat-section-default {
	&.recommended-products {
		bat-productcard-avalanche {
			.bat-productcard--avalanche {
				flex-direction: column;
				margin-bottom: 0;
				padding: 3px;
				&:hover {
					.bat-productcard-content-price {
						display: none;
					}
					.bat-productcard-content-cta-list {
						button {
							&.active {
								display: flex;
							}
						}
					}
				}
				.bat-indicator {
					flex-direction: column;
					margin: 0;
					padding: 7px 6px;
					span {
						@include font-rem(10px, 10px);
						font-weight: 700;
						margin-bottom: 3px;
						margin-right: 0;
					}
				}
				.bat-productcard-content {
					padding: 0;
					
					& > * {
						order: 1;
					}
					&-cta-list {
						border: 0;
						margin: 0;
						padding: 0;
						.arrow-link-dark {
							display: none;
						}
						button {
							display: none;
							max-width: unset;
						}
					}
					&-description {
						display: block;
					}
					&-label {
						display: none;
					}
					&-name {
						font-weight: 700;
						margin-bottom: 5px;
					}
					&-price {
						align-self: flex-start;
						display: inline-flex;
						justify-content: flex-start;
						position: relative;
						&::after {
							color: $navy;
							content: '\e903';
							font-family: 'icomoon';
							font-size: 26px;
							position: absolute;
							right: 0;
							transform: translateX(100%);
						}
					}
					&-quantity {
						@include font-rem(15px, 15px);
						color: $dark-gray;
						display: block;
						margin-bottom: 10px;
					}
					&-strengths {
						order: 0;
					}
					&-out-of-stock {
						margin: 10px 0;
					}
				}
				.bat-productcard-tile {
					margin-bottom: 10px;
					&-image {
						padding: 0;
						text-align: center;
						a {
							display: inline-block;
							width: 75%;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	bat-productcard-avalanche {
		.bat-productcard {			
			&--avalanche {
				flex-direction: column;
				margin-bottom: 20px;
				max-width: 339px;

				.bat-productcard-tile {
					flex: 1 0 100%;
					margin-bottom: 10px;
					text-align: center;
					img {
						padding-right: 0;
						width: 100%;
					}
				}			

				.bat-productcard-content {
					padding-left: 4px;					
					&-cta-list {
						border-top: 1px solid $system-red;
						margin-bottom: 15px;
						padding-bottom: 0;
						padding-top: 15px;
						button {
							display: none;
							
							&.active {
								display: none;
							}
						}
						.bat-cta-style {
							&.arrow-link-dark {
								align-self: flex-start;
								display: inline-flex;
							}
						}
					}			
					&-description {
						margin-top: 8px;
						order: 6;
						p {
							display: none;
						}
					}			
					&-headline {	
						margin-bottom: 15px;
					}
				}

				&:hover {		
					.bat-productcard-content {				
						&-cta-list {
							border-bottom: 1px solid $system-red;
							border-top: 0;
							padding-bottom: 15px;							
							padding-top: 0;
							button {
								&.active {
									display: flex;
								}
							}
							.bat-cta-style {
								&.arrow-link-dark {
									display: none;
								}
							}
						}			
						&-description {
							p {
								display: block;
							}
						}
					}
				}

			}
		}
	}
}

@include media-breakpoint-up(xl) {
	bat-productcard-avalanche {
		.bat-productcard {			
			&--avalanche {
				.bat-productcard-content {
					&-strengths {
						.bat-indicator {
							align-items: center;
							flex-direction: row;
							font-family: $primary-font;
							justify-content: center;
							margin: 0;
							padding: 16px 13px 17px;
							width: 50%;
							span {
								@include font-rem(12px, 12px);
								font-weight: 700;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}
}
