.bat-plp-filter-container {
	display: flex;
	flex-direction: row;
	position: relative;
	width: 100%;
}

.bat-plp-button-wrapper {
	display: flex;
	padding-bottom: 1rem;
	width: 100%;
}

.bat-filter-button {
	[data-parameters='filterButton'] {
		display: none;
		max-width: initial;
		text-transform: initial;
		width: initial;
	}
}

.bat-sort-button {
	margin-left: auto;
	position: relative;
	[data-parameters='sortButton'] {
		max-width: initial;
		text-transform: initial;
		width: initial;
	}
}

.bat-sort-select {
	background: $white;
	border-bottom: 1px solid $black;
	border-left: 1px solid $black;
	border-right: 1px solid $black;
	display: none;
	position: absolute;
	top: 0;
	z-index: 9999;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			margin: 0;
			padding: .5rem .75rem;
			button {
				height: 100%;
				text-align: left;
				width: 100%;
			}
		}
	}
}

.bat-filter {
	background-color: $off-white;
	display: none;
	height: max-content;
	transition: 500ms;
	&-wrapper {
		height: 100%;
		overflow-y: auto;
		padding: 2rem;
		width: 100%;
	}
	&-closebutton {
		position: absolute;
		right: .5rem;
		top: .5rem;
		.button-dark {
			align-items: center;
			border-radius: 100%;
			border-width: 2px;
			display: flex;
			height: 25px;
			justify-content: center;
			padding: .25rem;
			width: 25px;
		}
	}
	&-collapse-container {
		margin-top: 2rem;
		.group-title {
			border-bottom: 1px solid $outline;
			display: flex;
			justify-content: space-between;
			padding: 1rem;
		}
		.group-reset {
			display: flex;
			padding: 1rem 0;
			.reset-button {
				margin-left: auto;
				text-transform: initial;
			}
		}
	}
	&-collapse {
		align-items: center;
		display: flex;
		justify-content: flex-start;
		padding: 1rem 2rem;
		.title {
			font-weight: bold;
		}
		.clear-button {
			margin-left: auto;
			padding: .5rem;
		}
		.fa {
			padding: .5rem;
		}
	}
	&-collapse-items {
		border-bottom: 1px solid $outline;
		display: none;
		padding: 0 2rem 2rem;
		ul {
			margin: 0;
			padding: 0;
			li {
				font-size: .75rem;
				line-height: 1;
				list-style-type: none;
				margin: 0;
				margin-bottom: 1rem;
				padding: 0;
			}
		}
	}
	&-applybutton {
		padding: 2rem;
	}
}

.bat-price-slider {
	min-height: 7rem;
	width: 100%;
	&-output {
		display: flex;
		justify-content: space-between;	
		label {
			display: flex;
			flex-direction: column;
			input {
				display: inline;
				width: 5rem;
			}
		}
	}
	&-range {
		padding: 2rem;
		position: relative;
		input {
			box-shadow: 0;
			outline: 0;
			&[type='range'] {
				-webkit-appearance: none;
				left: 0;
				margin: 0;
				position: absolute;
				width: 100%;
				&:focus {
					outline: none;
					&::-webkit-slider-runnable-track {
						background: $gray;
					}
					&::-ms-fill-lower {
						background: $gray;
					}
					&::-ms-fill-upper {
						background: $gray;
					}
				}
				&::-webkit-slider-runnable-track {
					background: $gray;
					border: 0;
					border-radius: 1px;
					box-shadow: none;
					cursor: pointer;
					height: 5px;
					width: 100%;
				}
				&::-webkit-slider-thumb {
					-webkit-appearance: none;
					background: $outline;
					border-radius: 25px;
					cursor: pointer;
					height: 18px;
					margin-top: -7px;
					position: relative;
					width: 18px;
					z-index: 2;
				}
				&::-moz-range-track {
					background: $gray;
					border: 0;
					border-radius: 1px;
					box-shadow: none;
					cursor: pointer;
					height: 5px;
					width: 100%;
				}
				&::-moz-range-thumb {
					background: $outline;
					border-radius: 25px;
					cursor: pointer;
					height: 18px;
					position: relative;
					width: 18px;
					z-index: 2;
				}
				&::-ms-track {
					background: transparent;
					border-color: transparent;
					color: transparent;
					cursor: pointer;
					height: 5px;
					width: 100%;
				}
				&::-ms-fill-lower,
				&:-ms-fill-upper {
					background: $gray;
					border: 0;
					border-radius: 1px;
					box-shadow: none;
				}
				&::-ms-thumb {
					background: $outline;
					border-radius: 25px;
					cursor: pointer;
					height: 18px;
					position: relative;
					width: 18px;
					z-index: 2;
				}
			}
		}
	}
}

.bat-plp-slot {
	width: 100%;
}
