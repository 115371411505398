/* grid component */
.aem-Grid {
	display: flex;
	flex-wrap: wrap;
	flex-basis: 100%;
	width: 100%;
}

/* placeholder for new components */
.aem-Grid-newComponent {
	margin: 0;
	width: 100% !important;
}

/* column of a grid */
.aem-GridColumn {
	box-sizing: border-box;
}

/* force showing hidden */
.aem-GridShowHidden > .aem-Grid > .aem-GridColumn {
	display: block !important;
}

.aem-Grid-Container {
	
	&--xxl {
		max-width: $max-width-xxl;
		margin: 0 auto;
	}
	
	&--xl {
		max-width: $max-width-xl;
		margin: 0 auto;
	}
	
	&--lg {
		max-width: $max-width-lg;
		margin: 0 auto;
	}
	
	&--md {
		max-width: $max-width-md;
		margin: 0 auto;
	}
	
	&--sm {
		max-width: $max-width-sm;
		margin: 0 auto;
	}
}

