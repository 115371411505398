.exclusive-hero {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
    &:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding-top: 18.93333%;
			background-image: url("/content/dam/gtr/images/webp-gtr/bg-foot-airport-registration.webp");
			background-size: cover;
			background-position: bottom right;
			background-repeat: no-repeat;
		}
    &--content {
      padding: 40px 18px 0;
  
      &-headline {
        letter-spacing: 0.04em;
  
        .bat-headline-style {
          text-transform: unset;
          margin-bottom: 10px;
          font-size: 32px;
        }
  
        &.light {
          &,
          .bat-headline-style {
            color: #fff;
          }
        }
      }
      .exclusive-sub-title {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.04em;
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.04em;
        }
      }
      &-text {
        background: #fff;
        padding: 70px 40px (70 / 375 * 100%);
        
        &-bottom {
          letter-spacing: 0.04em;
          padding-bottom: 40px;
        }
      }
  
      &-cta-list {
        margin-top: 30px;
      }
    }
    .exclusive-hero--content-text h2,
    &--code {
      font-size: 36px;
      font-weight: bold;
      margin-top: 28px;
      color: #0047B8;
    }
  }
  
  .exclusive-card-block {
    text-align: center;
    padding: 40px 15px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.15) 13.24%, rgba(0, 0, 0, 0) 71.91%), #F3F3F3;
    position: relative;
    margin-top: -20%;
  
    &:before {
      content: '';
      background: url('/content/dam/gtr/images/webp-exclusive-offer/bg-divider.webp');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      padding-top: 20%;
      bottom: 100%;
      position: absolute;
      left: 0;
    }
  
    .text {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 25px;
    }
  }