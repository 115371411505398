.batbreadcrumb  {
	.bat-batbreadcrumb-list {
		text-align: center;			
		@include media-breakpoint-up('md') {
			text-align: left;
		}
		.bat-batbreadcrumb-item {
			display: inline-block;
			list-style: none;
			
			&::after {
				content: '>';
				padding: 0 .5rem;
			}
			&:last-child {
				&::after {
					content: none;
				}
			}
		}
	}
}
