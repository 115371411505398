//BACKGROUND COLORS
.bg-gray-dark {
	background: $dark-90;
}

.bg-gray-dark-50 {
	background: $dark-50;
}

.bg-gray-medium {
	background: $gray;
}

.bg-gray-light {
	background: $dark-10;
}

.bg-black {
	background: $black;
}

.bg-white {
	background: $white;
}

.bg-red {
	background: $red;
}

.bg-green {
	background: $green;
}
