@include media-breakpoint-up(md) {
  .in-store-exclusive-content {
    background-image: url(/content/dam/gtr/images/webp-in-store/bg-content.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 60px;

    .bat-section-content-style {
      background: linear-gradient(359.91deg, rgba(0, 0, 0, 0.1) -0.47%, rgba(0, 0, 0, 0) 10.06%), linear-gradient(180deg, rgba(255, 255, 255, 0) 1.06%, rgba(215, 219, 230, 0.72) 100%), #FFFFFF;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 80%;
      max-width: 1350px;
      margin: 0 auto;
      padding: 170px 0 60px;
      display: flex;
      flex-direction: column;
      min-height: 700px;

      .contentwrapper {
        max-width: 580px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        [data-component-name="contentWrapper"] {
         &, & > div, .aem-Grid {
           height: 100%;
         }
       }

        .aem-Grid {
          flex-direction: column;

          > .text:last-child {
            margin-top: auto;
          }

          > .headline {
            margin-bottom: 86px;
          }
        }
      }
    }

    .offer-label,
    .offer-code {
      color: $system-blue;
    }

    .offer-label {
      @include font-rem(20px, 1.5);
      margin-top: 37px;

      p {
        font-size: 20px;
      }
    }

    .offer-code {
      margin-bottom: 166px;

      p {
        @include font-rem(48px, 1.3);
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    .text.section {
      text-align: center;
      color: #000;
    }

    .exclusive-desc-bot {
      h2 {
        color: $system-blue;
        text-transform: uppercase;
      }
    }

    .image.section {
      text-align: center;
      display: flex;
      flex-direction: column;

      small {
        color: $system-blue;
        order: -1;
        font-size: 16px;
        margin-bottom: 10px;
      }

      img {
        width: auto;
        max-width: 100%;
      }
    }
    &.in-store {
      .bat-section {
        height: 100%;
        display: flex;
      }
    }
  }
}