// For AEM
.expander {
	
	bat-expander-default {
		
		.bat-expander {
			border-top: solid 1px $gray;
		}
	}
	
	~ .expander {
		
		bat-expander-default {
		
			.bat-expander {
				border-top: 0;
			}
		}
		
	}
}

bat-expander-default {
	
	.bat-expander {
		border-top: solid 1px $gray;
	}
	
	~ bat-expander-default {
		
		.bat-expander {
			border-top: 0;
		}
	}
}

.bat-expander {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: $max-width-xxl;
	
	&-button {
		align-items: flex-start;
		border-bottom: solid 1px $gray;
		display: flex;
		justify-content: flex-start;
		padding: 10px;
		width: 100%;
		
		&-label {
			display: inline-block;
			
		}
		
		&-icon {
			margin-left: auto;
			
			&-close,
			&-open {
				
				i {
					height: 20px;
					margin: 0 0 0 auto;
					width: 20px;
				}
			}
			
			&-close {
				display: block;
			}
			
			&-open {
				display: none;
			}
		}
		
		.active & {
			
			&-icon {
				
				&-close {
					display: none;
				}
				
				&-open {
					display: block;
				}
			}
		}
		
		.icon-alignment-left & {
			
			&-label {
				order: 2;
			}
			
			&-icon {
				margin: 0 20px 0 0;
				order: 1;
			}
		}
		
		.below & {
			order: 2;
		}
	}
	
	&-content {
		height: 0;
		overflow: hidden;
		
		.active & {
			border-bottom: solid 1px $gray;
			height: auto;
		}
		
		.authorEditMode & { // sass-lint:disable-line class-name-format
			border-bottom: solid 1px $gray;
			height: auto;
		}
		
		& > div {
			padding: 20px 0;
		}
		
		.below & {
			order: 1;
		}
	}
}
